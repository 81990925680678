import { PUT_MERCHANT_FEES, RESET_PUT_MERCHANT_FEES } from '../actions/merchantFees';
import { setupRollbar } from '../helpers/rollbar_helper';

export default function(state = [], action) {
	switch(action.type) {
		case PUT_MERCHANT_FEES:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return action.payload.status;
		case RESET_PUT_MERCHANT_FEES: 
			return action.payload;
		default:
			return state;
	}

}