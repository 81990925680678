import {PUT_CURRENCY, RESET_CURRENCY} from '../actions/currencies';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case PUT_CURRENCY:
			let response = 'Some error occured while editing currencies, please try again';
			if(action.error) {
				if(action.payload.response?.data) {
					response = action.payload.response.data.message;
				}
				setupRollbar(action);
				return {type: 'error', response: response}
			}
			return action.payload.data;
		case RESET_CURRENCY:
			return action.payload;
		default:
			return state;
	}

}