import {POST_API_KEY, RESET_API_KEY} from '../actions/apiKey';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case POST_API_KEY:
			let response = 'Some error occured while creating api key, please try again';
			if(action.payload.response) {
				if(action.payload.response?.data?.includes('ApiKeyNameExists')) {
					response = 'This api key name already exists';
				}
			}
			if(action.error) {
				setupRollbar(action);
				return {type: 'error', response: response}
			}
			return action.payload.data;
		case RESET_API_KEY:
			return action.payload;
		default:
			return state;
	}

}