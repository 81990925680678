import Form from 'react-validation/build/form';
import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import Selectize from '../ui_elements/selectize';

const Pagination = (props) => {
    return (
        <div className={props.classNames === 'noBorder' ? 'pagination-content clearfix no-border' : 'pagination-content clearfix ' + props.classNames}>
            {props.hideResult === false ? (
                <p className='pull-left pages-summary'>Page {props.initialPage + 1}/{props.pages} - {props.all} results</p>
            ):(
                <p className='pull-left pages-summary'>0 results</p>
            )}
            <div className='nav'>
                <nav className='text-center'>  
                    <ReactPaginate 
                        activeClassName='active' 
                        breakClassName='page-item break' 
                        containerClassName='pagination' 
                        forcePage={props.initialPage} 
                        initialPage={0} 
                        marginPagesDisplayed={1} 
                        nextClassName='pega-item' 
                        nextLabel='>' 
                        nextLinkClassName='page-link' 
                        onPageChange={props.handlePageChange} 
                        pageClassName='page-item' 
                        pageCount={props.pageCount} 
                        pageLinkClassName='page-link' 
                        pageRangeDisplayed={9}  
                        previousClassName='page-item' 
                        previousLabel='<' 
                        previousLinkClassName='page-link' 
                    />
                </nav>
            </div>
            {props.hideResult === false ? (
                <Form onSubmit={props.handleSubmit}>
                    {props.itemsPerPage !== false ? (
                        <div className='pull-right pages-number'>
                            <p className='clearfix pull-left'>
                                <span>Items per page:</span>
                            </p>
                            <Selectize 
                                dataOptions={[16,24,32,48,64,72,128]} 
                                isAsync={true} 
                                onChange={props.handleSelectChange} 
                                selected={props.perPage} 
                                thisId='paginationCount' 
                            />
                        </div>
                     ) : (
                        <div className='pull-right'></div>
                    )}
                </Form>
            ) : (
                <div></div>
            )}
        </div>
    )
}

Pagination.propTypes = {
    all: PropTypes.number.isRequired,
    classNames: PropTypes.string,
    disabledNav: PropTypes.bool,
    handlePageChange: PropTypes.func.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    hideResult: PropTypes.bool.isRequired,
    initialPage: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired
}

export default Pagination;