import {GET_VIEW} from '../actions/currentView';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = '', action) {
	switch(action.type) {
		case GET_VIEW:
			return action.payload;
		default:
			return state;
	}

}