import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { connect } from 'react-redux'
import { initAlert } from 'helpers/alert_helper'
import Breadcrumbs from 'components/ui_elements/breadcrumbs'
import LayoutContainer from 'components/layout/layout_container';
import CurrenciesTable from 'components/currencies/currencies_table'
import CurrencyModal from 'components/currencies/currency_modal'
import { secureStorage } from 'helpers/secure_storage_helper'
import { getCurrencies } from 'actions/currencies'
import _ from 'lodash'

const Currencies = () => {
    const dispatch = useDispatch();
    const currencies = useSelector(state => state.currencies);
    const isAdmin = useSelector(state => state.isAdmin);
    const [editCurrency, setEditCurrency] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [offset, setOffset] = useState(0);

    useEffect(() => {
        document.title = "Currenices - Bitcoin Payment Processor";
        if(_.isEmpty(currencies)) {
            dispatch(getCurrencies());
        }
    }, []);

    const currencySubmit = (data, editing) => {
        if(data.type === 'error') {
            let resp = data.response
            if(resp.includes('=>')) {
                resp = resp.substring(resp.indexOf('=>') + 3).replace(')', '');
            }
            initAlert({
                type: 'error', 
                title: 'Error', 
                html:resp, 
                confirmText: 'OK'
            });
        } else {
            let text = 'created new currency';
            if(editing) {
                text = 'updated currency';
            }
            initAlert({
                type: 'success', 
                title: 'Success', 
                html: 
                    'You have successfully ' + text +
                    '<br />' +
                    '<b>' + data.code + '</b>' + 
                    '<span> - ' + data.name + '</span>',
                confirmText: 'OK'
            });
            dispatch(getCurrencies());
            setOffset(0);
        }
    }
    const showModalFunc = (isEdit, el) => {
        if(isEdit) {
            setEditCurrency(el);
            setShowModal(true);

        } else {
            setEditCurrency('');
            setShowModal(true);
        }
        
    }
    const modalClosed = () => {
        setShowModal(false);
    }
    return (
        <LayoutContainer active='currencies' >
            <Breadcrumbs dataPath={{0: ['Home', '/'], 1: ['Currencies', '']}}/>
            <div className='row'>
                <div className='col-md-12'>
                    <h2 className='pull-left mrg-btm-20'>Currencies</h2>  
                    {(isAdmin) &&
                        <button className='btn btn-primary pull-right btn-long mrg-btm-12' onClick={() => showModalFunc(false)} >New currency <i className='fa fa-plus-circle'></i></button>
                    }
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <CurrenciesTable currencies={currencies} showModal={showModalFunc} />
                    </div>
                </div>
            </div>
            {showModal === true &&
                <CurrencyModal
                    editCurrency={editCurrency} 
                    onModalClosed={modalClosed} 
                    formSent={currencySubmit} 
                    showOnInit={true} />
            }
        </LayoutContainer>
    )
    
}

export default Currencies;
