import { setForbidden } from 'actions/setForbidden'
import axios from 'axios'
import { ENV, store } from 'index'

let axiosInstance

const initAxiosInstance = () => {
	axiosInstance = axios.create({
		baseURL: `${ENV.API_URL}`,
	})
	axiosInstance.interceptors.response.use(
		(response) => response,
		(error) => {
			if (error.response && error.response.status === 403) {
				store.dispatch(setForbidden(true));
			}
			return Promise.reject(error);
		}
	)
}

export { axiosInstance as axios, initAxiosInstance }