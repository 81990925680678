import React from 'react';
import loader from '../../images/loader.gif';
import PropTypes from 'prop-types';

const Loading = (props) => {
    return (
        <div className={'loader '+ (props.noMargin === true ? '' : 'mrg-vertical-15 mrg-horizon-15')}>
            <img src={loader} alt='Loading...' />
        </div>
    )
}

Loading.propTypes = {
    noMargin: PropTypes.bool
}

export default Loading
