import {setupRollbar} from '../helpers/rollbar_helper';
import {RESEND_PAYOUT_NOTIFICATION, RESET_RESEND_PAYOUT_NOTIFICATION} from "../actions/resendPayoutNotification";

export default function(state = {}, action) {
	switch(action.type) {
		case RESEND_PAYOUT_NOTIFICATION:
			if(action.error) {
				setupRollbar(action);
				return {data: 'error', status: 500}
			}
			return {data: action.payload.data, status: action.payload.status};
		case RESET_RESEND_PAYOUT_NOTIFICATION:
			return {}
		default:
			return state;
	}

}