import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Loading from 'components/ui_elements/loading'



const CurrenciesTable = (props) => {
    return (
        <div className='overflow-y-auto relative scrollable'>
            <table className='table table-lg table-hover table-bordered-top'>
                <thead>
                    <tr>
                        <th>
                            Currency
                        </th>
                        <th>
                            Name
                        </th>
                        <th>
                            Type
                        </th>
                        <th>
                            Blockchain
                        </th>
                        <th>
                            Precision
                        </th>
                        <th>
                            Payments transfer
                        </th>
                        <th>
                            Payouts transfer
                        </th>
                        <th>
                            Payments price
                        </th>
                        <th>
                            Payouts price
                        </th>
                        <th>
                            Edit
                        </th>
                    </tr>
                </thead>
                {_.isEmpty(props.currencies) ? (
                    <tbody>
                        <tr>
                            <td colSpan='6'>
                                <Loading />
                            </td>
                        </tr>
                    </tbody>
                ) : (
                    <tbody>
                        {props.currencies.sort(function(a, b){return a.currencyType === 'crypto' ? -1 : b.currencyType === 'crypto' ? 1 : 0}).map(function(val,i) {
                            return (
                                [
                                    <tr key={i}>
                                        <td className='v-align'>
                                            <div>
                                                <h5 style={{fontWeight: 'bold'}}>{val.code}</h5>
                                            </div>
                                        </td>
                                        <td className='v-align'>
                                            <div>
                                                <span>{val.name}</span>
                                            </div>
                                        </td>
                                        <td className='v-align'>
                                            <div>
                                                <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{val.currencyType}</span>
                                            </div>
                                        </td>
                                        <td className='v-align'>
                                            <div>
                                                <span>{val.blockchain}</span>
                                            </div>
                                        </td>
                                        <td className='v-align'>
                                            <div>
                                                <span>{val.precision}</span>
                                            </div>
                                        </td>
                                        <td className='v-align'>
                                            <div>
                                                <span>{val.paymentTransferEnabled ? 
                                                    <i className='fa fa-check text-success'></i> : ' '
                                                }</span>
                                            </div>
                                        </td>
                                        <td className='v-align'>
                                            <div>
                                                <span>{val.payoutTransferEnabled ? 
                                                    <i className='fa fa-check text-success'></i> : ' '
                                                }</span>
                                            </div>
                                        </td>
                                        <td className='v-align'>
                                            <div>
                                                <span>{val.paymentPriceEnabled ? 
                                                    <i className='fa fa-check text-success'></i> : ' '
                                                }</span>
                                            </div>
                                        </td>
                                        <td className='v-align'>
                                            <div>
                                                <span>{val.payoutPriceEnabled ? 
                                                    <i className='fa fa-check text-success'></i> : ' '
                                                }</span>
                                            </div>
                                        </td>
                                        <td>
                                            <button className='mrg-top-10 mrg-btm-5 btn btn-default btn-sm' data-toggle='modal' onClick={() => props.showModal(true,val)} >Edit</button>
                                        </td>
                                    </tr>
                                ]
                            )
                        })}
                    </tbody>
                )}
            </table>
        </div>
    )
}

CurrenciesTable.propTypes = {
    currencies: PropTypes.array.isRequired,
    showModal: PropTypes.func.isRequired
}

export default CurrenciesTable;