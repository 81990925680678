import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Button from 'react-validation/build/button'
import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import $ from 'jquery'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import Loading from 'components/ui_elements/loading'
import { postMerchant } from 'actions/merchant'
import { updateInputStateHook, handleModalFormSubmitHook } from 'helpers/form_helper'
import ModalContainer from 'components/ui_elements/modal_container'
import Selectize from 'components/ui_elements/selectize'
import { feeDecimal } from 'helpers/fees_helper'
import { required, textName, maxlength, newName}  from 'helpers/validator_helper'

const NewMerchantModal = ({formSent, onModalClosed}) => {
    const dispatch = useDispatch();
    const newMerchant = useSelector(state => state.newMerchant);
    const merchantsNames = useSelector(state => state.merchantsNames);
    const account = useSelector(state => state.account);
    const [frequencyShown, setFrequencyShown] = useState(true);
    const [disabledForm, setDisabledForm] = useState(false);
    const [formValues, setFormValues] = useState({
        name: ''
    });

    useEffect(() => {
         if(frequencyShown === false) {
            setFrequencyShown(true);
        }
    }, [frequencyShown]);

    useEffect(() => {
        if(!_.isEmpty(newMerchant)) {
            formSent(newMerchant, formValues.name);
            $('#merchantModal').modal('hide');
        }
    }, [newMerchant]);

    const handleChange = (event) => {
        if(event.target.value) {
            setFormValues({...formValues,  [event.target.id]: event.target.value });
        }
    }
    const selectMounted = (el, id) => {
        setFormValues({
            ...formValues,
            [id]: el.value
        })
    }
    const handleSelectChange = (val, e) => {
        setFormValues({ ...formValues, [e]: val});
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        handleModalFormSubmitHook({
            event: event, 
            setDisabledForm: setDisabledForm,  
            sendFunction: () => dispatch(postMerchant({formValues}))
        });
    }
    const handleModalHide = () => {
        onModalClosed();
    }
    if(_.isEmpty(account)) {
        return <Loading />
    }
    return (
    	<ModalContainer classNames='modal-md' thisId='merchantModal' title='New merchant' onHide={handleModalHide} showInit={true} >
            <Form onSubmit={handleSubmit} id='merchantModalForm' className='basic-form'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='name'>Name: *</label>
                            <Input type='text' className='form-control' id='name' data-name='name' names={merchantsNames} data-maxlength='24' validations={[required, textName, maxlength, newName]}  value={formValues.name} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 text-right small'>
                        <p>* - Field is required</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button type='button' className='btn btn-default' data-dismiss='modal'>Cancel</button>
                        {disabledForm === true ? (
                            <button className='btn btn-primary pull-right disabled' type='submit' disabled>Create merchant</button>
                        ) : (
                            <Button className='btn btn-primary pull-right' type='submit' >Create merchant</Button>
                        )}
                    </div>
                </div>
            </Form>
        </ModalContainer>
    )
}

NewMerchantModal.propTypes = {
    formSent: PropTypes.func.isRequired,
    onModalClosed: PropTypes.func.isRequired
}

export default NewMerchantModal;