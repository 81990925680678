import swal from 'sweetalert2'; 
import 'sweetalert2/dist/sweetalert2.min.css';

export function initAlert({type, title, html, confirmText, cancelText, onSuccess, onDismiss, isShownCancelButton, classNames, errorCallback}) {
      if(type === 'warning' || type === 'info') {
        new swal({
            icon: type,
            title: title,
            html: html,
            type: type,
            showCancelButton: isShownCancelButton === false ? false : true,
            confirmButtonColor: '#1a3b59',
            cancelButtonColor: '#ffffff',
            cancelButtonText: cancelText || 'Cancel',
            confirmButtonText: confirmText,
            confirmButtonClass: classNames,
            reverseButtons: true,
            allowOutsideClick: false,
            closeOnConfirm: false,
            target: 'body'
        }).then(function(result) {
            if(result.isDismissed) {
                onDismiss();
            } if(result.isConfirmed) {
                onSuccess();
            }
        }, function(dismiss) {
            onDismiss();
        });
      } else {
        if(errorCallback) {
            new swal({
                icon: type,
                title: title,
                html: html,
                type: type,
                confirmButtonColor: '#1a3b59',
                confirmButtonClass: 'btn-long ' + classNames,
                showCancelButton: false,
                reverseButtons: true,
                allowOutsideClick: true,
                closeOnConfirm: false,
                target: 'body'
            }).then(function(result) {
                onSuccess();
            });
        } else {
            new swal({
                icon: type,
                title: title,
                html: html,
                type: type,
                confirmButtonColor: '#1a3b59',
                confirmButtonClass: 'btn-long ' + classNames,
                showCancelButton: false,
                reverseButtons: true,
                allowOutsideClick: true,
                closeOnConfirm: false,
                target: 'body'
            });
        }
        
      }
    
}

export function displaySuccessErrorMessage() {
    
}