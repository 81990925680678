import {GET_MERCHANTS_PAGINATED, RESET_MERCHANTS_PAGINATED} from '../actions/merchantsPaginated';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_MERCHANTS_PAGINATED:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			let data = action.payload.data;
			return {data: data, status: action.payload.status, meta: action.meta}
		case RESET_MERCHANTS_PAGINATED:
			return action.payload
		default:
			return state;
	}

}