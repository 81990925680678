import {GET_MERCHANTS_LIST} from '../actions/merchantsList';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_MERCHANTS_LIST:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			if(action.payload.data) {
				let resp = action.payload.data.map(function(val, i) {
					return {label: val.merchant.name, value: val.merchant.id}
				});
				return resp;
			} else {
				return [{label:'', value:''}]
			}
		default:
			return state;
	}

}