import { axios } from 'axiosInstance'
import { ENV } from "index" 
import {getHeader} from '../helpers/headers_helper'
 
export const POST_BALANCE_ADJUSTMENT = 'POST_BALANCE_ADJUSTMENT'; 
export const RESET_BALANCE_ADJUSTMENT = 'RESET_BALANCE_ADJUSTMENT';

export function postBalanceAdjustment({merchantId, amount, currency, adminComment, merchantComment, asOfDate}) {
    let data = {
        'merchantId': merchantId,
        'amount': amount,
        'currency': currency,
        'adminComment': adminComment,
        'merchantComment': merchantComment,
        'asOfDate': asOfDate
    };
    const dataPayload = axios.post(`${ENV.API_URL}/analytics/adjustments`, data, {
        headers: getHeader()
    })
    return {
        type: POST_BALANCE_ADJUSTMENT,
        payload: dataPayload
    }
}
export function resetBalanceAdjustment() {
    const dataPayload = '';
    return {
        type: RESET_BALANCE_ADJUSTMENT,
        payload: dataPayload
    }
}

