import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Loading from '../ui_elements/loading';
import MerchantAccessModalContainer from '../modals/merchant_access_modal_container';
import {secureStorage} from '../../helpers/secure_storage_helper';
import {encodeBasicUrl} from '../../helpers/helpers';
import { ADMIN_PREFIX } from 'App';

const UsersTable= (props) => {
    let users = '';
    if(!_.isEmpty(props.usersList)) {
        users = props.usersList.map(function(val,i) {
            let isEnabled = val.enabled === true ? 'enabled' : 'disabled';
            return (
                <tr key={i}>
                    <td className='v-align'>
                        <div>
                            <span>{val.email}</span>
                        </div>
                    </td>
                    {props.isMerchant &&
                        <td className='v-align'>
                            <div>
                                <span>Merchant {val.availableMerchants[0].role}</span>
                            </div>
                        </td>
                    }
                    {props.isAdmin &&
                        <td className='v-align'>
                            <div>
                                {val.availableMerchants && val.availableMerchants.length ? (
                                    <div>
                                        {val.availableMerchants.map(function(merch, ind){
                                            return <div><span key={ind}><Link to={'/' + ADMIN_PREFIX + '/merchants/' + encodeBasicUrl(merch.id)}>{merch.name}</Link><span> (Merchant {merch.role})</span>{(ind + 1) < val.availableMerchants.length ? ',  ' : ''}</span><br /></div>
                                        })}
                                    </div>

                                ) : (
                                    <div>-</div>
                                )}
                            </div>
                        </td>
                    }
                    <td className='v-align'>
                        {val.isAdmin ? (
                            <span>---</span>
                        ) : (
                            <div>
                                {val.approvedTermsAndConditions ? (
                                    <span className='mrg-left-10'>
                                        <i className="fa fa-check text-success mrg-right-5"></i> approved
                                    </span>
                                ) : (
                                    <span>
                                    </span>
                                )}
                            </div>
                        )}
                    </td>
                    <td className='v-align'>
                        {val.isAdmin ? (
                            <span>ADMIN</span>
                        ) : (
                            <div className='relative'>
                                <span className={"status " + (val.enabled ? 'online' : '')}> </span>
                                <span className='pdd-left-20'>{isEnabled}</span>
                            </div>
                        )}
                    </td>
                    {props.isAdmin &&
                        <td>
                            {(val.email !== secureStorage.getItem('userEmail')) || !val.isAdmin ? (
                                <div className='toggle-checkbox toggle-success toggle-sm checkbox-inline mrg-top-15'>
                                    <input type='checkbox' name={'toggle' + i} id={'toggle' + i} checked={val.enabled} onChange={(event) => props.onUserSwitch(event, val.id, val.email)} />
                                    <label htmlFor={'toggle' + i}></label>
                                </div>
                            ) : (
                                <div className='mrg-btm-45'>
                                </div>
                            )}
                        </td>
                    }
                    {(props.isAdmin || props.isMerchantAdmin) &&
                        <td className='v-align'>
                            {props.userEmail !== val.email &&
                                <button className='btn btn-default btn-table no-mrg-btm' onClick={() => props.showModalMerchantAccess(val.id, val.email, val.availableMerchants)}>Edit</button>
                            }
                        </td>
                    }
                </tr>
            )
        });
    }
    return (
        <div className='overflow-y-auto relative scrollable'>
            <table className='table table-hover table-bordered-top'>
                <thead>
                    <tr>
                        <th>
                            User email
                        </th>
                        {props.isMerchant &&
                            <th>
                                User role
                            </th>
                        }
                        {props.isAdmin &&
                            <th>
                                User merchants
                            </th>
                        }
                        <th className='min-width-170'>
                            Terms & Conditions
                        </th>
                        <th className='min-width-170'>
                            Status
                        </th>
                        {props.isAdmin &&
                            <th className='min-width-170'>
                                Disable / enable
                            </th>
                        }
                        {(props.isAdmin || props.isMerchantAdmin) &&
                            <th>
                                Edit user
                            </th>
                        }
                    </tr>
                </thead>
                {_.isEmpty(props.users) ? (
                    <tbody>
                        <tr>
                            <td colSpan='4'>
                                <Loading />
                            </td>
                        </tr>
                    </tbody>
                ) : (
                    <tbody>
                        {props.users.status === 200 && _.isEmpty(props.usersList) ? (
                            <tr>
                                <td colSpan='4'>
                                    <h4 className="text-center mrg-top-10 mrg-btm-10">No users.</h4>
                                </td>
                            </tr>
                        ) : (
                              users 
                        )}
                    </tbody>
                )}
            </table>
            {props.showModalMerchantAccessState === true && (props.isAdmin || props.isMerchantAdmin) &&
                <MerchantAccessModalContainer 
                    formSent={props.formSent} 
                    formSentDeleted={props.formSentDeleted}
                    formSentUpdated={props.formSentUpdated}
                    isAdmin={props.isAdmin}
                    isMerchant={props.isMerchant}
                    isMerchantAdmin={props.isMerchantAdmin}
                    onModalClosed={props.modalClosedMerchantAccess} 
                    showOnInit={true} 
                    userId={props.userId} 
                    userMerchants={props.userMerchants}
                    userName={props.userName}
                />
            }
        </div>
    )
}

UsersTable.propTypes = {
    formSent: PropTypes.func.isRequired,
    formSentDeleted: PropTypes.func.isRequired,
    formSentUpdated: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isMerchant: PropTypes.bool.isRequired,
    isMerchantAdmin: PropTypes.bool.isRequired,
    modalClosedMerchantAccess: PropTypes.func.isRequired,
    onUserSwitch: PropTypes.func.isRequired,
    showModalMerchantAccess: PropTypes.func.isRequired,
    showModalMerchantAccessState: PropTypes.bool.isRequired,
    userEmail: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    userMerchants: PropTypes.array.isRequired,
    userName: PropTypes.string.isRequired,
    users: PropTypes.object.isRequired,
    usersList: PropTypes.array.isRequired
}

export default UsersTable;