import SecureStorage from 'secure-web-storage';
import CryptoJS from 'crypto-js';
import { ENV } from 'index';

let secureStorage;

const initSecureStorage = () => {
    let KEY = ENV.CLIENT_ID;

    secureStorage = new SecureStorage(localStorage, {
        hash: function hash(key) {
            key = CryptoJS.SHA256(key, KEY);
            return key.toString();
        },
        encrypt: function encrypt(data) {
            data = CryptoJS.AES.encrypt(data, KEY);
            data = data.toString();
            return data;
        },
        decrypt: function decrypt(data) {
            data = CryptoJS.AES.decrypt(data, KEY);
            data = data.toString(CryptoJS.enc.Utf8);
            return data;
        }
    });
};

export { secureStorage, initSecureStorage }