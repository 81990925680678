import React from 'react';
import PropTypes from 'prop-types';

const Export = (props) => {
  let btnClass = 'btn btn-default pull-right dropdown-toggle';
  return (
    <div className='clearfix export'>
        <button 
            aria-expanded="false" 
            aria-haspopup="true" 
            className={props.classNames ? btnClass + ' ' + props.classNames : btnClass} 
            data-toggle="dropdown" 
            disabled={props.disabled}
            id={props.id} 
        >{props.name} <i className="ti-angle-down"></i>
        </button>
        <div className="dropdown-menu" aria-labelledby="{props.id}">
            {props.formats.map((format) => {
                return <a href='#' data-value={format} key={format} onClick={(e) => {props.onSelected(e.target, props.statement)}}>{format}</a>
            })}
        </div>
     </div>
  )
}

Export.propTypes = {
    classNames: PropTypes.string,
    disabled: PropTypes.bool,
    formats: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onSelected: PropTypes.func.isRequired,
    statement: PropTypes.string
}

export default Export;