import $ from 'jquery';
import _ from 'lodash';
import {secureStorage} from './secure_storage_helper';
import {isoToDate} from './date_helper';

export function columnsCollapse() {
    $('.trHidden').on('show.bs.collapse', function() {
        $('.collapsible-body').find('.collapse.show').collapse('hide');
        $(this).addClass('active').prev().addClass('active').find('[data-toggle="collapse"]').addClass('active').find('i').addClass('ti-angle-up').removeClass('ti-angle-down');
    }).on('hide.bs.collapse', function() {
        $(this).removeClass('active').prev().removeClass('active').find('[data-toggle="collapse"]').removeClass('active').find('i').addClass('ti-angle-down').removeClass('ti-angle-up');
    });
}
export function parseJwt(token) {
    let base64Url;
    if(token && token !== null) {
        base64Url = token.split('.')[1]; 
    } else {
        return
    }
    if(base64Url !== undefined) { 
        let base64 = base64Url.replace('-', '+').replace('_', '/'); 
        return JSON.parse(window.atob(base64)); 
    } else { 
        return 
    } 
}
export function isTokenValid() {
    if((new Date().getTime() / 1000 ) >= (secureStorage.getItem('tokenExpires')) || (secureStorage.getItem('tokenExpires') === '')) {
            localStorage.clear();
            return false;
        } else {
            return true;
        }
}
export function encodeBasicUrl(val) {
    if(val !== undefined) {
        return encodeURIComponent(val.toLowerCase()).split('%20').join('+');
    }
    return val   
}
export function decodeBasicUrl(val) {
    let result = val.split('+').join(' ');
    return decodeURIComponent(result);
}
export function getMeta(meta, el, func) {
    let metaResult = meta
    if(func !== undefined) {
        if(func === 'parseDate') {
            metaResult = isoToDate(meta);
        } else {
            metaResult = func();
        }
    }
    return meta !== undefined ? metaResult : el;
}
export function selectCurrentClient(list, client) {
   return _.find(list, function(o) { return o.value === client; });
} 
export function generateUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}
export function removeStorage(el, account) {
    secureStorage.removeItem('merchantId');
    secureStorage.removeItem('userRole');
    secureStorage.removeItem('role');
    if(el) {
        secureStorage.setItem('merchantId', el);
    }
    //secureStorage.setItem('switchMerchant', true);
    secureStorage.setItem('userRole', account.user.role);
}


