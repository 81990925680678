import React from 'react';
import PropTypes from 'prop-types';

const Modal = (props) => {
    return (
        <div className='modal fade' id={props.thisId}>
            <div className={props.classNames ? props.classNames + ' modal-dialog' : 'modal-dialog'} role='document'>
                <div className='modal-content'>
                    <div className='modal-header'>
                        <h4 className='modal-title'>{props.title}</h4>
                        <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
                            <i className='ti-close'></i>
                        </button>
                    </div>
                    <div className='modal-body'>
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

Modal.propTypes = {
    children: PropTypes.node.isRequired,
    classNames: PropTypes.string,
    thisId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
}

export default Modal;