import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_MERCHANT_FEES = 'GET_MERCHANT_FEES'
export const RESET_GET_MERCHANT_FEES = 'RESET_GET_MERCHANT_FEES'
export const PUT_MERCHANT_FEES = 'PUT_MERCHANT_FEES'
export const RESET_PUT_MERCHANT_FEES = 'RESET_PUT_MERCHANT_FEES'

export function getMerchantFees(merchantId) {
	let data = {
	  	headers: getHeader()
	}
	let dataPayload
	if(merchantId === 'undefined') {
		dataPayload = {response: 200, data: {}}
	} else {
		dataPayload = axios.get(`${ENV.API_URL}/internal/admin/merchants/${merchantId}/fees`, data)
	}
	return {
		type: GET_MERCHANT_FEES,
		payload: dataPayload
	}
}

export function putMerchantFees({merchantId, data}) {
	const dataPayload = axios.put(`${ENV.API_URL}/internal/admin/merchants/${merchantId}/fees`, data, {
	    headers: getHeader()
	})
	return {
		type: PUT_MERCHANT_FEES,
		payload: dataPayload
	}
}

export function resetGetMerchantFees() {
	const dataPayload = {}
	return {
		type: RESET_GET_MERCHANT_FEES,
		payload: dataPayload
	}
}

export function resetPutMerchantFees() {
	const dataPayload = {}
	return {
		type: RESET_PUT_MERCHANT_FEES,
		payload: dataPayload
	}
}