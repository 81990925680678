import {secureStorage} from '../helpers/secure_storage_helper';

export function getHeader(token, noContext) {
	let accessToken = token;
	let role = secureStorage.getItem('role');
	let merchant = secureStorage.getItem('merchantId');
	if(!token) {
		accessToken = localStorage.getItem('accessToken');
	}
	let headers = {
	  	'Authorization': 'Bearer ' + accessToken
	}
	if(!noContext && role !== 'ADMIN' && merchant !== null && merchant !== 'undefined') {
		headers['X-Merchant-Context'] = merchant;
	}
	return headers;
}