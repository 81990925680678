import React, { useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {ADMIN_PREFIX} from 'App'
import { ENV } from "index"
import { getToken, resetGetToken } from 'actions/getToken'
import {secureStorage} from '../../helpers/secure_storage_helper'


const Callback = ({adminLogin}) => {
    const history = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const lastAuthCode = secureStorage.getItem('lastAuthCode')
    const query = new URLSearchParams(location.search)
	const code = query.get('code')
    useEffect(() => {
		if (localStorage.getItem('accessToken') === null || localStorage.getItem('accessToken') === undefined) {
			if (code && code !== lastAuthCode) {
				dispatch(getToken({ keycloakDomain: ENV.KEYCLOAK_DOMAIN, code, redirect_uri: adminLogin ? ENV.LOCAL_URL + ADMIN_PREFIX + '/callback' : ENV.LOCAL_URL + 'callback', client_id: adminLogin ? ENV.KEYCLOAK_CLIENT_BACK : ENV.KEYCLOAK_CLIENT_FRONT, is_backend: adminLogin ? true : false }))
			}
		}
	}, [code])
    
    useEffect(() => {
        if(secureStorage.getItem('role') !== null) {
            history(adminLogin ? '/' + ADMIN_PREFIX : '/');  
        }
    }, [secureStorage.getItem('role') ]);
   
        return (
            <div></div>
        )
    
}

export default Callback;