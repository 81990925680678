import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import $ from 'jquery'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { getMerchantsPaginated, resetMerchantsPaginated } from 'actions/merchantsPaginated'
import { postUser } from 'actions/user'
import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Button from 'react-validation/build/button'
import Loading from 'components/ui_elements/loading'
import Selectize from 'components/ui_elements/selectize'
import ModalContainer from 'components/ui_elements/modal_container'
import { required, email, newEmail } from 'helpers/validator_helper'
import { handleModalFormSubmitHook } from 'helpers/form_helper'
import { secureStorage } from 'helpers/secure_storage_helper'

const NewUserModal = ({formSent, onModalClosed}) => {
    const dispatch = useDispatch();
    const isAdmin = useSelector(state => state.isAdmin);
    const isMerchant = useSelector(state => state.isMerchant);
    const users = useSelector(state => state.users);
    const merchantsIdsPaginated = useSelector(state => state.merchantsIdsPaginated);
    const newUser = useSelector(state => state.newUser);
    const [disabledForm, setDisabledForm] = useState(false);
    const [invite, setInvite] = useState(false);
    const [limit, setLimit] = useState(128);
    const [offset, setOffset] = useState(0);
    const [merchantId, setMerchantId] = useState('');
    const [userRole, setUserRole] = useState('');
    const [merchantsNamesLoaded, setMerchantsNamesLoaded] = useState(false);
    const [searchedMerchant, setSearchedMerchant] = useState('');
    const [userName, setUserName] = useState('');
    const [merchantsPaginated, setMerchantsPaginated] = useState([]);
    
    const submitText = 'Create user';

    useEffect(() => {
        if(isMerchant) {
            setMerchantsNamesLoaded(true);
            setInvite(true);
        }
    }, []);

    useEffect(() => {
        if(!_.isEmpty(newUser)) {
            formSent(newUser, userName);
            $('#userModal').modal('hide');
        }
    }, [newUser]);

    useEffect(() => {
        if(_.isEmpty(merchantsIdsPaginated) && merchantsNamesLoaded === false) {
            let params = {
                limit: limit,
                offset: offset,
                select: true
            };
            if(searchedMerchant !== '') {
                params.name = searchedMerchant;
            }
            dispatch(getMerchantsPaginated(params));
            setMerchantsNamesLoaded(true);
        } else {
            if(isAdmin && merchantsIdsPaginated !== 'error') {
                let merchantsPaginatedVal = merchantsIdsPaginated.slice();
                merchantsPaginatedVal.unshift({'label': 'Select merchant', 'value': ''});
                setMerchantsPaginated(merchantsPaginatedVal);
            }
        }
    }, [merchantsIdsPaginated]);

    const selectMounted = (el, id) => {
        if(id === 'merchantId') {
            setMerchantId(el.value);
        } else {
            setUserRole(el.value);
        }
    }
    const handleSelectInputChange = (el) => {
        if(searchedMerchant !== el) {
            setSearchedMerchant(el);
            let params = {
                limit: limit,
                offset: offset,
                select: true
            };
            if(el !== '' && el !== '[' && el !== ']') {
                params.name = el;
            }
            dispatch(getMerchantsPaginated(params));
        }
        
    }
    const handleModalHide = () => {
        onModalClosed();
    }
    const handleChange = (event) => {
        setUserName(event.target.value);
    }
    const handleSelectChange = (el, id) => {
        if(id === 'merchantId') {
            setMerchantId(el);
        } else {
            setUserRole(el);
        }
    }
    const handleSubmit = (event) => {
        let merchantIdVal = merchantId;

        if(secureStorage.getItem('role') === 'MERCHANTADMIN') {
            merchantIdVal = secureStorage.getItem('merchantId');
        }
         handleModalFormSubmitHook({
            event: event, 
            setDisabledForm: setDisabledForm,  
            sendFunction: () => dispatch(postUser({
                email: userName, 
                merchantId: merchantIdVal,
                role: userRole
            }))
        });
    }
    if(_.isEmpty(merchantsIdsPaginated) && isAdmin) {
        return <Loading />
    }
    return ( 
        <ModalContainer thisId='userModal' title={invite ? 'New user' : 'New user'} onHide={handleModalHide}  showInit={true}>
            <Form className='basic-form' onSubmit={handleSubmit}  id='userModalForm'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='merchantName'>Email: *</label>
                            <Input type='email' className='form-control' id='userName' names={users} name='userName' validations={[required, email, newEmail]}  value={userName} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                {isAdmin && merchantsPaginated.length &&
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label htmlFor='merchantId'>Assign to merchant:</label>
                                <Selectize 
                                    thisId='merchantId' 
                                    dataOptions={merchantsPaginated} 
                                    handleInputChange={handleSelectInputChange}
                                    isAsync={true} 
                                    isSearchable={true} 
                                    onChange={handleSelectChange} 
                                    onMounted={selectMounted}
                                />
                            </div>
                        </div>
                    </div>
                }
                {merchantId !== '' &&
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label htmlFor='merchantId'>User role:</label>
                                <Selectize 
                                    thisId='userRole' 
                                    dataOptions={[{label: 'MERCHANT USER', value: 'USER'}, {label: 'MERCHANT ADMIN', value: 'ADMIN'}, {label: 'MERCHANT VIEWER', value: 'VIEWER'}]}
                                    isAsync={true} 
                                    isSearchable={true} 
                                    onChange={handleSelectChange} 
                                    onMounted={selectMounted} 
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className='row'>
                    <div className='col-md-12 text-right small'>
                        <p>* - Field is required</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button type='button' className='btn btn-default' data-dismiss='modal'>Cancel</button>
                        {disabledForm === true ? (
                            <button className='btn btn-primary pull-right disabled' type='submit' disabled>{submitText}</button>
                        ) : (
                            <Button className='btn btn-primary pull-right' type='submit'>{submitText}</Button>
                        )}
                    </div>
                </div>
            </Form>
        </ModalContainer>
    )
    
}



export default NewUserModal;