import {combineReducers} from 'redux';
//Api Keys
import NewApiKey from './new_api_key';
import NewNotificationKey from './new_notification_key';
import UpdatedApiKey from './updated_api_key';
import DeletedApiKey from './deleted_api_key';
//Balances
import Balances from './balances';
import BalanceSnapshot from './balance_snapshot';
import BalanceAllSnapshot from './balance_all_snapshot';
import NewBalanceAdjustment from './new_balance_adjustment';
//Currencies
import PaymentPriceCurrencies from './payment_price_currencies';
import PayoutPriceCurrencies from './payout_price_currencies';
import Currencies from './currencies';
import AllPaymentCurrencies from './all_payment_currencies';
import AllPayoutCurrencies from './all_payout_currencies';
import PaymentTransferCurrencies from './payment_transfer_currencies';
import PayoutTransferCurrencies from './payout_transfer_currencies';
import NewCurrency from './new_currency';
//General
import Account from './account';
import AddressValid from './address_valid';
import IsAdmin from './is_admin';
import CurrentView from './current_view';
import Token from './token';
import RollbarSetup from './rollbar_setup';
import forbidden from './forbidden';
//Merchant
import IsMerchant from './is_merchant';
import IsMerchantUser from './is_merchant_user';
import IsMerchantAdmin from './is_merchant_admin';
import IsMerchantViewer from './is_merchant_viewer';
import IsUserAdmin from './is_user_admin';
import IsUnassignedMerchant from './is_unassigned_merchant';
import MerchantsNamesPaginated from './merchants_names_paginated';
import MerchantsList from './merchants_list';
import MerchantsListValues from './merchants_list_values';
import Merchant from './merchant';
import MerchantFees from './merchant_fees';
import UpdatedMerchantFees from './updated_merchant_fees';
import SelfMerchant from './self_merchant';
import DisabledMerchant from './disabled_merchant';
import NewMerchant from './new_merchant';
import MerchantsIdsPaginated from './merchants_ids_paginated';
import NewMerchantAccess from './new_merchant_access';
import DeletedMerchantAccess from './deleted_merchant_access';
import UpdatedMerchantAccess from './updated_merchant_access';
import MerchantContext from './merchant_context';
import UpdatedFee from './updated_fee';
import MerchantsPaginated from './merchants_paginated';
import Contact from './contact';
import UpdatedContact from './updated_contact';
import BankDetails from './bank_details';
import UpdatedBankDetails from './updated_bank_details';
import ConversionSetting from './conversion_setting';
//Payments
import NewPayment from './new_payment';
import TransactionsHistory from './transactions_history';
import TransactionsAllHistory from './transactions_all_history';
import Unsold from './unsold';
//Payouts
import PayoutsLimit from './payouts_limit';
import PayoutsLimitEur from './payouts_limit_eur';
import PayoutPrice from './payout_price';
import PayoutPriceFiat from './payout_price_fiat';
import NewPayout from './new_payout';
import ConfirmedPayout from './confirmed_payout';
import ConfirmedFiatPayout from './confirmed_fiat_payout';
import UnsoldPayouts from './unsold_payouts';
import CancelledPayout from './cancel_payout';
//Permissions
import Permissions from './permissions';
import UpdatedPermission from './updated_permission';
//Terms and Conditions
import NewApprove from './new_approve';
import AllTermsReset from './all_terms_reset';
//Trades
import Trades from './trades';
//Users
import NewUser from './new_user';
import Users from './users';
import UnassignedUsers from './unassigned_users';
import UpdatedUser from './updated_user';
//Notifications
import resendPaymentNotification from "./resendPaymentNotification";
import resendPayoutNotification from "./resendPayoutNotification";
//Dashboard
import dashboardSummary from './dashboard_summary';
import dashboardSummaryMerchant from './dashboard_summary_merchant';
import liveBalance from './live_balance';
import liveBalanceMerchant from './live_balance_merchant';
import activeTabDashboard from './active_tab_dashboard';
//Conversion
import conversionLimit from './conversion_limit';
import conversionEstimation from './conversion_estimation';
import newConversion from './new_conversion';
import declaredConversion from './declared_conversion';
import retriedConversion from './retried_conversion';
import cancelledConversion from './cancelled_conversion'

const rootReducer = combineReducers({
	//Api Keys
	newApiKey: NewApiKey,
	updatedApiKey: UpdatedApiKey,
	deletedApiKey: DeletedApiKey,
	newNotificationKey: NewNotificationKey,
	//Balances
	balances: Balances,
	balanceSnapshot: BalanceSnapshot,
	balanceAllSnapshot: BalanceAllSnapshot,
	newBalanceAdjustment: NewBalanceAdjustment,
	//Currencies
	paymentPriceCurrencies: PaymentPriceCurrencies,
	allPaymentCurrencies: AllPaymentCurrencies,
	allPayoutCurrencies: AllPayoutCurrencies,
	payoutPriceCurrencies: PayoutPriceCurrencies,
	currencies: Currencies,
	paymentTransferCurrencies: PaymentTransferCurrencies,
	payoutTransferCurrencies: PayoutTransferCurrencies,
	newCurrency: NewCurrency,
	//General
	account: Account,
	isAdmin: IsAdmin,
	addressValid: AddressValid,
	currentView: CurrentView,
	token: Token,
	rollbarSetup: RollbarSetup,
	forbidden,
	//Merchant
	isMerchant: IsMerchant,
	isMerchantAdmin: IsMerchantAdmin,
	isMerchantUser: IsMerchantUser,
	isMerchantViewer: IsMerchantViewer,
	isUserAdmin: IsUserAdmin,
	isUnassignedMerchant: IsUnassignedMerchant,
	merchantsNamesPaginated: MerchantsNamesPaginated,
	merchantsIdsPaginated: MerchantsIdsPaginated,
	newMerchant: NewMerchant,
	merchantsList: MerchantsList,
	merchantsListValues: MerchantsListValues,
	merchant: Merchant,
	merchantFees: MerchantFees,
	updatedMerchantFees: UpdatedMerchantFees,
	selfMerchant: SelfMerchant,
	disabledMerchant: DisabledMerchant,
	newMerchantAccess: NewMerchantAccess,
	deletedMerchantAccess: DeletedMerchantAccess,
	updatedMerchantAccess: UpdatedMerchantAccess,
	merchantContext: MerchantContext,
	updatedFee: UpdatedFee,
	merchantsPaginated: MerchantsPaginated,
	contact: Contact,
	updatedContact: UpdatedContact,
	bankDetails: BankDetails,
	updatedBankDetails: UpdatedBankDetails,
	conversionSetting: ConversionSetting,
	//Payments
	newPayment: NewPayment,
	transactionsHistory: TransactionsHistory,
	transactionsAllHistory: TransactionsAllHistory,
	unsold: Unsold,
	//Payouts
	payoutsLimit: PayoutsLimit,
	payoutsLimitEur: PayoutsLimitEur,
	payoutPrice: PayoutPrice,
	payoutPriceFiat: PayoutPriceFiat,
	newPayout: NewPayout,
	confirmedPayout: ConfirmedPayout,
	confirmedFiatPayout: ConfirmedFiatPayout,
	unsoldPayouts: UnsoldPayouts,
	cancelledPayout: CancelledPayout,
	//Permissions
	updatedPermission: UpdatedPermission,
	permissions: Permissions,
	//Terms and Conditions
	newApprove: NewApprove,
	allTermsReset: AllTermsReset,
	//Trades
	trades: Trades,
	//Users
	newUser: NewUser,
	users: Users,
	unassignedUsers: UnassignedUsers,
	updatedUser: UpdatedUser,
	//notifications
	resendPaymentNotificationState: resendPaymentNotification,
	resendPayoutNotificationState: resendPayoutNotification,
	//dashboard
	dashboardSummary: dashboardSummary,
	dashboardSummaryMerchant: dashboardSummaryMerchant,
	liveBalance: liveBalance,
	liveBalanceMerchant: liveBalanceMerchant,
	activeTabDashboard: activeTabDashboard,
	//conversion
	conversionLimit: conversionLimit,
	conversionEstimation: conversionEstimation,
	newConversion: newConversion,
	declaredConversion: declaredConversion,
	retriedConversion: retriedConversion,
	cancelledConversion: cancelledConversion
})

export default rootReducer;