import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Button from 'react-validation/build/button'
import { useState, useEffect } from 'react'
import _ from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { getBalanceSnapshot, resetBalanceSnapshot } from 'actions/balanceSnapshot'
import { getBalanceAllSnapshot, resetBalanceAllSnapshot } from 'actions/balanceAllSnapshot'
import DatepickerContainer from 'components/ui_elements/datepicker_container'
import Selectize from 'components/ui_elements/selectize'
import LoadingError from 'components/ui_elements/loading_error'
import Tooltip from 'components/ui_elements/tooltip'
import Breadcrumbs from 'components/ui_elements/breadcrumbs'
import Loading from 'components/ui_elements/loading'
import LayoutContainer from 'components/layout/layout_container'
import DateRangePicker from 'components/ui_elements/date_range_picker'
import BalanceHistoryTable from 'components/balance_history/balance_history_table'
import { secureStorage } from 'helpers/secure_storage_helper'
import { columnsCollapse, getMeta } from 'helpers/helpers'
import { selectedFormatExportTrades } from 'helpers/export_helper'
import { dateObjToDate, dateToIso, hourFromDate } from 'helpers/date_helper'
import { SUCCESS, SUCCESS_INVERSE } from 'helpers/colors_helper'
import PropTypes from 'prop-types'

const BalanceHistory = () => {
    const dispatch = useDispatch();
    const date = new Date();
    const balanceSnapshot = useSelector(state => state.balanceSnapshot);
    const meta = balanceSnapshot.meta;
    const [day, setDay] = useState(dateObjToDate(date));
    const [availableTimes, setAvailableTimes] = useState([]);
    const [selectedTime, setSelectedTime] = useState();
    const [locations, setLocations] = useState([]);
    const [data, setData] = useState({});
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        document.title = "Balance history - Bitcoin Payment Processor";
        dispatch(getBalanceSnapshot(dateToIso(day)))
        return(() => dispatch(resetBalanceSnapshot()))
    }, []);

    useEffect(() => {
        if(!_.isEmpty(balanceSnapshot) && balanceSnapshot.type !== 'error') {
            columnsCollapse();
            getUniqueTimesLocations();
            getHourlyBalances();
        }
    }, [balanceSnapshot]);

    useEffect(() => {
        if(selectedTime && !_.isEmpty(balanceSnapshot) && balanceSnapshot.type !== 'error') {
            getHourlyBalances();
        }
    }, [selectedTime]);

    const handleDateChange = (val) => {
        let newDate = dateObjToDate(val.date);
        sendRequest(newDate);
        setDay(newDate);
    }
    const handleSelectChange = (val) => {
        setSelectedTime(val)
    }
    const sendRequest = (date) => {
        dispatch(resetBalanceSnapshot());
        setAvailableTimes([]);
        setSelectedTime();
        dispatch(getBalanceSnapshot(dateToIso(date)));
    }
    const getUniqueTimesLocations = () => {
        const uniqueTimesSet = new Set();
        const uniqueLocations = new Set();
        balanceSnapshot.data?.forEach(obj => {
            uniqueTimesSet.add(hourFromDate(obj.time));
            uniqueLocations.add(obj.location);
        });
        const uniqueTimesArray = Array.from(uniqueTimesSet).sort();
        const uniqueLocationsArray = Array.from(uniqueLocations);
        setAvailableTimes(uniqueTimesArray);
        if(uniqueTimesArray.length) {
            setSelectedTime(loaded ? uniqueTimesArray[0] : uniqueTimesArray[uniqueTimesArray.length - 1]);
            if(!loaded) {
                setLoaded(true);
            }
        }
        setLocations(uniqueLocationsArray); 
    }
    const getHourlyBalances = () => {
        const balancesData = balanceSnapshot.data?.filter(obj => hourFromDate(obj.time) === selectedTime);
        const transformedData = balancesData.reduce((acc, curr) => {
          const { currency, ...rest } = curr;    
          if (!acc[currency]) {
            acc[currency] = [];
          }
          acc[currency].push(curr);
          
          return acc;
        }, {});
        setData(transformedData);
    }
    return (
        <LayoutContainer active='history'>
            <Breadcrumbs dataPath={{0: ['Home', '/'], 1: ['Balance history', '']}}/>
            <div className='row'>
                <div className='col-md-12'>
                    <h2 className='pull-left mrg-btm-20'>Balance history</h2>  
                </div>
            </div>
            {balanceSnapshot === 'error' ? (
                <LoadingError />
            ) : (
            <>
                <div className='card'>
                    <div className='pdd-horizon-20 pdd-top-20 pgg-btm-10'>
                        <Form id='paymentFilterForm'>
                            <div className="row mrg-top-5">
                                <div className='col-md-3'>
                                    <DatepickerContainer label='Snapshot date' thisId='snapDate' onChange={handleDateChange} date={day} />
                                </div>
                                {availableTimes.length ? (
                                    <div className='col-md-3'>
                                        <label htmlFor='snapTime'>Snapshot time:</label>
                                        <Selectize thisId='snapTime' selected={selectedTime} dataOptions={availableTimes} onChange={handleSelectChange} />
                                    </div>
                                ) : (<></>)}
                            </div>
                        </Form>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='card no-border-top'>
                            <BalanceHistoryTable locations={locations} data={data} />
                        </div>
                    </div>
                </div>
            </>
            )}
        </LayoutContainer>
    )
}

BalanceHistory.propTypes = {
    match: PropTypes.object
}

export default BalanceHistory
