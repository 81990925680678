import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_PERMISSIONS_INFO = 'GET_PERMISSIONS_INFO'
export const DELETE_PERMISSION = 'DELETE_PERMISSION'
export const PUT_PERMISSION = 'PUT_PERMISSION'
export const RESET_PERMISSION = 'RESET_PERMISSION'

export function getPermissionsInfo() {
	const dataPayload = axios.get(`${ENV.API_URL}/permissions`, {
	    headers: getHeader()
	})
	return {
		type: GET_PERMISSIONS_INFO,
		payload: dataPayload
	}
}

export function deletePermission(merchantId, permission) {
	const dataPayload = axios.delete(`${ENV.API_URL}/internal/admin/merchants/${merchantId}/permissions/${permission}`, {
	    headers: getHeader()
	})
	return {
		type: DELETE_PERMISSION,
		payload: dataPayload
	}
}

export function putPermission(merchantId, permission) {
	let data = {}
	const dataPayload = axios.put(`${ENV.API_URL}/internal/admin/merchants/${merchantId}/permissions/${permission}`, data,  {
	    headers: getHeader()
	})
	return {
		type: PUT_PERMISSION,
		payload: dataPayload
	}
}

export function resetPermission() {
	const dataPayload = {}
	return {
		type: RESET_PERMISSION,
		payload: dataPayload
	}
}

