import React from 'react';
import PropTypes from 'prop-types';
import Loading from 'components/ui_elements/loading';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {encodeBasicUrl} from 'helpers/helpers';
import { ADMIN_PREFIX } from 'App';

const MerchantsTable = (props) => {
    return (
        <div className='overflow-y-auto relative scrollable'>
            {_.isEmpty(props.merchantsPaginated) ? (
                <table className='table table-lg table-hover table-bordered-top'>
                    <tbody>
                            <tr>
                                <td colSpan='6'>
                                    <Loading />
                                </td>
                            </tr>
                        </tbody>
                </table>
            ) : (
                <table className='table table-lg table-hover table-bordered-top'>
                    <thead>
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>
                                Status
                            </th>
                            <th className='width-num-240'>
                                Merchant details
                            </th>
                        </tr>
                    </thead>
                    {props.merchantsPaginated.data.items.length === 0 ? (
                        <tbody>
                            <tr>
                                <td colSpan='6'>
                                    <h4 className='text-center mrg-top-5'>No merchants.</h4>
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody>
                            {props.merchantsPaginated.data.items.map(function(val,i){
                                let enabled = val.enabled === true ? 'enabled' : 'disabled';
                                let name = encodeBasicUrl(val.name);
                                let link = '/' + ADMIN_PREFIX + '/merchants/' + val.id;
                                return (
                                    <tr key={name}>
                                        <td className='v-align'>
                                            <div>
                                                <span><Link to={link}>{val.name}</Link></span>
                                            </div>
                                        </td>
                                        <td className='v-align'>
                                            <div className='relative'>
                                                <span className={"status " + (val.enabled ? 'online' : '')}> </span>
                                                <span className='pdd-left-20'>{enabled}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="btn-group" role="group" aria-label="Info">
                                                <Link className='btn btn-default no-mrg-btm btn-sm' to={link}>Details</Link>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    )}
                </table>
            )}
        </div>
    )
}

MerchantsTable.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    merchantsPaginated: PropTypes.object.isRequired
}

export default MerchantsTable;