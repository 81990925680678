import {PUT_APPROVE_TERMS_AND_CONDITIONS, RESET_APPROVE_TERMS_AND_CONDITIONS} from '../actions/termsAndConditions';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case PUT_APPROVE_TERMS_AND_CONDITIONS:
			let response = '';
			if(action.error) {
				let message = action.payload.response;

				if(message) {
					response = message.data[0] ? message.data[0].message : 'Some error occured';
				}
				setupRollbar(action);
				return {type: 'error', response: response}
			}
			return {data: action.payload, status: action.payload.status}
		case RESET_APPROVE_TERMS_AND_CONDITIONS:
			return action.payload
		default:
			return state;
	}

}