import React, {Component} from 'react';
import _ from 'lodash';
import MerchantsSearchForm from './merchants_search_form';
import {connect} from 'react-redux';
import {updateInputState} from 'helpers/form_helper'; 
import PropTypes from 'prop-types';

class MerchantsSearchFormContainer extends Component {
    constructor(props) { 
        super(props);
        let name = '';
        if(!_.isEmpty(this.props.merchantsPaginated)) {
            let meta = this.props.merchantsPaginated.meta;
            if(meta.name) {
                name = meta.name; 
            }
        }
        this.state = { 
            formDisabled: true,
            name: name,
            showModal: false
        }
    }
    componentDidMount() {
        document.title = "Merchants - Bitcoin Payment Processor";
        this.props.searchParamsUpdate(this.state);
    }
    componentDidUpdate() {
        if(!_.isEmpty(this.props.merchantsPaginated) && this.props.merchantsPaginated !== 'error') {
            if(this.state.formDisabled === true) {
                this.setState({
                    formDisabled: false
                })
            }
        }
    }
    handleChange(event) {
        updateInputState(this, event);
    }
    handleSubmit(event) {
        this.props.searchParamsUpdate(this.state, true, event);
    }
    render() {
        let thisState = this.state;
        return ( 
            <MerchantsSearchForm
                formDisabled={thisState.formDisabled}
                handleChange={this.handleChange.bind(this)}
                handleSubmit={this.handleSubmit.bind(this)}
                name={thisState.name}
            />
        )
    } 
}

MerchantsSearchFormContainer.propTypes = {
    history: PropTypes.object,
    location:  PropTypes.object,
    searchParamsUpdate: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    return {
        isAdmin: state.isAdmin,
        merchantsPaginated: state.merchantsPaginated
    }
}

export default connect(mapStateToProps, {
})(MerchantsSearchFormContainer);