import {GET_ACCOUNT} from '../actions/account';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_ACCOUNT:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return action.payload.data;
		default:
			return state;
	}

}