import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Loading from 'components/ui_elements/loading';
import ModalContainer from 'components/ui_elements/modal_container';
import NumberFormatter from 'components/ui_elements/number_formatter'
import Export from 'components/ui_elements/export';
import {profitClass} from 'helpers/class_helper';
import {toFixedNoZero} from 'helpers/fees_helper';
import {percentMultiplied} from 'helpers/fees_helper';

const ProfitLine = (props) => {
    let percentage = 0;
    let valueClass = (el) => {
        if(el) {
            return el < 0 ? 'text-danger' : '';
        } else {
            return '';
        }
        
    }
    return (
        <div className="row">
            <div className="col-md-12">
                <div className="form-group">
                    <label>{props.label}: </label>
                    <h5><span className={valueClass(props.value)}>{props.value ? <NumberFormatter toFormat={props.value} currCode='EUR' /> : '0.00'}</span> EUR <span className='font-size-12'>({props.valuePercentage ? percentMultiplied(props.valuePercentage).toFixed(2) : '0' }%)</span></h5>
                </div>
            </div>
        </div>
    )
}

const ProfitModal = (props) => {
    return (
    	<ModalContainer classNames='modal-lg' thisId='profitsModal' title={'Profits for ' + props.paymentId} onHide={props.handleModalHide} showInit={props.showOnInit}>
            <ProfitInfo {...props} />
            <div className='row'>
                <div className='col-md-12'>
                    <button type="button" className="btn btn-default btn-full" data-dismiss="modal">Close</button>
                </div>
            </div>
        </ModalContainer>
    )
}

export const ProfitInfo = (props) => {
    return (
        <div className='row'>
            <div className='col-md-12'>
                <p className='mrg-btm-10'>Profit summary in EUR</p>
                <div className='flex-container'>
                    <div className='mrg-right-30'>
                        {props.profitDetails ? (
                            <h2 className={profitClass(props.profitDetails.tradeProfit.profitAmount) + ' no-mrg-btm min-height-34'}>{props.profitDetails.tradeProfit.profitAmount ? <NumberFormatter toFormat={props.profitDetails.tradeProfit.profitAmount} currCode='EUR' /> : '0.00'}<span className='font-size-16 mrg-left-5'>({props.profitDetails.tradeProfit.profitPercentage ? percentMultiplied(props.profitDetails.tradeProfit.profitPercentage).toFixed(2) : '0'}%)</span></h2>
                        ) : (
                            <h2 className='no-mrg-btm min-height-34'>0.00<span className='font-size-16 mrg-left-5'>0%</span></h2>
                        )}
                        <span className='inline-block mrg-btm-5 font-size-13 text-semibold'>Trading result</span>
                    </div>
                    <div className='mrg-right-30'>
                        {props.profitDetails ? (
                            <h2 className={profitClass(props.profitDetails.actualProfit.profitAmount) + ' no-mrg-btm min-height-34'}>{props.profitDetails.actualProfit.profitAmount ? <NumberFormatter toFormat={props.profitDetails.actualProfit.profitAmount} currCode='EUR' /> : '0.00'}<span className='font-size-16 mrg-left-5'>({props.profitDetails.actualProfit.profitPercentage ? percentMultiplied(props.profitDetails.actualProfit.profitPercentage).toFixed(2) : '0'}%)</span></h2>
                        ) : (
                            <h2 className='no-mrg-btm min-height-34'>0.00<span className='font-size-16 mrg-left-5'>0%</span></h2>
                        )}
                        <span className='inline-block mrg-btm-5 font-size-13 text-semibold'>Actual profit</span>
                    </div>
                </div>
                <hr />
                <div className='row'>
                    <div className='col-md-3'>
                        <ProfitLine label='Network fee' value={props.profitDetails ? props.profitDetails.networkFeeEur : 0} valuePercentage={props.profitDetails ? props.profitDetails.networkFeeEurPercentage : 0} />
                    </div>
                    <div className='col-md-3'>
                        <ProfitLine label='Transaction fees' value={props.profitDetails ? props.profitDetails.transactionFees : 0} valuePercentage={props.profitDetails ? props.profitDetails.transactionFeesPercentage : 0} />
                    </div>
                </div>
                <hr className='no-mrg-top' />
            </div>        
        </div>
    )
}

ProfitLine.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
    valuePercentage: PropTypes.number.isRequired
}

ProfitModal.propTypes = {
    handleModalHide: PropTypes.func.isRequired,
    paymentId: PropTypes.string.isRequired,
    profitDetails: PropTypes.object.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

export default ProfitModal;