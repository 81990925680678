import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Button from 'react-validation/build/button'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import $ from 'jquery'
import _ from 'lodash'
import PropTypes from 'prop-types'
import ModalContainer from 'components/ui_elements/modal_container'
import { updateInputStateHook, handleModalFormSubmitHook } from 'helpers/form_helper'
import { required, number } from 'helpers/validator_helper'

const InputSuffix = styled.span`
    position: absolute;
    right: 25px;
    top: 36px;
    padding-left: 10px;
    border-left: 1px solid #e6ecf5;
`

const DeclareConversionModal = ({boughtCurrency, conversionId, onModalClosed, formSent, showOnInit}) => {
    const currencies = useSelector(state => state.currencies);
    const [state, setState] = useState({
        boughtAmount: '',
        isFormDisabled: false,
        currencyPrecision: 2
    });
    useEffect(() => {
        if(boughtCurrency) {
            let currSettings = currencies.find(curr => curr.code === boughtCurrency);
            if(currSettings) {
                setState((prevState) => ({
                    ...prevState,
                    currencyPrecision: currSettings.precision
                }));
            }
        }
    }, [boughtCurrency]);
    const handleModalHide = () => {
        onModalClosed();
    }
    const handleChange = (event) => {
        updateInputStateHook(setState, state, event);
    }
    const handleSubmit = (event) => {
        handleModalFormSubmitHook({ 
            event: event,  
            sendFunction: () => formSent(state.boughtAmount),
            resetFunction: resetAndClose
        });
    }
    const resetAndClose = () => {
        onModalClosed();
        $('#declareConversionModal').modal('hide');
    }
    return (
        <ModalContainer thisId='declareConversionModal' title='Declare conversion' onHide={handleModalHide} showInit={showOnInit} >
            <Form onSubmit={handleSubmit} id='declareConversionForm' className='basic-form'>
                 <div className='row'>
                    <div className='col-lg-12 pdd-lrft-7'>
                        <div className='form-group'>
                            <label htmlFor='boughtAmount'>Amount to declare: *</label>
                            <Input type='text' className='form-control' id='boughtAmount' name='boughtAmount' data-precision={state.currencyPrecision} validations={[required, number]} data-currency={state.boughtCurrency} value={state.boughtAmount} onChange={handleChange} />
                            <InputSuffix>{boughtCurrency}</InputSuffix>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 text-right small'>
                        <p className='mrg-btm-5'>* - Field is required</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <Button className='btn btn-primary pull-right width-100 mrg-btm-20 mrg-top-10 no-mrg-right' type='submit' disabled>Declare</Button>
                    </div>
                </div>
            </Form>
        </ModalContainer>
    )
    
}

DeclareConversionModal.propTypes = {
    boughtCurrency: PropTypes.string.isRequired,
    formSent: PropTypes.func.isRequired,
    onModalClosed: PropTypes.func.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

export default DeclareConversionModal;
