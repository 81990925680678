import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from 'helpers/headers_helper'

export const GET_LIVE_BALANCE = 'GET_LIVE_BALANCE'
export const RESET_LIVE_BALANCE = 'RESET_LIVE_BALANCE'

export function getLiveBalance() {
	let data = {
	  headers: getHeader()
	}
	let dataPayload = axios.get(`${ENV.API_URL}/analytics/dashboard/live-balances`, data)
	
	return {
		type: GET_LIVE_BALANCE,
		payload: dataPayload
	}
}


export function resetLiveBalance() {
	const dataPayload = ''
	return {
		type: RESET_LIVE_BALANCE,
		payload: dataPayload
	}
}
