import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { TabsContext } from 'contexts/tabs.context'
import _ from 'lodash'
import { initAlert } from 'helpers/alert_helper'
import { getMerchantContact, resetMerchantContact } from 'actions/contact'
import { getBankDetails, resetBankDetails } from 'actions/bankDetails'
import Tab from 'components/ui_elements/tab'
import Tooltip from 'components/ui_elements/tooltip'
import DataPresentationRow from 'components/ui_elements/data_presentation_row'
import EditContactModalContainer from './edit_contact_modal_container'
import EditBillingModalContainer from './edit_billing_modal_container'

const ContactTab = () => {
    const dispatch = useDispatch();
    const { active } = useContext(TabsContext);

    const isAdmin = useSelector(state => state.isAdmin);
    const isMerchantAdmin = useSelector(state => state.isMerchantAdmin);
    const merchant = useSelector(state => state.merchant);
    const contact = useSelector(state => state.contact);
    const bankDetails = useSelector(state => state.bankDetails);

    const merchantId = merchant.id;

    const [showBillingModal, setShowBillingModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        return () => {
            dispatch(resetMerchantContact());
            dispatch(resetBankDetails());
        }
    }, []);
    useEffect(() => {
        if(merchantId !== undefined && loaded === false) {
            dispatch(getMerchantContact(merchantId));
            dispatch(getBankDetails(merchantId));
            setLoaded(true);
        }
    }, [merchantId]);

    const showBillingModalFunc = () => {
        setShowBillingModal(true);
    }
    const showContactModalFunc = () => {
        setShowContactModal(true);
    }
    const onModalClosed = () => {
        setShowContactModal(false);
    }
    const onBillingModalClosed = () => {
        setShowBillingModal(false);
    }
    const formSent = (el) => {
        if(el === 200) {
            dispatch(getMerchantContact(merchantId));
        }
    }
    const bankFormSent = (el) => {
        if(el === 200) {
            dispatch(getBankDetails(merchantId));
        }
    }
    return (
        <Tab name='contact' labelledby='contact-tab' active={active}>
            <>
                <div className='pdd-horizon-30 pdd-vertical-30'>
                    <div className='row'>
                        {!_.isEmpty(contact) && 
                            <div className='col-md-5 mrg-btm-40'>
                                <h2 className='mrg-btm-20'>Contact info <span className='relative top--2'><Tooltip id='contactTooltip' placement='top' text='Company address' /></span></h2>
                                {!_.isEmpty(contact.data) ? (
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <DataPresentationRow name='Company name' large={true}>
                                                <p>{contact.data.companyName}</p>
                                            </DataPresentationRow>
                                            {contact.data.addressLine1 &&
                                                <DataPresentationRow name='Address' large={true}>
                                                    <p>{contact.data.addressLine1}
                                                        {contact.data.addressLine2 &&
                                                            <span>
                                                            , {contact.data.addressLine2}
                                                            </span>
                                                        }
                                                    </p>
                                                </DataPresentationRow>
                                            }
                                            {contact.data.postcode &&
                                                <DataPresentationRow name='Post code' large={true}>
                                                    <p>{contact.data.postcode}</p>
                                                </DataPresentationRow>
                                            }
                                            {contact.data.city &&
                                                <DataPresentationRow name='City' large={true}>
                                                    <p>{contact.data.city}</p>
                                                </DataPresentationRow>
                                            }
                                            {contact.data.country &&
                                                <DataPresentationRow name='Country' large={true}>
                                                    <p>{contact.data.country}</p>
                                                </DataPresentationRow>
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        {(isAdmin || isMerchantAdmin) ? (
                                            <div className="alert alert-warning font-size-14" role="alert">
                                                There is no contact info. Please set up info by clicking 'Edit contact'
                                            </div>
                                        ) : (
                                            <div className="alert alert-warning font-size-14" role="alert">
                                                There is no contact info. Please contact us or Merchant Admin to update Your contact data
                                            </div>
                                        )}
                                        
                                    </div>
                                )}
                                {(isAdmin || isMerchantAdmin) &&
                                    <div className='row pos-bottom-full'>
                                        <div className='col-md-12'>
                                            <button className='btn btn-default btn-full mrg-top-5' onClick={showContactModalFunc}>Edit contact</button>
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                        {!_.isEmpty(bankDetails) && (
                            <div className='col-md-5 mrg-btm-40'>
                                <h2 className='mrg-btm-20'>Billing info <span className='relative top--2'><Tooltip id='billingTooltip' placement='top' text='Info for Bank transfer' /></span></h2>
                                {!_.isEmpty(bankDetails.data) ? (
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            {bankDetails.data.bankName &&
                                                <DataPresentationRow name='Bank name' large={true}>
                                                    <p>{bankDetails.data.bankName}</p>
                                                </DataPresentationRow>
                                            }
                                            {bankDetails.data.swift &&
                                                <DataPresentationRow name='Swift' large={true}>
                                                    <p>{bankDetails.data.swift}</p>
                                                </DataPresentationRow>
                                            }
                                            {bankDetails.data.accountNumber &&
                                                <DataPresentationRow name='Account number' large={true}>
                                                    <p>{bankDetails.data.accountNumber}</p>
                                                </DataPresentationRow>
                                            }
                                            {bankDetails.data.iban &&
                                                <DataPresentationRow name='IBAN' large={true}>
                                                    <p>{bankDetails.data.iban}</p>
                                                </DataPresentationRow>
                                            }
                                        </div>
                                    </div>
                                ) : (
                                    <div className="alert alert-warning font-size-14" role="alert">
                                        {isAdmin ? (
                                            <span>There is no billing info. Please set up info by clicking 'Edit billing'</span>
                                        ) : (
                                            <span>There is no billing info. Please contact us to update Your billing data</span>
                                        )}
                                        
                                    </div>
                                )}
                                {isAdmin && (
                                    <div className='row pos-bottom-full'>
                                        <div className='col-md-12'>
                                            <button className='btn btn-default btn-full mrg-top-5' onClick={showBillingModalFunc}>Edit billing</button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
                {showContactModal === true &&
                    <EditContactModalContainer 
                        contact={contact.data}
                        merchantId={merchantId}
                        formSent={formSent}
                        onModalClosed={onModalClosed}
                        showOnInit={true}
                    />
                }
                {showBillingModal === true &&
                    <EditBillingModalContainer 
                        bankDetails={bankDetails}
                        merchantId={merchantId}
                        formSent={bankFormSent}
                        showOnInit={true}
                        onModalClosed={onBillingModalClosed}
                    />
                }
            </>
        </Tab>
    )
}

export default ContactTab;