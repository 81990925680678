import {POST_CONVERSION, RESET_CONVERSION} from '../actions/conversion';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = '', action) {
	switch(action.type) {
		case POST_CONVERSION:
			if(action.error) {
				setupRollbar(action);
				let message = action.payload.response?.data.message;
				if(message) {
					return {type: 'error', response: message}
				}
				return {type: 'error'}
			}
			return action.payload
		case RESET_CONVERSION:
			return action.payload
		default:
			return state;
	}

}