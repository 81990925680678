import { axios } from 'axiosInstance'
import {dateNextDay} from 'helpers/date_helper'
import {getHeader} from 'helpers/headers_helper'
import { ENV } from "index"

export const GET_TRANSACTIONS_HISTORY = 'GET_TRANSACTIONS_HISTORY'
export const GET_TRANSACTIONS_HISTORY_RESET_META = 'GET_TRANSACTIONS_HISTORY_RESET_META'
export const RESET_TRANSACTIONS_HISTORY = 'RESET_TRANSACTIONS_HISTORY'

export function getTransactionsHistory({amountFrom, amountTo, currency, paymentId, payoutId, timeStart, timeEnd, transactionType, merchantId, details, limit, shownFilters, allResults, exchangeRate, apiKeyID, prevState, before}) {
    let type = GET_TRANSACTIONS_HISTORY

    let paramsData = new URLSearchParams()
    if(timeStart !== undefined && timeStart !== 'Invalid date' && timeStart !== '') {
        paramsData.append("timeStart", timeStart + 'T00:00:00')
    }
    if(timeEnd !== undefined && timeEnd !== 'Invalid date' && timeEnd !== '') {
        let nextDay = dateNextDay(timeEnd)
        paramsData.append("timeEnd", nextDay + 'T00:00:00')
    } 
    let params = {
        'allResults': allResults,
        'timeStart': timeStart !== 'Invalid date' ? timeStart : '',
        'timeEnd': timeEnd !== 'Invalid date'? timeEnd : '',
        'shownFilters': shownFilters,
        'before': before
    }
    let otherParams = [{'amountFrom': amountFrom}, {'amountTo': amountTo}, {'currency': currency}, {'paymentId': paymentId}, {'payoutId': payoutId}, {'merchantId': merchantId}, {'transactionType': transactionType}, {'details': details}, {'limit': limit}, {'exchangeRate':exchangeRate}, {'apiKeyID': apiKeyID}, {'before': before}  ]
    otherParams.map(function(val,i){
        let key = Object.keys(val)[0]
        let value
        if(key === 'merchantId') {
            if(val[key] !== undefined) {
                if (val[key].value === '') {
                    return value
                } else {
                    value = val[key]
                }
            }
        } else if (key === 'transactionType') {
            if(val[key] !== undefined) {
                value = val[key].toUpperCase()
            }
        } else {
            value = val[key]
        }
        if(value !== '' && value !== undefined) {
            paramsData.append(key, value)
            params[key] = value
        }
        return value
    })
    let data = {
        headers: getHeader(),
        params: paramsData
    }
    const dataPayload = axios.get(`${ENV.API_URL}/ui/transactions/list`, data)
    return {
        type: type,
        payload: dataPayload,
        meta: params
    }
}

export function resetTransactionsHistory() {
    let type = RESET_TRANSACTIONS_HISTORY
    const dataPayload = {data: {status: 200}}
    return {
        type: type,
        payload: dataPayload
    }
}
