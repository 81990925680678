import { axios } from 'axiosInstance'
import {getHeader} from '../helpers/headers_helper'
import { ENV } from "index"
 
export const PUT_APPROVE_TERMS_AND_CONDITIONS = 'PUT_APPROVE_TERMS_AND_CONDITIONS'
export const RESET_APPROVE_TERMS_AND_CONDITIONS = 'RESET_APPROVE_TERMS_AND_CONDITIONS'
export const PUT_RESET_ALL_TERMS = 'PUT_RESET_ALL_TERMS'
export const RESET_ALL_TERMS = 'RESET_ALL_TERMS'

export function putApproveTermsAndConditions() {
    let data = {}
    const dataPayload = axios.put(`${ENV.API_URL}/account/terms-and-conditions`, data, {
        headers: getHeader()
    })
    return { 
        type: PUT_APPROVE_TERMS_AND_CONDITIONS, 
        payload: dataPayload
    } 
}

export function resetApproveTermsAndConditions() { 
    const dataPayload = ''
    return { 
        type: RESET_APPROVE_TERMS_AND_CONDITIONS, 
        payload: dataPayload 
    } 
}

export function putResetAllTerms() {
    let data = {}
    const dataPayload = axios.put(`${ENV.API_URL}/admin/users/reset-terms-conditions`, data, {
        headers: getHeader()
    })
    return { 
        type: PUT_RESET_ALL_TERMS, 
        payload: dataPayload
    } 
}
export function resetAllTerms() { 
    const dataPayload = ''
    return { 
        type: RESET_ALL_TERMS,
        payload: dataPayload 
    } 
}