import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import $ from 'jquery'
import _ from 'lodash'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {getTrades, resetTrades} from 'actions/trades'
import {selectedFormatExportTrades} from 'helpers/export_helper'
import TradesModal from './trades_modal'

const TradesModalContainer = ({paymentId, isPayout, onModalClosed, showOnInit}) => {
    const trades = useSelector(state => state.trades);
    const dispatch = useDispatch();
    const [exportLoading, setExportLoading] = useState(false);
    const [exportValue, setExportValue] = useState('');
    const [disabledForm, setDisabledForm] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        dispatch(getTrades(paymentId, isPayout));
        return () => {
            dispatch(resetTrades());
        }
    }, []);
    useEffect(() => {
        let tradesAll = trades;
        if(!_.isEmpty(trades)){
            if(exportLoading === true) {
                if(trades === 'error') {
                    setExportLoading(false);
                    setError(true);
                } else {
                    if(trades.data.trades) {
                        selectedFormatExportTrades(exportValue, trades, paymentId); 
                        setExportLoading(false);
                        setExportValue('');
                        dispatch(resetTrades());
                        $('#tradesModal').modal('hide');
                        handleModalHide();
                    }
                }
            }
        }
    }, [trades]); 

    const handleModalHide = () => {
        onModalClosed();
    }
    const selectedExport = (val) => {
        setExportLoading(true);
        setExportValue(val);
    }
    return ( 
        <TradesModal 
            disabledForm={disabledForm} 
            handleModalHide={handleModalHide} 
            isPayout={isPayout}
            paymentId={paymentId}
            selectedExport={selectedExport}
            showOnInit={showOnInit || false} 
            trades={trades}
        />
    )
}

TradesModalContainer.propTypes = {
    isPayout: PropTypes.bool,
    onModalClosed: PropTypes.func.isRequired,
    paymentId: PropTypes.string.isRequired,
    showOnInit: PropTypes.bool.isRequired
}


export default TradesModalContainer;