import React, {Component} from 'react'
import Select, { components, OptionProps } from 'react-select'
import PropTypes from 'prop-types'
import variables from 'styles/_variables.scss'
import {GRAY, DANGER_LIGHT, DANGER_ALT, PRIMARY_ALT, NEUTRAL_LIGHT, NEUTRAL_MEDIUM, NEUTRAL_DARKER} from 'helpers/colors_helper'


const Option = (props) => {
    return (
        <div style={{backgroundColor: props.isSelected ? '#60a7ef' : props.isFocused ? '#f6f7fb' : '#fff', position: 'relative'}}>
            {props.data.icon && <i className="fa" style={{position: 'absolute', left: 10, top: 8, fontSize: 18, color: props.data.iconColor}}>{props.data.icon}</i>}
            <div style={{paddingLeft: props.data.icon ? '25px' : '0'}}>
                <components.Option {...props} />
            </div>
        </div>
    );
};

export default class Selectize extends Component {
	constructor(props) {
        super(props);
        let selected = this.props.dataOptions[0];
        let selectedOption = this.props.selected;
        if(selectedOption !== undefined && selectedOption !== '') {
            selected = selectedOption;
        }
        if( (typeof selected !== "object") && (selected !== null) ) {
            selected = {label: selected, value: selected};
        }
        this.state = {
            selected: selected
        }
        this.updateState = this.updateState.bind(this);
    }
    componentDidMount() {
        if(this.props.onMounted) {
            this.props.onMounted(this.state.selected, this.props.thisId);
        }
    }
    componentWillUpdate() {
        if(this.props.onUpdated) {
            this.props.onUpdated();
        }
    }
    updateState(newSelected) {
        let el = this.props.controlSelected;
        if(el && (el.value !== newSelected.value)) {
            newSelected.value = el.value;
            newSelected.label = el.label;
        }
        if(newSelected !== null) {
            if(Array.isArray(newSelected)) {
                this.setState({
                    selected: newSelected
                });
                if(this.props.onChange) {
                    this.props.onChange(newSelected, this.props.thisId);
                }
            } else {
                this.setState({
                    selected: {label: newSelected.label, value: newSelected.value}
                });
                if(this.props.onChange) {
                    this.props.onChange(newSelected.value, this.props.thisId);
                }
            }
        }
    }
    inputChange(el) {
        if(this.props.handleInputChange) {
            this.props.handleInputChange(el);
        }
        
    }
    render() {
        let options;
        let isSearchable = this.props.isSearchable || false;
        let isMulti = this.props.isMulti || false;
        if(this.props.dataOptions && this.props.dataOptions !== 'error') {
            if(this.props.dataOptions[0].label) {
                options = this.props.dataOptions;
            } else {
                options = this.props.dataOptions.map(function(value, i) {
                    return {label: value, value: value }
                });
            }
        }
        let selectProps = {};
        if (isMulti) {
            selectProps.defaultValue = this.state.selected;
        } else {
            selectProps.value = this.state.selected;
        }
        return (
            <Select
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    colors: {
                        ...theme.colors,
                        primary25: GRAY,
                        primary: PRIMARY_ALT,
                        neutral20: NEUTRAL_MEDIUM,
                        neutral30: NEUTRAL_MEDIUM,
                        neutral50: NEUTRAL_DARKER,
                        neutral10: NEUTRAL_LIGHT,
                        dangerLight: DANGER_LIGHT,
                        danger: DANGER_ALT
                    },
                })}
                isMulti={isMulti}
                components={{ Option }}
                options={options}
                name={this.props.thisId}
                id={this.props.thisId}
                {...selectProps}
                onChange={this.updateState}
                onInputChange={this.inputChange.bind(this)}
                isSearchable={isSearchable}
                backspaceRemoves={isSearchable}
                isClearable={false}
            />
        )
    }
}

Selectize.propTypes = {
    dataOptions: PropTypes.array.isRequired,
    handleInputChange: PropTypes.func,
    isAsync: PropTypes.bool,
    isSearchable: PropTypes.bool,
    isMulti: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    selected: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.string,
        PropTypes.number
    ]),
    thisId: PropTypes.string.isRequired
}