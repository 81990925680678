import React, { Component } from 'react'
import BalancesLayout from 'components/balances/balances_layout'
import {secureStorage} from 'helpers/secure_storage_helper'
import PropTypes from 'prop-types'

class Balances extends Component {
    componentDidMount() {
        document.title = "Balances - Bitcoin Payment Processor";
    }
    render() {
        return (
            <BalancesLayout
                history={this.props.history}
            />
        )
    }
}

Balances.propTypes = {
    history: PropTypes.object,
    location:  PropTypes.object,
    match: PropTypes.object
}

export default Balances;
