import {POST_NOTIFY_KEY, RESET_NOTIFY_KEY} from '../actions/notificationKey';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case POST_NOTIFY_KEY:
			let response = 'Some error occured while creating Notification Key, please try again';
			if(action.error) {
				let message = action.payload.response;
				if(message) {
					response = message.data[0]?.message;
				}
				setupRollbar(action);
				return {type: 'error', response: response}
			}
			return action.payload.data;
		case RESET_NOTIFY_KEY:
			return action.payload;
		default:
			return state;
	}

}