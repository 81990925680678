import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';
import React from 'react';
import PropTypes from 'prop-types';
import ModalContainer from '../ui_elements/modal_container';
import {required, email, newEmail} from '../../helpers/validator_helper';
import Selectize from '../ui_elements/selectize';

const MerchantAccessEdit = (props) => {
    return (
            <div>
                <div className='mrg-top-10'>
                    {props.assignForm === true && props.formBlocked === false ? (
                        <Form className={props.classNames + ' basic-form'} onSubmit={props.handleSubmit}  id='merchantAssignForm'>
                            <div className='form-group'>
                                <label className='mrg-btm-10' htmlFor='merchantId'>Merchant:</label>
                                {props.assignValues ? (
                                    <h5>{props.assignValues.name}</h5>
                                ) : (
                                    <Selectize 
                                        thisId='merchantId' 
                                        dataOptions={props.merchantsIds} 
                                        isAsync={true} 
                                        isSearchable={true} 
                                        onChange={props.handleSelectChange} 
                                        handleInputChange={props.handleSelectInputChange}
                                        onMounted={props.selectMounted} 
                                    />
                                )}
                                
                            </div>
                            {props.assignValues &&
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label htmlFor='merchantId'>User role:</label>
                                            <Selectize 
                                                thisId='userRole' 
                                                dataOptions={[{label: 'MERCHANT ADMIN', value: 'ADMIN'}, {label: 'MERCHANT USER', value: 'USER'}, {label: 'MERCHANT VIEWER', value: 'VIEWER'}]}
                                                isAsync={true} 
                                                isSearchable={true} 
                                                onChange={props.handleSelectChange} 
                                                onMounted={props.selectMounted}
                                                selected={props.assignValues && {label: 'MERCHANT ' + props.assignValues.role, value: props.assignValues.role}}  
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            <div className='row'>
                                <div className='col-md-6'>
                                    {props.disabledForm === true ? (
                                        <button className='btn btn-primary btn-full disabled' type='submit' disabled>{props.title}</button>
                                    ) : (
                                        <Button className='btn btn-primary btn-full' type='submit'>{props.title}</Button>
                                    )}
                                </div>
                                <div className='col-md-6'>
                                    <button type='button' className='btn btn-default btn-full' onClick={props.hideAssignForm}>Cancel</button>
                                </div>
                            </div>
                        </Form>
                    ) : (
                        <div>
                        {props.isAdmin && props.formBlocked == false &&
                            <button className='btn btn-primary btn-full' onClick={props.showAssignForm}>{props.title} <i className='fa fa-plus-circle'></i></button>
                        }
                        </div>
                    )}
                </div>
            </div>
    )
}

const MerchantAccessModal = (props) => {
    return (
    	<ModalContainer classNames='modal-md' thisId='merchantAccessModal' title='Edit access to merchants' onHide={props.handleModalHide}  showInit={props.showOnInit}>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='form-group'>
                        <label>User:</label>
                        <h5>{props.userName}</h5>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <div className='form-group mrg-btm-15'>
                        {props.assignForm !== true &&
                        [
                            <label className='mrg-btm-10'>{props.isAdmin ? 'Merchants assigned' : 'Merchant assigned'}</label>,
                            <table className='width-100 table-hover'>
                                <tbody>
                                        {props.userMerchants.map(function(val,i){
                                            return (
                                                [
                                                <tr>
                                                    <td className='v-align pdd-vertical-10'>
                                                        <h5 className='no-mrg'>{val.name}</h5>
                                                    </td>
                                                    <td className='v-align pdd-vertical-10'>
                                                        <p className='no-mrg'>User role: MERCHANT {val.role}</p>
                                                    </td>
                                                    <td className='v-align'>
                                                        <button className='btn btn-default btn-xs no-border font-size-12 pull-right' onClick={() => props.deleteAccessMerchant(val.id, val.name)}>
                                                            Delete <i className='fa fa-trash-o static'></i>
                                                        </button>
                                                        <button className='btn btn-default btn-xs no-border font-size-12 pull-right' onClick={() => props.editAccessMerchant(val)}>
                                                            Edit <i className='fa fa-pencil static'></i>
                                                        </button>
                                                        
                                                    </td>
                                                </tr>,
                                                <tr className='no-hover'>
                                                    {props.editAssignForm && props.assignValues === val && 
                                                        <td colSpan='3'>
                                                            <hr />
                                                            <MerchantAccessEdit 
                                                                assignForm={true}
                                                                assignValues={val}
                                                                title='Update user role' 
                                                                disabledForm={props.disabledForm} 
                                                                formBlocked={props.formBlocked}
                                                                handleSubmit={props.handleUpdateSubmit} 
                                                                hideAssignForm={props.hideAssignForm}
                                                                isAdmin={props.isAdmin}
                                                                merchantsIds={props.merchantsIds} 
                                                                showAssignForm={props.showAssignForm} 
                                                                handleSelectChange={props.handleSelectChange}  
                                                                selectMounted={props.selectMounted} 
                                                            />
                                                        </td>
                                                    }
                                                </tr>]
                                            )
                                        })}
                                    
                                </tbody>
                            </table>]
                        }
                        <div className='row'>
                            <div className='col-md-12'>
                                {props.editAssignForm !== true &&
                                    <MerchantAccessEdit 
                                        assignForm={props.assignForm}
                                        title='Assign new merchant' 
                                        classNames='mrg-top-minus-12'
                                        disabledForm={props.disabledForm} 
                                        formBlocked={props.formBlocked}
                                        handleSubmit={props.handleSubmit} 
                                        hideAssignForm={props.hideAssignForm}
                                        isAdmin={props.isAdmin}
                                        merchantsIds={props.merchantsIds} 
                                        showAssignForm={props.showAssignForm} 
                                        handleSelectChange={props.handleSelectChange}  
                                        selectMounted={props.selectMounted} 
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-md-12'>
                    <button type='button' className='btn btn-default btn-full' data-dismiss='modal'>Close</button>
                </div>
            </div>
        </ModalContainer>
    )
}

MerchantAccessModal.propTypes = {
    assignForm: PropTypes.bool.isRequired,
    assignValues: PropTypes.object.isRequired,
    deleteAccessMerchant: PropTypes.func.isRequired,
    disabledForm: PropTypes.bool.isRequired,
    editAccessMerchant: PropTypes.func.isRequired,
    editAssignForm: PropTypes.bool.isRequired,
    formBlocked: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleModalHide: PropTypes.func.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    handleSelectInputChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    handleUpdateSubmit: PropTypes.func.isRequired,
    hideAssignForm: PropTypes.func.isRequired,
    merchantsIds:  PropTypes.array.isRequired,
    selectMounted: PropTypes.func.isRequired,
    showAssignForm: PropTypes.func.isRequired,
    showOnInit: PropTypes.bool.isRequired,
    userMerchants: PropTypes.array.isRequired,
    userName: PropTypes.string.isRequired
}

export default MerchantAccessModal;
