import {GET_PAYOUT_PRICE, RESET_PAYOUT_PRICE} from '../actions/payouts';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = '', action) {
	switch(action.type) {
		case GET_PAYOUT_PRICE:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return action.payload.data
		case RESET_PAYOUT_PRICE:
			return action.payload
		default:
			return state;
	}

}