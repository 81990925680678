import { createContext, useState, useEffect } from 'react'

export const TabsContext = createContext({
})

export const TabsProvider = ({children}) => {
	const [active, setActive] = useState('home');
	const [merchant, setMerchant] = useState({});

	const value = {
		active,
		setActive,
		merchant,
		setMerchant
	}
	return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>
}