import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import { DashboardTabsContext } from 'contexts/dashboard.tabs.context'
import { getDashboardSummary, resetDashboardSummary } from 'actions/dashboardSummary'
import { getDashboardSummaryMerchant, resetDashboardSummaryMerchant } from 'actions/dashboardSummaryMerchant'
import { getLiveBalance, resetLiveBalance } from 'actions/liveBalance'
import { getLiveBalanceMerchant, resetLiveBalanceMerchant } from 'actions/liveBalanceMerchant'
import { postActiveTabDashboard } from 'actions/activeTabDashboard'

import DashboardTab from './dashboard_tabs'
import DashboardMerchantBalances from './dashboard_merchant_balances'
import DashboardMerchant from './dashboard_merchant'
import SimpleDataBox from 'components/ui_elements/simple_data_box'
import Loading from 'components/ui_elements/loading'
import LoadingError from 'components/ui_elements/loading_error'
import ProfitModalContainer from 'components/profit/profit_modal_container'
import NumberFormatter from 'components/ui_elements/number_formatter'
import DateRangePicker from 'components/ui_elements/date_range_picker'
import NavItemTab from 'components/ui_elements/nav_item_tab'
import { profitClass, lossStyle } from 'helpers/class_helper'
import { dateToIso, dateObjToDate, parseStartOfMonthDate, parseEndDate, parseStartOfYearDate, parseEndOfYearDate } from 'helpers/date_helper'
import { totalCommaPrecision } from 'helpers/number_helper'
import { secureStorage } from 'helpers/secure_storage_helper'
import variables from 'styles/_variables.scss'

const NUMBER_OF_CURRENCIES_TO_FEATURE = 3;

const DateRangePickerCustom = styled.div`
    width: 214px;
    display: inline-block;
    position: relative;
    top: -4.5px;
    .form-control {
        height: 36px!important;
        border-color: ${variables.buttonBorderColor};
    }
    .active {
        input.form-control {
            background-color: ${variables.defaultLogo};
            color: ${variables.defaultWhite};
            i.datepicker-icon {
                color: ${variables.defaultWhite};
            }
        }
    }
`

const NavItemTabCustom = styled.div`
    text-align: center;
    li {
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        a {
            color: #808080!important;
            padding: 20px;
            span {
                font-size: 19px;
                color: #212529;
                margin-top: 5px;
                display: block;
            }
        }
    }
    
`

const NavTabsCustom = styled.ul`
    background: #f6f7fb;
`

const CardCustom = styled.div`
    border-top: 0;
`

const TabButton = ({tab, activeTab, btnText, handleTabChange, style, children, showRange}) => {
    return (
        <>  
            {children ? (
                <>
                    {children}
                </>
            ) : (
                <button className={activeTab.view === tab ? 'btn btn-primary btn-sm' : 'btn btn-default btn-sm'} style={{...style, 'marginRight': '-1px'}} onClick={() => handleTabChange(tab)}>{btnText}</button>
            )}
        </>
    )
}

const DashboardSummary = () => {
    const { active, setActive } = useContext(DashboardTabsContext)
    const activeTabDashboard = useSelector(state => state.activeTabDashboard)
    const isAdmin = useSelector(state => state.isAdmin)
    const isMerchant = useSelector(state => state.isMerchant)
    const currencies = useSelector(state => state.currencies)
    const dashboardSummary = useSelector(state => state.dashboardSummary.data)
    const dashboardSummaryMerchant = useSelector(state => state.dashboardSummaryMerchant.data)
    const merchant = useSelector(state => state.merchant)
    const liveBalance = useSelector(state => state.liveBalance.data)
    const liveBalanceMerchant = useSelector(state => state.liveBalanceMerchant.data)
    const merchantContext = useSelector(state => state.merchantContext)
    const dispatch = useDispatch()
    const [profitDetails, setProfitDetails] = useState({})
    const [showProfitModal, setShowProfitModal] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalType, setModalType] = useState('')
    const [showRange, setShowRange] = useState(false)
    const [transactionsSum, setTransactionsSum] = useState({payments: 0, payouts: 0, profit: 0})
    const [balanceSum, setBalanceSum] = useState({liveBalance: 0})
    let date = new Date()
    let start = dateObjToDate(date)
    let end = dateObjToDate(date)
    const now = end;
    const [formValues, setFormValues] = useState({
        dateFrom: '',
        dateTo: ''
    });
    const [initialDatesLoaded, setInitialDatesLoaded] = useState(false);
    useEffect(() => {
        handleDateChange(activeTabDashboard)
        callInitialFunctions()
    }, [])
    useEffect(() => {
        if(merchantContext && merchantContext !=='' && isMerchant) {
            callInitialFunctions()
        }
    }, [merchantContext])
    useEffect(() => {
        if(initialDatesLoaded && (merchantContext === '' || merchantContext === 'ADMIN' || secureStorage.getItem('userRole') === null)) {
            callInitialFunctions()
        }
    }, [initialDatesLoaded])

    useEffect(() => {
        callInitialFunctions()
    }, [isAdmin]);

    useEffect(() => {
        callInitialFunctions()
    }, [isMerchant])

    useEffect(() => {
        if(formValues) {
            callInitialFunctions()
        }
        if(formValues.dateFrom !== '' && formValues.dateTo !== '') {
            setInitialDatesLoaded(true)
        }
    }, [formValues])

    useEffect(() => {
        if(dashboardSummary && isAdmin) {
            if(dashboardSummary.payments) {
                if(dashboardSummary.payments.length) {
                    sumTransactions(dashboardSummary.payments, 'payments')
                } else {
                    setTransactionsSum(previousState => {return {...previousState, payments: 0}})
                }
            }
            if(dashboardSummary.payouts) {
                if(dashboardSummary.payouts.length) {
                    sumTransactions(dashboardSummary.payouts, 'payouts');
                } else {
                    setTransactionsSum(previousState => {return {...previousState, payouts: 0}})
                }
                
            }
            if(dashboardSummary.profit) {
                if(dashboardSummary.profit.length) {
                    sumTransactions(dashboardSummary.profit, 'profit');
                } else {
                    setTransactionsSum(previousState => {return {...previousState, profit: 0}})
                }
            }
        }

        if(dashboardSummaryMerchant && isMerchant) {
            if(dashboardSummaryMerchant.payments) {
                if(dashboardSummaryMerchant.payments.length) {
                    sumTransactions(dashboardSummaryMerchant.payments, 'payments');
                } else {
                    setTransactionsSum(previousState => {return {...previousState, payments: 0}});
                }
            }
            if(dashboardSummaryMerchant.payouts) {
                if(dashboardSummaryMerchant.payouts.length) {
                    sumTransactions(dashboardSummaryMerchant.payouts, 'payouts');
                } else {
                    setTransactionsSum(previousState => {return {...previousState, payouts: 0}});
                }
                
            }
            if(dashboardSummaryMerchant.profit) {
                if(dashboardSummaryMerchant.profit.length) {
                    sumTransactions(dashboardSummaryMerchant.profit, 'profit');
                } else {
                    setTransactionsSum(previousState => {return {...previousState, profit: 0}});
                }
                
            }
        }
    }, [dashboardSummary, dashboardSummaryMerchant]);

    useEffect(() => {
        if(isAdmin) {
            if(liveBalance && liveBalance.length) {
                sumLiveBalance(liveBalance, 'liveBalance');
            }
        } else {
            if(liveBalanceMerchant && liveBalanceMerchant.length) {
                sumLiveBalance(liveBalanceMerchant, 'liveBalance');
            }
        }
    }, [liveBalance, liveBalanceMerchant]);

    const callInitialFunctions = () => {
        if(initialDatesLoaded) {
            if(isAdmin) {
                dispatch(getDashboardSummary({from: dateToIso(formValues.dateFrom), to: dateToIso(formValues.dateTo)}));
                dispatch(getLiveBalance());
            }
            if(isMerchant) {
                let merchantId = secureStorage.getItem('merchantId');
                if(merchantId !== null) {
                    dispatch(getDashboardSummaryMerchant({from: dateToIso(formValues.dateFrom), to: dateToIso(formValues.dateTo), merchantId: merchantId}));
                    dispatch(getLiveBalanceMerchant({merchantId}));
                }
            }
        }
    }
    const sendRequest = () => {
        callInitialFunctions();
    }
    const sumTransactions = (list, name) => {
        const result = list.reduce((sum, el) => {return sum + el.euroAmount}, 0);
        setTransactionsSum(previousState => {return {...previousState, [name]: result}});
    }
    const sumLiveBalance = (list, name) => {
        const result = list.reduce((sum, el) => {return sum + el.euroAmount}, 0);
        setBalanceSum(previousState => {return {...previousState, [name]: result}});
    }
    const showProfitModalFunc = (el) => {
        setShowProfitModal(true);
        setProfitDetails(el);
    }
    const modalProfitClosed = () => {
        setShowProfitModal(false);
        setProfitDetails({});
    }
    const showModalFunc = (el) => {
        setShowModal(true);
        setModalType(el);
    }
    const modalClosed = () => {
        setShowModal(false);
        setModalType('');
    }
    const handleTabChange = (tab) => {
        setShowRange(false);
        handleDateChange(tab);
    }
    const handleCustomTabChange = (tab) => {
        //dispatch(postActiveTabDashboard('custom', formValues));
        setShowRange(true);
    }
    const setActiveTab = (tab) => {
        setActive(tab);
        dispatch(postActiveTabDashboard({...activeTabDashboard, activeTab: tab}));
    }
    const handleDateChange = (val) => {
        let viewName = val.view ? val.view : val;
        switch(viewName) {
            case 'today':
                let todayValues = {
                    dateFrom: dateObjToDate(date),
                    dateTo: dateObjToDate(date)
                }
                dispatch(postActiveTabDashboard({...activeTabDashboard, view: 'today', formValues: todayValues}));
                setFormValues({
                    ...formValues, 
                    ...todayValues
                });
                return 
            case 'currentMonth':
                let strt = parseStartOfMonthDate(date);
                let nd = parseEndDate(date);
                let currenyMonthValues = {
                    dateFrom: dateObjToDate(strt),
                    dateTo: dateObjToDate(nd)
                }
                dispatch(postActiveTabDashboard({...activeTabDashboard, view: 'currentMonth', formValues: currenyMonthValues}));
                setFormValues({
                    ...formValues, 
                    ...currenyMonthValues
                });
                return
            case 'currentYear':
                let strtYear = parseStartOfYearDate(date);
                let ndYear = parseEndOfYearDate(date);
                let currenyYearValues = {
                    dateFrom: dateObjToDate(strtYear),
                    dateTo: dateObjToDate(ndYear)
                }
                dispatch(postActiveTabDashboard({...activeTabDashboard, view: 'currentYear', formValues: currenyYearValues}));
                setFormValues({
                    ...formValues, 
                    ...currenyYearValues
                });
                return
            case 'custom':
                let customValues = {
                    dateFrom: val.formValues.dateFrom,
                    dateTo: val.formValues.dateTo
                }
                dispatch(postActiveTabDashboard({...activeTabDashboard, view: 'custom', formValues: customValues}));
                setFormValues({
                    ...formValues, 
                    ...customValues
                }); 
                return
            default:
                let defaultValues = {
                    dateFrom: dateObjToDate(val.startDate),
                    dateTo: dateObjToDate(val.endDate)
                }
                dispatch(postActiveTabDashboard({...activeTabDashboard, view: 'custom', formValues: defaultValues}));
                setFormValues({
                    ...formValues, 
                    ...defaultValues
                }); 
                return
        }
        
    }
    if(isAdmin && _.isEmpty(dashboardSummary) || isMerchant && _.isEmpty(dashboardSummaryMerchant)) {
        return <Loading />
    }
    if(dashboardSummary === 'error' || dashboardSummaryMerchant === 'error') {
        return <LoadingError />
    }
    
    let boxClasses = 'col-md-6 col-sm-6';
    if(isAdmin) {
        boxClasses = 'width-33 pdd-horizon-10';
    }
    const TabButtonParams = {
        handleTabChange: handleTabChange,
        activeTab: activeTabDashboard
    }
    const TabParams = {
        start: start,
        end: end,
        isAdmin: isAdmin,
        formValues: formValues,
        timeScope: activeTabDashboard,
        activeTab: activeTabDashboard.activeTab
    }
    return (
        <>
            {isMerchant &&
                <DashboardMerchantBalances sendRequest={sendRequest} />
            }
            <div className='row'>
                <div className='col-lg-12'> 
                    <TabButton tab='today' btnText='Today' {...TabButtonParams} style={{'marginLeft': '5px'}} />
                    <TabButton tab='currentMonth' btnText='Current month' {...TabButtonParams} />
                    <TabButton tab='currentYear' btnText='Current year' {...TabButtonParams} />
                    <TabButton tab='custom' btnText='Custom' {...TabButtonParams} handleTabChange={handleCustomTabChange} showRange={showRange}>
                        {formValues.dateFrom !== '' && formValues.dateTo !== '' && 
                            <DateRangePickerCustom>
                                <DateRangePicker
                                    now={now} 
                                    thisId='dateRange' 
                                    additionalPdd={true} 
                                    classNames={activeTabDashboard.view === 'custom' ? 'active tabButtonStyles' : 'tabButtonStyles'}
                                    start={formValues.dateFrom} 
                                    end={formValues.dateTo} 
                                    onChange={handleDateChange} 
                                    label={false}
                                />
                            </DateRangePickerCustom>
                        }
                    </TabButton>
                </div>
            </div>
            <>
                {isAdmin &&
                    <CardCustom className='card no-border'>
                        <div className='tab-info'>
                            <NavTabsCustom className='nav nav-tabs' id='myTab' role='tablist'>
                                <NavItemTabCustom> 
                                    <NavItemTab key='payments' name='Payments' subText={totalCommaPrecision({toFormat: transactionsSum.payments, currCode: 'EUR', currencies: currencies}) + ' EUR'} hrefText='payments' id='payments' idText='payments-tab' handleClick={() => setActiveTab('payments')} active={activeTabDashboard.activeTab} />
                                </NavItemTabCustom>
                                <NavItemTabCustom> <NavItemTab key='payouts' name='Payouts'  subText={totalCommaPrecision({toFormat: transactionsSum.payouts, currCode: 'EUR', currencies: currencies}) + ' EUR'} hrefText='payouts' id='payouts' idText='payouts-tab' handleClick={() => setActiveTab('payouts')} active={activeTabDashboard.activeTab} /></NavItemTabCustom> 
                                {isAdmin &&
                                    <>
                                        <NavItemTabCustom> <NavItemTab key='profit' name='Profit' subText={totalCommaPrecision({toFormat: transactionsSum.profit, currCode: 'EUR', currencies: currencies}) + ' EUR'} hrefText='profit' id='profit' idText='profit-tab' handleClick={() => setActiveTab('profit')} active={activeTabDashboard.activeTab} /></NavItemTabCustom> 
                                    </>
                                }
                                <NavItemTabCustom style={{position: 'absolute', right: 0, backgroundColor: 'rgb(221 253 237)'}}> <NavItemTab key='balance' name={isAdmin ? "Merchants' live balances" : 'Live balance'} subText={totalCommaPrecision({toFormat: balanceSum.liveBalance, currCode: 'EUR', currencies: currencies}) + ' EUR'}  hrefText='balance' idText='balance-tab' id='balance' handleClick={() => setActiveTab('balance')} active={activeTabDashboard.activeTab} /></NavItemTabCustom> 
                            </NavTabsCustom>
                            <div className='tab-content' id='myTabContent'>
                                <DashboardTab {...TabParams} featuredTransactions={isAdmin ? dashboardSummary?.payments : dashboardSummaryMerchant?.payments} showModalFunc={showModalFunc} sendRequest={sendRequest} type='payments' typeSingular='payment' />
                                <DashboardTab {...TabParams} featuredTransactions={isAdmin ? dashboardSummary?.payouts : dashboardSummaryMerchant?.payouts} showModalFunc={showModalFunc} sendRequest={sendRequest} type='payouts' typeSingular='payout' />
                                <DashboardTab {...TabParams} featuredTransactions={dashboardSummary?.profit} type='profit' typeSingular='profit' />
                                <DashboardTab {...TabParams} featuredTransactions={liveBalance ? liveBalance.filter(balance => balance.euroAmount !== 0) : liveBalance} showModalFunc={showModalFunc} type='balance' typeSingular='live balance' />

                            </div>
                        </div>
                    </CardCustom>
                }
                {isMerchant &&
                    <DashboardMerchant />
                }
            </>
        </>
    )
}

export default DashboardSummary;