import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import $ from 'jquery'
import _ from 'lodash'
import { switchMerchant } from '../../actions/switchMerchant'
import { resetMerchant } from 'actions/merchant'
import { resetUsers } from 'actions/users'
import { resetTransactionsHistory } from 'actions/transactionsHistory'
import { resetTransactionsAllHistory } from 'actions/transactionsHistoryAll'
import { getAccount } from 'actions/account'
import { logout as keycloakLogout } from 'actions/keycloakLogout'
import { getCurrencies } from 'actions/currencies'
import { setView } from '../../actions/currentView'
import { getMerchant, resetSelfMerchant } from 'actions/merchant'
import { removeStorage } from '../../helpers/helpers'
import {initAlert} from 'helpers/alert_helper'
import Layout from './layout'
import { ADMIN_PREFIX } from 'App'
import { ENV } from "index"
import { secureStorage } from '../../helpers/secure_storage_helper'
import { resetLiveBalanceMerchant } from 'actions/liveBalanceMerchant'
import { resetLiveBalance } from 'actions/liveBalance'
import { resetMerchantsList } from 'actions/merchantsList'

const LayoutContainer = (props) => {
    const history = useNavigate();
    const dispatch = useDispatch();
    const [collapsedClass, setCollapsedClass] = useState('')
    const [isCollapsed, setIsCollapsed] = useState(false)
    const forbidden = useSelector(state => state.forbidden)
    const [merchantsLoaded, setMerchantsLoaded] = useState(false)
    const [searchMerchantName, setSearchMerchantName] = useState('')
    const [merchantsIds, setMerchantsIds] = useState([])
    const [isLogout, setLogout] =  useState(false)
    const selfMerchant = useSelector(state => state.selfMerchant)
    const isUnassignedMerchant = useSelector(state => state.isUnassignedMerchant)
    const isMerchant = useSelector(state => state.isMerchant)
    const isAdmin = useSelector(state => state.isAdmin)
    const isUserAdmin = useSelector(state => state.isUserAdmin)
    const account = useSelector(state => state.account)
    const currencies = useSelector(state => state.currencies)
    const token = useSelector(state => state.token)

    useEffect(() => {
        $('.side-panel-toggle').on('click', function(e) {
            $('.side-panel').toggleClass("side-panel-open");
            e.preventDefault();
        });
        dispatch(setView(props.active));
    }, []);
     useEffect(() => {
        if(!currencies.length) {
            dispatch(getCurrencies());
        }
    }, [currencies])
    useEffect(() => {
        if(isLogout) {
            if(token.session === undefined && localStorage.getItem('accessToken') === null) {
                setLogout(false);
                history(isAdmin ? '/' + ADMIN_PREFIX + '/login' : '/login');
            }
        }
    }, [localStorage.getItem('accessToken'), isLogout])
    useEffect(() => {
        if(token.logout){
            if(secureStorage.getItem('role') === 'ADMIN' || secureStorage.getItem('userRole') === 'ADMIN') {
                localStorage.clear();
            } else {
                secureStorage.removeItem('userRole');
                secureStorage.removeItem('role');
                secureStorage.removeItem('userEmail');
                secureStorage.removeItem('tokenExpires');
                secureStorage.removeItem('approvedTerms');
                localStorage.removeItem('accessToken');
            }
            secureStorage.removeItem('refreshToken');
            setLogout(true);
        }
    }, [token])

    useEffect(() => {
        loadMerchants();
    }, [account]);


    const searchMerchant = (event) => {
        setSearchMerchantName(event.target.value);
        let merch = [];
        let userRole = secureStorage.getItem('userRole');
        let role = secureStorage.getItem('role');
        _.forEach(account.context.availableMerchants, function(val, key) { 
            let label = val.name.toLowerCase();
            if(label.includes(event.target.value.toLowerCase())) {
                merch.push(val);
            }
        });
        if(userRole === 'ADMIN' && role !== 'ADMIN' && userRole.toLowerCase().includes(event.target.value)) {
            merch.unshift({name: userRole, id: userRole});
        };
        setMerchantsIds(merch);
    }
    const loadMerchants = () => {
        if(!_.isEmpty(account) && account.context) {
            let merchants = account.context.availableMerchants.slice();
            let userRole = secureStorage.getItem('userRole');
            let role = secureStorage.getItem('role');
            if(userRole === 'ADMIN' && role !== 'ADMIN' ) {
                merchants.unshift({name: userRole, id: userRole});
            }
            setMerchantsIds(merchants);
        }
    }
	const handleSideNavToggle = (e) => {
		e.preventDefault();
	    let css = (isCollapsed === false) ? 'is-collapsed' : '';
        setIsCollapsed(!isCollapsed)
        setCollapsedClass(css);
	}
    const logout = () => {
        onLogout();
    }
    const onLogout = () => {
        dispatch(keycloakLogout({
            refreshToken: secureStorage.getItem('refreshToken'),
            keycloakDomain: ENV.KEYCLOAK_DOMAIN,
            client_id: isAdmin || isUserAdmin ? ENV.KEYCLOAK_CLIENT_BACK : ENV.KEYCLOAK_CLIENT_FRONT,
            is_backend: isAdmin || isUserAdmin
        }));
	}
    const switchMerchantFunc = (el) => {
        dispatch(switchMerchant(el));
        dispatch(resetMerchant());
        dispatch(resetMerchantsList())
        dispatch(resetTransactionsAllHistory());
        dispatch(resetTransactionsHistory());
        dispatch(resetUsers());
        dispatch(resetLiveBalanceMerchant());
        dispatch(resetLiveBalance());
        dispatch(resetSelfMerchant())
        if(el !== 'ADMIN') {
            removeStorage(el, account);
        } else {
            secureStorage.removeItem('merchantId');
            secureStorage.removeItem('userRole');
            secureStorage.setItem('role', 'ADMIN');
        }
        dispatch(getAccount());
        if(el !== 'ADMIN') {
            dispatch(getMerchant(el, true));
        }
        history(el !== 'ADMIN' ? isAdmin ? '/' + ADMIN_PREFIX + '/context' : '/' : '/' + ADMIN_PREFIX);
        setMerchantsLoaded(false);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
    }
    const getMerchantRole = (merchantType) => {
        switch(merchantType) {
            case 'MERCHANT':
                return 'MERCHANT USER';
            case 'MERCHANTADMIN':
                return'MERCHANT ADMIN';
            case 'MERCHANTVIEWER':
                return 'MERCHANT VIEWER';
            default:
                return '';
        }
    }
        let merchantName = '';
        let merchantRole = '';
        let enabled = true;
        if(isAdmin) {
            merchantName = 'ADMIN';
        }
        if(!_.isEmpty(selfMerchant)) {
            enabled = selfMerchant.enabled;
            merchantName = selfMerchant.name;
            let merchantType = (account.user.role);
            merchantRole = getMerchantRole(merchantType);
        }
        if(account === 'error') { 
            initAlert({
                type: 'error', 
                title: 'Error', 
                html: 'Account not found. Please try to log in again.',
                confirmText: 'OK'
            });
            return <></>
        } 
        return (
        	<Layout
                account={account}
                active={props.active} 
                children={props.children} 
                collapsedClass={collapsedClass} 
                enabled={enabled}
                handleSideNavToggle={handleSideNavToggle}
                handleSubmit={handleSubmit}
                history={history} 
                isAdmin={isAdmin}
                isMerchant={isMerchant}
                isUnassignedMerchant={isUnassignedMerchant}
                logout={logout}
                merchantsIds={merchantsIds}
                merchantRole={merchantRole}
                permissions={account.permissions}
                searchMerchant={searchMerchant} 
                searchMerchantName={searchMerchantName}
                switchMerchant={switchMerchantFunc}
        	/>
        ) 
    
}

LayoutContainer.propTypes = {
    active: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.node
    ]).isRequired
}

export default LayoutContainer


