import React, { useContext, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { TabsContext } from 'contexts/tabs.context'
import { getMerchant } from 'actions/merchant'
import { putApiKey, resetApiKey, deleteApiKey } from 'actions/apiKey'
import { disableMerchant, resetDisableMerchant } from 'actions/merchant'
import { initAlert } from 'helpers/alert_helper'
import DataPresentationRow from 'components/ui_elements/data_presentation_row'
import Tab from 'components/ui_elements/tab'
import MerchantTable from './api_keys_table'
import NewApiKeyModalContainer from './new_api_key_modal_container'

const ApiKeyTab = () => {
    const dispatch = useDispatch();
    const { active } = useContext(TabsContext);

    const isAdmin = useSelector(state => state.isAdmin);
    const isMerchantAdmin = useSelector(state => state.isMerchantAdmin);
    const isMerchantUser = useSelector(state => state.isMerchantUser);
    const merchant = useSelector(state => state.merchant);
    const updatedApiKey = useSelector(state => state.updatedApiKey);
    const deletedApiKey = useSelector(state => state.deletedApiKey);
    const disabledMerchant = useSelector(state => state.disabledMerchant);

    const [showModal, setShowModal] = useState(false);
    const [updatedMerchant, setUpdatedMerchant] = useState(false);
    const [apiKeyDeleted, setApiKeyDeleted] = useState(false);
    const [userDisableChange, setUserDisableChange] = useState(false);

    useEffect(() => {
        if(updatedApiKey === 200 && updatedMerchant === false) {
            setUpdatedMerchant(true);
            merchantGet();
        }
    }, [updatedApiKey]);

    useEffect(() => {
        if(apiKeyDeleted === true && deletedApiKey === 200) {
            resetKey();
            setApiKeyDeleted(false);
            merchantGet();
        }
    }, [deletedApiKey]);

    useEffect(() => {
        if(userDisableChange === true) {
            let disabledStatus = merchant.enabled === false ? 'enabled' : 'disabled';
            if(disabledMerchant === 'OK' || disabledMerchant === 'error') {
                setUserDisableChange(false);
                if(disabledMerchant === 'error') {
                    initAlert({
                        type: 'error',
                        title: 'Error',
                        html: 'Something went wrong. Please try again',
                        confirmText: 'OK'
                    });

                } else {
                    initAlert({
                        type: 'success',
                        title: 'Success',
                        html: 'You ' + disabledStatus + ' this merchant'
                    });
                    merchantGet();
                }
                dispatch(resetDisableMerchant());
            }
        }
    }, [disabledMerchant]);

    const merchantGet = () => {
        dispatch(getMerchant(merchant.id));
    }

    const resetKey = () => {
        dispatch(resetApiKey());
    }

    const handleApiSwith = (event, value, name) => {
        event.preventDefault();
        let isEnabled = event.target.checked === true ? 'enable' : 'disable';
        let etar = event.target.checked;
        initAlert({
            type: 'warning',
            title: 'API key ' + (event.target.checked === true ? 'enabling' : 'disabling'),
            html:
                'You are about to ' + isEnabled + ' API key ' +
                '<b>' + name + '</b>.' +
                '<br />' +
                'Are you sure you want to do this?',
            confirmText: 'Yes, ' + isEnabled,
            onSuccess: () => onSwitchedApiKey(etar, value),
            onDismiss: onExit
        });
    }
    const handleApiKeyDeleted = () => {
        setApiKeyDeleted(true);
    }
    const handleDeleteClick = (event, apiKey) => {
        event.preventDefault();
        initAlert({
            type: 'warning',
            title: 'Warning',
            html:
                'You are about to delete API key ' +
                '<br />' +
                '<b>' + apiKey + '</b>' +
                '<br />' +
                '<br />' +
                'Are you sure you want to do this?',
            confirmText: 'Yes, delete',
            onSuccess: () => onDeleted(apiKey),
            onDismiss: onExit
        });
    }
    const handleDisableToggle = (event) => {
        event.preventDefault();
        let disabled = merchant.enabled === false ? 'enable' : 'disable';
        initAlert({
            type: 'warning',
            title: 'Warning',
            html:
                'You are about to ' + disabled + ' merchant ' +
                '<b>' + merchant.name + '</b>.' +
                '<br />' +
                 'Are you sure you want to do this?',
            confirmText: 'Yes, ' + disabled,
            onSuccess: () => onDisabled(disabled),
            onDismiss: onExit
        });
    }
    const modalClosed = () => {
        setShowModal(false);
    }
    const newApiKeySubmit = (data) => {
        if(data.type === 'error') {
            initAlert({
                type: 'error',
                title: 'Error',
                html: data.response,
                confirmText: 'OK'
            });
            resetKey();
        } else {
            initAlert({
                type: 'info',
                title: 'API key created',
                html:
                    'New api key was created!' +
                    '<br />' +
                    '<br />' +
                    'Your new API key number:' +
                    '<br />' +
                    '<br />' +
                    '<div class="col-md-12">' +
                        '<div class="card no-mrg-top no-mrg-btm">' +
                            '<div class="card-block">' +
                                '<div>' +
                                    '<p class="clearfix no-mrg-btm">' +
                                        '<span>' + data.apiKey + '</span>' +
                                    '</p>' +
                                '</div>' +
                            '</div>' +
                        '</div>' +
                    '</div>' +
                    '<br />' +
                    '<br />' +
                    'We do not save your API key numbers!' +
                    '<br />' +
                    'Please confirm that you saved your API key somewhere safe.' +
                    '<br />' +
                    'After confirmation you not be able to see it.',
                confirmText: 'I saved API key',
                onSuccess: onExit,
                onDismiss: onExit,
                isShownCancelButton: false
            });
            resetKey()
            merchantGet();
        }
    }
    const onDeleted = (apiKey) => {
        dispatch(deleteApiKey(merchant.id, apiKey));
        handleApiKeyDeleted();
    }
    const onDisabled = (disabled) => {
        dispatch(disableMerchant({
            merchantId: merchant.id,
            enabled: !merchant.enabled
        }));
        setUserDisableChange(true);
    }
    const onSwitchedApiKey = (event,value) => {
        resetKey();
        dispatch(putApiKey(merchant.id, event,value));
        setUpdatedMerchant(false);
    }
    const onExit = () => {
        return
    }
    const showModalFunc = () => {
        setShowModal(true)
    }
    return (
        <Tab name='home' labelledby='home-tab' active={active}>
            <div className='pdd-horizon-30 pdd-vertical-30'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h2 className='pull-left mrg-btm-30'>Merchant details</h2>
                        {isAdmin &&
                            <div className='toggle-checkbox toggle-success checkbox-inline pull-right'>
                                <label className='check-inline-label'>{merchant.enabled ? 'Disable ' : 'Enable '} merchant</label>
                                <input type='checkbox' name='toggleDisableClient' id='toggleDisableClient' checked={merchant.enabled} onChange={handleDisableToggle} />
                                <label htmlFor='toggleDisableClient'></label>
                            </div>
                        }
                    </div>
                    <div className='col-md-4'>
                        <DataPresentationRow name='Merchant status' large={true}>
                            <div className='relative'>
                                <span className={"status mrg-top--2 " + (merchant.enabled === true ? 'online' : '')}> </span>
                                <span className='pdd-left-20'>{merchant.enabled === true ? 'enabled' : 'disabled'}</span>
                            </div>
                        </DataPresentationRow>
                    </div>
                    <div className='col-md-4'>
                        <DataPresentationRow name='No. of API Keys' large={true}>
                            <p>{merchant.apiKeys.length}</p>
                        </DataPresentationRow>
                        
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <h3 className='pull-left mrg-btm-20 mrg-top-20'>Api keys</h3>
                    </div>
                    <div className='col-md-8 relative'>
                        {(isAdmin || isMerchantAdmin) &&
                            <button className='btn btn-primary pull-right btn-long absolute top-15 right-15' onClick={showModalFunc}>
                                New API key
                                <i className='fa fa-plus-circle'></i>
                            </button>
                        }
                    </div>
                </div>

                <MerchantTable
                    isAdmin={isAdmin}
                    isMerchantAdmin={isMerchantAdmin}
                    isMerchantUser={isMerchantUser}
                    handleApiSwith={handleApiSwith}
                    handleDeleteClick={handleDeleteClick}
                    merchant={merchant}
                    merchantId={merchant.id}
                />
            </div>
        {showModal === true &&
            <NewApiKeyModalContainer
                formSent={newApiKeySubmit}
                merchant={merchant}
                onModalClosed={modalClosed}
                showOnInit={true}
            />
        }
    </Tab>
    )
}

export default ApiKeyTab;
