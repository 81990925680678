import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_MERCHANT_CONTACT = 'GET_MERCHANT_CONTACT'
export const RESET_MERCHANT_CONTACT = 'RESET_MERCHANT_CONTACT'
export const PUT_MERCHANT_CONTACT = 'PUT_MERCHANT_CONTACT'
export const RESET_PUT_MERCHANT_CONTACT = 'RESET_PUT_MERCHANT_CONTACT'

export function getMerchantContact(merchantId) {
	let data = {
	  headers: getHeader()
	}
	let dataPayload = axios.get(`${ENV.API_URL}/internal/admin/merchants/${merchantId}/contact`, data)
	
	return {
		type: GET_MERCHANT_CONTACT,
		payload: dataPayload
	}
}

export function putMerchantContact({merchantId, address1, address2, city, companyName, country, postCode}) {
	let data = {
		"addressLine1" : address1,
		"addressLine2": address2,
		"city": city,
		"country": country,
		"companyName": companyName,
		"postcode": postCode
	};
	const dataPayload = axios.put(`${ENV.API_URL}/internal/admin/merchants/${merchantId}/contact`, data, {
	    headers: getHeader()
	})
	return {
		type: PUT_MERCHANT_CONTACT,
		payload: dataPayload
	}
}

export function resetMerchantContact() {
	const dataPayload = ''
	return {
		type: RESET_MERCHANT_CONTACT,
		payload: dataPayload
	}
}

export function resetPutMerchantContact() {
	const dataPayload = ''
	return {
		type: RESET_PUT_MERCHANT_CONTACT,
		payload: dataPayload
	}
}