import React, {Component} from 'react';
import {connect} from 'react-redux';
import UnsoldPayouts from './unsold_payouts';
import {getUnsoldPayouts, resetUnsoldPayouts} from '../../actions/unsoldPayouts';

class UnsoldPayoutsContainer extends Component {
    componentDidMount() {
        this.props.getUnsoldPayouts();
    }
    componentWillUnmount() {
        this.props.resetUnsoldPayouts();
    }
    render() {
        return (
        	<UnsoldPayouts unsoldPayouts={this.props.unsoldPayouts} />
        )
    }
}


function mapStateToProps(state) {
    return {
        unsoldPayouts: state.unsoldPayouts
    }
}

export default connect(mapStateToProps, {
    getUnsoldPayouts,
    resetUnsoldPayouts
})(UnsoldPayoutsContainer);