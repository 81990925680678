import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'
 
export const GET_UNSOLD = 'GET_UNSOLD'
export const RESET_UNSOLD = 'RESET_UNSOLD'

export function getUnsoldTransactions() { 
    let data = {
        headers: getHeader()
    } 
    const dataPayload = axios.get(`${ENV.API_URL}/unsold-transactions`, data)
    return { 
        type: GET_UNSOLD, 
        payload: dataPayload
    } 
}

export function resetUnsoldTransactions() {
    const dataPayload = {}
    return {
        type: RESET_UNSOLD,
        payload: dataPayload
    }
}