import {GET_MERCHANTS_NAMES_PAGINATED} from '../actions/merchantsPaginated';
import {secureStorage} from '../helpers/secure_storage_helper';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_MERCHANTS_NAMES_PAGINATED:
			if(action.error) {
				setupRollbar(action);
				return 'error';
			}
			if(action.payload.data?.items?.length) {
				let resp = action.payload.data.items.map(function(val, i) {
					return {label: val.name, value: val.id}
				});
				return resp;
			} else {
				return [{label:'', value:''}]
			}
		default:
			return state;
	}

}