import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Textarea from 'react-validation/build/textarea'
import Button from 'react-validation/build/button'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { connect } from 'react-redux'
import $ from 'jquery'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { confirmFiatPayout } from 'actions/confirmFiatPayout'
import ModalContainer from 'components/ui_elements/modal_container'
import DatepickerContainer from 'components/ui_elements/datepicker_container'
import { secureStorage } from 'helpers/secure_storage_helper'
import { updateInputStateHook, handleModalFormSubmitHook } from 'helpers/form_helper'
import { dateObjToDate, dateToIso } from 'helpers/date_helper'
import { required } from 'helpers/validator_helper'

const ConfirmFiatPayoutModal = ({onModalClosed, showOnInit, transactionId}) => {
    const dispatch = useDispatch();
    const [reloadForm, setReloadForm] = useState(false);
    let date = new Date();
    const [state, setState] = useState({
        sentTime: dateObjToDate(date),
        orderNumber: '',
        sourceBankDetails: '',
        id: transactionId
    })
  

    const handleModalHide = (e) => {
        onModalClosed();
    }
    const handleChange = (event) => {
        updateInputStateHook(setState, state, event);
    }
    const handleDateChange = (e,el) => {
        setState((prevState) => ({
            ...prevState,
            sentTime: el
        }));
    }
    const handleSubmit = (event) => {
        handleModalFormSubmitHook({ 
            event: event,  
            sendFunction: () => dispatch(confirmFiatPayout({
                ...state,
                sentTime: dateToIso(state.sentTime) + 'T00:00:00Z'
            })),
            resetFunction: resetAndClose
        });
    }
    const resetAndClose = () => {
        onModalClosed();
        $('#confirmPayoutModal').modal('hide');
    }
    
    return (
        <ModalContainer thisId='confirmPayoutModal' title='Confirm FIAT payout' onHide={handleModalHide} showInit={showOnInit} >
            <Form onSubmit={handleSubmit} id='confirmPayoutModalForm' className='basic-form'>
                <div className='row'>
                    <div className='col-md-12'>
                        <DatepickerContainer label='Sent time' thisId='sentTime' onChange={handleDateChange} date={state.sentTime} />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='orderNumber'>Order number: *</label>
                            <Input type='text' className='form-control' id='orderNumber' name='orderNumber' validations={[required]} onChange={handleChange}/>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='sourceBankDetails'>Source bank: *</label>
                            <Textarea className='form-control' style={{paddingTop: 10, paddingBottom: 10, height: 140, minHeight: 50, resize: 'none'}} id='sourceBankDetails' name='sourceBankDetails' validations={[required]} onChange={handleChange}/>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        {reloadForm === true ? (
                            <button className='btn btn-primary pull-right width-100 mrg-btm-20 mrg-top-10 no-mrg-right' disabled>Confirm payout</button>
                        ) : (
                            <Button className='btn btn-primary pull-right width-100 mrg-btm-20 mrg-top-10 no-mrg-right' type='submit' disabled>Confirm payout</Button>
                        )}
                    </div>
                </div>
            </Form>
        </ModalContainer>
    )
    
}

ConfirmFiatPayoutModal.propTypes = {
    onModalClosed: PropTypes.func.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

export default ConfirmFiatPayoutModal;
