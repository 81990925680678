import React from 'react';
import PropTypes from 'prop-types';
import SideNav from './side_nav';
import Navbar from './navbar';
import MerchantNav from './merchant_nav';

const Layout = (props) => {
    return (
    	<div className={'app ' + props.collapsedClass}>
            <div className='layout'>
             	<SideNav 
                    active={props.active} 
                    isAdmin={props.isAdmin} 
                    isMerchant={props.isMerchant} 
                    isUnassignedMerchant={props.isUnassignedMerchant}
                    onToggle={props.handleSideNavToggle} 
                    permissions={props.permissions}  
                />
                <div className='page-container'>
                	<Navbar 
                        merchantName={props.merchantName} 
                        onToggle={props.handleSideNavToggle} 
                        merchantRole={props.merchantRole}
                    />
                    <MerchantNav 
                        account={props.account} 
                        enabled={props.enabled}
                        handleSubmit={props.handleSubmit}
                        isAdmin={props.isAdmin}
                        logout={props.logout} 
                        merchantName={props.merchantName}  
                        merchantsIds={props.merchantsIds}
                        searchMerchant={props.searchMerchant}
                        searchMerchantName={props.searchMerchantName}
                        switchMerchant={props.switchMerchant}
                    />
                    <div className='main-content'>
                        <div className='container-fluid'>
                    	   {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Layout.propTypes = {
    account: PropTypes.object.isRequired,
    active: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.node
    ]).isRequired,
    collapsedClass: PropTypes.string.isRequired,
    enabled: PropTypes.bool.isRequired,
    handleSideNavToggle: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    history: PropTypes.object,
    isAdmin: PropTypes.bool.isRequired,
    isMerchant: PropTypes.bool.isRequired,
    isUnassignedMerchant: PropTypes.bool,
    logout: PropTypes.func.isRequired,
    merchantsIds: PropTypes.array,
    merchantName: PropTypes.string.isRequired,
    merchantRole: PropTypes.string.isRequired,
    permissions: PropTypes.array,
    searchMerchant: PropTypes.func.isRequired,
    searchMerchantName: PropTypes.string.isRequired,
    switchMerchant: PropTypes.func.isRequired
}

export default Layout;