import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const PUT_CANCEL_CONVERSION = 'PUT_CANCEL_CONVERSION'
export const RESET_CANCEL_CONVERSION = 'RESET_CANCEL_CONVERSION'

export function putCancelConversion(id) {
	const dataPayload = axios.put(`${ENV.API_URL}/v3/conversions/${id}/cancel`, {},  {
	    headers: getHeader()
	});
	return {
		type: PUT_CANCEL_CONVERSION,
		payload: dataPayload
	}
}

export function resetCancelConversion() {
	const dataPayload = {}
	return {
		type: RESET_CANCEL_CONVERSION,
		payload: dataPayload
	}
}