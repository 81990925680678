import {GET_CURRENCIES} from '../actions/currencies';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = [], action) {
	switch(action.type) {
		case GET_CURRENCIES:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			let resp = action.payload.data.map(currency => (
				{
					...currency,
					precision: currency.precision ? currency.precision : 2
				}
			))
			return resp
		default:
			return state;
	}

}