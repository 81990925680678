import React from 'react'
import PropTypes from 'prop-types'
import DataPresentationRow from 'components/ui_elements/data_presentation_row'
import NumberFormatter from 'components/ui_elements/number_formatter'
import {lossStyle} from 'helpers/class_helper'
import {fullTimeUtc} from 'helpers/date_helper'

const BalanceHistoryTableInner = (props) => {
    let val = props.val;
    return (
        <div className='pdd-horizon-15 pdd-vertical-40'>
            <div className='row'>
                <div className='col-md-5 offset-md-1'>
                    <h4 className='mrg-btm-20'>Wallet info:</h4>
                    <DataPresentationRow name='Wallet confirmed'>
                        <p><span className={lossStyle(val.walletConfirmedBtc)}><NumberFormatter toFormat={val.walletConfirmedBtc} currCode='BTC' /></span> BTC (<span className={lossStyle(val.walletConfirmedEstimatedEur)}><NumberFormatter toFormat={val.walletConfirmedEstimatedEur} currCode='EUR' /></span> EUR)</p>
                    </DataPresentationRow>
                    <DataPresentationRow name='Wallet unconfirmed'>
                        <p><span className={lossStyle(val.walletUnconfirmedBtc)}><NumberFormatter toFormat={val.walletUnconfirmedBtc} currCode='BTC' /></span> BTC (<span className={lossStyle(val.walletUnconfirmedEstimatedEur)}><NumberFormatter toFormat={val.walletUnconfirmedEstimatedEur} currCode='EUR' /></span> EUR)</p>
                    </DataPresentationRow>
                </div>
                <div className='col-md-5'>
                    <h4 className='mrg-btm-20'>Exchange info:</h4>
                    <DataPresentationRow name='Kraken'>
                        <p><span className={lossStyle(val.krakenBtc)}><NumberFormatter toFormat={val.krakenBtc} currCode='BTC' /></span> BTC (<span className={lossStyle(val.krakenEur)}><NumberFormatter toFormat={val.krakenEur} currCode='EUR' /></span> EUR)</p>
                    </DataPresentationRow>
                </div>
            </div>
        </div>
    )
}

BalanceHistoryTableInner.propTypes = {
    val: PropTypes.object.isRequired
}

export default BalanceHistoryTableInner;