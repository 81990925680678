import {GET_TOKEN, REFRESH_TOKEN, RESET_GET_TOKEN} from 'actions/getToken';
import {POST_LOGOUT} from 'actions/keycloakLogout';
import {setupRollbar} from 'helpers/rollbar_helper';

const initialState = {
	session: undefined,
	lastAuthCode: undefined,
	backToUri: '',
	logout: false
}

export default function(state = initialState, action) {
	switch(action.type) {
		case GET_TOKEN:
			if(action.error) {
				setupRollbar(action);
				return {
					...initialState,
					session: 'error'
				}
			}
			return {...state, session: action.payload.data, is_backend: action.meta.is_backend}
		case REFRESH_TOKEN: 
			if(action.error) {
				setupRollbar(action);
				return {
					...initialState,
					session: 'error'
				}
			}
			return {...state, session: action.payload.data, is_backend: action.meta.is_backend}
		case POST_LOGOUT:
			if(action.error) {
				setupRollbar(action);
				return {
					...initialState,
					logout: 'error'
				}
			}
			return {
				...initialState,
				logout: true
			}
		case RESET_GET_TOKEN: 
			return initialState;
		default:
			return state;
	}

}