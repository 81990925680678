import React, {Component} from 'react';
import $ from 'jquery'; 
import _ from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'; 
import {putBankDetails, resetPutBankDetails} from 'actions/bankDetails';
import {feeMultiplied} from 'helpers/fees_helper';
import EditBillingModal from './edit_billing_modal';
import {updateInputState, handleModalFormSubmit} from 'helpers/form_helper';

class EditBillingModalContainer extends Component {
    constructor(props) {
        super(props);
        let enabled = false;
        let accountNumber = '';
        let bankName = '';
        let iban = '';
        let swift = '';
        let bankDetails =  this.props.bankDetails;
        if(!_.isEmpty(bankDetails)) {
            let bankData = bankDetails.data;
            accountNumber = bankData.accountNumber ? bankData.accountNumber : '';
            bankName = bankData.bankName ? bankData.bankName : '';
            iban = bankData.iban ? bankData.iban : '';
            swift = bankData.swift ? bankData.swift : '';
        }
        this.state = {
            accountNumber: accountNumber,
            bankName: bankName,
            disabledForm: false,
            iban: iban,
            swift: swift
        }
    }
    componentDidUpdate(prevProps) {
        let updatedBankDetails = this.props.updatedBankDetails;
        if(updatedBankDetails === 200) {
            this.props.formSent(updatedBankDetails);
            $('#editBillingModal').modal('hide');
            this.props.resetPutBankDetails();
        }
    }
    handleModalHide() {
        this.props.onModalClosed();
    }
    handleChange(event) {
        updateInputState(this, event);
    }
    handleSubmit(event) {
        let thisState = this.state;
        handleModalFormSubmit({
            self: this, 
            event: event, 
            states: {
                disabledForm: true
            }, 
            sendFunction: this.props.putBankDetails, 
            sendProps: {
                merchantId: this.props.merchantId,
                accountNumber: thisState.accountNumber, 
                bankName: thisState.bankName,
                iban: thisState.iban,
                swift: thisState.swift
            },
            resetFunction: this.props.resetFunction
        });
    }
    render() {
        let thisProps = this.props;
        let thisState = this.state;
        let showOnInit = thisProps.showOnInit || false;
        return ( 
            <EditBillingModal 
                accountNumber={thisState.accountNumber}
                bankName={thisState.bankName}
                iban={thisState.iban}
                disabledForm={thisState.disabledForm} 
                enabled={thisState.enabled}
                handleChange={this.handleChange.bind(this)}
                handleModalHide={this.handleModalHide.bind(this)} 
                handleSubmit={this.handleSubmit.bind(this)} 
                limit={thisState.limit}
                merchant={thisProps.merchant}
                showOnInit={showOnInit} 
                swift={thisState.swift}
            />
        )
    }
}

EditBillingModalContainer.propTypes = {
    bankDetails: PropTypes.object.isRequired,
    formSent: PropTypes.func.isRequired,
    merchantId: PropTypes.string.isRequired,
    onModalClosed: PropTypes.func.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

function mapStateToProps(state) { 
    return { 
        merchant: state.merchant,
        updatedBankDetails: state.updatedBankDetails
    } 
} 

export default connect(mapStateToProps, {putBankDetails, resetPutBankDetails})(EditBillingModalContainer);
