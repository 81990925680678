import React, { useEffect, useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import _ from 'lodash'
import { DashboardTabsContext } from 'contexts/dashboard.tabs.context'
import { getTransactionsHistory, resetTransactionsHistory } from 'actions/transactionsHistory'
import { getDashboardSummary, resetDashboardSummary } from 'actions/dashboardSummary'
import { getDashboardSummaryMerchant, resetDashboardSummaryMerchant } from 'actions/dashboardSummaryMerchant'
import { getLiveBalance, resetLiveBalance } from 'actions/liveBalance'
import { getLiveBalanceMerchant, resetLiveBalanceMerchant } from 'actions/liveBalanceMerchant'
import { postActiveTabDashboard } from 'actions/activeTabDashboard'

import SimpleDataBox from 'components/ui_elements/simple_data_box'
import Loading from 'components/ui_elements/loading'
import LoadingError from 'components/ui_elements/loading_error'
import ProfitModalContainer from 'components/profit/profit_modal_container'
import NumberFormatter from 'components/ui_elements/number_formatter'
import DateRangePicker from 'components/ui_elements/date_range_picker'
import NavItemTab from 'components/ui_elements/nav_item_tab'
import { profitClass, lossStyle } from 'helpers/class_helper'
import { dateToIso, dateObjToDate, parseStartOfMonthDate, parseEndDate, parseStartOfYearDate, parseEndOfYearDate } from 'helpers/date_helper'
import { secureStorage } from 'helpers/secure_storage_helper'
import variables from 'styles/_variables.scss'
import { ADMIN_PREFIX } from 'App'
import { Col, Container, Row } from 'react-bootstrap'

const MerchantBox = styled.div`
    height: 100%;
    padding: 20px 20px 60px;
    margin-top: 15px;
    margin-bottom: 0;
    position: relative;
    .btn {
        position: absolute;
        bottom: 10px;
        width: 100%;
        width: calc(100% - 40px);
    }
    h2:last-child {
        margin-bottom: 0;
    }
`

const IconCustom = styled.i`
    position: absolute;
    right: 15px;
    top: 50%;
    font-size: 35px;
`

const StyledHeader = styled.p`
    font-weight: 600;
    color: ${variables.defaultDark}
`
const processBalances = (balances = []) => {
    const orderedCurrencies = ['EUR', 'USD', 'GBP'];
    
    const sortedBalances = balances.sort((a, b) => {
        const aIndex = orderedCurrencies.includes(a.currency) ? orderedCurrencies.indexOf(a.currency) : Infinity;
        const bIndex = orderedCurrencies.includes(b.currency) ? orderedCurrencies.indexOf(b.currency) : Infinity;
        
        if (aIndex !== bIndex) return aIndex - bIndex;
        return a.currency.localeCompare(b.currency);
    });

    return sortedBalances;
};

const MerchantBalanceLine = ({balance}) => (
    <h2 className="font-primary">
        <div className="inline-block mrg-top-15 mrg-btm-15 font-size-35"><NumberFormatter toFormat={balance.amount} currCode={balance.currency} /></div>
        <span className='font-size-12 mrg-left-5'>{balance.currency}</span>
    </h2>
)

const BalanceDisplay = ({ startBalance, endBalance, isSameDay, start, end }) => {
    const sortedStartBalances = processBalances(startBalance?.monetaryValues);
    const sortedEndBalances = processBalances(endBalance?.monetaryValues);
    const allCurrencies = [...new Set([
        'EUR',
        ...sortedStartBalances.map(b => b.currency),
        ...sortedEndBalances.map(b => b.currency)
    ])];

    const findBalanceByCurrency = (balances, currency) => 
        balances.find(balance => balance.currency === currency) || { currency, amount: 0 };

    return (
        <div className='row'>
            <div className={isSameDay ? 'col-md-12' : 'col-md-6'}>
                {!isSameDay &&<span>{start}</span>}
                {allCurrencies.map(currency => (
                    <MerchantBalanceLine 
                        key={`start-${currency}`} 
                        balance={findBalanceByCurrency(sortedStartBalances, currency)} 
                    />
                ))}
                {!isSameDay &&<IconCustom className='icon ti-arrow-right'></IconCustom>}
            </div>
            {!isSameDay &&
                <div className='col-md-6'>
                    <span>{end}</span>
                    {allCurrencies.map(currency => (
                        <MerchantBalanceLine 
                            key={`end-${currency}`} 
                            balance={findBalanceByCurrency(sortedEndBalances, currency)} 
                        />
                    ))}
                </div>
            }
        </div>
    )
};

const MerchantTransactionBox = ({type, values, onClick, isSameDay, start, end}) => {
    return (
        <div className={type === 'balance' ? isSameDay ? 'col-md-4 col-sm-4' : 'col-md-6 col-sm-6' : isSameDay ? 'col-md-4 col-sm-4' : 'col-md-3 col-sm-3'}>
            <MerchantBox className='card'>
                <StyledHeader className="mrg-btm-5">{type === 'payment' ? 'Payments' : type === 'payout' ? 'Payouts' : 'Balances'}</StyledHeader>
                {type === 'balance' ? (
                    <BalanceDisplay startBalance={values.startBalance} endBalance={values.endBalance} isSameDay={isSameDay} start={start} end={end} />
                ) : (
                    <>
                        {values && values.length && values[0].monetaryValues ? (
                            <div>
                                {processBalances(values[0].monetaryValues).map(balance => (
                                    <MerchantBalanceLine balance={balance} />
                                ))}
                            </div>
                        ) : (
                            <MerchantBalanceLine balance={{amount: 0, currency: 'EUR'}} />
                        )}
                         <button className='btn btn-default' onClick={onClick}>Show details</button>
                    </>
                )}
            </MerchantBox>
        </div>
    )
}

const DashboardMerchant = () => {
    const history = useNavigate();
    const isAdmin = useSelector(state => state.isAdmin);
    const isMerchant = useSelector(state => state.isMerchant);
    const isUserAdmin = useSelector(state => state.isUserAdmin);
    const dashboardSummary = useSelector(state => state.dashboardSummary.data);
    const dashboardSummaryMerchant = useSelector(state => state.dashboardSummaryMerchant.data);
    const transactionsHistory = useSelector(state => state.transactionsHistory);
    const activeTabDashboard = useSelector(state => state.activeTabDashboard);
    const dispatch = useDispatch();
    const isSameDay = activeTabDashboard.formValues.dateFrom === activeTabDashboard.formValues.dateTo;
    const [shouldRedirect, setRedirect] = useState(false);

    useEffect(() => {
        if(shouldRedirect) {
            if(transactionsHistory.meta) {
                history(isUserAdmin ? '/' + ADMIN_PREFIX + '/context/explorer' : '/explorer');
                setRedirect(false);
            }
        }
    }, [transactionsHistory]);

    const transactionsGetHistory = (type) => {
        let dateFrom = dateToIso(activeTabDashboard.formValues.dateFrom);
        let dateTo = dateToIso(activeTabDashboard.formValues.dateTo);
        dispatch(resetTransactionsHistory());
        dispatch(getTransactionsHistory({
            transactionType: type === 'payments' ? 'PAYMENT' : 'PAYOUT',
            timeStart: dateFrom, 
            timeEnd: dateTo,
            limit: 128,
            offset: 0
        }));
        setRedirect(true);
    }

    const redirectToTransactionsListing = (type) => {
        dispatch(postActiveTabDashboard({...activeTabDashboard, overriteTransactions: true}));
        transactionsGetHistory(type);
    }

    if(isAdmin && _.isEmpty(dashboardSummary) || isMerchant && _.isEmpty(dashboardSummaryMerchant)) {
        return <Loading />
    }
    if(dashboardSummary === 'error' || dashboardSummaryMerchant === 'error') {
        return <LoadingError />
    }
    return (
        <div className='row'> 
            <MerchantTransactionBox type='payment' isSameDay={isSameDay} values={dashboardSummaryMerchant.payments} onClick={() => redirectToTransactionsListing('payments')} />
            <MerchantTransactionBox type='payout' isSameDay={isSameDay} values={dashboardSummaryMerchant.payouts} onClick={() => redirectToTransactionsListing('payouts')} />
            <MerchantTransactionBox type='balance' isSameDay={isSameDay} start={activeTabDashboard.formValues.dateFrom} end={activeTabDashboard.formValues.dateTo} values={dashboardSummaryMerchant.balanceUpdates} />
        </div>
                   
    )
}

export default DashboardMerchant;