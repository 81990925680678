import {GET_PAYOUTS_LIMIT, RESET_PAYOUTS_LIMIT} from '../actions/payoutsLimit';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_PAYOUTS_LIMIT:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return {data: action.payload.data, status: action.payload.status}
		case RESET_PAYOUTS_LIMIT:
			return action.payload
		default:
			return state;
	}

}