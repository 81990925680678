import React from 'react';
import ReactDOM from "react-dom/client";
import {Provider} from 'react-redux';
import axios from "axios";
import{createStore, applyMiddleware} from 'redux';
import ReduxPromise from 'redux-promise';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import './styles/index.scss';
import App from './App';
import reducers from './reducers';
import { initSecureStorage } from 'helpers/secure_storage_helper'
import { initValidationHelper } from 'helpers/validator_helper';
import { initBlockExplorers } from 'helpers/block_explorers';
import { initAxiosInstance } from 'axiosInstance';

const createStoreWithMiddleware = applyMiddleware(ReduxPromise)(createStore);
const root = ReactDOM.createRoot(document.getElementById("root"));
export let store = createStoreWithMiddleware(reducers);

export let ENV = {
	ADDRESS: '',
	API_URL: '',
	APP_MODE: '',
	AUTH0_URL: '',
	KEYCLOAK_CLIENT_FRONT: '',
	KEYCLOAK_CLIENT_BACK: '',
	KEYCLOAK_REALM_FRONT: '',
	KEYCLOAK_REALM_BACK: '',
	KEYCLOAK_DOMAIN: '',
	CLIENT_ID: '',
	HISTORY_BTC: '',
	HISTORY_LTC: '',
	LOCAL_URL: '',
	ROLLBAR_TOKEN: '',
	TX: '',
	PAYMENT_PAGE_URL: '',
	IS_TEST: false,
	GA_KEY: '',
	NODE_OPTIONS: ''
}

const getEnv = async () => {
	try {
		const { data } = await axios.get('/config.json');
		ENV = {
			...data
		};
	} catch (error) {
		alert("Failed to fetch environment config", error);
	}
}

const initUi = () => {
	getEnv().then(() => {
		initSecureStorage()
		initValidationHelper()
		initBlockExplorers()
		initAxiosInstance()
		root.render(
			<Provider store={store}>
				<App />
			</Provider>
		)
	})
}

window.addEventListener('DOMContentLoaded', initUi());