import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from 'helpers/headers_helper'

export const GET_MERCHANT_DASHBOARD_SUMMARY = 'GET_MERCHANT_DASHBOARD_SUMMARY'
export const RESET_MERCHANT_DASHBOARD_SUMMARY = 'RESET_MERCHANT_DASHBOARD_SUMMARY'

export function getDashboardSummaryMerchant({from, to, merchantId}) {
	let data = {
	  headers: getHeader(),
	  params: {
	  	from: from,
	  	to: to
	  }
	}
	let dataPayload = axios.get(`${ENV.API_URL}/analytics/dashboard/summary/${merchantId}`, data)
	
	return {
		type: GET_MERCHANT_DASHBOARD_SUMMARY,
		payload: dataPayload
	}
}


export function resetDashboardSummaryMerchant() {
	const dataPayload = ''
	return {
		type: RESET_MERCHANT_DASHBOARD_SUMMARY,
		payload: dataPayload
	}
}
