import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import generateZeroWithDecimals from 'helpers/generate_zero_with_decimals'


const NumberFormatter = ({toFormat, currCode, isCoin, isAbs, noJsx, overridePrecision}) => {
	
	const currencies = useSelector(state => state.currencies);
	const [currencyDetails, setCurrencyDetails] = useState();
	useEffect(() => {
		if(overridePrecision) {
			setCurrencyDetails({precision: overridePrecision});
		} else {
			if(currencies.length) {
				let currDetails = currencies.find((curr) => curr.code === currCode);
				if(currDetails) {
					setCurrencyDetails(currDetails);
				} else {
					setCurrencyDetails({precision: 2});
				}
			}
		}
		
    }, [currencies, currCode]);
    if(!currencyDetails || !currencyDetails.precision) {
    	return <>0</>
    }
	let el = toFormat;
	if(typeof toFormat === 'array' || typeof toFormat === 'object' || typeof toFormat === 'undefined') {
		return <>0</>
	}
	if(typeof toFormat === 'string') {
		el = parseFloat(toFormat);
	}
	if(isNaN(el)) {
        return <>0</>;
    }
	let value = el.toFixed(currencyDetails.precision);
	if(el > 0 && value == 0) {
		return <>{generateZeroWithDecimals(currencyDetails.precision)}</>
		
	}
	if(isAbs) {
		value = Math.abs(value);
	}
	let numb = value.toString().split('.');
	if(noJsx) {
		return numb[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'") + '.' + numb[1];
	}
	return <>{numb[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'") + '.' + numb[1]}</>;
}

export default NumberFormatter;