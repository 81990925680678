import React, { useContext, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TabsContext } from 'contexts/tabs.context'
import { getMerchant } from 'actions/merchant'
import { resetNotificationKey } from 'actions/notificationKey'
import { initAlert } from 'helpers/alert_helper'
import Tab from 'components/ui_elements/tab'
import Tooltip from 'components/ui_elements/tooltip'
import NewNotifyKeyModalContainer from './new_notify_key_modal_container'

const NotifyTab = ({}) => {
    const dispatch = useDispatch();
    const { active } = useContext(TabsContext);
    const merchant = useSelector(state => state.merchant);
    const isAdmin = useSelector(state => state.isAdmin);
    const isMerchantAdmin = useSelector(state => state.isMerchantAdmin);

    const merchantId = merchant.id;
    const [showNotifyModal, setShowNotifyModal] = useState(false);

    const showNotifyModalFunc = () => {
        setShowNotifyModal(true);
    }
    const newNotifyKeySubmit = (data) => {
        if(data.type === 'error') {
            initAlert({
                type: 'error', 
                title: 'Error', 
                html: data.response, 
                confirmText: 'OK'
            });
            dispatch(resetNotificationKey());
        } else {
            initAlert({
                type: 'info', 
                title: 'Notification Key created', 
                html: 
                    'New Notification Key was created!' +
                    '<br />' +
                    '<br />' +
                    'Your new Notification Key number:' +
                    '<br />' +
                    '<br />' +
                    '<div class="col-md-12">' +
                        '<div class="card no-mrg-top no-mrg-btm">' +
                            '<div class="card-block">' +
                                '<div>' +
                                    '<p class="clearfix no-mrg-btm">' +
                                        '<span>' + data.key + '</span>' +
                                    '</p>' +
                                '</div>' +
                            '</div>' +
                        '</div>' +
                    '</div>' +
                    '<br />' +
                    '<br />' +
                    'You will not be able to see Your Notification Key number,' +
                    '<br />' + 
                    'after closing this window!' +
                    '<br />' +
                    '<br />' +
                    'Please confirm that You saved Your Notification Key' +
                    '<br />' + 
                    'somewhere safe.', 
                confirmText: 'I saved Notification Key', 
                onSuccess: onExit, 
                onDismiss: onExit, 
                isShownCancelButton: false
            });
            dispatch(resetNotificationKey());
            dispatch(getMerchant(merchantId));
        }
        
    }
    const notifyModalClosed = () => {
        setShowNotifyModal(false);
    }
    const onExit = () => {
        return
    }
    return (
        <Tab name='notify' labelledby='notify-tab' active={active}>
                <div className='pdd-horizon-30 pdd-vertical-30'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h2 className='mrg-btm-20'>Notification Key <span className='relative top--2'><Tooltip id='notificationTooltip' placement='top' text='Notification authentication' /></span></h2>
                            {merchant.wasHmacKeyGenerated &&
                                <div className="alert alert-info font-size-14" role="alert">
                                    You have already generated Notification Key.
                                </div>
                            }
                            <p className='mrg-btm-30'>
                                To verify notification sent by Bitcoin Payment Processor, you need to generate Notification Key. 
                                Key should be generated once, if you already have a key, generating new one will replace the old one.
                            </p>
                            {(isAdmin || isMerchantAdmin) &&
                                <button className='btn btn-default btn-long' onClick={showNotifyModalFunc}>Generate Notification Key</button>
                            }
                        </div>
                    </div>
                </div>
                {showNotifyModal === true &&
                    <NewNotifyKeyModalContainer 
                        formSent={newNotifyKeySubmit} 
                        merchant={merchant.id} 
                        onModalClosed={notifyModalClosed} 
                        showOnInit={true} 
                    />
                }
        </Tab>
    )
}

export default NotifyTab;