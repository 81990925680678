import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import React from 'react';
import PropTypes from 'prop-types';
import ModalContainer from 'components/ui_elements/modal_container';

const NewNotifyKeyModal = (props) => {
    return (
    	<ModalContainer thisId='notifyKeyModal' title='New Notification Key' onHide={props.handleModalHide} showInit={props.showOnInit}>
            <Form onSubmit={props.handleSubmit} id='newNotificationKeyForm' className='basic-form'>
                <div className='row'>
                    <div className='col-md-12'>
                        <p className='mrg-btm-30'>Create new Notification Key for verifying notification sent by Bitcoin Payment Processor. If You already have a key, the old one will be replaced.</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button type='button' className='btn btn-default' data-dismiss='modal'>Cancel</button>
                        {props.disabledForm === true ? (
                            <button className='btn btn-primary pull-right disabled' type='submit' disabled>Create Notification Key</button>
                        ) : (
                            <Button className='btn btn-primary pull-right' type='submit'>Create Notification Key</Button>
                        )}
                    </div>
                </div>
            </Form>
        </ModalContainer>
    )
}

NewNotifyKeyModal.propTypes = {
    disabledForm: PropTypes.bool.isRequired,
    handleModalHide: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    merchant: PropTypes.object.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

export default NewNotifyKeyModal;