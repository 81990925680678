import {GET_BALANCE_ALL_SNAPSHOT, RESET_BALANCE_ALL_SNAPSHOT} from '../actions/balanceAllSnapshot';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_BALANCE_ALL_SNAPSHOT:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return {data: action.payload.data, status: action.payload.status, meta: action.meta};
		case RESET_BALANCE_ALL_SNAPSHOT: 
			return action.payload;
		default:
			return state;
	}

}