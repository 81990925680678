import {DISABLE_MERCHANT, RESET_DISABLE_MERCHANT} from '../actions/merchant';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case DISABLE_MERCHANT:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return 'OK';
		case RESET_DISABLE_MERCHANT:
			return action.payload;
		default:
			return state;
	}
}
