import {DELETE_API_KEY, RESET_API_KEY} from '../actions/apiKey';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case DELETE_API_KEY:
			let response = 'Some error occured while deleting key, please try again';
			if(action.error) {
				setupRollbar(action);
				return {type: 'error', response: response}
			}
			return action.payload.status;
		case RESET_API_KEY:
			return action.payload;
		default:
			return state;
	}

}