import React, { useEffect} from 'react';
import {Link} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ADMIN_PREFIX } from 'App';
import Error404Icon from 'images/icons/404-9.svg'

const NotFound = () => {
    const isAdmin = useSelector(state => state.isAdmin);
    const isUserAdmin = useSelector(state => state.isUserAdmin);
    let prefix = isAdmin ? '/' + ADMIN_PREFIX : isUserAdmin ? '/' + ADMIN_PREFIX + '/context' : '';
    useEffect(() => {
        document.title = "404 - Bitcoin Payment Processor";
    }, []);
    return (
        <div className='container text-center'>
            <br />
            <br />
            <br />
            <br />
            <img src={Error404Icon} style={{height: 350}} />
            <br />
            <br />
            <h2>Page not found</h2>
            <p>We are sorry but the page you are looking for does not exist</p>
            <Link className='btn btn-blue' to={prefix + '/'}>Go to Start Page</Link>
        </div>
    )
}

export default NotFound