import React, {Component} from 'react';
import $ from 'jquery'
import { tooltip } from 'bootstrap'
import PropTypes from 'prop-types'

class Tooltip extends Component {
    componentDidMount() {
        let classNames = this.props.customClass ? 'tooltip ' + this.props.customClass : 'tooltip'
        let template = '<div class="' + classNames + '"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
        $('#' + this.props.id).tooltip({
            template: template
        });
    }
    render() {
        if(this.props.custom) {
            return (
                <span 
                    id={this.props.id} 
                    data-toggle="tooltip" 
                    data-placement={this.props.placement} 
                    title={this.props.text}>
                    {this.props.children}
                </span>
            )
        } else {
            return (

                <i
                    id={this.props.id} 
                    className='ti-help-alt help' 
                    data-toggle="tooltip" 
                    data-placement={this.props.placement} 
                    title={this.props.text}
                    data-html='true'
                    >
                </i>
            )
        }
    }
}

Tooltip.propTypes = {
    id: PropTypes.string.isRequired,
    classNames: PropTypes.string,
    placement: PropTypes.string.isRequired,
    custom: PropTypes.bool,
    text: PropTypes.string
}

export default Tooltip;