export function calculatePage(offset) {
    return parseInt(offset, 10) + 1;
}

export function calculatePages(allResults, limit) {
    return Math.ceil(allResults / limit);
}

export function handlePageChange({self, offset, funct, args}) {
    self.setState({offset: offset});
    funct( ...args );
}

export function handlePageHookChange({setOffset, offset, funct, args}) {
    setOffset(offset);
    funct( ...args );
}

export function handlePaginationChange({self, limit, offset, funct, args}) {
    let limitNum = parseInt(limit, 10);
    self.setState({
        limit: limitNum,
        offset: offset
    });
    funct(...args);
}

export function handlePaginationHookChange({setLimit, limit, setOffset, offset, funct, args}) {
    let limitNum = parseInt(limit, 10);
    setLimit(limit);
    setOffset(offset);
    funct(...args);
}