import { createContext, useState, useEffect } from 'react'

export const DashboardTabsContext = createContext({

})

export const DashboardTabsProvider = ({children}) => {
	const [active, setActive] = useState('payments');

	const value = {
		active,
		setActive
	}
	return <DashboardTabsContext.Provider value={value}>{children}</DashboardTabsContext.Provider>
}