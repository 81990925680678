import React from 'react';
import PropTypes from 'prop-types';

const Datepicker = (props) => {
    return (
        <div>
            <div className='form-group no-mrg'>
                <label style={{marginBottom: 8}} htmlFor='from'>{props.label ? props.label + ':' : 'Date:'}</label>
                <div className='datepicker-container input-append date'>
                    <input style={{height: 38}} type='text' id={props.thisId} name='date' className='form-control' value={props.date} onChange={props.handleChange} />
                    <label htmlFor={props.thisId}>
                        <i className='ei ei-calendar-flip datepicker-icon add-on'></i>
                    </label>
                </div>
            </div>
        </div>
    )
}

Datepicker.propTypes = {
    date: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    thisId: PropTypes.string.isRequired,
}

export default Datepicker;