import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Textarea from 'react-validation/build/textarea'
import Button from 'react-validation/build/button'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import $ from 'jquery'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { getPayoutLimit, resetPayoutLimit } from 'actions/payoutsLimit'
import { getAddressValidation, resetAddressValidation } from 'actions/addressValidate'
import { getCurrencies } from 'actions/currencies'
import { getPayoutPrice, resetPayoutPrice } from 'actions/payouts'
import { getBankDetails } from 'actions/bankDetails'
import ModalContainer from 'components/ui_elements/modal_container'
import NumberFormatter from 'components/ui_elements/number_formatter'
import Selectize from 'components/ui_elements/selectize'
import { secureStorage } from 'helpers/secure_storage_helper'
import { updateInputStateHook, handleModalFormSubmitHook } from 'helpers/form_helper'
import { required, maxValue, coinaddress, fiat, number, maxFx, minValue, maxlength, btc_min, fiat_min } from 'helpers/validator_helper'
import { totalCommaPrecision } from 'helpers/number_helper'

const NewPayoutModal = ({onModalClosed, formSent, showOnInit}) => {
    const dispatch = useDispatch();
    const addressValid = useSelector(state => state.addressValid);
    const allPayoutCurrencies = useSelector(state => state.allPayoutCurrencies);
    const payoutPriceCurrencies = useSelector(state => state.payoutPriceCurrencies);
    const currencies = useSelector(state => state.currencies);
    const isAdmin = useSelector(state => state.isAdmin);
    const isMerchant = useSelector(state => state.isMerchant);
    const bankDetails = useSelector(state => state.bankDetails);
    const payoutTransferCurrencies = useSelector(state => state.payoutTransferCurrencies);
    const payoutsLimit = useSelector(state => state.payoutsLimit);
    const payoutsLimitEur = useSelector(state => state.payoutsLimitEur);
    const payoutPrice = useSelector(state => state.payoutPrice);
    const [available, setAvailable] = useState(0);
    const [minPayoutAmount, setMinPayoutAmount] = useState({label: 0, value: 0});
    const [currency, setCurrency] = useState({value: '', currencyType: 'crypto'});
    const [isAddressValid, setIsAddressValid] = useState(false);
    const merchantId = secureStorage.getItem('merchantId');
    const [state, setState] = useState({
        amountPayoutCoin: '',
        amountPayoutFiat: '',
        currencyPayout: {},
        gettingLimit: false,
        isFormDisabled: false,
        isShownPrice: false,
        loadedLimit: false,
        payCurrency: {},
        payoutAutomatically: false,
        payoutDestination: '',
        payoutNotificationUrl: '',
        payoutComment: '',
        price: 0,
        priceShown: false,
        reloadForm: false,
        skipTrading: false,
        timeout: '',
        typed: false,
        userType: secureStorage.getItem('role'),
        currencyPrecision: 2
    });
    useEffect(() => {
        getBasicLimit();
        if(isMerchant) {
            dispatch(getBankDetails(merchantId))
        }
        if(_.isEmpty(currencies)) {
            dispatch(getCurrencies());
        }
        return () => {
            resetTimeout();
            dispatch(resetAddressValidation());
            dispatch(resetPayoutLimit());
        }
    }, []);

    useEffect(() => {
        if(state.reloadForm === true) {
            if(isMerchant) {
                dispatch(getPayoutLimit({priceCurrency: state.currencyPayout, transferCurrency: state.payCurrency}));
                setState((prevState) => ({
                    ...prevState,
                    reloadForm: false,
                    gettingLimit: true
                }));
            } else {
                setState((prevState) => ({
                    ...prevState,
                    reloadForm: false
                }));
            }
            
        }
    }, [state.reloadForm]);

    useEffect(() => {
        getBasicLimit();
    }, [state.payCurrency, state.currencyPayout]);

     useEffect(() => {
        if(state.currencyPayout.value) {
            let currSettings = currencies.find(curr => curr.code === state.currencyPayout.value);
            if(currSettings) {
                setState((prevState) => ({
                    ...prevState,
                    currencyPrecision: currSettings.precision
                }));
            }
        }
    }, [state.currencyPayout]);

    useEffect(() => {
        if(isMerchant && (state.gettingLimit === true && (state.currencyPayout.value === payoutsLimit.data.currency))) {
            setState((prevState) => ({
                ...prevState,
                gettingLimit: false
            }));
        }
    }, [payoutsLimit]);

    useEffect(() => {
        if(state.gettingPrice === true && (payoutPrice)) {
            let price = payoutPrice.transferAmount;
            setState((prevState) => ({
                ...prevState,
                gettingPrice: false,
                priceShown: true,
                price: price
            }));
        }
    }, [payoutPrice]);

    useEffect(() => {
        if(state.gettingAddressValidation === true && !_.isEmpty(addressValid)) {
            setState((prevState) => ({
                ...prevState,
                gettingAddressValidation: false
            }));
        }
        getBasicLimit();
    }, [addressValid]);
    useEffect(() => {
        if(state.payCurrency.value) {
            setCurrency({value: state.payCurrency.value, currencyType: payoutTransferCurrencies.find(el => state.payCurrency.value === el.code).currencyType});
        }
    }, []);
    useEffect(() => {
        if(isMerchant) {
            if(!_.isEmpty(payoutsLimit) && payoutsLimit.data !== undefined) {
                let newAvailable = payoutsLimit.data.available;
                //newAvailable = Math.round(newAvailable * 100) / 100;
                setAvailable(newAvailable);
                setMinPayoutAmount({
                    value: payoutsLimit.data.minPayoutAmount,
                    label : totalCommaPrecision({
                        toFormat: payoutsLimit.data.minPayoutAmount,
                        currCode: payoutsLimit.data.currency,
                        currencies: currencies
                    })
                });
            }
        } else {
            setAvailable(9999999);
        }
    }, [payoutsLimit]);
    useEffect(() => {
        let newCurrency = payoutTransferCurrencies.find(el => state.payCurrency.value === el.code);
        if(newCurrency) {
            setCurrency({value: state.payCurrency.value, currencyType: newCurrency.currencyType});
        }
    }, [state.currencyPayout, state.payCurrency]);

    useEffect(() => {
        if(addressValid.data) {
            setIsAddressValid(addressValid.data.isValid);
        } 
    }, [addressValid]);

    const getBasicLimit = () => {
        if(isMerchant && state.loadedLimit === false && !_.isEmpty(state.payCurrency) && !_.isEmpty(state.currencyPayout)) {
            dispatch(getPayoutLimit({priceCurrency: state.currencyPayout, transferCurrency: state.payCurrency}));
            setState((prevState) => ({
                ...prevState,
                loadedLimit: true
            }));
        }
    }
    const resetTimeout = () => {
        clearTimeout(state.timeout);
    }
    const handleModalHide = () => {
        onModalClosed();
    }
    const handleChange = (event) => {
        updateInputStateHook(setState, state, event);
    }
    const calcDecimalPoints = (numStr) => {
        var pieces = numStr.split(".");
        if(pieces[1] !== undefined) {
            return pieces[1].length;
        }
        return 0;
        
    }
    const handleAddressChange = (event) => {
        let currency = state.payCurrency.value;
        dispatch(resetAddressValidation());
        dispatch(getAddressValidation({address: event.target.value, currency: currency}));
        setState((prevState) => ({
            ...prevState,
            gettingAddressValidation: true
        }));
        updateInputStateHook(setState, state, event);
    }
    const handleExchange = ({event, from, payChangeTo, calculateFrom}) => {
        let etar = event;
        let max = 0;
        if(isMerchant) {
            max = payoutsLimit.data.available;
        } else {
            max = 9999;
        }
        let decimalPoints = calcDecimalPoints(etar);
        let conditions = isMerchant && (etar >= fiat_min && etar <= max && (etar.slice(-1) !== '.' || etar.slice(-1) !== ',') && decimalPoints < 3);
        let timeoutVal = 'timeout'; 
        let currency  = state.currencyPayout;
        if(from === 'coin') {
            conditions = etar >= btc_min && etar <= max && (isAdmin ? (etar):(etar <= max)) && (etar.slice(-1) !== '.' || etar.slice(-1) !== ',') && decimalPoints < 9;
            currency = payChangeTo;
        }
        if(conditions) {
            resetTimeout();
            let timeout = setTimeout(() => {
                dispatch(resetPayoutPrice(from));
                let payCurrency = calculateFrom ? calculateFrom : state.currencyPayout.value;
                dispatch(getPayoutPrice(etar, currency, payCurrency));
                setState((prevState) => ({
                    ...prevState,
                    gettingPrice: true
                }));
            }, 500);
            setState((prevState) => ({
                ...prevState,
                [timeoutVal]: timeout
            }));
         } else {
            resetTimeout();
            setState((prevState) => ({
                ...prevState,
                priceShown: false,
                gettingPrice: false
            }));
        }
    }
    const handleCoinChange = (event) => {
        setState((prevState) => ({
            ...prevState,
            amountPayoutCoin: event.target.value,
            amountPayoutFiat: '',
            price: 0,
            priceShown: false
        }));
        handleExchange({event: event.target.value, from: 'coin', payChangeTo: state.payCurrency});
    }
    const handleSubmit = (event) => {
        handleModalFormSubmitHook({ 
            event: event,  
            sendFunction: () => formSent({
                coin: state.amountPayoutCoin, 
                fiat: state.amountPayoutFiat, 
                address: state.payoutDestination, 
                currency: state.currencyPayout, 
                payCurrency: state.payCurrency,
                autoApprove: state.payoutAutomatically,
                notificationUrl: state.payoutNotificationUrl,
                comment: state.payoutComment,
                skipTrading: state.skipTrading
            }),
            resetFunction: resetAndClose
        });
    }
    const resetAndClose = () => {
        onModalClosed();
        $('#merchantModal').modal('hide');
    }
    const handleSelectChange = (val, e) => {
        setState((prevState) => ({
            ...prevState,
            [e]: {value: val, label: val},
            reloadForm: true,
            priceShown: false,
            price: 0
        }));
        if(state.amountPayoutCoin !== '' && state.amountPayoutCoin > 0) {
            handleExchange({event: state.amountPayoutCoin, from: 'coin', payChangeTo: state.payCurrency, calculateFrom: val});
        }
    }
    const handlePaySelectChange = (val,e) => {
        setState((prevState) => ({
            ...prevState,
            [e]: {value: val, label: val},
            reloadForm: true,
            price: 0,
            priceShown: false
        }));
        handleExchange({event: state.amountPayoutCoin, from: 'coin', payChangeTo: val});
        if(state.payoutDestination && state.payoutDestination !== '') {
            let currency = state.payCurrency.value;
            dispatch(resetAddressValidation());
            dispatch(getAddressValidation({address: state.payoutDestination, currency: val}));
            setState((prevState) => ({
                ...prevState,
                gettingAddressValidation: true
            }));
        }
    }
    const handleCheckboxChange = (val) => {
        setState((prevState) => ({
            ...prevState,
            payoutAutomatically: val.target.checked
        }));
    }
    const handleSkipTrading = () => {
        setState((prevState) => ({
            ...prevState,
            skipTrading: !state.skipTrading
        }));
    }
    const selectMounted = (el, id) => {
        setState((prevState) => ({
            ...prevState,
            [id]: el
        }));
    }
    const removeApiError = (thisForm) => {
        thisForm.hideError();
    }
    return (
        <ModalContainer thisId='merchantModal' title='New payout' onHide={handleModalHide} showInit={showOnInit} >
            <Form onSubmit={handleSubmit} id='newPayoutForm' className='basic-form'>
                <div className='row no-mrg'>
                    {allPayoutCurrencies.length ? (
                        <div className='col-lg-6 width-sm-screen-12 no-pdd-left pdd-right-7 no-pdd-sm-screen'>
                            <div className='form-group clearfix'>
                                <label htmlFor='currencyPayout'>Calculate based on: *</label>
                                <Selectize thisId='currencyPayout' dataOptions={allPayoutCurrencies} onMounted={selectMounted} isSearchable={true} onChange={handleSelectChange} />
                            </div>
                        </div>
                    ) : (
                        <span></span>
                    )}
                    {(!_.isEmpty(payoutsLimit) || payoutsLimit.data !== undefined) ? (
                        <div className='col-lg-6 width-sm-screen-12 no-pdd-right pdd-lrft-7 no-pdd-sm-screen'>
                            <div className='form-group'>
                                <label htmlFor='amountPayoutCoin'>Amount to payout ({state.currencyPayout.value}): *</label>
                                <Input type='text' className={'form-control ' + (payoutPrice === 'error' && 'is-invalid-input')} id='amountPayoutCoin' name='amountPayoutCoin' data-usertype={state.userType} data-precision={state.currencyPrecision} validations={[required, number, maxValue, minValue]} data-payout={true} data-max={available} data-min={minPayoutAmount.value} data-min-label={minPayoutAmount.label} data-currency={state.currencyPayout.value} value={state.amountPayoutCoin} onChange={handleCoinChange} />
                            </div>
                        </div>
                    ) : (
                        <div className='mrg-top-15'></div>
                    )}
                </div>
                {payoutPriceCurrencies.length && payoutTransferCurrencies.length ? (
                    <div className='row'>
                        {(payoutPriceCurrencies.includes(state.currencyPayout.value)) &&
                            <div className='col-md-12'>
                                <div className='form-group clearfix'>
                                    <label htmlFor='payCurrency'>Pay in: *</label>
                                    <Selectize thisId='payCurrency' dataOptions={isAdmin ? payoutTransferCurrencies.filter(el => el.currencyType === 'crypto').map(curr => curr.code) : payoutTransferCurrencies.map(curr => curr.code)} onMounted={selectMounted} isSearchable={true} onChange={handlePaySelectChange} />
                                </div>
                            </div>
                        }
                    </div>
                ) : (<span></span>)}
                {currency.currencyType === 'crypto' ? (
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label htmlFor='payoutDestination'>Destination address: *</label>
                                <Input type='text' className='form-control' id='payoutDestination' name='payoutDestination' validations={[required, coinaddress]} data-coin={state.payCurrency.value} value={state.payoutDestination} data-valid-address={isAddressValid}  onChange={handleAddressChange}/>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        {isMerchant && bankDetails && (
                            <>
                                {_.isEmpty(bankDetails.data) ? (
                                    <>
                                        {/*to={'/merchants/' + encodeBasicUrl(merch.id)}*/}
                                        <p className='text-danger'>There is no billing data defined, please contact us to update your billing data</p>
                                        <br />
                                        <br />
                                    </>
                                ) : (
                                    <>
                                        <p>Payout in EUR will be sent as a bank transfer to:</p>
                                        <div className='well available-well'>
                                            <p>Bank: <b>{bankDetails.data.bankName}</b></p>
                                            <p>IBAN: <b>{bankDetails.data.iban}</b></p>
                                            <p className='no-mrg-btm'>Swift: <b>{bankDetails.data.swift}</b></p>
                                        </div>
                                    </>
                                )}
                                
                            </>
                        )}
                    </>
                )}
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='payoutNotificationUrl'>Notification Url:</label>
                            <Input type='text' className='form-control' id='payoutNotificationUrl' name='payoutNotificationUrl' value={state.payoutNotificationUrl} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='payoutComment'>Comment:</label>
                            <Textarea rows='3' className='form-control textarea-small' id='payoutComment' name='payoutComment' data-maxlength='150' data-optional='true' data-name='comment' validations={[maxlength]} value={state.payoutComment} onChange={handleChange}></Textarea>
                        </div>
                    </div>
                </div>
                {isAdmin && currency.currencyType === 'crypto' && 
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group mrg-btm-5'>
                                <div className='checkbox checkbox-inline checkbox-primary'>
                                    <input id='skipTrading' name='skipTrading' type='checkbox' checked={state.skipTrading} onChange={handleSkipTrading} />
                                    <label htmlFor='skipTrading'>Skip trading (prevent placing trade requests)</label>
                                </div>
                            </div>
                       </div>
                    </div>
                }
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <div className='checkbox checkbox-inline checkbox-primary'>
                                <input id='payoutAutomatically' name='payoutAutomatically' type='checkbox' checked={state.payoutAutomatically} onChange={handleCheckboxChange} />
                                <label htmlFor='payoutAutomatically'>Payout automatically (skip calculation step)</label>
                            </div>
                        </div>
                   </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 text-right small'>
                        <p className='mrg-btm-5'>* - Field is required</p>
                    </div>
                    <div className='col-md-12 text-right small'>
                        {payoutPrice === 'error' ? (
                            <span></span>
                        ) : (
                            <div>
                                {state.priceShown === true &&
                                    <div>
                                        <h6 className='text-center mrg-top-15 mrg-btm-15'>Estimated value for given parameters is:</h6>
                                        <h3 className='text-center mrg-btm-20'><NumberFormatter toFormat={state.price} currCode={currency ? currency.value : ''} overridePrecision={currency.currencyType === 'fiat' && 4} /> {currency ? currency.value : ''}</h3>
                                    </div>
                                }
                            </div>
                        )}
                        {state.payoutAutomatically === false &&
                            <h6 className='text-center mrg-top-15 mrg-btm-15'>Click 'Calculate' to get price. The price will be calculated based on {currency ? currency.value : ''} exchange rate and will be valid for 1 hour. </h6>
                        }
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        {state.gettingLimit === true || state.reloadForm === true || (currency.currencyType === 'fiat' && _.isEmpty(bankDetails.data) && isMerchant) ? (
                            <button className='btn btn-primary pull-right width-100 mrg-btm-20 mrg-top-10 no-mrg-right' disabled>{state.payoutAutomatically ? 'Send payout' : 'Calculate payout'}</button>
                        ) : (
                            <Button className='btn btn-primary pull-right width-100 mrg-btm-20 mrg-top-10 no-mrg-right' type='submit' disabled>{state.payoutAutomatically ? 'Send payout' : 'Calculate payout'}</Button>
                        )}
                    </div>
                </div>
            </Form>
        </ModalContainer>
    )
    
}

NewPayoutModal.propTypes = {
    formSent: PropTypes.func.isRequired,
    onModalClosed: PropTypes.func.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

export default NewPayoutModal;
