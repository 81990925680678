import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'; 
import ProfitModal from './profit_modal';


class ProfitModalContainer extends Component {
    handleModalHide() {
        this.props.onModalClosed();
    }
    render() {
        let thisProps = this.props;
        let showOnInit = thisProps.showOnInit || false;
        return ( 
            <ProfitModal 
                handleModalHide={this.handleModalHide.bind(this)} 
                paymentId={thisProps.paymentId}
                profitDetails={this.props.profitDetails}
                showOnInit={showOnInit} 
            />
        )
    }
}

ProfitModalContainer.propTypes = {
    onModalClosed: PropTypes.func.isRequired,
    paymentId: PropTypes.string.isRequired,
    profitDetails: PropTypes.object.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

function mapStateToProps(state) { 
    return { 
    } 
} 

export default connect(mapStateToProps, {})(ProfitModalContainer);