import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from 'helpers/headers_helper'

export const GET_LIVE_BALANCE_MERCHANT = 'GET_LIVE_BALANCE_MERCHANT'
export const RESET_LIVE_BALANCE_MERCHANT = 'RESET_LIVE_BALANCE_MERCHANT'

export function getLiveBalanceMerchant({merchantId}) {
	let data = {
	  headers: getHeader()
	}
	let dataPayload = axios.get(`${ENV.API_URL}/analytics/dashboard/live-balances/${merchantId}`, data)
	
	return {
		type: GET_LIVE_BALANCE_MERCHANT,
		payload: dataPayload
	}
}


export function resetLiveBalanceMerchant() {
	const dataPayload = ''
	return {
		type: RESET_LIVE_BALANCE_MERCHANT,
		payload: dataPayload
	}
}
