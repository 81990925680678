import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_MERCHANT = 'GET_MERCHANT'
export const GET_SELF_MERCHANT = 'GET_SELF_MERCHANT'
export const POST_MERCHANT = 'POST_MERCHANT'
export const DISABLE_MERCHANT = 'DISABLE_MERCHANT'
export const RESET_POST_MERCHANT = 'RESET_POST_MERCHANT'
export const RESET_DISABLE_MERCHANT = 'RESET_DISABLE_MERCHANT'
export const RESET_MERCHANT = 'RESET_MERCHANT'
export const RESET_SELF_MERCHANT = 'RESET_SELF_MERCHANT'

export function getMerchant(merchantId, self) {
	let data = {
	  	headers: getHeader()
	}
	let dataPayload
	let type = GET_MERCHANT;
	if(merchantId === 'undefined') {
		dataPayload = {response: 200, data: {}}
	} else {
		dataPayload = axios.get(`${ENV.API_URL}/internal/admin/merchants/${merchantId}`, data)
	}
	if(self === true) {
		type = GET_SELF_MERCHANT
	}
	return {
		type: type,
		payload: dataPayload
	}
}

export function postMerchant({formValues}) {
	let data = formValues
	const dataPayload = axios.post(`${ENV.API_URL}/internal/admin/merchants`, data, {
	    headers: getHeader()
	})
	return {
		type: POST_MERCHANT,
		payload: dataPayload
	}
}

export function disableMerchant({merchantId, enabled}) {
	let data = {
		"enabled": enabled 
	};
	const dataPayload = axios.put(`${ENV.API_URL}/internal/admin/merchants/${merchantId}`, data, {
	    headers: getHeader()
	})
	return {
		type: DISABLE_MERCHANT,
		payload: dataPayload
	}
}
export function resetPostMerchant() {
	const dataPayload = {}
	return {
		type: RESET_POST_MERCHANT,
		payload: dataPayload
	}
}
export function resetDisableMerchant() {
	const dataPayload = ''
	return {
		type: RESET_DISABLE_MERCHANT,
		payload: dataPayload
	}
}

export function resetMerchant() {
	const dataPayload = ''
	return {
		type: RESET_MERCHANT,
		payload: dataPayload
	}
}
export function resetSelfMerchant() {
	return {
		type: RESET_SELF_MERCHANT,
		payload: {}
	}
}
