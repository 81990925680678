import axios from 'axios'
import { ENV } from "index"

export const getBasePath = (isBackend) => `/realms/${isBackend ? ENV.KEYCLOAK_REALM_BACK : ENV.KEYCLOAK_REALM_FRONT }/protocol/openid-connect`
export const getKeycloakTokenUrl = (isBackend) => `${getBasePath(isBackend)}/token`
export const getKeycloakLogoutUrl = (isBackend) => `${getBasePath(isBackend)}/logout`

export const GET_TOKEN = 'GET_TOKEN'
export const POST_LOGOUT = 'POST_LOGOUT'
export const RESET_GET_TOKEN = 'RESET_GET_TOKEN'
export const REFRESH_TOKEN = 'REFRESH_TOKEN'
export const RESET_REFRESH_TOKEN = 'RESET_REFRESH_TOKEN'

export const getToken = ({ keycloakDomain, code, redirect_uri, client_id, is_backend}) => {
	const dataPayload = axios.post(
		`${keycloakDomain}${getKeycloakTokenUrl(is_backend)}`,
		new URLSearchParams({
			client_id: client_id,
			grant_type: 'authorization_code',
			code,
			redirect_uri,
		}),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		},
	)
	return {
		type: GET_TOKEN,
		payload: dataPayload,
		meta: {is_backend: is_backend}
	}
}

export function resetGetToken() {
	const dataPayload = {};
	return {
		type: RESET_GET_TOKEN,
		payload: dataPayload
	}
}

export const logout = ({ keycloakDomain, refreshToken = '', client_id, is_backend }) => {
	const dataPayload = refreshToken
		? axios.post(
				`${keycloakDomain}${getKeycloakLogoutUrl(is_backend)}`,
				new URLSearchParams({
					client_id: client_id,
					refresh_token: refreshToken,
				}),
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				},
		  )
		: Promise.reject()
	return {
		type: POST_LOGOUT,
		payload: dataPayload
	}
}

export const refreshToken = ({ refreshToken = '', keycloakDomain, client_id, is_backend }) => {
	const dataPayload = refreshToken 
		? axios.post(
		`${keycloakDomain}${getKeycloakTokenUrl(is_backend)}`,
		new URLSearchParams({
			client_id: client_id,
			grant_type: 'refresh_token',
			refresh_token: refreshToken,
		}),
		{
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		},
	) : Promise.reject()
	return {
		type: REFRESH_TOKEN,
		payload: dataPayload,
		meta: {is_backend: is_backend}
	}
}

export function resetRefreshToken() {
	const dataPayload = {};
	return {
		type: RESET_REFRESH_TOKEN,
		payload: dataPayload
	}
}