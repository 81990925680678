import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const POST_USER = 'POST_USER'
export const PUT_USER = 'PUT_USER'
export const RESET_USER = 'RESET_USER'

export function postUser({email, merchantId, role}) {
	let data = {
		'email': email
	}
	if(merchantId !== '') {
		data.merchantId = merchantId
	}
	if(role !== '') {
		data.role = role
	}
	const dataPayload = axios.post(`${ENV.API_URL}/admin/users`, data, {
	    headers: getHeader()
	})
	return {
		type: POST_USER,
		payload: dataPayload
	}
}

export function putUser(userId, enabled) {
	let data = {
  		'enabled': enabled
	}
	const dataPayload = axios.put(`${ENV.API_URL}/admin/users/${userId}`, data, {
	    headers: getHeader()
	})
	return {
		type: PUT_USER,
		payload: dataPayload
	}
}

export function resetUser() {
	const dataPayload = ''
	return {
		type: RESET_USER,
		payload: dataPayload
	}
}
