import {GET_ACCOUNT} from '../actions/account';
import {secureStorage} from '../helpers/secure_storage_helper';

export default function(state = null, action) {
	switch(action.type) {
		case GET_ACCOUNT:
			if(action.payload.data) {
				if((action.payload.data.context?.viewType === 'MERCHANT' || action.payload.data.context?.viewType === 'MERCHANTADMIN' || action.payload.data.context.viewType === 'MERCHANTVIEWER') && (secureStorage.getItem('merchantId') === 'undefined' || secureStorage.getItem('merchantId') === null)) {
					return true
				} else {
					return false
				}
			} else {
				return false
			}
		default:
			return state;
	}

}