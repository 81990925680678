import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_BALANCE_SNAPSHOT = 'GET_BALANCE_SNAPSHOT'
export const RESET_BALANCE_SNAPSHOT = 'RESET_BALANCE_SNAPSHOT'

export function getBalanceSnapshot(day) {
	let data = {
	  headers: getHeader(),
	  params: {
	  	day: day
	  }
	}
	let dataPayload = axios.get(`${ENV.API_URL}/admin/balance-snapshots`, data)

	const meta = {
		...data.params
	}
	return {
		type: GET_BALANCE_SNAPSHOT,
		payload: dataPayload,
		meta
	}
}

export function resetBalanceSnapshot() {
	const dataPayload = {};
	return {
		type: RESET_BALANCE_SNAPSHOT,
		payload: dataPayload
	}
}