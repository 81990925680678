import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';
import React from 'react';
import PropTypes from 'prop-types';
import {required, key, maxlength, newKeyName} from 'helpers/validator_helper';
import ModalContainer from 'components/ui_elements/modal_container';

const NewApiKeyModal = (props) => {
    return (
    	<ModalContainer thisId='apiKeyModal' title='New API key' onHide={props.handleModalHide} showInit={props.showOnInit}>
            <Form onSubmit={props.handleSubmit} id='newApiKeyForm' className='basic-form'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='newKeyName'>Name (unique): *</label>
                            <Input type='text' className='form-control' names={props.merchant.apiKeys} validations={[required, key, maxlength, newKeyName]} data-maxlength='24' data-name='api key' id='newKeyName' name='newKeyName' value={props.newKeyName} onChange={props.handleChange}  />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 text-right small'>
                        <p>* - Field is required</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button type='button' className='btn btn-default' data-dismiss='modal'>Cancel</button>
                        {props.disabledForm === true ? (
                            <button className='btn btn-primary pull-right disabled' type='submit' disabled>Create API key</button>
                        ) : (
                            <Button className='btn btn-primary pull-right' type='submit'>Create API key</Button>
                        )}
                    </div>
                </div>
            </Form>
        </ModalContainer>
    )
}

NewApiKeyModal.propTypes = {
    disabledForm: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleModalHide: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    merchant: PropTypes.object.isRequired,
    newKeyName: PropTypes.string.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

export default NewApiKeyModal;