import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_PAYOUTS_LIMIT = 'GET_PAYOUTS_LIMIT'
export const RESET_PAYOUTS_LIMIT = 'RESET_PAYOUTS_LIMIT'
export const GET_PAYOUTS_LIMIT_EUR = 'GET_PAYOUTS_LIMIT_EUR'
export const RESET_PAYOUTS_LIMIT_EUR = 'RESET_PAYOUTS_LIMIT_EUR'

export function getPayoutLimit({priceCurrency, transferCurrency, eurLimit}) { 
    let priceCurr = priceCurrency
    let transferCurr = transferCurrency
    if(priceCurrency instanceof Object) {
        priceCurr = priceCurrency.value
    }
    if(transferCurrency instanceof Object) {
        transferCurr = transferCurrency.value
    }
    let data = { 
        headers: getHeader(),
        params: {
            'priceCurrency': priceCurr,
            'transferCurrency': transferCurr
        }
    } 
    let type = GET_PAYOUTS_LIMIT
    if(eurLimit === true) {
        type = GET_PAYOUTS_LIMIT_EUR
    }
    const dataPayload = axios.get(`${ENV.API_URL}/v3/payout-limits`, data)
    return { 
        type: type, 
        payload: dataPayload
    } 
}
export function resetPayoutLimitEur() { 
    return { 
        type: RESET_PAYOUTS_LIMIT_EUR, 
        payload: {}
    } 
}

export function resetPayoutLimit() { 
    return { 
        type: RESET_PAYOUTS_LIMIT, 
        payload: {}
    } 
}