import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Loading from 'components/ui_elements/loading';
import NumberFormatter from 'components/ui_elements/number_formatter'
import Tooltip from 'components/ui_elements/tooltip'
import BalanceHistoryTableInner from 'components/balance_history/balance_history_table_inner'
import {fullTimeUtc} from 'helpers/date_helper'
import {lossStyle} from 'helpers/class_helper'
import {encodeBasicUrl} from 'helpers/helpers'
import { CRYPTO_NAMES } from 'helpers/crypto_names'

const BalanceHistoryTable = ({locations, data}) => {
    return (
        <div className='overflow-y-auto relative scrollable'>
            <table className='table table-lg table-hover table-bordered-top'>
                <thead>
                    <tr>
                        <th width={300}>
                            Currency
                        </th>
                        {locations.map((name) => <th>{name}</th>)}
                    </tr>
                </thead>
                {_.isEmpty(data) ? (
                    <tbody>
                        <tr>
                            <td colSpan='6'>
                                <Loading />
                            </td>
                        </tr>
                    </tbody>
                ) : (
                    <tbody>
                        {Object.entries(data).map(([currency, entries]) => {
                            return (
                                <tr key={currency}>
                                    <td><h5 style={{fontWeight: 'bold'}}>{CRYPTO_NAMES[currency] ? CRYPTO_NAMES[currency] : currency}</h5></td>
                                    {locations.map((name) => {const location = entries.find(el => el.location === name); return (
                                        <td width={410}>{location ? 
                                            <div className='flex-container' style={{'justify-content': 'space-between'}}>
                                                <span style={{fontWeight: location.location === 'Wallet' ? 'bold' : 'normal'}}><NumberFormatter toFormat={location.amount} currCode={currency} /></span>
                                                <div>
                                                    {location.unconfirmedAmount ? (
                                                        <Tooltip id={currency + location.location + 'unconfirmedAmount'} placement='top' text={`Unconfirmed amount: ${location.unconfirmedAmount} ${currency}`} custom={true}>
                                                            <span style={{marginRight: 5}} className={'badge badge-warning'}>Unconfirmed</span>
                                                        </Tooltip>
                                                    ) : (<></>)}
                                                </div>
                                            </div> 
                                        : '-'}</td>
                                    )})}
                                </tr>
                        )})}
                    </tbody>
                )}
            </table>
        </div>
    )
}

BalanceHistoryTable.propTypes = {
    locations: PropTypes.array.isRequired,
    data: PropTypes.object.isRequired
}

export default BalanceHistoryTable;