import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from 'helpers/headers_helper'
 
export const CONFIRM_FIAT_PAYOUT = 'CONFIRM_FIAT_PAYOUT'
export const RESET_CONFIRM_FIAT_PAYOUT = 'RESET_CONFIRM_FIAT_PAYOUT'

export function confirmFiatPayout({orderNumber, sourceBankDetails, sentTime, id}) {
    let data = {
        'orderNumber': orderNumber,
        'sourceBankDetails': sourceBankDetails,
        'sentTime': sentTime
    }
    const dataPayload = axios.put(`${ENV.API_URL}/internal/payouts/${id}/confirm`, data, {
        headers: getHeader()
    })
    return {
        type: CONFIRM_FIAT_PAYOUT,
        payload: dataPayload
    }
}

export function resetConfirmedFiatPayout() {
    const dataPayload = {}
    return { 
        type: RESET_CONFIRM_FIAT_PAYOUT, 
        payload: dataPayload
    } 
}


