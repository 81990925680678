import {GET_MERCHANT_DASHBOARD_SUMMARY, RESET_MERCHANT_DASHBOARD_SUMMARY} from 'actions/dashboardSummaryMerchant';
import {setupRollbar} from 'helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_MERCHANT_DASHBOARD_SUMMARY:
			if(action.error) {
				if(action.payload.response?.status === 404 || action.payload.response?.status === 403) {
					return {data: {}, status: 200};
				}
				setupRollbar(action);
				return 'error'
			}
			return {data: action.payload.data, status: action.payload.status};
		case RESET_MERCHANT_DASHBOARD_SUMMARY:
			return action.payload;
		default:
			return state;
	}

}