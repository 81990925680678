import { axios } from 'axiosInstance'
import { ENV } from "index"
import _ from 'lodash'
import {secureStorage} from '../helpers/secure_storage_helper'
import {getHeader} from '../helpers/headers_helper'

export const GET_MERCHANTS_LIST = 'GET_MERCHANTS_LIST'
export const RESET_MERCHANTS_LIST = 'RESET_MERCHANTS_LIST'

export function getMerchantsList() {
	let data = {
	  	headers: getHeader()
	}
	const MERCHANT_ID = secureStorage.getItem('merchantId')
    let dataPayload
    if(secureStorage.getItem('role') === 'MERCHANT') {
    	dataPayload = {status: 200, data: {}}
    } else {
    	dataPayload = axios.get(`${ENV.API_URL}/ui/statements/merchant-list`, data)
    }
	return {
		type: GET_MERCHANTS_LIST,
		payload: dataPayload
	}
}

export function resetMerchantsList() {
	const dataPayload = {}
	return {
		type: RESET_MERCHANTS_LIST,
		payload: dataPayload
	}
}