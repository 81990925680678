export function getStatusClassname(el) {
    switch(el) {
        case 'NEW':
            return 'status info'
        case 'OPEN':
            return 'status info'
        case 'PROCESSING':
            return 'status info'
        case 'PENDING':
            return 'status warning'
        case 'PENDINGNEW':
            return 'status info'
        case 'CONFIRMED':
            return 'status online'
        case 'DONE':
            return 'status online'
        case 'COMPLETE':
            return 'status online'
        case 'COMPLETED':
            return 'status online'
        case 'CONFIRMED':
            return 'status online'
        case 'PAID':
            return 'status online'
        case 'SENT':
            return 'status online'
        case 'FILLED':
            return 'status online'
        case 'EXPIRED':
            return 'status no-disturb'
        case 'CANCELED':
            return 'status no-disturb'
        case 'CANCELLED':
            return 'status no-disturb'
        case 'INVALID':
            return 'status away'
        case 'FAILED':
            return 'status away'
        case 'UNDERPAID':
            return 'status wait'
        case 'QUEUED':
            return 'status primary'
        case 'PARTIALLYFILLED':
            return 'status primary'
        case 'IN_PROGRESS':
            return 'status primary'
        default:
            return'status'
    }
};

export function profitClass(el) {
    if(el > 0) {
        return 'profit';
    } else if (el === 0) {
        return ''
    } else {
        return 'loss';
    }
}

export function camelCaseToText(camelCase) {
    return camelCase.replace(/([A-Z])/g, ' $1')
}

export const camelCaseToWords = inputString => typeof inputString === 'string' ? inputString.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()) : '';

export const lossStyle = (el) => el < 0 ? 'text-danger' : '';