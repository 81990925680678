import {PUT_DECLARE_CONVERSION, RESET_PUT_DECLARE_CONVERSION} from '../actions/declareConversion';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = '', action) {
	switch(action.type) {
		case PUT_DECLARE_CONVERSION:
			let response = 'Some error occured while updating data, please try again';
			if(action.error) {
				setupRollbar(action);
				return {type: 'error', response: response}
			}
			return action.payload.status;
		case RESET_PUT_DECLARE_CONVERSION:
			return action.payload;
		default:
			return state;
	}

}