import React, {Component} from 'react';
import $ from 'jquery'; 
import _ from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'; 
import {postApiKey, resetApiKey} from 'actions/apiKey';
import NewApiKeyModal from './new_api_key_modal';
import {updateInputState, handleModalFormSubmit} from 'helpers/form_helper';

class NewApiKeyModalContainer extends Component {
    state = {
        newKeyName: '',
        disabledForm: false
    }
    componentDidUpdate(prevProps) {
        let newApiKey = this.props.newApiKey;
        if(!_.isEmpty(newApiKey)) {
            this.props.formSent(newApiKey);
            $('#apiKeyModal').modal('hide');
        }
    }
    handleModalHide() {
        this.props.onModalClosed();
    }
    handleChange(event) {
        updateInputState(this, event);
    }
    handleSubmit(event) {
        handleModalFormSubmit({
            self: this, 
            event: event, 
            states: {
                disabledForm: true
            }, 
            sendFunction: this.props.postApiKey, 
            sendProps: {
                merchantId: this.props.merchant.id, 
                name: this.state.newKeyName
            },
            resetFunction: this.props.resetFunction
        });
    }
    render() {
        let thisProps = this.props;
        let thisState = this.state;
        let showOnInit = thisProps.showOnInit || false;
        return ( 
            <NewApiKeyModal 
                disabledForm={thisState.disabledForm} 
                handleChange={this.handleChange.bind(this)} 
                handleModalHide={this.handleModalHide.bind(this)} 
                handleSubmit={this.handleSubmit.bind(this)} 
                merchant={thisProps.merchant} 
                newKeyName={thisState.newKeyName} 
                showOnInit={showOnInit} 
            />
        )
    }
}

NewApiKeyModalContainer.propTypes = {
    formSent: PropTypes.func.isRequired,
    onModalClosed: PropTypes.func.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

function mapStateToProps(state) { 
    return { 
        newApiKey: state.newApiKey
    } 
} 

export default connect(mapStateToProps, {postApiKey, resetApiKey})(NewApiKeyModalContainer);