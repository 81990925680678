import moment from 'moment';

export const GLOBAL_CALENDAR_START = '01.01.2016';

export let dateFormatToDateObj = date => moment(date, 'DD.MM.YYYY').toDate();

export let dateToIso = date => moment(date, 'DD.MM.YYYY').format('YYYY-MM-DD');

export let dateToName = date => moment(date, 'DD.MM.YYYY').format('DD-MM-YYYY');

export let dateNextDay = date => moment(date, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD');

export let dateStandard = date => moment(date, 'DD.MM.YYYY').format('Do MMMM YYYY');

export let dateNextDayStandard = date => moment(date, 'DD.MM.YYYY').add(1, 'days').format('Do MMMM YYYY');

export let dateToIsoFull = date => moment(date).toISOString();

export let isoToDate = date => moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');

export let isoReverse = date =>  moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');

export let dateObjToDate = date => moment(date).format('DD.MM.YYYY');

export let dateObjToCalendarDate = date => moment(date).format('YYYY-MM-DD');

export let parseStartDate = date => new Date(date.getFullYear(), date.getMonth() - 3, date.getDate());

export let parseStartOfMonthDate = date => new Date(date. getFullYear(), date. getMonth(), 1);

export let parseStartLastMonth = date => new Date(date.getFullYear(), date.getMonth() - 1, 1);

export let parseEndOfLastMonth = date => {
  date.setDate(1);
  date.setHours(-1);
  return date;
}
 
export const currentYear = new Date().getFullYear();

export let parse7DaysAgo = date => new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7);

export let parseStartOfYearDate = date => new Date(currentYear, 0, 1); 

export let parseEndOfYearDate = date => new Date(currentYear, 11, 31); 

export let parseEndDate = date => new Date(date.getFullYear(), date.getMonth(), date.getDate());

export let parseWeekDay = date =>  moment(date, "YYYY-MM-DD").format('dddd');

export let timeUtc = date => moment(date).utc().format('DD.MM.YYYY');

export let timeUtcExclusive = date => moment(date).subtract(1, 'millisecond').utc().format('DD.MM.YYYY');

export let timeUtcDash = date => moment(date).utc().format('DD-MM-YYYY');

export let timeUtcDashExclusive = date => moment(date).subtract(1, 'millisecond').utc().format('DD-MM-YYYY');

export let fullTimeUtc = date => moment(date).utc().format('DD.MM.YYYY HH:mm:ss');

export let fullTime = date => moment(date, "YYYY-MM-DDTHH:mm:ssUTC").format('DD.MM.YYYY HH:mm:ss');

export let shortTimeUtc = date => moment(date).utc().format('DD.MM HH:mm:ss');

export let hourFromDate = date => moment(date).utc().format('HH:mm:ss');

export let getTimeDifference = (date1, date2) => moment.utc(moment(date1,"YYYY-MM-DDTHH:mm:ss:SSS").diff(moment(date2,"YYYY-MM-DDTHH:mm:ss:SSS")))._i;

export function getWeek(date) { 
    let d = new Date(+date);
    d.setHours(0,0,0);
    d.setDate(d.getDate()+4-(d.getDay()||7));
    return Math.ceil((((d-new Date(d.getFullYear(),0,1))/8.64e7)+1)/7);
}
export function getMonday(d) {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? -6:1),
      date = new Date(d.setDate(diff));
  return moment(date).format("YYYY-MM-DD");
}
export function getSunday(d) {
  d = new Date(d);
  var day = d.getDay(),
      diff = d.getDate() - day + (day === 0 ? 0 : 7),
      date = new Date(d.setDate(diff));
  return moment(date).format("YYYY-MM-DD");
}
