import {CONFIRM_PAYOUT, RESET_CONFIRMED_PAYOUT} from '../actions/payout';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case CONFIRM_PAYOUT:
			let response = 'Some error occured, please try again';
			if(action.error) {
				let message = action.payload.response?.data.message;
				if(message) {
					response = message;
				}
				setupRollbar(action);
				return {type: 'error', response: response}
			}
			return action.payload;
		case RESET_CONFIRMED_PAYOUT:
			return action.payload;
		default:
			return state;
	}

}