import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import $ from 'jquery'
import _ from 'lodash'
import PropTypes from 'prop-types';
import { secureStorage } from 'helpers/secure_storage_helper'
import { updateInputStateHook, handleModalFormSubmitHook } from 'helpers/form_helper'
import { getCurrencies } from 'actions/currencies'
import { btc_min, fiat_min } from 'helpers/validator_helper'
import NewPaymentModal from './new_payment_modal'

const NewPaymentModalContainer = ({formSent, onModalClosed, showOnInit }) => {
    const dispatch = useDispatch();
    const paymentPriceCurrencies = useSelector(state => state.paymentPriceCurrencies);
    const currencies = useSelector(state => state.currencies);
    const isAdmin = useSelector(state => state.isAdmin);
    const paymentTransferCurrencies = useSelector(state => state.paymentTransferCurrencies);
    const [disabledForm, setDisabledForm] = useState(false);
    const [currencyPrecision, setCurrencyPrecision] = useState(2);

    const [formValues, setFormValues] = useState({
        amountPayment: '',
        confirmedRedirect: '',
        defineRedirects: false,
        expiredRedirect: '',
        invalidRedirect: '',
        merchantTransactionId: '',
        notificationUrl: '',
        paymentComment: '',
        transferCurrency: '',
        reloadForm: false,
        unconfirmedRedirect: ''
    });

    useEffect(() => {
        if (_.isEmpty(currencies)) {
            dispatch(getCurrencies());
        }
    }, [currencies]);

    useEffect(() => {
        if (formValues.reloadForm === true) {
            setFormValues((prevState) => ({
                ...prevState,
                reloadForm: false
            }));
        }
    }, [formValues.reloadForm]);
    useEffect(() => {
        if(formValues.acquiring && formValues.acquiring.value) {
            let currSettings = currencies.find(curr => curr.code === formValues.acquiring.value);
            if(currSettings) {
                setCurrencyPrecision(currSettings.precision);
            }
        }
    }, [formValues.acquiring]);

    const handleModalHide = () => {
        onModalClosed();
    };

    const handleChange = (event) => {
        updateInputStateHook(setFormValues, formValues, event);
    };

    const handleCheckboxChange = (val) => {
        if (val.target.checked === true) {
            setFormValues((prevState) => ({
                ...prevState,
                defineRedirects: val.target.checked,
                reloadForm: true
            }));
        } else {
            setFormValues((prevState) => ({
                ...prevState,
                defineRedirects: val.target.checked,
                confirmedRedirect: '',
                invalidRedirect: '',
                expiredRedirect: '',
                unconfirmedRedirect: '',
                reloadForm: true
            }));
        }
    };

    const handlePaySelectChange = (val, e) => {
        setFormValues((prevState) => ({
            ...prevState,
            [e]: { value: val, label: val }
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        handleModalFormSubmitHook({
            event: event, 
            setDisabledForm: setDisabledForm, 
            sendFunction: () => formSent({
                amount: formValues.amountPayment,
                message: formValues.paymentComment,
                currency: formValues.acquiring.value,
                transferCurrency: formValues.transferCurrency.value,
                merchantTransactionId: formValues.merchantTransactionId,
                notificationUrl: formValues.notificationUrl,
                confirmedRedirect: formValues.confirmedRedirect,
                expiredRedirect: formValues.expiredRedirect,
                invalidRedirect: formValues.invalidRedirect,
                unconfirmedRedirect: formValues.unconfirmedRedirect,
                defineRedirects: formValues.defineRedirects
            }),
            resetFunction: resetAndClose
        });
    };

    const resetAndClose = () => {
        onModalClosed();
        $('#paymentModal').modal('hide');
    };

    const selectMounted = (el, id) => {
        setFormValues((prevState) => ({
            ...prevState,
            [id]: el
        }));
    };

    return (
        <NewPaymentModal
           paymentPriceCurrencies={paymentPriceCurrencies}
           currency={formValues.acquiring?.value}
           currencyPrecision={currencyPrecision}
           amountPayment={formValues.amountPayment}
           confirmedRedirect={formValues.confirmedRedirect}
           defineRedirects={formValues.defineRedirects}
           expiredRedirect={formValues.expiredRedirect}
           handleChange={handleChange}
           handleCheckboxChange={handleCheckboxChange}
           handleModalHide={handleModalHide}
           handlePaySelectChange={handlePaySelectChange}
           handleSubmit={handleSubmit}
           invalidRedirect={formValues.invalidRedirect}
           merchantTransactionId={formValues.merchantTransactionId}
           notificationUrl={formValues.notificationUrl}
           paymentComment={formValues.paymentComment}
           paymentTransferCurrencies={paymentTransferCurrencies}
           reloadForm={formValues.reloadForm}
           selectMounted={selectMounted}
           showOnInit={showOnInit}
           unconfirmedRedirect={formValues.unconfirmedRedirect}
         />
    );
};

NewPaymentModalContainer.propTypes = {
    formSent: PropTypes.func.isRequired,
    onModalClosed: PropTypes.func.isRequired,
    showOnInit: PropTypes.bool.isRequired,
    paymentPriceCurrencies: PropTypes.array.isRequired
};

export default NewPaymentModalContainer;