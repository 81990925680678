import { ENV } from "index"

const EXPLORER_TX = '/tx/';
const EXPLORER_ADDRESS = '/address/';
let EXPLORERS,
	EXPLORERS_TX,
	EXPLORERS_ADDRESS

const initBlockExplorers = () => {
	EXPLORERS = {
		'BTC': ENV.HISTORY_BTC,
		'LTC': ENV.HISTORY_LTC
	}
	EXPLORERS_TX = {
		'BTC': ENV.IS_TEST === 'true' ? 'https://www.blockchain.com/explorer/transactions/btc-testnet/' : 'https://www.blockchain.com/explorer/transactions/btc/',
		'BTC_TEST': 'https://www.blockchain.com/explorer/transactions/btc-testnet/',
		'ETH': 'https://etherscan.io/tx/',
		'ETH_GOERLI': 'https://goerli.etherscan.io/tx/',
		'TRX': 'https://tronscan.org/#/transaction/',
		'TRX_SHASTA': 'https://shasta.tronscan.org/#/transaction/'
	}
	EXPLORERS_ADDRESS = {
		'BTC': ENV.IS_TEST === 'true' ? 'https://www.blockchain.com/explorer/addresses/btc-testnet/' : 'https://www.blockchain.com/explorer/addresses/btc/',
		'BTC_TEST': 'https://www.blockchain.com/explorer/addresses/btc-testnet/',
		'ETH': 'https://etherscan.io/address/',
		'ETH_GOERLI': 'https://goerli.etherscan.io/address/',
		'TRX': 'https://tronscan.org/#/address/',
		'TRX_SHASTA': 'https://shasta.tronscan.org/#/address/'
	}
}

export {initBlockExplorers, EXPLORER_TX, EXPLORER_ADDRESS, EXPLORERS, EXPLORERS_TX, EXPLORERS_ADDRESS}