import {GET_MERCHANT_FEES, RESET_GET_MERCHANT_FEES} from '../actions/merchantFees';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = [], action) {
	switch(action.type) {
		case GET_MERCHANT_FEES:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return action.payload.data;
		case RESET_GET_MERCHANT_FEES: 
			return action.payload;
		default:
			return state;
	}

}