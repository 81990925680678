import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_BALANCE_ALL_SNAPSHOT = 'GET_BALANCE_ALL_SNAPSHOT'
export const RESET_BALANCE_ALL_SNAPSHOT = 'RESET_BALANCE_ALL_SNAPSHOT'

export function getBalanceAllSnapshot({limit, offset, start, end, allResults}) {
	let offsetCalc = 0;
    if(offset !== 0 || offset !== undefined) {
        offsetCalc = parseInt(offset,10) * limit;
    }
	let data = {
	  headers: getHeader(),
	  params: {
	  	limit: allResults,
	  	offset: offsetCalc,
	  	start: start,
	  	end: end
	  }
	}
	let dataPayload = axios.get(`${ENV.API_URL}/admin/balance-snapshots`, data)
	return {
		type: GET_BALANCE_ALL_SNAPSHOT,
		payload: dataPayload,
		meta: data.params
	}
}

export function resetBalanceAllSnapshot() {
	const dataPayload = {};
	return {
		type: RESET_BALANCE_ALL_SNAPSHOT,
		payload: dataPayload
	}
}