import React, { useEffect } from 'react'
import $ from 'jquery'
import PropTypes from 'prop-types'
import Modal from './modal'

const ModalContainer = ({onHide, thisId, showInit, title, children, classNames}) => {
    useEffect(() => {
        if(onHide) {
            $('#' + thisId).on('hide.bs.modal', (e) => {
                let elId = e.target.id;
                if(elId !== "sentDate" && elId !== "asOfDate" && elId !== "sentTime") {
                    onHide();
                }
            });
        }
        if(showInit === true) {
            $('#' + thisId).modal('show');
        }
    }, []);
    return <Modal thisId={thisId} title={title} children={children} classNames={classNames} />
}

ModalContainer.propTypes = {
    children: PropTypes.node.isRequired,
    onHide: PropTypes.func,
    showInit: PropTypes.bool,
    thisId: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
}

export default ModalContainer