import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Selectize from '../ui_elements/selectize'; 
import {email} from '../../helpers/validator_helper';

const UsersSearchForm = (props) => {
    return (
        <div className='card'>
            <div className='pdd-horizon-20 pdd-top-20 pgg-btm-10'>
                <Form onSubmit={props.handleSubmit} id='searchUserForm'>
                    <div className='row'>
                        <div className='col-md-12 clearfix'>
                             <div className='row'>
                                <div className='col-md-3'>
                                    <div className='form-group'>
                                        <label htmlFor='email'>User email:</label>
                                        <Input type='email' className='form-control' value={props.email} id='email' name='email' validations={[email]} data-optional='true' onChange={props.handleChange} />
                                    </div>
                                </div>
                                {props.isAdmin && !_.isEmpty(props.merchantsNames) &&
                                    <div className='col-md-3'>
                                        <div className='form-group'>
                                            <label htmlFor='merchantId'>User merchant:</label>
                                            <Selectize 
                                                thisId='merchantId' 
                                                dataOptions={props.merchantsNames} 
                                                handleInputChange={props.handleSelectInputChange}
                                                selected={props.merchantId} 
                                                isSearchable={true} 
                                                isAsync={true}
                                                onChange={props.handleSelectChange}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className='col-md-3'>
                                    {props.formDisabled === true ? (
                                        <button className='btn btn-primary btn-sm btn-full mrg-top-25 disabled' type='submit' disabled>
                                            <span>Search user</span>
                                        </button>
                                    ) : (
                                        <Button className='btn btn-primary btn-sm btn-full mrg-top-25' type='submit'>
                                            <span>Search user</span>
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </div>   
    )       
}

UsersSearchForm.propTypes = {
    email: PropTypes.string.isRequired,
    formDisabled: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSelectChange: PropTypes.func.isRequired,
    handleSelectInputChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    merchantId: PropTypes.string.isRequired,
    merchantsNames: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.object
    ]).isRequired
}

export default UsersSearchForm;