import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_CONVERSION_ESTIMATION = 'GET_CONVERSION_ESTIMATION'
export const RESET_CONVERSION_ESTIMATION = 'RESET_CONVERSION_ESTIMATION'

export function getConversionEstimation({sellAmount, sellCurrency, buyCurrency}) { 
    let data = { 
        headers: getHeader(),
        params: {
            'sellAmount': sellAmount,
            'sellCurrency': sellCurrency,
            'buyCurrency': buyCurrency
        }
    } 
    const dataPayload = axios.get(`${ENV.API_URL}/v3/estimate-conversion`, data)
    return { 
        type: GET_CONVERSION_ESTIMATION, 
        payload: dataPayload
    } 
}

export function resetConversionEstimation() { 
    return { 
        type: RESET_CONVERSION_ESTIMATION, 
        payload: {}
    } 
}