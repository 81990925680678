import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'
 
export const POST_CONVERSION = 'POST_CONVERSION'
export const RESET_CONVERSION = 'RESET_CONVERSION'

export function postConversion({sellAmount, sellCurrency, buyCurrency}) {
    let data = {
        'sellAmount': sellAmount,
        'sellCurrency': sellCurrency,
        'buyCurrency': buyCurrency
    };
    const dataPayload = axios.post(`${ENV.API_URL}/v3/conversions`, data, {
        headers: getHeader()
    })
    return {
        type: POST_CONVERSION,
        payload: dataPayload
    }
}
export function resetConversion() {
    const dataPayload = ''
    return {
        type: RESET_CONVERSION,
        payload: dataPayload
    }
}

