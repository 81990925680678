import React, {useEffect} from 'react'
import { ENV } from "index"
import {parseJwt} from '../../helpers/helpers'
import {ADMIN_PREFIX} from 'App'
import {getBasePath} from 'actions/getToken'
import {secureStorage} from '../../helpers/secure_storage_helper'

const Login = ({adminLogin}) => {
    useEffect(() => {
        isTokenValid();
        document.title = 'Bitcoin Payment Processor';
    }, []);
    const isTokenValid = () => { 
        let dateNow = Date.now() / 1000;
        let isNotExpired = secureStorage.getItem('tokenExpires') > dateNow;
        if(localStorage.getItem('accessToken') === null) { 
            initKeycloak(); 
        } else { 
            let parsedToken = parseJwt(localStorage.getItem('accessToken')); 
            if(isNotExpired && parsedToken !== undefined && parsedToken.iss === 'https://' + ENV.AUTH0_URL) { 
                //history(adminLogin ? '/' + ADMIN_PREFIX : '/'); 
            } else { 
                initKeycloak(); 
            } 
        } 
    } 
    const initKeycloak = () => {
        localStorage.removeItem('accessToken');
        secureStorage.removeItem('tokenExpires');
        secureStorage.removeItem('userEmail');
        //secureStorage.removeItem('merchantId');
        secureStorage.removeItem('role');
        secureStorage.removeItem('userRole');
        secureStorage.removeItem('approvedTerms');
        secureStorage.removeItem('refreshToken');
        let domain = ENV.KEYCLOAK_DOMAIN;
        let basePath = getBasePath(adminLogin);
        let client = adminLogin ? ENV.KEYCLOAK_CLIENT_BACK : ENV.KEYCLOAK_CLIENT_FRONT;
        let redirect = adminLogin ? ENV.LOCAL_URL + '' + ADMIN_PREFIX + '/callback' : ENV.LOCAL_URL + 'callback';
        window.location.href = domain + basePath + '/auth?response_type=code&client_id=' + client + '&redirect_uri=' + redirect;
    }
    return (
        <div></div>
    )
}

export default Login;
