import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const PUT_MERCHANT_ACCESS = 'PUT_MERCHANT_ACCESS'
export const DELETE_MERCHANT_ACCESS = 'DELETE_MERCHANT_ACCESS'
export const UPDATE_MERCHANT_ACCESS = 'UPDATE_MERCHANT_ACCESS'
export const RESET_MERCHANT_ACCESS = 'RESET_MERCHANT_ACCESS'
export const RESET_DELETED_MERCHANT_ACCESS = 'RESET_DELETED_MERCHANT_ACCESS'
export const RESET_UPDATE_MERCHANT_ACCESS = 'RESET_UPDATE_MERCHANT_ACCESS'

export function putMerchantAccess({userId, merchantId, role}) {
	let data = {}
	const dataPayload = axios.put(`${ENV.API_URL}/admin/users/${userId}/grant-merchant-access?merchantId=${merchantId}`, data,  {
	    headers: getHeader()
	})
	return {
		type: PUT_MERCHANT_ACCESS,
		payload: dataPayload
	}
}

export function deleteMerchantAccess({userId, merchantId}) {
	let data = {}
	const dataPayload = axios.put(`${ENV.API_URL}/admin/users/${userId}/revoke-merchant-access?merchantId=${merchantId}`, data, {
	    headers: getHeader()
	})
	return {
		type: DELETE_MERCHANT_ACCESS,
		payload: dataPayload
	}
}

export function updateMerchantAccess({userId, merchantId, role}) {
	let data = {
		merchantId: merchantId,
		role: role
	}
	const dataPayload = axios.put(`${ENV.API_URL}/admin/users/${userId}/update-merchant-access`, data,  {
	    headers: getHeader()
	})
	return {
		type: UPDATE_MERCHANT_ACCESS,
		payload: dataPayload
	}
}

export function resetMerchantAccess() {
	const dataPayload = {}
	return {
		type: RESET_MERCHANT_ACCESS,
		payload: dataPayload
	}
}

export function resetDeletedMerchantAccess() {
	const dataPayload = {}
	return {
		type: RESET_DELETED_MERCHANT_ACCESS,
		payload: dataPayload
	}
}

export function resetUpdateMerchantAccess() {
	const dataPayload = {}
	return {
		type: RESET_UPDATE_MERCHANT_ACCESS,
		payload: dataPayload
	}
}

