import {GET_ADDRESS_VALIDATION, RESET_ADDRESS_VALIDATION} from '../actions/addressValidate';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_ADDRESS_VALIDATION:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return {data: action.payload.data, status: action.payload.status}
		case RESET_ADDRESS_VALIDATION:
			return action.payload
		default:
			return state;
	}

}