import React from 'react';


const LoadingError = () => {
    return (
        <div className='text-danger mrg-btm-20 mrg-top-15'>
        	Error - try reloading the page.  
        	<a className='text-danger text-bold' href='javascript:void(0);' onClick={() => {window.location.reload()}}>
        		Reload page 
        		<i className='fa fa-retweet'></i>
        	</a>
        </div>
    )
}

export default LoadingError;
