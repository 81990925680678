import React from 'react';
import PropTypes from 'prop-types';

const Tab = ({name, labelledby, children, active}) => {
    return (
        <div className={active === name ? 'tab-pane fade active show' : 'tab-pane fade'} id={name} role='tabpanel' aria-labelledby={labelledby}>
            {children}
        </div>
    )
}

Tab.propTypes = {
    children: PropTypes.node.isRequired,
    active: PropTypes.string.isRequired,
    labelledby: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

export default Tab;
