import {SET_ROLLBAR} from '../actions/rollbar';

export default function(state = {}, action) {
	switch(action.type) {
		case SET_ROLLBAR:
			return action.payload;
		default:
			return state;
	}

}