import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_BALANCES = 'GET_BALANCES';
export const RESET_BALANCES = 'RESET_BALANCES';

export function getBalances() {
	let data = {
	  headers: getHeader()
	}
	let dataPayload = axios.get(`${ENV.API_URL}/analytics/money-balance`, data)
	return {
		type: GET_BALANCES,
		payload: dataPayload
	}
}

export function resetBalances() {
	const dataPayload = {};
	return {
		type: RESET_BALANCES,
		payload: dataPayload
	}
}