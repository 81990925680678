import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';
import React from 'react';
import PropTypes from 'prop-types';

const MerchantsSearchForm = (props) => {
    return (
        <div className='card'>
            <div className='pdd-horizon-20 pdd-top-20 pgg-btm-10'>
                <Form onSubmit={props.handleSubmit} id='editFeeForm' className='basic-form'>
                    <div className='row'>
                        <div className='form-group col-md-6'>
                            <label htmlFor="name">Merchant:</label>
                            <Input placeholder='Search merchant...' type='text' className='form-control' id='name' name='name' value={props.name} onChange={props.handleChange} /> 
                        </div>
                        <div className='col-md-3'>
                            {props.formDisabled === true ? (
                                <button type='submit' className='btn btn-primary btn-full mrg-top-25 disabled' disabled>
                                    <span>Search</span>
                                </button>
                            ) : (
                                <Button type='submit' className='btn btn-primary btn-full mrg-top-25'>Search</Button>
                            )}
                        </div>
                    </div>
                </Form>
            </div>
        </div>
                            
    )
}
MerchantsSearchForm.propTypes = {
    formDisabled: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired
}

export default MerchantsSearchForm;