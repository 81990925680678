import React from 'react';
import PropTypes from 'prop-types'
import NumberFormatter from 'components/ui_elements/number_formatter'
import {fullTimeUtc} from 'helpers/date_helper'
import {lossStyle} from 'helpers/class_helper'

const UnsoldPaymentsTable = (props) => {
    return (
        <div className='overflow-y-auto relative scrollable mrg-top-20'>
            <table className='table table-hover table-bordered-top'>
                <thead>
                    <tr>
                        <th className='min-width-125 text-right'>
                            BTC amount
                        </th>
                        <th>
                            Creation time
                        </th>
                        <th>
                            Trade ID
                        </th>
                        <th>
                            Payment ID
                        </th>
                    </tr>
                </thead>
                {props.unsold.length ? (
                    <tbody className='collapsible-body'>
                        {props.unsold.map(function(val,i) {
                            return (
                                <tr key={i}>
                                    <td className='v-align'>
                                        <div className="list-info text-right">
                                            <h5><span className={lossStyle(val.btcAmount)}><NumberFormatter toFormat={val.btcAmount} currCode='BTC' /></span></h5>
                                        </div>
                                    </td>
                                    <td className='v-align'>
                                        <div>
                                            <span>{fullTimeUtc(val.tradeCreatedTime)}</span>
                                        </div>
                                    </td>
                                    <td className='v-align'>{val.tradeId}</td>
                                    <td className='v-align'>{val.paymentId}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                ) : (
                    <tbody className='collapsible-body'>
                        <tr>
                            <td colSpan='4'>
                                <h5 className='text-center'>No unsold payments</h5>
                            </td>
                        </tr>
                    </tbody>
                )}
            </table>
        </div>
    )
}

UnsoldPaymentsTable.propTypes = {
    unsold: PropTypes.array.isRequired
}

export default UnsoldPaymentsTable;