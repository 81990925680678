import React, {Component} from 'react';
import {connect} from 'react-redux';
import UnsoldPayments from './unsold_payments';
import {getBalances, resetBalances} from '../../actions/balances';
import {getUnsoldTransactions, resetUnsoldTransactions} from '../../actions/unsoldTransactions';
import PropTypes from 'prop-types';

class UnsoldPaymentsContainer extends Component {
    componentDidMount() {
        this.props.getUnsoldTransactions();
    }
    componentWillUnmount() {
        this.props.resetUnsoldTransactions();
    }
    render() {
        return (
        	<UnsoldPayments unsold={this.props.unsold} />
        )
    }
}


function mapStateToProps(state) {
    return {
        unsold: state.unsold
    }
}

export default connect(mapStateToProps, {
    getUnsoldTransactions, 
    resetUnsoldTransactions
})(UnsoldPaymentsContainer);