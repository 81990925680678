import React, { useEffect, useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import styled from 'styled-components'
import { DateRange } from 'react-date-range'
import { enGB } from 'react-date-range/src/locale';
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import variables from 'styles/_variables.scss'
import $ from 'jquery'
import PropTypes from 'prop-types'
import datepicker from 'bootstrap-datepicker'
import { dateObjToDate, dateFormatToDateObj, parseStartOfMonthDate, parseEndDate, parseStartLastMonth, parseEndOfLastMonth, parse7DaysAgo } from 'helpers/date_helper'
import Tooltip from '../ui_elements/tooltip'

const DateRangeContainer = styled(Dropdown)`
    position: relative;
    .dropdown-menu {
        border: 1px solid ${variables.borderColor};
        padding-top: 0;
        border-radius: 0;
    }
`

const DateRangeCustom = styled(DateRange)`
    font-size: 13px;
    .rdrMonth {
        width: 24em;
        padding: 0 0.733em 1.666em 0.733em;
    }
    .rdrMonthAndYearWrapper {
        padding-top: 0;
        height: 50px;
    }
    .rdrDayToday {
        .rdrDayNumber {
            color: ${variables.defaultDark};
            font-weight: 600;
            span:after {
                display: none;
            }
        }
    }
    .rdrDateDisplayItem {
        border-radius: 0;
        input {
            color: ${variables.defaultDark};
        }
    }
    .rdrNextPrevButton {
        border-radius: 0;
    }
`

const CalendarIcon = styled.label`
    position: absolute;
    right: 0;
    top: 0;
`

const ClearIcon = styled.label`
    position: absolute;
    right: 60px;
    top: 37px;
    font-size: 14px;
    &.explorer {
        top: 40px;
    }
`

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
    >
        {children}
    </span>
));

const DateRangePickerInput = ({start, end, thisId, fromId, toId, now, onChange, classNames, additionalPdd, tooltips, tooltipsTexts, label, clearDateOption, explorer}) => {
    const [initialDataLoaded, setInitialDataLoaded] = useState(false);
    const [selectionRange, setSelectionRange] = useState({
        startDate: start === 'Invalid date' || start === '' ? '' : new Date(dateFormatToDateObj(start)),
        endDate: end === 'Invalid date' || end === '' ? '' : new Date(dateFormatToDateObj(end)),
        key: 'selection',
    });
    const [isCleared, setIsCleared] = useState(false);
    const handleSelect = (ranges) =>{
        let date = new Date();
        let dateRangeSelection = {
            startDate: parse7DaysAgo(date),
            endDate: parseEndDate(date),
            key: 'selection'
        }
        setSelectionRange(ranges.selection);
        setIsCleared(false);
        onChange(ranges.selection);
    }
    const getLast7Days = () => {
        let date = new Date();
        let last7Days = {
            startDate: parse7DaysAgo(date),
            endDate: parseEndDate(date),
            key: 'selection'
        }
        setSelectionRange(last7Days);
        setIsCleared(false);
        onChange(last7Days);
    }
    const getThisMonth = () => {
        let date = new Date();
        let thisMonth = {
            startDate: parseStartOfMonthDate(date),
            endDate: parseEndDate(date),
            key: 'selection'
        }
        setSelectionRange(thisMonth);
        setIsCleared(false);
        onChange(thisMonth);

    }
    const getLastMonth = () => {
        let date = new Date();
        let lastMonth = {
            startDate: parseStartLastMonth(date),
            endDate: parseEndOfLastMonth(date),
            key: 'selection'
        }
        setSelectionRange(lastMonth);
        setIsCleared(false);
        onChange(lastMonth);
    }
    const clearDate = (e) => {
        e.preventDefault();
        const emptyDate = {
            startDate: '',
            endDate: '',
            key: 'selection'
        }
        setSelectionRange(emptyDate);
        setIsCleared(true);
        onChange(emptyDate);
    }
    const handleChange = () => { return };
    return (
        <DateRangeContainer className={classNames} >    
                <div className='width-100'>
                    <div className='form-group no-mrg'>
                        {label !== false && <label htmlFor='from' style={explorer && {marginBottom: 8}}>Date range: {tooltips === true && <Tooltip id='fromTooltip' placement='top' text={tooltipsTexts[0]} />}</label>}
                        <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
                            <div className='datepicker-container input-append date'>
                                <input style={{height: 38}} type='text' id={fromId} name='datePayInTimeInner' className='form-control' value={isCleared || start === 'Invalid date' || end === 'start' ? '' : start + ' - ' + end} />
                                
                                <CalendarIcon htmlFor={fromId}>
                                    <i className='ei ei-calendar-flip datepicker-icon add-on'></i>
                                </CalendarIcon>
                             </div>
                        </Dropdown.Toggle>
                        {clearDateOption &&
                            <ClearIcon onClick={(e) => clearDate(e)} className={explorer ? 'explorer' : ''}>
                                <i className='ei ei-unchecked text-danger'></i>
                            </ClearIcon>
                        }
                    </div>
                </div>
            <Dropdown.Menu style={{width: 533}}>
                <div className='row'>
                    <div className='col-md-5'>
                        <div style={{padding: '10px 0 0 15px', position: 'absolute'}}>
                            <button className='btn btn-default btn-sm btn-full' onMouseDown={() => getLast7Days()}>Last 7 days</button>
                            <button className='btn btn-default btn-sm btn-full' onMouseDown={() => getThisMonth()}>This month</button>
                            <button className='btn btn-default btn-sm btn-full' onMouseDown={() => getLastMonth()}>Last month</button>
                        </div>
                    </div>
                    <div className='col-md-7 no-pdd-left'>
                        <DateRangeCustom
                            locale={enGB}
                            showDateDisplay={true}
                            dateDisplayFormat='dd.MM.yy'
                            moveRangeOnFirstSelection={false}
                            preventSnapRefocus={true}
                            editableDateInputs={true}
                            ranges={[selectionRange]}
                            onChange={handleSelect}
                            retainEndDateOnFirstSelection={true}
                          />
                        
                    </div>
                </div>
            </Dropdown.Menu>
        </DateRangeContainer>
    )
}

DateRangePickerInput.propTypes = {
    start: PropTypes.string.isRequired,
    end: PropTypes.string.isRequired,
    additionalPdd: PropTypes.bool,
    classNames: PropTypes.string.isRequired,
    fromId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    thisId: PropTypes.string.isRequired,
    tooltipsTexts: PropTypes.array,
    tooltips: PropTypes.bool
}

export default DateRangePickerInput;