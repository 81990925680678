import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import _ from 'lodash';
import Tooltip from '../ui_elements/tooltip'; 
import Logo from '../../images/logo/logo_paygate.png';
import {encodeBasicUrl} from '../../helpers/helpers';
import { ADMIN_PREFIX } from 'App';

const NavElement = (props) => {
    return (
        <li className={'nav-item ' + (props.active === props.activeClass && 'active')}>
            <Link to={props.link} >
                <span className='icon-holder'>
                    <i className={props.icon}></i>
                </span>
                <span className='title'>{props.name} {props.tooltip && <Tooltip id={props.tooltipID} placement='top' text={props.tooltipText} />}</span>
            </Link>
        </li>
    )
}

const NavHeader = (props) => {
    return (
         <li className='nav-item nav-header'>
            <a href='#'>
                <span className='title'>{props.title}</span>
            </a>
        </li>
    )
}

const SideNav = (props) => {
    let merchant = props.selfMerchant;
    let name = '';
    const isAdmin = useSelector(state => state.isAdmin);
    const isUserAdmin = useSelector(state => state.isUserAdmin);
    if(!_.isEmpty(merchant.id)) {
        name = encodeBasicUrl(merchant.id);
    }
    let logoStyle = {
        backgroundImage: `url(${Logo})`
    }
    const typeBasedRoute = (admin, userAdmin, general) => {
        if(isAdmin) {
            return admin
        }
        if(isUserAdmin) {
            return userAdmin
        }
        return general
    }
    return (
    	<div className='side-nav'>
            <div className='side-nav-inner'>
                <div className='side-nav-logo'>
                    <Link to={typeBasedRoute('/' + ADMIN_PREFIX, '/' + ADMIN_PREFIX + '/context', '/')}>
                        <div className='logo logo-dark' style={logoStyle}></div>
                    </Link>
                    <div className='mobile-toggle side-nav-toggle' onClick={props.onToggle}>
                        <a href=''>
                            <i className='ti-arrow-circle-left'></i>
                        </a>
                    </div>
                </div>
                <ul className='side-nav-menu scrollable'>
                    <NavElement active={props.active} name='Dashboard' activeClass='dashboard' icon='ti-home' link={typeBasedRoute('/' + ADMIN_PREFIX, '/' + ADMIN_PREFIX + '/context' , '/')} />
                    {props.isUnassignedMerchant === false &&
                        <>
                            <NavElement name='Transactions Explorer' active={props.active} activeClass='explorer' icon='ei ei-search-alt' link={typeBasedRoute('/' + ADMIN_PREFIX + '/explorer', '/' + ADMIN_PREFIX + '/context/explorer', '/explorer')} />
                        </>
                    }
                    {props.isUnassignedMerchant === false &&
                        <NavHeader title='Management' />
                    }
                    {props.isAdmin && 
                        <NavElement active={props.active} name='Merchants' activeClass='merchants' icon='ei ei-conference' link={'/' + ADMIN_PREFIX + '/merchants'} />
                    }
                    {props.isMerchant && props.isUnassignedMerchant === false &&
                        <NavElement active={props.active} name='My keys' activeClass='apikey' icon='ei ei-key sm' link={typeBasedRoute('/' + ADMIN_PREFIX + '/merchants', '/' + ADMIN_PREFIX + '/context/keys', '/keys')} />
                    }
                    {props.isUnassignedMerchant === false &&
                        <NavElement active={props.active} name='Users' activeClass='users' icon='ei ei-deal' link={typeBasedRoute('/' + ADMIN_PREFIX + '/users', '/' + ADMIN_PREFIX + '/context/users', '/users')} />
                    }
                    {props.isAdmin &&
                        [
                            <NavHeader title='Admin' key='admin' />,
                            <NavElement active={props.active} name='Balances' activeClass='balances' icon='ei ei-devaluation' link={'/' + ADMIN_PREFIX + '/balances'} key='balances' />,
                            <NavElement active={props.active} name='Balance history' activeClass='history' icon='ei ei-panel-1' link={'/' + ADMIN_PREFIX + '/history'} key='history' />,
                            <NavElement active={props.active} name='Currencies' activeClass='currencies' icon='ei ei-euro' link={'/' + ADMIN_PREFIX + '/currencies'} key='currencies' />
                        ]
                    }
                </ul>
            </div>
        </div>
    )
}

NavElement.propTypes = {
    active: PropTypes.string.isRequired,
    activeClass: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tooltip: PropTypes.bool,
    tooltipID: PropTypes.string,
    tooltipText: PropTypes.string
}

NavHeader.propTypes = {
    title: PropTypes.string.isRequired
}

SideNav.propTypes = {
    active: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    isMerchant: PropTypes.bool.isRequired,
    isUnassignedMerchant: PropTypes.bool,
    onToggle: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    return{
        selfMerchant: state.selfMerchant
    }
}

export default connect(mapStateToProps)(SideNav);
