import React, {Component} from 'react';
import $ from 'jquery';
import PropTypes from 'prop-types';
import datepicker from 'bootstrap-datepicker';
import Datepicker from './datepicker';
import {dateObjToDate} from '../../helpers/date_helper';

export default class DatepickerContainer extends Component {
    state = {
        date: this.props.date,
    }
    componentDidMount() {
        let self = this;
        let cont =  $('#' + this.props.thisId);
        cont.datepicker({
            allowInputToggle: true,
            assumeNearbyYear: true,
            autoclose: true,
            maxViewMode: 2,
            format: 'dd.mm.yyyy',
            weekStart: 1,
            startDate: "01.01.2016",
            endDate: this.props.noEndDate ? undefined : this.props.endDate || '0d'
        }).on('changeDate', function (event) {
            let newDate = dateObjToDate(event.date);
            if(self.props.onChange) {
               self.props.onChange(event,newDate);
           }
            self.setState({ 
                [event.target.name]: newDate
            });
        });

    }
    handleChange() {
    }
    render() {
        let thisProps = this.props;
        let thisState = this.state;
        return <Datepicker thisId={thisProps.thisId} date={thisState.date} handleChange={this.handleChange} label={thisProps.label} />
    }
}

DatepickerContainer.propTypes = {
    date: PropTypes.string.isRequired,
    endDate: PropTypes.string,
    noEndDate: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    thisId: PropTypes.string.isRequired,
    label: PropTypes.string
}
