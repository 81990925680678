import {PUT_MERCHANT_ACCESS, RESET_MERCHANT_ACCESS} from '../actions/merchantAccess';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case PUT_MERCHANT_ACCESS:
			let response = action.payload.response;
			if(action.error) {
				setupRollbar(action);
				return {type: 'error', response: response}
			}
			return {data: action.payload, status: action.payload.status}
		case RESET_MERCHANT_ACCESS:
			return action.payload
		default:
			return state;
	}

}