import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const POST_NOTIFY_KEY = 'POST_NOTIFY_KEY'
export const RESET_NOTIFY_KEY = 'RESET_NOTIFY_KEY'

export function postNotificationKey({merchantId}) {
	let data = {}
	const dataPayload = axios.post(`${ENV.API_URL}/internal/admin/merchants/${merchantId}/generate-hmac-key`, data,  {
	    headers: getHeader()
	})
	return {
		type: POST_NOTIFY_KEY,
		payload: dataPayload
	}
}

export function resetNotificationKey() {
	return {
		type: RESET_NOTIFY_KEY,
		payload: {}
	}
}
