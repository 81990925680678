import {GET_MERCHANTS_LIST} from '../actions/merchantsList';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_MERCHANTS_LIST:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			let data = action.payload.data;
			if(data.length) {
				return {data: data, status: action.payload.status}
			} else {
				return action.payload
			}
		default:
			return state;
	}

}