import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Pagination from './pagination';

export default class PaginationContainer extends Component {
    state = {
        selected: this.props.initial - 1,
        perPage: this.props.perPage,
        paginationLoaded: false,
        disabledNav: false,
        initialDisable: true
    }
    componentWillUpdate(nextProps) {
        if((this.props.initial === nextProps.initial) && this.state.disabledNav === true) {
            this.setState({
                disabledNav: false
            })
        }
    }
    handleSelectChange(value,event) {
        this.setState({
            perPage: value,
            selected: 0
        });
        this.props.onChange(value, 0);
    }
    handlePageChange(e) {
        let self = this;
        if(this.state.paginationLoaded === true) {
            if(e.selected !== this.state.selected) {
                this.setState({
                    selected: e.selected,
                    disabledNav: true
                });
                this.props.onPageChange(e.selected);
            }
        } else {
            self.setState({
                paginationLoaded: true
            })
        }
    }
    handleSubmit(event) {
        event.preventDefault();
    }
    render() {
        let pageCount = parseInt(this.props.pages, 10); 
        let initialPage = parseInt(this.props.initial, 10) - 1;
        let hideResult = false;
        let thisProps = this.props;
        if((initialPage + 1) > this.props.pages) {
            hideResult = true;
        }
        return (
            <Pagination
                all={thisProps.all} 
                classNames={thisProps.classNames}
                disabledNav={this.state.disabledNav}
                handlePageChange={this.handlePageChange.bind(this)}
                handleSelectChange={this.handleSelectChange.bind(this)}
                handleSubmit={this.handleSubmit.bind(this)} 
                hideResult={hideResult} 
                initialPage={initialPage} 
                itemsPerPage={this.props.itemsPerPage}
                pageCount={pageCount} 
                pages={thisProps.pages} 
                perPage={thisProps.perPage} 
            />
        )
    }
}

PaginationContainer.propTypes = {
    all: PropTypes.number.isRequired,
    initial: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onPageChange: PropTypes.func.isRequired,
    pages: PropTypes.number.isRequired,
    perPage: PropTypes.number.isRequired
}