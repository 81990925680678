import {GET_UNASSIGNED_USERS, RESET_UNASSIGNED_USERS} from '../actions/users';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_UNASSIGNED_USERS:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			let result = {};
			if(action.payload.data) {
				result = action.payload.data.map((user) => {
					return {label: user.email, value: user.email} 
				});
			}
			return {data: result, status: action.payload.status}
		case RESET_UNASSIGNED_USERS:
			return action.payload
		default:
			return state;
	}

}