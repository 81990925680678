import { useState } from 'react'
import Breadcrumbs from 'components/ui_elements/breadcrumbs'
import LayoutContainer from 'components/layout/layout_container'
import BalancesSummary from './balances_summary'
import UnsoldPaymentsContainer from './unsold_payments_container'
import UnsoldPayoutsContainer from './unsold_payouts_container'

const BalancesLayout = (props) => {
    const [showZero, setShowZero] = useState(false);
    const handleToggle = (el) => {
        setShowZero(el.target.checked);
    }
    return (
        <LayoutContainer active='balances'>
            <Breadcrumbs dataPath={{0: ['Home', '/'], 1: ['Balances', '']}}/>
            <div className='flex-container' style={{justifyContent: 'space-between'}}>
                <div>
                    <h2 className='mrg-btm-20'>Balances</h2>  
                </div>
                <div>
                    <div className='flex-container'>
                        <label className='no-mrg-btm mrg-top-5'>Show zero balances</label>
                        <div className='mrg-left-10'>
                            <div className='toggle-checkbox toggle-success checkbox-inline mrg-btm-5'>
                                <input 
                                    checked={showZero} 
                                    id={'showZero'}                                          
                                    name={'showZero'} 
                                    onChange={handleToggle} 
                                    type='checkbox' 
                                />
                                <label htmlFor={'showZero'}></label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-12'>
                    <BalancesSummary showZero={showZero} />
                </div>
            </div>
        </LayoutContainer>
    )  
}

export default BalancesLayout;