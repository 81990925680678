import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_ACCOUNT = 'GET_ACCOUNT'

export function getAccount(token) {
    let data = {
    	headers: getHeader(token)
  	}
	const dataPayload = axios.get(`${ENV.API_URL}/account`, data)
	return {
		type: GET_ACCOUNT,
		payload: dataPayload
	}
}