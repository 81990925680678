import {PUT_RETRY_CONVERSION, RESET_RETRY_CONVERSION} from 'actions/retryConversion';
import {setupRollbar} from 'helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case PUT_RETRY_CONVERSION:
			if(action.error) {
				if(action.payload.response?.status === 400) {
					return {data: action.payload.response?.message, status: 400};
				}
				setupRollbar(action);
				return 'error'
			}
			return {data: action.payload.data, status: action.payload.status};
		case RESET_RETRY_CONVERSION:
			return action.payload;
		default:
			return state;
	}

}