import React, {Component} from 'react'
import {connect} from 'react-redux'
import { TabsProvider } from 'contexts/tabs.context'
import _ from 'lodash';
import MerchantLayout from 'components/merchant/merchant_layout';
import {columnsCollapse} from 'helpers/helpers';
import {decodeBasicUrl} from 'helpers/helpers';
import {getMerchant, resetMerchant} from 'actions/merchant';
import {secureStorage} from 'helpers/secure_storage_helper';
import PropTypes from 'prop-types';

class Merchant extends Component {
      constructor(props) {
        super(props);
        let userName;

        if(_.isEmpty(this.props.match?.params)) {
            userName = decodeBasicUrl(window.location.pathname.split("/").pop());
        } else {
            userName = decodeBasicUrl(this.props.match.params.id);
        }
        this.onExit = this.onExit.bind(this);
        this.handlePageSetup = this.handlePageSetup.bind(this);
        this.state = {
            loadedMerchant: false,
            merchantId: userName,
            notFound: false,
            updatedName: false,
            userChecked: false,            
            userDownloaded: false,
            view: this.props.isAdmin ? 'merchants' : 'apikey'
         }
      }
	  componentDidMount() {
		document.title = 'Bitcoin Payment Processor';
        this.handlePageSetup();
	  }
      componentDidUpdate() {
        if(this.state.loadedMerchant === false && (this.props.isAdmin || this.props.isMerchant)) {
            this.handlePageSetup();
        }
        let propsEl = this.props;
        let stateEl = this.state;
        if(!_.isEmpty(propsEl.merchant) && stateEl.updatedName === false) {
            document.title = propsEl.merchant.name + ' - Bitcoin Payment Processor';
            this.setState({
                updatedName: true
            })
        }
        /*if(propsEl.isAdmin || (propsEl.merchants.data && propsEl.merchants.data.length)) {
            let merchants = propsEl.merchants.data;
            let self = this;
            if(!_.isEmpty(merchants) && stateEl.userChecked === false) {
                let user  = _.some(merchants, function(division) {
                  if(division.id.toLowerCase() === stateEl.userName) {
                    self.setState({
                        merchantId: division.id,
                        view: 'merchants'
                    })
                    return true;
                  }
                });
                if(!_.isEmpty(this.props.selfMerchant)) {
                    if(this.props.selfMerchant.id.toLowerCase() === stateEl.userName) {
                        self.setState({
                            merchantId: this.props.selfMerchant.id,
                            view: 'apikey'
                        })
                        user = true;
                    }
                }
                if(user !== true) {
                    this.setState({
                        userChecked: true,
                        notFound: true
                    })
                } else {
                    this.setState({
                        userChecked: true
                    })
                }

            }
        }*/
        if(stateEl.merchantId && (stateEl.userDownloaded === false)) {
            propsEl.getMerchant(stateEl.merchantId);
            this.setState({
                userDownloaded: true
            })
        }
        if(!_.isEmpty(propsEl.merchant)) {
            columnsCollapse();
        }
      }
      componentWillUnmount() {
        if(this.props.isAdmin || this.props.isMerchant) {
          this.props.resetMerchant();
        }
      }
      handlePageSetup() {
        if(this.props.isAdmin) {
            this.setState({
                loadedMerchant: true
            })
        }
        if(this.props.isMerchant) {
            let id = secureStorage.getItem('merchantId');
            this.setState({
                merchantId: id,
                view: 'apikey',
                userChecked: true
            });
            this.props.getMerchant(id);
            this.setState({
                loadedMerchant: true
            })
        }
      }
      onSuccess() {
        this.props.getMerchant(this.state.merchantId);
      }
      onExit() {
        return
      }
	  render() {
        let thisProps = this.props;
        let thisState = this.state;
	  	return (
            <TabsProvider>
    			<MerchantLayout
                    history={thisProps.history} 
                    isAdmin={thisProps.isAdmin}
                    isMerchant={thisProps.isMerchant}
                    merchant={thisProps.merchant} 
                    merchantId={thisState.merchantId} 
                    notFound={thisState.notFound} 
                    onSuccess={this.onSuccess.bind(this)}
                    selfMerchant={thisProps.selfMerchant} 
                    view={thisState.view}
                 />
            </TabsProvider>
		)
	}
	
}

Merchant.propTypes = {
    history: PropTypes.object,
    location:  PropTypes.object,
    match: PropTypes.object
}

function mapStateToProps(state) {
    return { 
        isAdmin: state.isAdmin,
        isMerchant: state.isMerchant,
        isRefMerchant: state.isRefMerchant,
        merchant: state.merchant,
        selfMerchant: state.selfMerchant
    }
}

export default connect(mapStateToProps, {
    getMerchant, 
    resetMerchant
})(Merchant);