import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Loading from 'components/ui_elements/loading'
import LoadingError from 'components/ui_elements/loading_error'
import UnsoldPayoutsTable from 'components/tables/unsold_payouts_table'
import NumberFormatter from 'components/ui_elements/number_formatter'
import {lossStyle} from 'helpers/class_helper'

const UnsoldPayouts = (props) => {
    return (
        <div className="row">
            <div className='col-md-12'>
                <h2 className='pull-left mrg-btm-20'>Unsold payouts - {props.unsoldPayouts.data ? props.unsoldPayouts.data.transactions.length : 0}</h2>
            </div>
            <div className='col-md-12'>
                <div className="card">
                    <div className="card-block">
                        <div>
                            <div className="panel-body">
                                {_.isEmpty(props.unsoldPayouts) ? (
                                    <Loading/>
                                ) : (
                                    <div>
                                        {props.unsoldPayouts === 'error' ? (
                                            <LoadingError/>
                                        ) : (
                                            <div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p className="mrg-btm-15">Total BTC</p>
                                                        {props.unsoldPayouts.data ? (
                                                            <h1 className="no-mrg-vertical"><span className={lossStyle(props.unsoldPayouts.data.totalBtc)}><NumberFormatter toFormat={props.unsoldPayouts.data.totalBtc} currCode='BTC' /></span><b
                                                            className="font-size-14 mrg-left-5">BTC</b></h1>
                                                        ) : (
                                                            <h1 className="no-mrg-vertical">-</h1>
                                                        )}

                                                    </div>
                                                </div>
                                                <hr className="mrg-top-15 mrg-btm-15"/>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <p className="mrg-btm-15 mrg-top-10">Payments</p>
                                                        {props.unsoldPayouts.data ? (
                                                            <UnsoldPayoutsTable
                                                                unsoldPayouts={props.unsoldPayouts.data.transactions}/>
                                                        ) : (
                                                            <div></div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

UnsoldPayouts.propTypes = {
    unsoldPayouts: PropTypes.object.isRequired
}

export default UnsoldPayouts;
