import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Textarea from 'react-validation/build/textarea';
import Button from 'react-validation/build/button';
import React from 'react';
import PropTypes from 'prop-types';
import ModalContainer from '../ui_elements/modal_container';
import { CSSTransition } from 'react-transition-group';
import Tooltip from '../ui_elements/tooltip'; 
import Selectize from '../ui_elements/selectize';
import {required, fiat, number, maxlength} from '../../helpers/validator_helper';

const NewPaymentModal = (props) => {
    return (
    	<ModalContainer thisId='paymentModal' title='New payment' onHide={props.handleModalHide} showInit={props.showOnInit} >
                <Form onSubmit={props.handleSubmit} id='newPaymentForm' className='basic-form'>
                    <div className='row no-mrg'>
                        <div className='col-lg-6 width-sm-screen-12 no-pdd-left pdd-right-7 no-pdd-sm-screen'>
                            <div className='form-group'>
                                <label htmlFor='amountPayment'>Amount: *</label>
                                <Input type='text' className='form-control' id='amountPayment' name='amountPayment' validations={[required, number]} data-currency={props.currency} data-precision={props.currencyPrecision} value={props.amountPayment} onChange={props.handleChange} />
                            </div>
                        </div>
                        {props.paymentPriceCurrencies && props.paymentPriceCurrencies.length ? (
                            <div className='col-lg-6 width-sm-screen-12 no-pdd-right pdd-lrft-7 no-pdd-sm-screen'>
                                <div className='form-group clearfix'>
                                    <label htmlFor='acquiring'>Currency: *</label>
                                    <Selectize thisId='acquiring' dataOptions={props.paymentPriceCurrencies} onMounted={props.selectMounted} isSearchable={true} onChange={props.handlePaySelectChange} />
                                </div>
                            </div>
                        ) : (<span></span>)}
                    </div>
                    {props.paymentTransferCurrencies && props.paymentTransferCurrencies.length ? (
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className='form-group clearfix'>
                                    <label htmlFor='transferCurrency'>Payment coin: *</label>
                                    <Selectize thisId='transferCurrency' dataOptions={props.paymentTransferCurrencies} onMounted={props.selectMounted} isSearchable={true} onChange={props.handlePaySelectChange} />
                                </div>
                            </div>
                        </div>
                    ) : (<span></span>)}
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label htmlFor='notificationUrl'>Notification Url:</label>
                                <Input type='text' className='form-control' id='notificationUrl' name='notificationUrl' value={props.notificationUrl} onChange={props.handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label htmlFor='merchantTransactionId'>Merchant Transaction Id:</label>
                                <Input type='text' className='form-control' id='merchantTransactionId' name='merchantTransactionId' value={props.merchantTransactionId} onChange={props.handleChange} />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label htmlFor='paymentComment'>Message: <Tooltip id='messageTooltip' placement='top' text='Message to be displayed in customer wallet' /></label>
                                <Textarea rows='3' className='form-control textarea-small' id='paymentComment'  validations={[maxlength]} name='paymentComment' data-maxlength='150' data-name='comment' value={props.paymentComment} onChange={props.handleChange}></Textarea>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <div className='checkbox checkbox-inline checkbox-primary'>
                                    <input id='defineRedirects' name='defineRedirects' type='checkbox' checked={props.defineRedirects} onChange={props.handleCheckboxChange} />
                                    <label htmlFor='defineRedirects'>Define redirects</label>
                                    <Tooltip id='redirectsTooltip' placement='top' text='URL after payment process' />
                                </div>
                            </div>
                        </div>
                    </div>
                    {props.defineRedirects === true &&
                        <CSSTransition
                              timeout={500}
                              classNames="input"
                            >
                            <div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label htmlFor='expiredRedirect'>Expired: </label>
                                            <Input type='text' className='form-control' id='expiredRedirect' name='expiredRedirect' value={props.expiredRedirect} onChange={props.handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label htmlFor='confirmedRedirect'>Confirmed: </label>
                                            <Input type='text' className='form-control' id='confirmedRedirect' name='confirmedRedirect' value={props.confirmedRedirect} onChange={props.handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label htmlFor='invalidRedirect'>Invalid: </label>
                                            <Input type='text' className='form-control' id='invalidRedirect' name='invalidRedirect' value={props.invalidRedirect} onChange={props.handleChange} />
                                        </div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label htmlFor='unconfirmedRedirect'>Unconfirmed: </label>
                                            <Input type='text' className='form-control' id='unconfirmedRedirect' name='unconfirmedRedirect' value={props.unconfirmedRedirect} onChange={props.handleChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CSSTransition>
                    }
                    <div className='row'>
                        <div className='col-md-12 text-right small'>
                            <p className='mrg-btm-5'>* - Field is required</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-12'>
                            {props.reloadForm === true ? (
                                <button className='btn btn-primary pull-right width-100 mrg-btm-20 mrg-top-10 no-mrg-right' disabled>Create payment</button>
                            ) : (
                                <Button className='btn btn-primary pull-right width-100 mrg-btm-20 mrg-top-10 no-mrg-right' type='submit' disabled>Create payment</Button>
                            )}
                        </div>
                    </div>
                </Form>
        </ModalContainer>
    )
}

NewPaymentModal.propTypes = {
    paymentPriceCurrencies: PropTypes.array.isRequired,
    amountPayment: PropTypes.string.isRequired,
    confirmedRedirect: PropTypes.string.isRequired,
    defineRedirects: PropTypes.bool.isRequired,
    expiredRedirect: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleCheckboxChange: PropTypes.func.isRequired,
    handleModalHide: PropTypes.func.isRequired,
    handlePaySelectChange: PropTypes.func.isRequired,
    invalidRedirect: PropTypes.string.isRequired,
    merchantTransactionId: PropTypes.string.isRequired,
    notificationUrl: PropTypes.string.isRequired,
    paymentComment: PropTypes.string.isRequired,
    paymentTransferCurrencies: PropTypes.string.isRequired,
    reloadForm: PropTypes.bool.isRequired,
    selectMounted: PropTypes.func.isRequired,
    unconfirmedRedirect: PropTypes.string.isRequired
}

export default NewPaymentModal;
