import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {putUser, resetUser} from '../../actions/user';
import {getUsers, resetUsers} from '../../actions/users';
import {getAccount} from '../../actions/account';
import {getMerchantsPaginated, resetMerchantsPaginated} from '../../actions/merchantsPaginated';
import {updateInputState} from '../../helpers/form_helper';
import UsersSearchForm from './users_search_form';
import PropTypes from 'prop-types';

class UsersSearchFormContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchedMerchant: '',
            email: '',
            limit: 128,
            offset: 0,
            merchantId: '',
            merchantsNamesLoaded: false,
            formDisabled: false,
            userId: '', 
            userName: '',
            userMerchants: []
        }
        this.formSent = this.formSent.bind(this);
    }
    componentDidMount() {
        document.title = "Users - Bitcoin Payment Processor";
        this.props.getAccount();
        this.props.getUsers(this.state.email, this.state.merchantId);
        this.props.searchParamsUpdate(this.state);
    }
    componentDidUpdate() {
        let thisProps = this.props;
        let thisState = this.state;
        if(_.isEmpty(thisProps.merchantsNamesPaginated) && thisProps.isAdmin && thisState.merchantsNamesLoaded === false) {
            let params = {
                limit: this.state.limit,
                offset: this.state.offset,
                select: true
            };
            if(thisState.searchedMerchant !== '') {
                params.name = this.state.searchedMerchant;
            }
            thisProps.getMerchantsPaginated(params);
            this.setState({
                merchantsNamesLoaded: true
            })
        }
        if(thisProps.users.status === 200 && thisState.formDisabled === true) {
            this.setState({
                formDisabled: false
            })
        }
        if(thisProps.resetUserForm === true) {
            this.setState({
                email: '', 
                merchantId: ''
            })
        }
    }
    handleChange(event) {
        updateInputState(this, event);
    }
    handleSelectChange(val, event) {
        this.setState({ [event]: val});
    }
    handleSelectInputChange(el) {
        if(this.state.searchedMerchant !== el) {
            this.setState({
                searchedMerchant: el
            })
            let params = {
                limit: this.state.limit,
                offset: this.state.offset,
                select: true
            };
            if(el !== '') {
                params.name = el;
            }
            this.props.getMerchantsPaginated(params);
        }
    }
    handleSubmit(event) {
        this.props.searchParamsUpdate(this.state, true, event);
    }
    formSent(email, merchant) {
        this.props.resetUsers();
        this.props.getAccount();
        this.props.getUsers(email, merchant);
        this.setState({
            email: email,
            merchantId: merchant
        })
    }
    render() {
        let thisProps = this.props;
        let thisState = this.state;
        return (
            <UsersSearchForm 
                email={thisState.email} 
                formDisabled={thisState.formDisabled}
                handleChange={this.handleChange.bind(this)}
                handleSelectChange={this.handleSelectChange.bind(this)}
                handleSelectInputChange={this.handleSelectInputChange.bind(this)}
                handleSubmit={this.handleSubmit.bind(this)}
                isAdmin={thisProps.isAdmin}
                merchantId={thisState.merchantId} 
                merchantsNames={thisProps.merchantsNamesPaginated} 
            />
        )
    }
}

UsersSearchFormContainer.propTypes = {
    history: PropTypes.object,
    location:  PropTypes.object,
    match: PropTypes.object,
    resetUserForm: PropTypes.bool.isRequired,
    searchParamsUpdate: PropTypes.func.isRequired
}

function mapStateToProps(state) {
    return { 
        account: state.account,
        isAdmin: state.isAdmin,
        merchantsNamesPaginated: state.merchantsNamesPaginated,
        users: state.users
    }
}

export default connect(mapStateToProps, {
    getUsers, 
    putUser, 
    resetUser, 
    resetUsers, 
    getMerchantsPaginated,
    resetMerchantsPaginated,
    getAccount
})(UsersSearchFormContainer);
