import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_CONVERSION_LIMIT = 'GET_CONVERSION_LIMIT'
export const RESET_CONVERSION_LIMIT = 'RESET_CONVERSION_LIMIT'

export function getConversionLimit({sellCurrency}) { 
    let sellCurr = sellCurrency;
    if(sellCurrency instanceof Object) {
        sellCurr = sellCurrency.value
    }
    let data = { 
        headers: getHeader(),
        params: {
            'sellCurrency': sellCurr
        }
    } 
    let type = GET_CONVERSION_LIMIT
    const dataPayload = axios.get(`${ENV.API_URL}/v3/conversion-limits`, data)
    return { 
        type: type, 
        payload: dataPayload
    } 
}

export function resetConversionLimit() { 
    return { 
        type: RESET_CONVERSION_LIMIT, 
        payload: {}
    } 
}