import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';
import React from 'react';
import PropTypes from 'prop-types';
import ModalContainer from 'components/ui_elements/modal_container';
import {text, swift, iban, account} from 'helpers/validator_helper';
import DataPresentationRow from 'components/ui_elements/data_presentation_row';

const EditBillingModal = (props) => {
    return (
    	<ModalContainer thisId='editBillingModal' title='Edit billing info' onHide={props.handleModalHide} showInit={props.showOnInit}>
            <Form onSubmit={props.handleSubmit} id='editContactModalForm' className='basic-form'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group clearfix'>
                            <label htmlFor='bankName'>Bank name:</label> 
                            <Input type='text' className='form-control' id='bankName' name='bankName' data-name='bank name' validations={[text]} data-optional='true' value={props.bankName} onChange={props.handleChange} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group clearfix'>
                            <label htmlFor='swift'>Swift:</label>
                            <Input type='text' className='form-control' id='swift' name='swift' validations={[swift]} data-name='swift' data-optional='true' value={props.swift} onChange={props.handleChange} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group clearfix'>
                            <label htmlFor='accountNumber'>Account number:</label>
                            <Input type='text' className='form-control' id='accountNumber' name='accountNumber' validations={[account]} data-name='account number' data-optional='true' value={props.accountNumber} onChange={props.handleChange} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group clearfix'>
                            <label htmlFor='iban'>IBAN:</label>
                            <Input type='text' className='form-control' id='iban' name='iban' validations={[iban]} data-name='IBAN' data-optional='true' value={props.iban} onChange={props.handleChange} />
                        </div>
                    </div>
                </div>
                <div className='row mrg-top-10'>
                    <div className='col-md-12'>
                        <button type='button' className='btn btn-default' data-dismiss='modal'>Cancel</button>
                        {props.disabledForm === true ? (
                            <button className='btn btn-primary pull-right disabled' type='submit' disabled>Update info</button>
                        ) : (
                            <Button className='btn btn-primary pull-right' type='submit'>Update info</Button>
                        )}
                    </div>
                </div>
            </Form>
        </ModalContainer>
    )
}

EditBillingModal.propTypes = {
    accountNumber: PropTypes.string.isRequired,
    bankName: PropTypes.string.isRequired,
    disabledForm: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleModalHide: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    iban: PropTypes.string.isRequired,
    showOnInit: PropTypes.bool.isRequired,
    swift: PropTypes.string.isRequired
}

export default EditBillingModal;