export const PRIMARY = '#7774e7';
export const SUCCESS = '#37c936';
export const INFO = '#0f9aee';
export const WARNING = '#f7931b';
export const DANGER = '#ff3c7e';
export const PRIMARY_INVERSE = 'rgba(119, 116, 231, 0.1)';
export const SUCCESS_INVERSE = 'rgba(55, 201, 54, 0.1)';
export const INFO_INVERSE = 'rgba(15, 154, 238, 0.1)';
export const WARNING_INVERSE = 'rgba(255, 134, 0, 0.1)';
export const DANGER_INVERSE = 'rgba(255, 60, 126, 0.1)';
export const DANGER_LIGHT = '#cad0d8';
export const DANGER_ALT = '#1a3b59';
export const PRIMARY_ALT ='#48a8f5';
export const GRAY = '#f6f7fb';
export const WHITE = '#fff';
export const DARK = '#515365';
export const NEUTRAL_LIGHT = '#edf2f8';
export const NEUTRAL_MEDIUM = '#e6ecf5';
export const NEUTRAL_DARKER = '#1a3b59';
export const BLACK = '#000';
