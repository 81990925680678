import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'
import moment from 'moment'
import _ from 'lodash'
import swal from 'sweetalert2'
import { postPayment, resetPayment } from 'actions/payment'
import { postPayout, resetPayout, confirmPayout, resetConfirmedPayout, cancelPayout, resetCancelPayout } from 'actions/payout'
import { postConversion, resetConversion } from 'actions/conversion'

import NewPayoutModal from 'components/new_payout/new_payout_modal'
import NewPaymentModalContainer from 'components/new_payment/new_payment_modal_container'
import NewConversionModal from 'components/new_conversion/new_conversion_modal'
import NumberFormatter from 'components/ui_elements/number_formatter'
import { initAlert } from 'helpers/alert_helper'
import { totalCommaPrecision } from 'helpers/number_helper'
import { dateToIsoFull } from 'helpers/date_helper'
import variables from 'styles/_variables.scss'

const CurrencyDisplay = styled.div`
    margin-right: 50px;
    h2 {
        font-size: 30px;
    }
    span {
        text-transform: uppercase;
        font-size: 14px;
        margin-left: 8px;
        font-weight: 400;
    }
`

const BalancesCard = styled.div`
    background-color: ${variables.defaultLogo};
    h2, h3 {
        color: ${variables.defaultWhite};
    }
`

const DashboardMerchantBalances = ({sendRequest}) => {
    const newPayment = useSelector(state => state.newPayment);
    const newPayout = useSelector(state => state.newPayout);
    const newConversion = useSelector(state => state.newConversion);
    const confirmedPayout = useSelector(state => state.confirmedPayout);
    const currencies = useSelector(state => state.currencies);
    const bankDetails = useSelector(state => state.bankDetails);
    const liveBalanceMerchant = useSelector(state => state.liveBalanceMerchant.data);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [showPayoutModal, setShowPayoutModal] = useState(false);
    const [showConversionModal, setShowConversionModal] = useState(false);
    const [confirmationSending, setConfirmationSending] = useState();
    const [currencyPayment, setCurrencyPayment] = useState();
    const [currencyPayout, setCurrencyPayout] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        if(!_.isEmpty(confirmedPayout) && confirmedPayout !== undefined && confirmationSending) {
            if(confirmedPayout.type === 'error') {
                initAlert({
                    type: 'error', 
                    title: 'Error', 
                    html: confirmedPayout.response
                });
                dispatch(resetPayout());     
                sendRequest();
                setConfirmationSending(false);
                return;
            }
            handleSuccess(confirmedPayout);
            dispatch(resetConfirmedPayout());
            setConfirmationSending(false);
        }

    }, [confirmedPayout]);

    useEffect(() => {
        if(!_.isEmpty(newPayment) && newPayment !== undefined && currencyPayment) {
            if(newPayment.type === 'error') {
                initAlert({
                    type: 'error',
                    title: 'Error',
                    html: newPayment.response
                });
                dispatch(resetPayment());
                return;
            }
            if(newPayment.status === 200) {
                initAlert({
                    type: 'success',
                    title: 'Payment created',
                    html:
                        'You have successfully created new payment for: ' +
                        '<br />' +
                        '<br />' +
                        '<h4 className="mrg-btm-15"><b>' + totalCommaPrecision({toFormat: newPayment.data.transferAmount, currCode: newPayment.data.transferCurrency, currencies}) + ' ' + newPayment.data.transferCurrency + '</b></h4>' +
                        'To see this payment go to Payment Page' +
                        '<br />' +
                        '<a className="btn btn-default mrg-top-30 mrg-btm-30 btn-long" target="_blank" rel="noopener noreferrer" href="' + newPayment.data.paymentPageUrl + '">Payment Page</a>' +
                        '<br />' +
                        'or click OK to continue',
                    confirmText: 'OK',
                    classNames: 'long'
                });
                dispatch(resetPayment());
                setCurrencyPayment(false);
            }
            sendRequest();
            setCurrencyPayment(false);
        }
    }, [newPayment]);

    useEffect(() => {
        if(!_.isEmpty(newConversion) && newConversion !== undefined) {
            if(newConversion.type === 'error') {
                initAlert({
                    type: 'error',
                    title: 'Error',
                    html: newConversion.response ? newConversion.response : 'Some error occured when creating new conversion. Please try again'
                });
                dispatch(resetConversion());
                return;
            }
            if(newConversion.status === 200) {
                let newConversionData = JSON.parse(newConversion.config.data);
                initAlert({
                    type: 'success',
                    title: 'Conversion request created',
                    html:
                        'You have successfully created new conversion ' +
                        '<br />' +
                        '<br />' +
                        'From:' +
                        '<br />' +
                        '<br />' +
                        '<h4 className="mrg-btm-15"><b>' + totalCommaPrecision({toFormat: newConversionData.sellAmount, currCode: newConversionData.sellCurrency, currencies}) + ' ' + newConversionData.sellCurrency + '</b></h4>' +
                        '<br />' +
                        'To:' +
                        '<br />' +
                        '<br />' +
                        '<h4 className="mrg-btm-15"><b>' + newConversionData.buyCurrency + '</b></h4>' +
                        '<br />' +
                        'After processing is completed conversion result' +
                        '<br />' +
                        'will be visible in Transactions Explorer',
                    confirmText: 'OK',
                    classNames: 'long'
                });
                dispatch(resetConversion());
            }
            sendRequest();
        }
    }, [newConversion]);

    useEffect(() => {
        if(!_.isEmpty(newPayout) && newPayout !== undefined && currencyPayout) {
            if(newPayout.type === 'error') {
                initAlert({
                    type: 'error', 
                    title: 'Error', 
                    html: newPayout.response
                });
                dispatch(resetPayout());
                return;
            }
            if(newPayout.data.status === 'SENT' || newPayout.data.status === 'QUEUED') {
                handleSuccess(newPayout);
                dispatch(resetConfirmedPayout());
                setCurrencyPayout(false);
                setConfirmationSending(false);
                return;
            } 
            let newPayoutData = newPayout.data;
            dispatch(resetPayout());
            sendRequest();
            newPayoutSubmit({
                transferAmount: newPayoutData.transferAmount, 
                priceAmount: newPayoutData.priceAmount, 
                address: newPayoutData.address, 
                transferCurrency: newPayoutData.transferCurrency,
                priceCurrency: newPayoutData.priceCurrency, 
                id: newPayoutData.payoutId, 
                expirationTime: newPayoutData.expirationTime
            });
            setCurrencyPayout(false);
        }
    }, [newPayout]);

    const modalPaymentClosed = () => {
        setShowPaymentModal(false);
    }
    const modalClosed = () => {
        setShowPayoutModal(false);
    }
    const modalConnversionClosed = () => {
        setShowConversionModal(false);
    }
    const onSend = ({amount, currency, transferCurrency, message, notificationUrl, merchantTransactionId, confirmedRedirect, expiredRedirect, invalidRedirect, unconfirmedRedirect, defineRedirects}) => {
        dispatch(postPayment({
            amount: amount,
            currency: currency,
            transferCurrency: transferCurrency,
            message: message,
            notificationUrl: notificationUrl,
            merchantTransactionId: merchantTransactionId,
            confirmedRedirect: confirmedRedirect,
            expiredRedirect: expiredRedirect,
            invalidRedirect: invalidRedirect,
            unconfirmedRedirect: unconfirmedRedirect,
            defineRedirects: defineRedirects
        }));
        setCurrencyPayment(currency);
    }
    const onSendPayout = ({coin, fiat, address, currency, payCurrency, notificationUrl, comment, autoApprove, skipTrading}) => {
        const postData = {
            coin: coin, 
            fiat: fiat, 
            currency: currency, 
            payCurrency: payCurrency, 
            notificationUrl: notificationUrl, 
            comment: comment, 
            autoApprove: autoApprove, 
            skipTrading: skipTrading
        }
        if(address && address !== '') {
            postData.address = address;
        }
        dispatch(postPayout({...postData}));
        setCurrencyPayout(currency);
    }
    const onSendConversion = ({sellAmount, sellCurrency, buyCurrency}) => {
        const postData = {
            sellAmount: sellAmount, 
            sellCurrency: sellCurrency, 
            buyCurrency: buyCurrency
        }
        dispatch(postConversion({...postData}));
    }
    const newPayoutSubmit = ({transferAmount, priceAmount, address, transferCurrency, priceCurrency, id, expirationTime}) => {
        let timeNow = dateToIsoFull(new Date());
        let payoutMessage = 
            'Your payout request have been calculated for' +
            '<br />' +
            '<div className="card mrg-top-15 no-mrg-btm">' +
                '<div className="card-block card-block-small">' +
                    '<p className="mrg-top-10 mrg-btm-8 clearfix text-center">' +
                        '<span className="text-dark font-size-18">' +
                            '<b>' + transferAmount + ' ' + transferCurrency +'</b>' +
                        '</span>' +
                    '</p>' +
                '</div>' +
            '</div>' +
            '<br />' +
            'and will cost' +
            '<br />' +
            '<div className="card mrg-top-15 no-mrg-btm">' +
                '<div className="card-block card-block-small pdd-left-15 pdd-right-15">' +
                    '<p className="mrg-top-10 mrg-btm-8 clearfix text-center">' +
                        '<span className="text-dark font-size-18">' +
                            '<b>' + priceAmount + ' ' + priceCurrency + '</b>' +
                        '</span>' +
                    '</p>' +
                '</div>' +
            '</div>' +
            '<br />';
        if(transferCurrency === 'EUR') {
                payoutMessage +=
                    'Bank details:' +
                    '<br />' +
                    '<div class="card mrg-top-15 no-mrg-btm">' +
                        '<div class="card-block pdd-15">' +
                            '<p>Bank: <b>' + bankDetails.data.bankName + '</b></p>' +
                            '<p>IBAN: <b>' + bankDetails.data.iban + '</b></p>' +
                            '<p>Swift: <b>' + bankDetails.data.swift + '</b></p>' +
                            '<p className="no-mrg-btm">Account number: <b>' + bankDetails.data.accountNumber + '</b></p>' +
                        '</div>' +
                    '</div>' +
                    '<br />' +
                    'This exchange rate is valid for <b id="timeLeft"></b>' +
                    '<br />' +
                    'Do you want to continue?' +
                    '<br />' +
                    '<br />' +
                    '<div id="myProgress" className="mrg-top-30 mrg-btm-30 hide">' +
                        '<div id="myBar">' +
                        '</div>' +
                    '</div>';

            } else {
                if(address !== null) {
                    payoutMessage += 
                        'sending address is:' +
                        '<br />' +
                        '<div className="card mrg-top-15 no-mrg-btm">' +
                            '<div className="card-block card-block-small pdd-left-15 pdd-right-15">' +
                                '<p className="mrg-top-10 mrg-btm-8 clearfix text-center">' +
                                    '<span className="text-dark font-size-18">' +
                                        '<b>' + address + '</b>' +
                                    '</span>' +
                                '</p>' +
                            '</div>' +
                        '</div>' +
                        '<br />' +
                        '<br />' +
                        'This exchange rate is valid for <b id="timeLeft"></b>' +
                        '<br />' +
                        'Do you want to continue?' +
                        '<br />' +
                        '<br />' +
                        '<div id="myProgress" className="mrg-top-30 mrg-btm-30 hide">' +
                            '<div id="myBar">' +
                            '</div>' +
                        '</div>';
                } else {
                        payoutMessage += 
                            '<br />' +
                            'This exchange rate is valid for <b id="timeLeft"></b>' +
                            '<br />' +
                            'Do you want to continue?' +
                            '<br />' +
                            '<br />' +
                            '<div id="myProgress" className="mrg-top-30 mrg-btm-30 hide">' +
                                '<div id="myBar">' +
                                '</div>' +
                            '</div>';
                }
        }
        initAlert({
            type: 'info', 
            title: 'Payout calculated', 
            html: payoutMessage, 
            confirmText: 'Yes, send ' + transferCurrency,  
            cancelText: 'Exit',
            onSuccess: () => sendCoin(id), 
            onDismiss: onCanceled
        });
        timerDisplay(expirationTime, timeNow);
    }
    const handleSuccess = (val) => {
        let el = val.data;
        initAlert({
            type: 'success', 
            title: 'Started processing', 
            html: 
                'Your payout is now being processed' +
                '<br/>' +
                '<br/>' + 
                '<div className="col-md-12">' +
                    '<div className="card no-mrg-top">' +
                        '<div className="card-block card-block-success">' +
                            '<div>' +
                                '<p className="clearfix mrg-btm-15">' +
                                    '<span className="pull-left font-size-14">Total ' + el.transferCurrency + ' to send:</span>' +
                                    '<span className="pull-right text-dark font-size-18">' + el.transferAmount + ' ' + el.transferCurrency + '</span>' + 
                                '</p>' +
                            '</div>' +
                            '<div>' +
                                '<p className="no-mrg-btm clearfix">' +
                                    '<span className="pull-left">Total price: </span>' +
                                    '<span className="pull-right text-dark font-size-18"><b>' + el.priceAmount + ' ' + el.priceCurrency + '</b></span>' +
                                '</p>' +
                            '</div>' +
                        '</div>' +
                    '</div>' +
                '</div>' +
                '<br/>' +
                'After processing is completed and payout is sent status in the system will change from <b>QUEUED</b> to <b>SENT</b>.'
        });
        dispatch(resetPayout());
        sendRequest();
    }
    const sendCoin = (id) => {
        dispatch(confirmPayout(id));
        setConfirmationSending(true);
    }
    const onCanceled = () => {
        return
    }
    const timerDisplay = (expirationTime, timeNow) => {
        let date1 = moment(timeNow, 'YYYY-MM-DD HH:mm:ss');
        let date2 = moment(expirationTime, 'YYYY-MM-DD HH:mm:ss');
        let diff = date2.diff(date1, 'seconds');
        let elem = document.getElementById('myBar'); 
        let text = document.getElementById('timeLeft');
        let hour = 3600;
        let width = 100;
        let endText = '';
        let id = setInterval(frame, 1000);
        let started = true;
        function frame() {
            if (width <= 0) {
                swal.close();
                //sendRequest();
                clearInterval(id);
            } else {
                diff--;
                width = diff / hour * 100;
                date2 = moment(date2).subtract(1, 'seconds');
                let newDate = moment.utc(moment(date2).diff(moment(date1)));
                endText = newDate.format('HH') === '00' ? newDate.format('mm:ss') + ' min' : newDate.format('HH:mm:ss') + ' min';
                text.innerHTML = endText;
                elem.style.width = width + '%';
                if (started === true) {
                  document.getElementById('myProgress').classList.remove('hide');
                  started = false;
                }
                if(width < 50) {
                  elem.classList.add('warning');
                } 
                if(width < 20) {
                  elem.classList.remove('warning')
                  elem.classList.add('danger');
                }
            }
        }
    }
    return (
        <>
            <BalancesCard className='card padding-20'> 
                <h3 className='mrg-btm-20'>Current balances</h3>
                <div className='flex-container flex-wrap' style={{rowGap: 15}}>
                    {liveBalanceMerchant && liveBalanceMerchant.length && liveBalanceMerchant[0].monetaryValues.length ? (
                        <>
                            {liveBalanceMerchant[0].monetaryValues.map(balance => (
                                <CurrencyDisplay>
                                    <h2><NumberFormatter toFormat={balance.amount} currCode={balance.currency} /><span>{balance.currency}</span></h2>
                                </CurrencyDisplay>
                            ))}
                        </>
                    ) : (
                        <CurrencyDisplay>
                            <h2>0.00<span>EUR</span></h2>
                        </CurrencyDisplay>
                    )}
                </div>
                <hr />
                <div className='row'>
                    <div className='col-md-4'>
                        <button className='btn btn-default btn-full mrg-top-10' onClick={() => setShowPaymentModal(true)}>New payment</button>
                    </div>
                    <div className='col-md-4'>
                        <button className='btn btn-default btn-full mrg-top-10' onClick={() => setShowPayoutModal(true)}>New payout</button>
                    </div>
                    <div className='col-md-4'>
                        <button className='btn btn-default btn-full mrg-top-10' onClick={() => setShowConversionModal(true)}>Convert</button>
                    </div>
                </div>
            </BalancesCard>
            {showPaymentModal === true &&
                <NewPaymentModalContainer showOnInit={true} onModalClosed={modalPaymentClosed} formSent={onSend} />
            }
            {showPayoutModal === true &&
                <NewPayoutModal showOnInit={true} onModalClosed={modalClosed} formSent={onSendPayout} />
            }
            {showConversionModal === true &&
                <NewConversionModal showOnInit={true} onModalClosed={modalConnversionClosed} formSent={onSendConversion} />
            }
        </>
    )
}

export default DashboardMerchantBalances;