import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const RESEND_PAYMENT_NOTIFICATION = 'RESEND_PAYMENT_NOTIFICATION'
export const RESET_RESEND_PAYMENT_NOTIFICATION = 'RESET_RESEND_PAYMENT_NOTIFICATION'

export function resendPaymentNotification(paymentId) {
    let config = {
    	headers: getHeader()
  	}
	const dataPayload = axios.post(`${ENV.API_URL}/v3/payments/${paymentId}/resend-notification`, {}, config)
	return {
		type: RESEND_PAYMENT_NOTIFICATION,
		payload: dataPayload
	}
}

export function resetResendPaymentNotification() {
	const dataPayload = {}
	return {
		type: RESET_RESEND_PAYMENT_NOTIFICATION,
		payload: dataPayload
	}
}