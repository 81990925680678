import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'
 
export const POST_PAYOUT = 'POST_PAYOUT'
export const CONFIRM_PAYOUT = 'CONFIRM_PAYOUT'
export const CANCEL_PAYOUT = 'CANCEL_PAYOUT'
export const RESET_PAYOUT = 'RESET_PAYOUT'
export const RESET_CONFIRMED_PAYOUT = 'RESET_CONFIRMED_PAYOUT'
export const RESET_CANCEL_PAYOUT = 'RESET_CANCEL_PAYOUT'

export function postPayout({coin, fiat, address, currency, payCurrency, notificationUrl, comment, autoApprove, skipTrading}) {
    let data = {
        'comment': comment,
        'autoApprove': autoApprove,
        'priceAmount': coin,
        'priceCurrency': currency.value,
        'transferCurrency': payCurrency.value
    }
    if(address) {
        data.address = address
    }
    if(notificationUrl) {
        data.notificationUrl = notificationUrl
    }
    if(skipTrading === true) {
        data.skipTrading = true
    }
    const dataPayload = axios.post(`${ENV.API_URL}/internal/payouts`, data, {
        headers: getHeader()
    })
    return {
        type: POST_PAYOUT,
        payload: dataPayload
    }
}
export function confirmPayout(id) {
    let data = {}
    const dataPayload = axios.post(`${ENV.API_URL}/internal/payouts/${id}/approve`, data, {
        headers: getHeader()
    })
    return {
        type: CONFIRM_PAYOUT,
        payload: dataPayload
    }
}
export function cancelPayout(id) {
    let data = {}
    const dataPayload = axios.put(`${ENV.API_URL}/internal/payouts/${id}/cancel`, data, {
        headers: getHeader()
    })
    return {
        type: CANCEL_PAYOUT,
        payload: dataPayload
    }
}
export function resetConfirmedPayout() {
    const dataPayload = {}
    return { 
        type: RESET_CONFIRMED_PAYOUT, 
        payload: dataPayload
    } 
}
export function resetPayout() {
    const dataPayload = ''
    return {
        type: RESET_PAYOUT,
        payload: dataPayload
    }
}
export function resetCancelPayout() {
    const dataPayload = ''
    return {
        type: RESET_CANCEL_PAYOUT,
        payload: dataPayload
    }
}


