import {GET_PAYOUT_PRICE_FIAT, RESET_PAYOUT_PRICE_FIAT} from '../actions/payouts';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = '', action) {
	switch(action.type) {
		case GET_PAYOUT_PRICE_FIAT:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return action.payload.data
		case RESET_PAYOUT_PRICE_FIAT:
			return action.payload
		default:
			return state;
	}

}