import React, {useEffect} from 'react'
import Error403Icon from 'images/icons/403-3.svg'
import { secureStorage } from 'helpers/secure_storage_helper'

const Error403 = () => {
    useEffect(() => {
        document.title = "403 - Forbidden"
        localStorage.removeItem('accessToken')
        secureStorage.removeItem('refreshToken')
    }, []);
    return (
        <div className='container text-center'>
            <br />
            <br />
            <br />
            <br />
            <img src={Error403Icon} style={{height: 350}} />
            <br />
            <br />
            <h2>Forbidden</h2>
            <p>You don't have access to view this page</p>
        </div>
    )
}


export default Error403

