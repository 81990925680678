import { axios } from 'axiosInstance'
import { ENV } from "index"
 
export const GET_UNSOLD_PAYOUTS = 'GET_UNSOLD_PAYOUTS'
export const RESET_UNSOLD_PAYOUTS = 'RESET_UNSOLD_PAYOUTS'

export function getUnsoldPayouts() {
    let data = {
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('accessToken')},
    } 
    const dataPayload = axios.get(`${ENV.API_URL}/unsold-payouts`, data)
    return { 
        type: GET_UNSOLD_PAYOUTS,
        payload: dataPayload
    } 
}

export function resetUnsoldPayouts() {
    const dataPayload = {}
    return {
        type: RESET_UNSOLD_PAYOUTS,
        payload: dataPayload
    }
}