export const CRYPTO_NAMES = {
	USDC_ERC20_GOERLI: 'USDC ERC20 Goerli',
	USDC_ERC20: 'USDC ERC20',
	USDT_TRC20_SHASTA: 'USDT TRC20 Shasta',
	USDT_ERC20_GOERLI: 'USDT ERC20 Goerli',
	USDT_TRC20: 'USDT TRC20',
	USDT_ERC20: 'USDT ERC20',
	BTC: 'BTC',
	BTC_TEST: 'BTC Test',
	TRX: 'TRX',
	TRX_SHASTA: 'TRX Shasta',
	ETH: 'ETH',
	ETH_GOERLI: 'ETH Goerli',
}