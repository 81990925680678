import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { putApproveTermsAndConditions, resetApproveTermsAndConditions } from 'actions/termsAndConditions'
import _ from 'lodash'
import {secureStorage} from 'helpers/secure_storage_helper'
import {initAlert} from 'helpers/alert_helper'
import Logo from 'images/logo/logo_paygate.png'
import PropTypes from 'prop-types'
import Loading from 'components/ui_elements/loading'
import { getAccount } from 'actions/account'

const TermsAndConditions = () => {
    const location = useLocation();
    const [delay, setDelay] = useState(true)
    const newApprove = useSelector(state => state.newApprove);
    const account = useSelector(state => state.account)

    const dispatch = useDispatch();
    const history = useNavigate();

    const [gettingApprove, setGettingApprove] = useState(false);
    useEffect(() => {
        document.title = "Accept Terms & Conditions - Bitcoin Payment Processor";
        setTimeout(() => {
            setDelay(false);
        }, 1000);
    }, []);

    useEffect(() => {
        if(!_.isEmpty(newApprove) && gettingApprove === true) {
            if(newApprove.type === 'error') {
                initAlert({
                    type: 'error', 
                    title: 'Error', 
                    html: 'Some error occured. Please try again.', 
                    confirmText: 'OK'
                });
                setGettingApprove(false);
            } else {
                secureStorage.setItem('approvedTerms', true);
                dispatch(resetApproveTermsAndConditions());
                dispatch(getAccount());
                setGettingApprove(false);
            }
           
        }
    }, [newApprove]);

    useEffect(() => {
        if(account.user?.approvedTermsConditions === true) {
            history('/')
        } 
    }, [account.user]);

    const handleAccept = () => {
        dispatch(putApproveTermsAndConditions());
        setGettingApprove(true)
    }
    let logoStyle = {
        backgroundImage: `url(${Logo})`
    }
    if(delay) {
        return <Loading />
    }
    return (
        <div className='row'>
                <div className='col-md-8 offset-md-2 mrg-top-50'>
                    <div className='main-content'>
                        <div className='container-fluid'>
                            <div className='height-50px mrg-btm-30'>
                                <div className='logo logo-dark logo-mini' style={logoStyle}></div>
                            </div>
                            <h1 className='pull-left mrg-btm-30 col-md-12'>Accept Terms & Conditions</h1> 
                            <div className='card regulations'>
                                <div className='pdd-horizon-30 pdd-vertical-30'>
                                    <h2 className='no-mrg-top'>Preamble</h2>
                                    <p>This agreement (<b>‘Terms’</b>) applies solely to users of any services made available by Bitclear AG with address Industriestrasse 26, 9491 Ruggell, Liechtenstein (<b>“Bitclear or Company”</b>) using the Website/Service www.bitclear.li (<b>“Website/Service”</b>) or any other services provided by Bitclear. When making use of the services found on the Website you are deemed to have read this agreement, understood and accepted the Terms and Conditions found in this agreement as well as our Privacy Policy compliant with the GDPR regulation. By applying to Bitclear’s services you have agreed to abide with the Terms and Conditions found in this agreement. If any disagreement shall arise please proceed with the removal of your account and stop any usage of Bitclear’s services.
                                    <br />
                                    <br />
                                    For any additional information on Bitclear, or any questions regarding this agreement, please feel free to contact Bitclear for further clarification through our Support team on info@bitclear.li.</p>

                                    <h2>1. Modification of Agreement Conditions</h2>
                                    <p>Bitclear reserves the right to modify the terms and conditions of this agreement at any time at its own discretion. Bitclear will make sure to provide notice of the made changes by updating the document on the Website and changing the version of the document. Any modifications to this document will come into effect immediately upon being announced on the Website or notified to the users. Thus, if any disagreement arises it is recommended to stop making use of the services.</p>
                         

                                    <h2>2. Eligibility</h2>
                                    <p>The services made available by Bitclear is for the sole use of persons of at least 18 years old and is considered an individual, legal person or other organization with full legal capacity to enter into this Agreement between him and Bitclear. Any actions from a person which is not considered eligible shall be borne on him and his guardians and Bitclear reserves the right to cancel or freeze his account in addition to legal action against him and his guardian for compensation.</p>
                           

                                    <h2>3. Restrictions of use</h2>
                                    <p>In using Bitclear’s services, you are accepting and affirming that you do not form part of any trade or any economic sanction list. Bitclear reserves the right to select its targeted markets and jurisdictions where its services are offered and may also limit or deny its services to certain countries as it may deem appropriate. Any applicable Laws of the country of the user are not omitted from the content of this Agreement. Any prohibited users are not to use any of Bitclear’s services.</p>
                               

                                    <h2>4. Descriptions of Services Offered</h2>
                                    <p>Bitclear through the Website/Service acts as a payments service provider (PSP) and offers a bitcoin payment method for your Website/Service for your product or service.</p>
                                    <p>Bitclear endeavors to uphold the accuracy of information posted on its Website/Service, nonetheless Bitclear cannot guarantee the accuracy, reliability, completeness, performance or fitness of the content through its Website/Service and will not be accepting liability for any loss or damage that may incur directly or indirectly from the content. Information on the Website can be changed without notice so users can independently decide on their decisions.</p>
                              
                     
                                    <h2>5. Registration</h2>
                                    <p>Users of the service must apply for a new account on {'https://www.bitclear.li/apply'} before making use of the services of the site. For the application of the account, you must provide certain information such as your real name or the name of your company, email address, phone and information on your intended use of services as well as accepting the Terms of Use and Privacy Policy. Please be informed that upon certain conditions and in Bitclear’s sole discretion, it may refuse to open an account for you.</p>

                                    <h2>6. User Identity Verification</h2>
                                    <p>Upon the creation of a new account on the Website, you agree to share any personal information requested for identity verification. Users must fill the KYC application and upload all the necessary documents on https://bitclear.li/kyc. Personal information requested is used for the sole purpose of the detection of money laundering, terrorist financing, fraud and other financial crimes.</p>
                                    <p>Additionally, to remain complaint with the relevant data protection regulations, you agree to give us the permission to keep a record of such information for the duration of your account and for a period beyond the closure of the account.</p>
                                    <p>Documents that may be requested for Identity Verification are the following:</p>
                                    <ul className="regulation-list">
                                        <li>Name and Surname / Name of the Company</li>
                                        <li>E-mail address</li>
                                        <li>Mailing address</li>
                                        <li>Telephone number</li>
                                        <li>Date of Birth / Company registration number</li>
                                        <li>Scanned copy of a valid government-issued passport or ID Card / Certificate of Incorporation</li>
                                        <li>Information about used cryptocurrency addresses</li>
                                    </ul>
                                    <p> </p>

                                    <h2>7. Account Usage</h2>
                                    <p>Accounts on the Website can only be used by the person whose name the account is registered under. Bitclear reserves the right to freeze, suspend or cancel accounts used by other persons whom are not the registered users. Bitclear will not take legal responsibility for these accounts.</p>
                                    <p>You shall not use the Website/Service for any purpose that is unlawful or prohibited by this Agreement and legal requirements. Your registration implies your confirmation and a guarantee that by using the Website/Service, you will act honestly and, in such way, that it would meet the interests of both you and Bitclear. Bitclear reserves the right to refuse or close an Account without prior notice if the use of the Website/Service is unlawful or unaccepted.</p>

                                    <h2>8. Security of Accounts</h2>
                                    <p>Bitclear has as its priority the safety of users’ accounts entrusted. It has applied technical and administrative measures which aim to secure your personal information from any loss, unauthorized access, unauthorized use and any alteration or disclosure.</p>
                                    <p>You have the duty to safeguard your personal account details especially the account password and controlling access to the account from any mobile devices and computer(s). You have also the duty to notify Bitclear of any illegal use of the account or password or any other security breach.</p>

                                    <h2>9. Intellectual Property Rights</h2>
                                    <p>The Website/Service is solely retained by Bitclear. Bitclear retains all the rights, title and interests of the content and all Bitclear’s and its Website text, logos, images and trademarks. Information contained on the Website shall not be construed as granting, by any implication or otherwise, of any license or right to make use of any trademark without asking for written permission or the permission of the owner of such trademarks.</p>

                                    <h2>10. Dispute Resolution and Applicable Law</h2>
                                    <p>You and Bitclear shall cooperate in good faith to resolve any dispute, controversy or claim (including non-contractual disputes or claims) arising out of, relating to or in connection with this Agreement, including with respect to the formation, applicability, breach, termination, validity or enforceability thereof.</p>
                                    <p>Each Party irrevocably agrees that any dispute not resolved within ninety (90) days as set forth in this Section shall be finally settled by arbitration in accordance with Part V (International Arbitration) of the Malta Arbitration Act and the Arbitration Rules of the Malta Arbitration Centre as at present in force, which shall be deemed to be incorporated by reference into this Agreement. The number of arbitrators shall be one. The place of arbitration shall be Malta. The language to be used in the arbitral proceedings shall be English. The governing law of the Agreement shall be as set forth in this Section hereunder. The arbitration award shall be final and binding on the Parties. The Parties undertake to carry out any award without delay and waive their right to any form of recourse insofar as such waiver can validly be made. Judgement upon the award may be entered by any court having jurisdiction thereof and having jurisdiction over the relevant Party or its assets. You and Bitclear will each pay the respective attorneys’ fees and expenses.</p>
                                    <p>Issues that may arise include claims, violation of laws and regulations, controversy or disputes between you and Bitclear and others which are not stated in the Terms. Users are responsible for any costs arising from any dispute resolution.</p>
                                    <p>These terms and conditions and any disputes that may arise shall be governed by the Laws of Malta mainly the Virtual Financial Assets Act, the Malta Digital Innovation Act and the Innovative Technology Arrangements and Services Act, inter alia.</p>

                                    <h2>11. Returns and Refunds</h2>
                                    <p>Any transaction in the Bitcoin network is final, therefore Crypto does not accept returns of Bitcoins and/or provide refunds. This is also due to the high price volatility of the market</p>

                                    <h2>12. Service Fees</h2>
                                    <p>In relation to services fees all transactions are negotiated via a separate agreement.</p>

                                    <h2>13. No Warranties</h2>
                                    <p>Bitclear provides its services on an “as is” and “as available” basis and any use of these services are at your own risk. Bitclear offers these services without warranties of any kind, express or implied including and without limitation to warranties of merchantability, fitness for a particular purpose, title and non-infringement with respect to the services. Without any limitation to the former, Bitclear does not warrant that any services including the Website will run without errors or defects and errors will be adjusted. Additionally, Bitclear does not warrant that it will meet your requirements or will be available uninterruptedly or secure at any time or location. Bitclear does not warrant that its services are free from viruses or other harmful content.</p>
                                    <p>Bitclear does not warrant, endorse, guarantee or assume any responsibility for any product or service offered or advertised by a third party through the service or through our Website/Service, and we will not be a part to nor monitor any interactions between you and third-party providers of products or services.</p>

                                    <h2>14. Limitation of Liability</h2>
                                    <p>You are accepting that Bitclear shall not be liable for any losses incurred by you caused by any of the following event, included but not limited to:</p>
                                    <ul className="regulation-list">
                                        <li>loss of funds or any other intangible losses</li>
                                        <li>use or failure to use Bitclear services</li>
                                        <li>illegal access or use of your account or alteration of your data by third parties</li>
                                        <li>misunderstandings of Bitclear’s Service</li>
                                        <li>losses related to Bitclear’s service not directly attributable to Bitclear</li>
                                    </ul>
                                    <p>Bitclear shall not in any way be liable for any event that results in the failure or delay of service from network maintenance or external factors, like for instance power failure, natural disasters, service provider complications or any governmental acts.</p>
                                    <p>Bitclear shall also not be liable to you or to any third party in cases of any direct, indirect special, incidental, consequential, exemplary or putative damages or any loss, theft, disappearance, or damages for lost profits.</p>
                                    <p>You should also understand and agree that the Bitclear shall not be liable and you shall be responsible for all liability in connection with any force majeur event, including acts of God, labour disputes or other industrial disturbances, electrical, telecommunications, hardware, software or other utility failures, software or smart contract bugs or weaknesses, earthquakes, storms or other nature-related events, blockages, embargoes, riots, acts or orders of government, acts of terrorism or war, technological change, changes in interests rates or other monetary conditions, and, for the avoidance of doubt, changes to any blockchain-related protocol.</p>

                                    <h2>15. Indemnification</h2>
                                    <p>You agree to indemnify, defend and hold Bitclear and its affiliates, contractors, licensors, and their respective directors, officers, employees and agents harmless from and against any claims and damages arising out of your breach or our enforcement of this Agreement.</p>
                                    <p>Bitclear reserves the right to exercise sole control over the defence, at your expense, of any claim subject to indemnification under this Section or Section 13. This indemnity is in addition to, and not in lieu of, any other indemnities set forth in a written agreement between you and the Company.</p>

                                    <h2>16. Termination of Agreement</h2>
                                    <p>At any time and for any reason in its sole discretion, Bitclear may terminate this Agreement, your access to your Account and may halt any pending transactions or funds without giving notice to you. The Company shall have no liability or obligation for the termination of this Agreement.</p>
                                    <p>Upon termination you will be notified with a written or electronic notice. Bitclear is not liable to you or any third party for termination.</p>

                                    <h2>17. Privacy Policy</h2>
                                    <p>Your personal data that you provide will be used to identify you as the account holder and to execute this Agreement.</p>
                                    <p>You confirm that you have entered correct data about yourself in every required form and that going forward, when changing or adding any data on the Website, you will enter only correct data. You shall bear any losses that occur regarding the submission of invalid/incorrect data.</p>
                                    <p>Bitclear may amend its Privacy Policy periodically. The privacy policy gives a clear insight of how Bitclear uses and protects personal information you provide to us. Please read the privacy policy carefully since it also forms part of the terms.</p>

                                    <h2>18. Disclaimers and Waivers</h2>
                                    <p>You agree to indemnify Bitclear and its affiliates and hold them harmless from and against any third-party claims except from Bitclear’s breach of these Terms and Conditions. Although Bitclear endeavors to preserve the data integrity on the site, it does not guarantee that the information and services provided on its Website. Bitclear will not be liable for errors arising</p>
                                    <p>BITCLEAR DOES NOT MAKE AND EXPRESSLY DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES, EXPRESS, IMPLIED OR STATUTORY; AND WITH RESPECT TO THE SERVICES OFFERED, BITCLEAR SPECIFICALLY DOES NOT REPRESENT AND WARRANT AND EXPRESSLY DISCLAIMS ANY REPRESENTATION OR WARRANTY, EXPRESS, IMPLIED OR STATUTORY, INCLUDING WITHOUT LIMITATION, ANY REPRESENTATIONS OR WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, USAGE, SUITABILITY OR FITNESS FOR ANY PARTICULAR PURPOSE, OR AS TO THE WORKMANSHIP OR TECHNICAL CODING THEREOF, OR THE ABSENCE OF ANY DEFECTS THEREIN, WHETHER LATENT OR PATENT.</p>

                                    <h2>19. Risks</h2>
                                    <h4>Technology and Internet risks</h4>
                                    <p>Any risks related with making use of an Internet based platform included, but not limited to, the failure of hardware, software, and Internet connections, risk of malicious software introduction and any other risks that relate to third parties which may gain unauthorized access to your personal information. Bitclear shall not be responsible for any loss in relation to any technological failure.</p>
                                    <h4>Blockchain risks</h4>
                                    <p>Blockchain being an autonomous public peer-to-peer network and is not in any manner controlled by Bitclear. No responsibility shall be sustained by Bitclear for any failure, mistake, error or breach which shall take place in the Blockchain or in any other networks in which Cryptocurrencies are distributed.</p>
                                    <p>Bitclear does not warrant or make no representation of any kind, express or implied, statutory or otherwise in relation to the Blockchain functionality or for any breach of security in the Blockchain.</p>
                                    <h4>Market volatility risks</h4>
                                    <p>You must keep in mind that throughout periods of high volume, illiquidity, fast movement or volatility of any Cryptocurrency the actual market rate at which your transaction has been done may vary. Bitclear shall not be liable for any such price fluctuations.</p>

                                    <h2>20. Complaints</h2>
                                    <p>If you have any complaints, questions or comments, kindly please contact info@bitclear.li and we will do our best to resolve any issues evolved.</p>
                                    <button onClick={handleAccept} className='btn btn-primary btn-full btn-lg mrg-top-30'>Accept Terms & Conditions</button>
                                           
                               
                                </div>
                            </div> 
                        </div>
                    </div>
                    
                </div>
            </div>
    )
}


export default TermsAndConditions;

