import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const PUT_DECLARE_CONVERSION = 'PUT_DECLARE_CONVERSION'
export const RESET_PUT_DECLARE_CONVERSION = 'RESET_PUT_DECLARE_CONVERSION'

export function putDeclareConversion({boughtAmount, conversionId}) {
	let data = {
		"boughtAmount" : boughtAmount
	}
	const dataPayload = axios.put(`${ENV.API_URL}/v3/conversions/${conversionId}/declare-result`, data, {
	    headers: getHeader()
	})
	return {
		type: PUT_DECLARE_CONVERSION,
		payload: dataPayload
	}
}

export function resetPutDeclareConversion() {
	const dataPayload = ''
	return {
		type: RESET_PUT_DECLARE_CONVERSION,
		payload: dataPayload
	}
}