const generateZeroWithDecimals = (num) => {
  if (typeof num !== 'number' || isNaN(num) || num < 0 || Math.floor(num) !== num) {
    return ''
  }

  let decimalPart = '';

  for (let i = 0; i < num - 1; i++) {
    decimalPart += '0';
  }

  return '0.' + decimalPart + '1';
}

export default generateZeroWithDecimals