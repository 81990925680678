import { axios } from 'axiosInstance'
import { ENV } from "index" 
import {getHeader} from '../helpers/headers_helper'
 
export const GET_ADDRESS_VALIDATION = 'GET_ADDRESS_VALIDATION' 
export const RESET_ADDRESS_VALIDATION = 'RESET_ADDRESS_VALIDATION' 
 
export function getAddressValidation({address, currency}) { 
    let data = {
        headers: getHeader(),
        params: {
            address: address,
            currency: currency
        }
    }
    const dataPayload = axios.get(`${ENV.API_URL}/internal/validate-address`, data)
    return { 
        type: GET_ADDRESS_VALIDATION, 
        payload: dataPayload
    } 
}
 
export function resetAddressValidation() { 
    const dataPayload = {};
    return { 
        type: RESET_ADDRESS_VALIDATION, 
        payload: dataPayload
    } 
}
