import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import Button from 'react-validation/build/button';
import React from 'react';
import PropTypes from 'prop-types';
import ModalContainer from 'components/ui_elements/modal_container';
import {required, text} from 'helpers/validator_helper';
import DataPresentationRow from 'components/ui_elements/data_presentation_row';

const EditContactModal = (props) => {
    return (
    	<ModalContainer thisId='editContactModal' title='Edit contact info' onHide={props.handleModalHide} showInit={props.showOnInit}>
            <Form onSubmit={props.handleSubmit} id='editContactModalForm' className='basic-form'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group clearfix'>
                            <label htmlFor='companyName'>Company name: *</label> 
                            <Input type='text' className='form-control' id='companyName' name='companyName' data-name='company name' validations={[required]} value={props.companyName} onChange={props.handleChange} />
                        </div>
                    </div>
                </div>
                <div className='row mrg-btm-5'>
                    <div className='col-md-12'>
                        <div className='form-group percents'>
                            <label htmlFor='address1'>Address: * </label>
                            <Input type='text' className='form-control' id='address1'  validations={[required]}  data-name='address' value={props.address1} onChange={props.handleChange} />
                            <Input type='text' className='form-control mrg-top-20' id='address2'  validations={[]} data-name='address' data-optional='true' value={props.address2} onChange={props.handleChange} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group clearfix'>
                            <label htmlFor='postCode'>Post code:</label>
                            <Input type='text' className='form-control' id='postCode' name='postCode' validations={[]} data-name='post code' data-optional='true' value={props.postCode} onChange={props.handleChange} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group clearfix'>
                            <label htmlFor='city'>City:</label>
                            <Input type='text' className='form-control' id='city' name='city' validations={[]} data-name='city' data-optional='true' value={props.city} onChange={props.handleChange} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group clearfix'>
                            <label htmlFor='country'>Country:</label>
                            <Input type='text' className='form-control' id='country' name='country' validations={[]} data-name='country' data-optional='true' value={props.country} onChange={props.handleChange} />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button type='button' className='btn btn-default' data-dismiss='modal'>Cancel</button>
                        {props.disabledForm === true ? (
                            <button className='btn btn-primary pull-right disabled' type='submit' disabled>Update info</button>
                        ) : (
                            <Button className='btn btn-primary pull-right' type='submit'>Update info</Button>
                        )}
                    </div>
                </div>
            </Form>
        </ModalContainer>
    )
}

EditContactModal.propTypes = {
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    disabledForm: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleModalHide: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    postCode: PropTypes.string.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

export default EditContactModal;