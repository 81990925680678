import {SET_FORBIDDEN} from '../actions/setForbidden'

export default function(state = false, action) {
	switch(action.type) {
		case SET_FORBIDDEN:
			return action.payload
		default:
			return state
	}

}