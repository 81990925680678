import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { TabsContext } from 'contexts/tabs.context'
import _ from 'lodash'
import { putPermission, deletePermission, resetPermission, getPermissionsInfo } from 'actions/permissions'
import { putAutoConvertSetting, resetAutoConvertSetting } from 'actions/autoConvertSetting'
import { getMerchant } from 'actions/merchant'
import {initAlert} from 'helpers/alert_helper'
import Tab from 'components/ui_elements/tab'
import LoadingError from 'components/ui_elements/loading_error'
import DataPresentationRow from 'components/ui_elements/data_presentation_row'

const SettingSection = styled.div`
    .text-data-presentation {
        margin-bottom: 15px;
    }
    label {
        margin-bottom: 0;
        margin-top: 6px;
    }
    .toggle-checkbox label {
        margin-top: 0;
    }
`

const SettingsTab = () => {
    const dispatch = useDispatch();
    const merchant = useSelector(state => state.merchant);
    const isAdmin = useSelector(state => state.isAdmin);
    const permissions = useSelector(state => state.permissions);
    const updatedPermission = useSelector(state => state.updatedPermission);
    const conversionSetting = useSelector(state => state.conversionSetting);

    const merchantId = merchant.id;

    const { active } = useContext(TabsContext);

    const [isPermissionRequested, setIsPermissionRequested] = useState(false);
    const [permissionChecked, setPermissionChecked] = useState(merchant.permissions);
    const [gettingChangedPermission, setGettingChangedPermission] = useState(false);

    useEffect(() => {
        if(isAdmin && isPermissionRequested === false) {
            dispatch(getPermissionsInfo());
            setIsPermissionRequested(true)
        }
    }, [isPermissionRequested]);
    useEffect(() => {
        if(gettingChangedPermission && !_.isEmpty(updatedPermission)) {
            if(updatedPermission.type === 'error') {
                initAlert({
                    type: 'error', 
                    title: 'Error', 
                    html: updatedPermission.response, 
                    confirmText: 'OK'
                });
                setGettingChangedPermission(false);
                dispatch(resetPermission());
            } else {
                setGettingChangedPermission(false);
                dispatch(resetPermission());
                dispatch(getMerchant(merchantId));
            }
            
        }
    }, [updatedPermission]);
    useEffect(() => {
        if(conversionSetting.type === 'error') {
            initAlert({
                type: 'error', 
                title: 'Error', 
                html: conversionSetting.response, 
                confirmText: 'OK'
            });
            dispatch(resetAutoConvertSetting())
        }
        if(conversionSetting.status === 200) {
            dispatch(resetAutoConvertSetting())
            dispatch(getMerchant(merchantId))
        }
        
    }, [conversionSetting]);

    const handlePermissionsToggle = (el) => {
        let isPermission = merchant.permissions.indexOf(el) > -1;
        let type = isPermission === false ? 'enable' : 'disable';
        initAlert({
            type: 'warning', 
            title: 'Warning', 
            html: 
                'You are about to ' + type + ' ' + el + ' for ' +
                '<b>' + merchant.name + '</b>.' +
                '<br />' + 
                'Are you sure you want to do this?', 
            confirmText: 'Yes, ' + type,
            onSuccess: () => onPermissionChange(el), 
            onDismiss: onExit
        });
    }
    const handleConvertToggle = () => {
        let type = merchant.autoConvertToEur ? 'disable' : 'enable';
        initAlert({
            type: 'warning', 
            title: 'Warning', 
            html: 
                'You are about to ' + type + ' auto conversion setting for ' +
                '<b>' + merchant.name + '</b>.' +
                '<br />' + 
                'Are you sure you want to do this?', 
            confirmText: 'Yes, ' + type,
            onSuccess: () => onConversionChange(), 
            onDismiss: onExit
        });
        
    }
    const onConversionChange = () => {
        dispatch(putAutoConvertSetting({merchantId, autoConvertToEur: !merchant.autoConvertToEur}));
    }
    const onPermissionChange = (type) => {
        setGettingChangedPermission(true);
        let isPermission = merchant.permissions.indexOf(type) > -1;
        if(isPermission === false) {
            dispatch(putPermission(merchantId, type));
        } else {
            dispatch(deletePermission(merchantId, type));
        }
    }

    const onExit = () => {
        return
    }
    
    return (
        <Tab name='settings' labelledby='settings-tab' active={active}>
                <div className='pdd-horizon-30 pdd-vertical-30'>
                    <div className='row'>
                        <div className='col-md-12 mrg-btm-15'>
                            <h2 className='pull-left mrg-btm-20'>Settings</h2>
                        </div>
                        {permissions.type === 'error' ? (
                            <LoadingError />
                        ) : (
                            <SettingSection className='col-md-8'>
                                {_.isEmpty(permissions) ? (
                                    <div></div>
                                ) : (
                                    <div>
                                        {permissions &&
                                            <div>
                                                {permissions.data.map(function(val, i){
                                                    return (
                                                        <DataPresentationRow name={val === 'Payout' ? 'Allow payouts in all crypto currencies' : val} large={true} key={i}>
                                                            <div className='toggle-checkbox toggle-success checkbox-inline'>
                                                                <input 
                                                                    checked={merchant.permissions.indexOf(val) > -1} 
                                                                    id={'toggle' + val}                                          
                                                                    name={'toggle' + val} 
                                                                    onChange={() => handlePermissionsToggle(val)} 
                                                                    type='checkbox' 
                                                                />
                                                                <label htmlFor={'toggle' + val}></label>
                                                            </div>
                                                        </DataPresentationRow>
                                                    )
                                                })}
                                            </div>
                                        }
                                        
                                    </div>
                                )}
                                <DataPresentationRow name='Convert payments/payouts automatically to EUR' large={true} tooltip={true} tooltipClass='settingsTooltip' tooltipText={`When on, all payments will be automatically converted to EUR, increasing merchant's EUR balance.<br /> Payouts will always deduct merchant's EUR balance.<br /> When off - merchant's balance is increased/decreased directly by payment/payout's transfer amount`}>
                                    <div className='toggle-checkbox toggle-success checkbox-inline'>
                                        <input 
                                            checked={merchant.autoConvertToEur} 
                                            id={'toggleConvert'}                                          
                                            name={'toggleConvert'} 
                                            onChange={handleConvertToggle} 
                                            type='checkbox' 
                                        />
                                        <label htmlFor={'toggleConvert'}></label>
                                    </div>
                                </DataPresentationRow>
                            </SettingSection>
                        )}
                    </div>
                </div>
        </Tab>
    )
}


export default SettingsTab;