import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Button from 'react-validation/build/button'
import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import $ from 'jquery'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { getConversionLimit, resetConversionLimit } from 'actions/conversionLimit'
import { getConversionEstimation, resetConversionEstimation } from 'actions/conversionEstimate'
import ModalContainer from 'components/ui_elements/modal_container'
import NumberFormatter from 'components/ui_elements/number_formatter'
import Selectize from 'components/ui_elements/selectize'
import { secureStorage } from 'helpers/secure_storage_helper'
import { updateInputStateHook, handleModalFormSubmitHook } from 'helpers/form_helper'
import { required, number, maxValue, minValue } from 'helpers/validator_helper'
import { totalCommaPrecision } from 'helpers/number_helper'

const NewConversionModal = ({onModalClosed, formSent, showOnInit}) => {
    const dispatch = useDispatch();
    const conversionLimit = useSelector(state => state.conversionLimit);
    const conversionEstimation = useSelector(state => state.conversionEstimation);
    const currencies = useSelector(state => state.currencies);
    const payoutTransferCurrencies = useSelector(state => state.currencies);
    const liveBalanceMerchant = useSelector(state => state.liveBalanceMerchant.data);
    const [minConversionAmount, setMinConversionAmount] = useState(0);
    const [reloadSell, setReloadSell] = useState(false);
    const [reloadBuy, setReloadBuy] = useState(false);
    const [conversionCurrencies, setConversionCurrencies] = useState([]);
    const [buyCurrencies, setBuyCurrencies] = useState([]);
    const [available, setAvailable] = useState({formatted: 0, value: 0});
    const [state, setState] = useState({
        sellAmount: '',
        sellCurrency: {},
        buyCurrency: {},
        isFormDisabled: false,
        userType: secureStorage.getItem('role'),
        currencyPrecision: 2
    });
    useEffect(() => {
        return () => {
            dispatch(resetConversionLimit());
            dispatch(resetConversionEstimation());
        }
        
    }, []);
    useEffect(() => {
        if(!_.isEmpty(conversionLimit) && conversionLimit.data !== undefined) {
            let newAvailable = totalCommaPrecision({
                toFormat:  conversionLimit.data.available,
                currCode: conversionLimit.data.currency,
                currencies: currencies
            });
            let minVal = totalCommaPrecision({
                toFormat:  conversionLimit.data.minAmount,
                currCode: conversionLimit.data.currency,
                currencies: currencies
            })
            setAvailable({formatted: newAvailable, value: conversionLimit.data.available});
            setMinConversionAmount(minVal);
        }
        
    }, [conversionLimit]);
    useEffect(() => {
        if(liveBalanceMerchant.length) {
            let monetaryValues = liveBalanceMerchant[0].monetaryValues;
            if(monetaryValues) {
                const filteredCurrencies = monetaryValues.filter(item => item.amount !== 0).map(item => item.currency);
                setConversionCurrencies(filteredCurrencies);
                setState((prevState) => ({
                    ...prevState,
                    sellCurrency: filteredCurrencies.find(el => el.value !== state.buyCurrency.value)
                }));
            }
            
        }
    }, [liveBalanceMerchant]);
    useEffect(() => {
        if(payoutTransferCurrencies && payoutTransferCurrencies.length && conversionCurrencies.length) {
            if(conversionCurrencies.length === 1)  {
                setBuyCurrencies(payoutTransferCurrencies.filter(el => el.code !== conversionCurrencies[0]).map(el => el.code));
            } else {
                setBuyCurrencies(payoutTransferCurrencies.map(el => el.code));
            }
            const newBuyCurrency = payoutTransferCurrencies.find(el => el.code !== state.sellCurrency?.value);
            setState((prevState) => ({
                ...prevState,
                buyCurrency: {label: newBuyCurrency.code, value: newBuyCurrency.code}
            }));
            
        }
    }, [payoutTransferCurrencies, conversionCurrencies]);
    useEffect(() => {
        if(!_.isEmpty(state.sellCurrency)) {
            dispatch(getConversionLimit({sellCurrency: state.sellCurrency}));
            let currSettings = currencies.find(curr => curr.code === state.sellCurrency.value);
            if(currSettings) {
                setState((prevState) => ({
                    ...prevState,
                    currencyPrecision: currSettings.precision
                }));
            }
        }
    }, [state.sellCurrency]);
    useEffect(() => {
        if(reloadBuy === true) {
            setReloadBuy(false);
        }
    }, [reloadBuy]);
    useEffect(() => {
        if(reloadSell === true) {
            setReloadSell(false);
        }
    }, [reloadSell]);
    const handleModalHide = () => {
        onModalClosed();
    }
    const handleSelectChange = (val, e) => {
        if(e === 'buyCurrency' && val === state.sellCurrency.value) {
            let newSellCurrency = conversionCurrencies.find(el => el !== val);
            setState((prevState) => ({
                ...prevState,
                sellCurrency: {value: newSellCurrency, label: newSellCurrency},
                [e]: {value: val, label: val}
            }));
            setReloadSell(true);
            handleEstimation(e, val, 'sellCurrency', newSellCurrency);
            return
        }
        else if(e === 'sellCurrency' && val === state.buyCurrency.value) {
            let newBuyCurrency = buyCurrencies.find(el => el !== val);
            setState((prevState) => ({
                ...prevState,
                buyCurrency: {value: newBuyCurrency, label: newBuyCurrency},
                [e]: {value: val, label: val}
            }));
            setReloadBuy(true);
            handleEstimation(e, val, 'buyCurrency', newBuyCurrency)
            return
        }
        else {
            setState((prevState) => ({
                ...prevState,
                [e]: {value: val, label: val}
            }));
            handleEstimation(e, val)
        }
        
    }
    const handleChange = (event) => {
        updateInputStateHook(setState, state, event);
        handleEstimation('sellAmount', event.target.value);
    }
    const handleEstimation = (name, value, additionalName, additionalValue) => {
        const sellAmountValue = isValidNumber(state.sellAmount);
        const valueFloat = isValidNumber(value);
        if(name === 'sellAmount') {
            if(valueFloat) {
                dispatchEstimation(name, value, additionalName, additionalValue);
            } else {
                dispatch(resetConversionEstimation());
            }
        } else {
            if(sellAmountValue) {
                dispatchEstimation(name, value, additionalName, additionalValue);
            } else {
                dispatch(resetConversionEstimation());
            }
        }
        
    }
    const dispatchEstimation = (name, value, additionalName, additionalValue) => {
        let values = {[name]: value};
        if(additionalName && additionalValue) {
            values[additionalName] = additionalValue;
        }
        dispatch(getConversionEstimation({sellAmount: state.sellAmount, sellCurrency: state.sellCurrency.value, buyCurrency: state.buyCurrency.value, ...values}));
    }
    const isValidNumber = (value) => {
        return value !== '' && !isNaN(value) && !isNaN(parseFloat(value));
    }
    const handleSubmit = (event) => {
        handleModalFormSubmitHook({ 
            event: event,  
            sendFunction: () => formSent({
                sellAmount: state.sellAmount, 
                sellCurrency: state.sellCurrency.value, 
                buyCurrency: state.buyCurrency.value
            }),
            resetFunction: resetAndClose
        });
    }
    const resetAndClose = () => {
        onModalClosed();
        $('#conversionModal').modal('hide');
    }
    const selectMounted = (el, id) => {
        setState((prevState) => ({
            ...prevState,
            [id]: el
        }));
    }
    return (
        <ModalContainer thisId='conversionModal' title='New conversion' onHide={handleModalHide} showInit={showOnInit} >
            <Form onSubmit={handleSubmit} id='newConversionForm' className='basic-form'>
                 <div className='row no-mrg'>
                    {conversionCurrencies.length && reloadSell !== true ? (
                        <div className='col-lg-6 width-sm-screen-12 no-pdd-left pdd-right-7 no-pdd-sm-screen'>
                            <div className='form-group clearfix'>
                                <label htmlFor='sellCurrency'>Convert from: *</label>
                                <Selectize thisId='sellCurrency' dataOptions={conversionCurrencies} onMounted={selectMounted} isSearchable={true} onChange={handleSelectChange} selected={state.sellCurrency} />
                            </div>
                        </div>
                    ) : (
                        <span></span>
                    )}
                    {(!_.isEmpty(conversionLimit) || conversionLimit.data !== undefined) ? (
                        <div className='col-lg-6 width-sm-screen-12 no-pdd-right pdd-lrft-7 no-pdd-sm-screen'>
                            <div className='form-group'>
                                <label htmlFor='sellAmount'>Amount to convert: *</label>
                                <Input type='text' className='form-control' id='sellAmount' name='sellAmount' data-usertype={state.userType} data-precision={state.currencyPrecision} validations={[required, number, maxValue, minValue]} data-max={available} data-min={minConversionAmount} data-min-label={minConversionAmount} data-currency={state.sellCurrency.value} value={state.sellAmount} onChange={handleChange} />
                            </div>
                        </div>
                    ) : (
                        <div className='mrg-top-15'></div>
                    )}
                </div>
                {buyCurrencies.length && reloadBuy !== true ? (
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='form-group clearfix'>
                                <label htmlFor='buyCurrency'>Convert to: *</label>
                                <Selectize thisId='buyCurrency' dataOptions={buyCurrencies} onMounted={selectMounted} isSearchable={true} onChange={handleSelectChange} selected={state.buyCurrency} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <span></span>
                )}
                <div className='row'>
                    <div className='col-md-12 text-right small'>
                        <p className='mrg-btm-5'>* - Field is required</p>
                    </div>
                    <div className='col-md-12 text-right small'>
                        {conversionEstimation === 'error' ? (
                            <span></span>
                        ) : (
                            <>
                                {conversionEstimation.amount &&
                                    <div>
                                        <h6 className='text-center mrg-top-15 mrg-btm-15'>Estimated conversion result:</h6>
                                        <h3 className='text-center mrg-btm-20'><NumberFormatter toFormat={conversionEstimation.amount} currCode={conversionEstimation.currency ? conversionEstimation.currency : ''} /> {conversionEstimation.currency ? conversionEstimation.currency : ''}</h3>
                                    </div>
                                }
                            </>
                        )}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <Button className='btn btn-primary pull-right width-100 mrg-btm-20 mrg-top-10 no-mrg-right' type='submit' disabled>Convert currencies</Button>
                    </div>
                </div>
            </Form>
        </ModalContainer>
    )
    
}

NewConversionModal.propTypes = {
    formSent: PropTypes.func.isRequired,
    onModalClosed: PropTypes.func.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

export default NewConversionModal;
