export function updateInputState(el, event) {
    el.setState({ [event.target.id]: event.target.value });
}
export function updateInputStateHook(setEl, el, event) {
    setEl({...el,  [event.target.id]: event.target.value });
}
export function handleModalFormSubmit({self, event, states, sendFunction, sendProps, resetFunction}) {
    event.preventDefault();
    if(states !== undefined) {
    	self.setState({
	        ...states
	    });
    }
    sendFunction({
        ...sendProps
    });
    if(resetFunction !== undefined) {
    	resetFunction();
    }
}

export function handleModalFormSubmitHook({event, sendFunction, resetFunction, setDisabledForm}) {
    event.preventDefault();
    if(setDisabledForm !== undefined) {
        setDisabledForm(true)
    }

    sendFunction();
    
    if(resetFunction !== undefined) {
        resetFunction();
    }
}

export function handleSubmit({event, self, funct, args, hasPagination}) {
    event.preventDefault();
    let states = {formDisabled: true};
    if(hasPagination === true) {
        states.offset = 0;
    }
    self.setState({
        ...states
    });
    funct(...args);
}

export function handleSubmitHook({event, funct, args, hasPagination, setOffset, setFormDisabled}) {
    event.preventDefault();
    setFormDisabled(true);
    if(hasPagination === true && setOffset) {
        setOffset(0);
    }
    funct(...args);
}