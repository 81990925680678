import React from 'react';
import {Link} from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ADMIN_PREFIX } from 'App';

const Breadcrumbs = (props) => {
    const isAdmin = useSelector(state => state.isAdmin);
    const isUserAdmin = useSelector(state => state.isUserAdmin);
    let prefix = isAdmin ? '/' + ADMIN_PREFIX : isUserAdmin ? '/' + ADMIN_PREFIX + '/context' : '';
    return (
        <div className='card pdd-vertical-10 pdd-horizon-15'>
            <ol className='breadcrumb pull-left'>
              {_.map(props.dataPath, function(val) {
                    if (val[1] === '') {
                        return <li className='breadcrumb-item active' key={val[0]}>{val[0]}</li>
                    }
                    return <li className='breadcrumb-item' key={val[0]}><Link to={prefix + val[1]}>{val[0]}</Link></li>
                })}
            </ol>
            {(_.size(props.dataPath) > 2) && <Link className="back pull-right" to={prefix + props.dataPath[(_.size(props.dataPath) - 2)][1]}>Back</Link>}
        </div>
    )
}

Breadcrumbs.propTypes = {
    dataPath: PropTypes.object.isRequired
}

export default Breadcrumbs;