import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_TRADES = 'GET_TRADES'
export const RESET_TRADES = 'RESET_TRADES'

export function getTrades(paymentId, isPayout) {
    let data = { 
        headers: getHeader()
    } 
    let type = 'payments'
    if(isPayout) {
        type = 'payouts'
    }
    const dataPayload = axios.get(`${ENV.API_URL}/admin/trades/${type}/${paymentId}`, data)
    return { 
        type: GET_TRADES, 
        payload: dataPayload,
    } 
}

export function resetTrades() {
    const dataPayload = ''
    return { 
        type: RESET_TRADES, 
        payload: dataPayload
    } 
}
 




