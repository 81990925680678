import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_BANK_DETAILS = 'GET_BANK_DETAILS'
export const RESET_BANK_DETAILS = 'RESET_BANK_DETAILS'
export const PUT_BANK_DETAILS = 'PUT_BANK_DETAILS'
export const RESET_PUT_BANK_DETAILS = 'RESET_PUT_BANK_DETAILS'

export function getBankDetails(merchantId) {
	let data = {
	  headers: getHeader()
	}
	let dataPayload = axios.get(`${ENV.API_URL}/internal/merchants/${merchantId}/bank-details`, data)
	
	return {
		type: GET_BANK_DETAILS,
		payload: dataPayload
	}
}

export function putBankDetails({bankName, iban, swift, accountNumber, merchantId}) {
	let data = {
		"bankName" : bankName,
		"iban": iban,
		"swift": swift,
		"accountNumber": accountNumber
	};
	const dataPayload = axios.put(`${ENV.API_URL}/internal/merchants/${merchantId}/bank-details`, data, {
	    headers: getHeader()
	})
	return {
		type: PUT_BANK_DETAILS,
		payload: dataPayload
	}
}

export function resetBankDetails() {
	const dataPayload = ''
	return {
		type: RESET_BANK_DETAILS,
		payload: dataPayload
	}
}

export function resetPutBankDetails() {
	const dataPayload = ''
	return {
		type: RESET_PUT_BANK_DETAILS,
		payload: dataPayload
	}
}