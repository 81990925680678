import {SWITCH_MERCHANT} from '../actions/switchMerchant';

export default function(state = '', action) {
	switch(action.type) {
		case SWITCH_MERCHANT:
			return action.payload;
		default:
			return state;
	}

}