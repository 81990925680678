import {POST_ACTIVE_TAB_DASHBOARD} from 'actions/activeTabDashboard';
import {setupRollbar} from 'helpers/rollbar_helper';

export default function(state = {view: 'today', formValues: {dateFrom: '', dateTo: ''}, overriteTransactions: false, activeTab: 'payments'}, action) {
	switch(action.type) {
		case POST_ACTIVE_TAB_DASHBOARD:
			return action.payload;
		default:
			return state;
	}

}