import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut, getElementAtEvent } from 'react-chartjs-2'
import _ from 'lodash'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { postActiveTabDashboard } from 'actions/activeTabDashboard'
import { getTransactionsHistory, resetTransactionsHistory } from 'actions/transactionsHistory'
import { colorsList } from './dashboard_tabs'
import { getMeta } from 'helpers/helpers'
import { SUCCESS, WARNING, INFO } from 'helpers/colors_helper'
import { totalCommaPrecision } from 'helpers/number_helper'
import { dateToIso } from 'helpers/date_helper'
import PropTypes from 'prop-types'
import { ADMIN_PREFIX } from 'App'

ChartJS.register(ArcElement, Tooltip, ChartDataLabels)

const DashboardChart = ({type, additionalDataValues, start, end}) => {
    const history = useNavigate();
    const plugins = [ChartDataLabels];
    const currencies = useSelector(state => state.currencies);
    const activeTabDashboard = useSelector(state => state.activeTabDashboard);
    const explorer = useSelector(state => state.explorer);
    const transactionsHistory = useSelector(state => state.transactionsHistory);
    const dispatch = useDispatch();
    const [featuredData, setFeaturedData] = useState([]);
    const [dataValues, setDataValues] = useState([]);
    const [sumValues, setSumValues] = useState([]);
    const [labels, setLabels] = useState([]);
    const [shouldRedirect, setRedirect] = useState(false);
    const chartRef = useRef();
    let now = end;
    let payHistory = transactionsHistory;
    let address = '';
    let txid = '';
    let coinAmount = '';
    let fiatAmount = '';
    let internalId = '';
    let limit = 128;
    let offset = 0;
    let selectClientInit = '';
    let shownFilters = false;
    let statusInit = [];
    if(!_.isEmpty(payHistory) && payHistory.meta) {
        let meta = payHistory.meta;
        txid = getMeta(meta.txid, '');
        address = getMeta(meta.address, '');
        coinAmount = getMeta(meta.coinAmount, '');
        end = getMeta(meta.to, end, 'parseDate');
        fiatAmount = getMeta(meta.fiatAmount, '');
        internalId = getMeta(meta.paymentId, '');
        limit = getMeta(meta.limit, limit);
        offset = getMeta(meta.offset, offset, () => {return meta.offset / meta.limit});
        selectClientInit = getMeta(meta.merchantId, '');
        shownFilters = getMeta(meta.shownFilters, shownFilters);
        start = getMeta(meta.from, start, 'parseDate');
        statusInit = getMeta(meta.status, []);
    }

    const options = {
        plugins: {
          legend: {
            display: false,
            position: 'bottom',
            align: 'start',
            labels: {
                textAlign: 'left',
                padding: 15,
            }
          },
          tooltip: {
            displayColors: false,
            callbacks: {
                label: (el) => {
                    return totalCommaPrecision({toFormat: el.parsed, currCode: 'EUR', currencies: currencies}) + ' EUR'
                }
            }
          },
          datalabels: {
            labels: {
              title: {
                font: {
                    size: 13,
                    weight: 'bold'
                    
                }
              }
            },
            formatter: (value, ctx) => {
                const percentPie = value / ctx.dataset.sumValues * 100;
                if(percentPie < 4) return ''
                const formattedValue = ctx.dataset.datalabels[ctx.dataIndex] + ':\n ' + totalCommaPrecision({toFormat: value, currCode: 'EUR', currencies: currencies}) + ' EUR';
                return formattedValue
            }
          },
          textStrokeWidth: 30
        },
    };

    useEffect(() => {
        setFeaturedData(additionalDataValues);
    }, [additionalDataValues]);

    useEffect(() => {
        if(shouldRedirect) {
            if(transactionsHistory.meta) {
                history('/' + ADMIN_PREFIX + '/explorer');
                setRedirect(false);
            }
        }
    }, [transactionsHistory]);

    useEffect(() => {
        setDataValues(featuredData ? featuredData.map(el => el.value) : []);
        setLabels(featuredData ? featuredData.map(el => el.labelText) : []);
    }, [featuredData]);

    useEffect(() => {
        setSumValues(dataValues.length ? dataValues.reduce((el, sumEl) => sumEl + el) : 0);
    }, [dataValues]);

    const transactionsGetHistory = (merchanId) => {
        let dateFrom = dateToIso(activeTabDashboard.formValues.dateFrom);
        let dateTo = dateToIso(activeTabDashboard.formValues.dateTo);
        dispatch(resetTransactionsHistory());
        dispatch(getTransactionsHistory({
            transactionType: type === 'payments' ? 'PAYMENT' : 'PAYOUT',
            timeStart: dateFrom, 
            timeEnd: dateTo,
            merchantId: merchanId,
            limit: limit,
            offset: offset
        }));
        setRedirect(true);
    }
    const handleChartClick = (el) => {
        if(type === 'payments' || type === 'payouts') {
            let chartElemIndex = getElementAtEvent(chartRef.current, el)[0].index;
            redirectToTransactionsListing(featuredData[chartElemIndex].merchantId);
        }
    }
    const redirectToTransactionsListing = (mrhId) => {
        dispatch(postActiveTabDashboard({...activeTabDashboard, merchantId: mrhId, overriteTransactions: true}));
        transactionsGetHistory(mrhId);
    }
    const data = {
      labels: labels,
      datasets: [
        {
          data: dataValues,
          datalabels: labels,
          sumValues: sumValues,
          cutout: window.innerHeight*0.1 + 25,
          backgroundColor: colorsList.backgroundColor,
          borderColor: colorsList.borderColor,
          borderWidth: 1,
        },
      ]
    }
    return (
        <div style={{minHeight: 350, height: 'calc(100vh - 220px)'}}>
            <Doughnut ref={chartRef} id={type} data={data} plugins={plugins} options={options} style={{marginBottom: 0, marginTop: '-20px', padding: '45px'}} onClick={handleChartClick} />
        </div>
    )
}

DashboardChart.propTypes = {
    history: PropTypes.object
}

export default DashboardChart;