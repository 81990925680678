import {PUT_RESET_ALL_TERMS, RESET_ALL_TERMS} from '../actions/termsAndConditions';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case PUT_RESET_ALL_TERMS:
			let response = '';
			if(action.error) {
				let message = action.payload.response;

				if(message) {
					response = message.data[0] ? message.data[0].message : 'Some error occured';
				}
				setupRollbar(action);
				return {type: 'error', response: response}
			}
			return {data: action.payload, status: action.payload.status}
		case RESET_ALL_TERMS:
			return action.payload
		default:
			return state;
	}

}