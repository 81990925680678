import React, {Component} from 'react';
import $ from 'jquery'; 
import _ from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'; 
import {putMerchantContact, resetPutMerchantContact} from 'actions/contact';
import {feeMultiplied} from 'helpers/fees_helper';
import EditContactModal from './edit_contact_modal';
import {updateInputState, handleModalFormSubmit} from 'helpers/form_helper';

class EditContactModalContainer extends Component {
    constructor(props) {
        super(props);
        let enabled = false;
        let address1 = '';
        let address2 = '';
        let city = '';
        let companyName = '';
        let country = '';
        let postCode = '';
        let contact =  this.props.contact;
        if(!_.isEmpty(contact)) {
            address1 = contact.addressLine1 ? contact.addressLine1 : '';
            address2 = contact.addressLine2 ? contact.addressLine2 : '';
            city = contact.city ? contact.city : '';
            companyName = contact.companyName ? contact.companyName : '';
            country = contact.country ? contact.country : '';
            postCode = contact.postcode ? contact.postcode : '';
        }
        this.state = {
            address1: address1,
            address2: address2,
            city: city,
            companyName: companyName,
            country: country,
            disabledForm: false,
            postCode: postCode
        }
    }
    componentDidUpdate(prevProps) {
        let updatedContact = this.props.updatedContact;
        if(updatedContact === 200) {
            this.props.formSent(updatedContact);
            $('#editContactModal').modal('hide');
            this.props.resetPutMerchantContact();
        }
    }
    handleModalHide() {
        this.props.onModalClosed();
    }
    handleChange(event) {
        updateInputState(this, event);
    }
    handleSubmit(event) {
        let thisState = this.state;
        handleModalFormSubmit({
            self: this, 
            event: event, 
            states: {
                disabledForm: true
            }, 
            sendFunction: this.props.putMerchantContact, 
            sendProps: {
                merchantId: this.props.merchantId,
                address1: thisState.address1,
                address2: thisState.address2, 
                city: thisState.city, 
                companyName: thisState.companyName,
                country: thisState.country,
                postCode: thisState.postCode
            },
            resetFunction: this.props.resetFunction
        });
    }
    render() {
        let thisProps = this.props;
        let thisState = this.state;
        let showOnInit = thisProps.showOnInit || false;
        return ( 
            <EditContactModal 
                address1={thisState.address1}
                address2={thisState.address2}
                city={thisState.city}
                companyName={thisState.companyName}
                country={thisState.country}
                disabledForm={thisState.disabledForm} 
                enabled={thisState.enabled}
                handleChange={this.handleChange.bind(this)}
                handleModalHide={this.handleModalHide.bind(this)} 
                handleSubmit={this.handleSubmit.bind(this)} 
                limit={thisState.limit}
                merchant={thisProps.merchant}
                postCode={thisState.postCode}
                showOnInit={showOnInit} 
            />
        )
    }
}

EditContactModalContainer.propTypes = {
    config: PropTypes.object.isRequired,
    contact: PropTypes.object.isRequired,
    formSent: PropTypes.func.isRequired,
    merchantId: PropTypes.func.isRequired,
    onModalClosed: PropTypes.func.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

function mapStateToProps(state) { 
    return { 
        merchant: state.merchant,
        updatedContact: state.updatedContact
    } 
} 

export default connect(mapStateToProps, {putMerchantContact, resetPutMerchantContact})(EditContactModalContainer);
