import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_USERS = 'GET_USERS'
export const GET_UNASSIGNED_USERS = 'GET_UNASSIGNED_USERS'
export const RESET_USERS = 'RESET_USERS'
export const RESET_UNASSIGNED_USERS = 'GET_UNASSIGNED_USERS'

export function getUsers(email, merchantId) {
	let params = {}
	if(email !== undefined) {
		if(email.length !== 0) {
			params.email = email
		}
	}
	if(merchantId !== undefined) {
		if(merchantId.length !== 0) {
			params.merchantId = merchantId
		}
	}

	let data = {
	  headers: getHeader(),
	  params: params
	}
	const dataPayload = axios.get(`${ENV.API_URL}/admin/users`, data)
	return {
		type: GET_USERS,
		payload: dataPayload
	}
}

export function getUnassignedUsers() {
	let params = {
		withoutMerchant: true
	}
	let data = {
	  headers: getHeader(),
	  params: params
	}
	const dataPayload = axios.get(`${ENV.API_URL}/admin/users`, data)
	return {
		type: GET_UNASSIGNED_USERS,
		payload: dataPayload
	}
}

export function resetUsers() {
	const dataPayload = {}
	return {
		type: RESET_USERS,
		payload: dataPayload
	}
}

export function resetUnassignedUsers() {
	const dataPayload = {}
	return {
		type: RESET_UNASSIGNED_USERS,
		payload: dataPayload
	}
}