import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const DELETE_API_KEY = 'DELETE_API_KEY'
export const POST_API_KEY = 'POST_API_KEY'
export const PUT_API_KEY = 'PUT_API_KEY'
export const RESET_API_KEY = 'RESET_API_KEY'

export function deleteApiKey(merchantId, apiKeyId) {
	const dataPayload = axios.delete(`${ENV.API_URL}/internal/admin/merchants/${merchantId}/api-keys/${apiKeyId}`, {
	    headers: getHeader()
	});
	return {
		type: DELETE_API_KEY,
		payload: dataPayload
	}
}

export function postApiKey({merchantId, name}) {
	 let data = {
  		'name': name,
  		'enabled': true
	};
	const dataPayload = axios.post(`${ENV.API_URL}/internal/admin/merchants/${merchantId}/api-keys`, data, {
	    headers: getHeader()
	})
	return {
		type: POST_API_KEY,
		payload: dataPayload
	}
}

export function putApiKey(merchantId, enabled, apiKeyId) {
	let data = {
  		'enabled': enabled
	};
	const dataPayload = axios.put(`${ENV.API_URL}/internal/admin/merchants/${merchantId}/api-keys/${apiKeyId}`, data, {
	    headers: getHeader()
	});
	return {
		type: PUT_API_KEY,
		payload: dataPayload
	}
}

export function resetApiKey(merchantId) {
	const dataPayload = '';
	return {
		type: RESET_API_KEY,
		payload: dataPayload
	}
}

