import {GET_PERMISSIONS_INFO} from '../actions/permissions';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_PERMISSIONS_INFO:
			let responseDelete = 'Some error occured, please try again';
			if(action.error) {
				setupRollbar(action);
				return {type: 'error', response: responseDelete}
			}
			return {status: action.payload.status, data: action.payload.data};
		default:
			return state;
	}

}