import axios from 'axios'
import {getKeycloakLogoutUrl} from 'actions/getToken'

export const POST_LOGOUT = 'POST_LOGOUT'
export const RESET_LOGOUT = 'RESET_LOGOUT'


export const logout = ({ keycloakDomain, refreshToken = '', client_id, is_backend }) => {
	const dataPayload = refreshToken
		? axios.post(
				`${keycloakDomain}${getKeycloakLogoutUrl(is_backend)}`,
				new URLSearchParams({
					client_id: client_id,
					refresh_token: refreshToken,
				}),
				{
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded',
					},
				},
		  )
		: Promise.reject()
	return {
		type: POST_LOGOUT,
		payload: dataPayload
	}
}

export function resetLogout() {
	const dataPayload = {};
	return {
		type: RESET_LOGOUT,
		payload: dataPayload
	}
}
