import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'
 
export const POST_PAYMENT = 'POST_PAYMENT'
export const RESET_PAYMENT = 'RESET_PAYMENT'

export function postPayment({amount, currency, transferCurrency, message, notificationUrl, merchantTransactionId, confirmedRedirect, expiredRedirect, invalidRedirect, unconfirmedRedirect, defineRedirects}) {
    const DEFAULT_TEXT = 'Payment created via panel'
    let data = {
        'priceAmount' : amount,
        'priceCurrency': currency,
        'transferCurrency': transferCurrency,
        'walletMessage': message ? message : DEFAULT_TEXT,
        'externalPaymentId': merchantTransactionId ? merchantTransactionId : DEFAULT_TEXT
    }
    if(defineRedirects === true) {
        data.redirectUrls = {}
        if(confirmedRedirect) {
            data.redirectUrls.confirmed = confirmedRedirect
        }
        if(expiredRedirect) {
            data.redirectUrls.expired = expiredRedirect
        }
        if(invalidRedirect) {
            data.redirectUrls.invalid = invalidRedirect
        }
        if(unconfirmedRedirect) {
            data.redirectUrls.unconfirmed = unconfirmedRedirect
        }
    }
    if(notificationUrl) {
        data.notificationUrl = notificationUrl
    }
    const dataPayload = axios.post(`${ENV.API_URL}/v3/payments`, data, {
        headers: getHeader()
    })
    return {
        type: POST_PAYMENT,
        payload: dataPayload
    }
}

export function resetPayment() {
    const dataPayload = ''
    return {
        type: RESET_PAYMENT,
        payload: dataPayload
    }
}

