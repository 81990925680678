import {GET_SELF_MERCHANT, RESET_SELF_MERCHANT} from '../actions/merchant';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_SELF_MERCHANT:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return action.payload.data;
		case RESET_SELF_MERCHANT:
			return action.payload;
		default:
			return state;
	}

}