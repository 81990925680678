import Form from 'react-validation/build/form'
import Input from 'react-validation/build/input'
import Button from 'react-validation/build/button'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCurrencies, putCurrency, resetCurrency } from 'actions/currencies'
import _ from 'lodash'
import $ from'jquery'
import PropTypes from 'prop-types'
import ModalContainer from 'components/ui_elements/modal_container'
import Selectize from 'components/ui_elements/selectize'
import { required, percent, textName, maxlength, minlength, max10, newName } from 'helpers/validator_helper'
import { updateInputStateHook, handleModalFormSubmitHook } from 'helpers/form_helper'


const CurrencyModal = ({formSent, onModalClosed, editCurrency}) => {
    const dispatch = useDispatch();
    const newCurrency = useSelector(state => state.newCurrency);
    const isEditCurrency = !_.isEmpty(editCurrency);
    const [editing, setEditing] = useState(isEditCurrency);
    const [submitButton, setSubmitButton] = useState(isEditCurrency ? 'Update currency' : 'Create currency');
    const [disabledForm, setDisabledForm] = useState(false);
    const defaultValues = {
        code: isEditCurrency ? editCurrency.code : '',
        blockchain: isEditCurrency ? editCurrency.blockchain : '',
        currencyType: editCurrency.currencyType ? editCurrency.currencyType : 'fiat', 
        paymentPriceEnabled: isEditCurrency ? editCurrency.paymentPriceEnabled : false,
        payoutPriceEnabled: isEditCurrency ? editCurrency.payoutPriceEnabled : false,
        paymentTransferEnabled: isEditCurrency ? editCurrency.paymentTransferEnabled : false,
        payoutTransferEnabled: isEditCurrency ? editCurrency.payoutTransferEnabled : false,
        precision: isEditCurrency ? editCurrency.precision : ''
    }
    const [formValues, setFormValues] = useState({...defaultValues});

    useEffect(() => {
        if(!_.isEmpty(newCurrency)) {
            formSent(newCurrency, editing);
            dispatch(resetCurrency());
            $('#currencyModal').modal('hide');
        }
    }, [newCurrency]);

    const handleChange = (event) => {
        updateInputStateHook(setFormValues, formValues, event);
    }
    const handleSelectChange = (val) => {
        setFormValues({
            ...formValues,
            paymentTransferEnabled: val === 'fiat' ? false : formValues.paymentTransferEnabled,
            currencyType: val
        })
    }
    const handleSubmit = (event) => {
        handleModalFormSubmitHook({
            event: event, 
            setDisabledForm: setDisabledForm, 
            sendFunction: () => dispatch(putCurrency({
                ...formValues
            }))
        });
    }
    const handleModalHide = () => {
        onModalClosed();
    }
    const handleToggle = (el) => {
        setFormValues({
            ...formValues,
            [el.target.id]: el.target.checked
        })
    }
    return (
    	<ModalContainer thisId='currencyModal' title={editing ? 'Edit currency' : 'New currency'} onHide={handleModalHide} showInit={true} >
            <Form onSubmit={handleSubmit} id='merchantModalForm' className='basic-form'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor='code'>Currency code *</label>
                            {editing ? (
                                <h4 className='mrg-top-10 mrg-btm-25'>{formValues.code}</h4>
                            ) : (
                                <Input type='text' className='form-control' id='code' data-name='Currency code' validations={[required]} value={formValues.code} onChange={handleChange} />
                            )}
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <label className='col-md-12'>Currency type</label>
                    <div className='col-md-12'>
                        <div className='form-group' style={{textTransform: 'capitalize'}}>
                            <Selectize thisId='currencyType' selected={formValues.currencyType}  dataOptions={['fiat', 'crypto']} onChange={handleSelectChange} />
                        </div>
                    </div>
                </div>
                {formValues.currencyType === 'crypto' ? (
                    <div className='row' style={{marginBottom: 10}}>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label htmlFor='code'>Blockchain *</label>
                                <Input type='text' className='form-control' id='blockchain' data-name='Blockchain' validations={[required]} value={formValues.blockchain} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                ) : (
                    <></>
                )}
                <div className='row' style={{marginBottom: 10}}>
                        <div className='col-md-12'>
                            <div className='form-group'>
                                <label htmlFor='precision'>Precision *</label>
                                <Input type='text' className='form-control' id='precision' data-name='Precision' validations={[required]} value={formValues.precision} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                <div className='row'>
                    <label className='col-md-6 lh-1-9'>Payments transfer enabled</label>
                    <div className='col-md-6'>
                        <div className='toggle-checkbox toggle-success checkbox-inline mrg-btm-15'>
                            <input 
                                checked={formValues.paymentTransferEnabled} 
                                id={'paymentTransferEnabled'}                                          
                                name={'paymentTransferEnabled'} 
                                onChange={handleToggle} 
                                type='checkbox' 
                                disabled={formValues.currencyType === 'fiat' ? true : false}
                            />
                            <label htmlFor={'paymentTransferEnabled'}></label>
                        </div>
                    </div>
                    <label className='col-md-6 lh-1-9'>Payouts transfer enabled</label>
                    <div className='col-md-6'>
                        <div className='toggle-checkbox toggle-success checkbox-inline mrg-btm-15'>
                            <input 
                                checked={formValues.payoutTransferEnabled} 
                                id={'payoutTransferEnabled'}                                          
                                name={'payoutTransferEnabled'} 
                                onChange={handleToggle} 
                                type='checkbox' 
                            />
                            <label htmlFor={'payoutTransferEnabled'}></label>
                        </div>
                    </div>
                    <label className='col-md-6 lh-1-9'>Payments price enabled</label>
                    <div className='col-md-6'>
                        <div className='toggle-checkbox toggle-success checkbox-inline mrg-btm-15'>
                            <input 
                                checked={formValues.paymentPriceEnabled} 
                                id={'paymentPriceEnabled'}                                          
                                name={'paymentPriceEnabled'} 
                                onChange={handleToggle} 
                                type='checkbox' 
                            />
                            <label htmlFor={'paymentPriceEnabled'}></label>
                        </div>
                    </div>
                    <label className='col-md-6 lh-1-9'>Payouts price enabled</label>
                    <div className='col-md-6'>
                        <div className='toggle-checkbox toggle-success checkbox-inline mrg-btm-15'>
                            <input 
                                checked={formValues.payoutPriceEnabled} 
                                id={'payoutPriceEnabled'}                                          
                                name={'payoutPriceEnabled'} 
                                onChange={handleToggle} 
                                type='checkbox' 
                            />
                            <label htmlFor={'payoutPriceEnabled'}></label>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12 text-right small'>
                        <p>* - Field is required</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button type='button' className='btn btn-default' data-dismiss='modal'>Cancel</button>
                        {disabledForm === true ? (
                            <button className='btn btn-primary pull-right disabled' type='submit' disabled>{submitButton}</button>
                        ) : (
                            <Button className='btn btn-primary pull-right' type='submit' >{submitButton}</Button>
                        )}
                    </div>
                </div>
            </Form>
        </ModalContainer>
    )
}

CurrencyModal.propTypes = {
    formSent: PropTypes.func.isRequired,
    onModalClosed: PropTypes.func.isRequired
}

export default CurrencyModal;