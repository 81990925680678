import Decimal from 'decimal.js-light';

export function feeDecimalNumber(el, emptyOption) {
    let decimal = new Decimal(el);
    if(emptyOption === true && el === '') {
        return '';
    } 
    return parseFloat(decimal.dividedBy(100).toString());
}

export function feeDecimal(el, emptyOption) {
    let decimal = new Decimal(el);
    if(emptyOption === true && el === '') {
        return '';
    } 
    return decimal.dividedBy(100).toString();
}
export function feeMultipliedNumber(el) {
    let decimal = new Decimal(el);
    return decimal.times(100).toFixed();
}
export function feeMultiplied(el) {
    let decimal = new Decimal(el);
    return decimal.times(100).toString();
}
export function percentMultiplied(el) {
    let decimal = new Decimal(el);
    return decimal.times(100);
}
export function toFixedNoZero(el, fixed) {
	let decimal = new Decimal(el);
	let value = decimal.toFixed(fixed);
	if(el > 0 && value == 0) {
        if(fixed == 4) {
          return 0.0001;  
        }
        else if (fixed == 8) {
            return 0.00000001
        }
        else {
            return 0.01;
        }
		
	}
	return value;
}