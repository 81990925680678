import { axios } from 'axiosInstance'
import { ENV } from "index"
import {feeDecimal} from '../helpers/fees_helper'
import {getHeader} from '../helpers/headers_helper'

export const PUT_FEE = 'PUT_FEE'
export const RESET_FEE = 'RESET_FEE'

export function putFee({merchantId, fxFeeTier0, fxFeeTier1, fxFeeTier2, fxFeeTier3, txFee, payoutFxFeeTier0, payoutFxFeeTier1, payoutFxFeeTier2, payoutFxFeeTier3, payoutTxFee, payoutDailyLimit, payoutNetworkFeeTiers}) {

	let objectMap = (obj, fn) =>
		Object.fromEntries(
			Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)])
		)

	let data = {
		"fxFeeTier0": feeDecimal(fxFeeTier0),
		"fxFeeTier1": feeDecimal(fxFeeTier1),
		"fxFeeTier2": feeDecimal(fxFeeTier2),
		"fxFeeTier3": feeDecimal(fxFeeTier3),
		"txFee": feeDecimal(txFee),
		"payoutFxFeeTier0": feeDecimal(payoutFxFeeTier0),
		"payoutFxFeeTier1": feeDecimal(payoutFxFeeTier1),
		"payoutFxFeeTier2": feeDecimal(payoutFxFeeTier2),
		"payoutFxFeeTier3": feeDecimal(payoutFxFeeTier3),
		"payoutTxFee": feeDecimal(payoutTxFee),
		"payoutDailyLimit": payoutDailyLimit,
		"payoutNetworkFeeTiers": objectMap(payoutNetworkFeeTiers, tierFee => feeDecimal(tierFee))
	}
	const dataPayload = axios.put(`${ENV.API_URL}/internal/admin/merchants/${merchantId}`, data, {
	    headers: getHeader()
	})
	return {
		type: PUT_FEE,
		payload: dataPayload
	}
}

export function resetFee() {
	const dataPayload = ''
	return {
		type: RESET_FEE,
		payload: dataPayload
	}
}
