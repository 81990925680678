import { CONFIRM_FIAT_PAYOUT, RESET_CONFIRM_FIAT_PAYOUT} from 'actions/confirmFiatPayout'
import { setupRollbar } from 'helpers/rollbar_helper'

export default function(state = {}, action) {
	switch(action.type) {
		case CONFIRM_FIAT_PAYOUT:
			let response = 'Some error occured, please try again';
			if(action.error) {
				let message = action.payload.message;
				if(message) {
					response = message;
				}
				setupRollbar(action);
				return {type: 'error', response: response}
			}
			return action.payload;
		case RESET_CONFIRM_FIAT_PAYOUT:
			return action.payload;
		default:
			return state;
	}

}