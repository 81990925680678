import {POST_BALANCE_ADJUSTMENT, RESET_BALANCE_ADJUSTMENT} from 'actions/balanceAdjustment';
import {setupRollbar} from 'helpers/rollbar_helper';

export default function(state = '', action) {
	switch(action.type) {
		case POST_BALANCE_ADJUSTMENT:
			if(action.error) {
				setupRollbar(action);
				let message = action.payload.response?.data.message;
				if(message) {
					return {type: 'error', response: message}
				}
				return {type: 'error'}
			}
			return action.payload.status
		case RESET_BALANCE_ADJUSTMENT:
			return action.payload
		default:
			return state;
	}

}