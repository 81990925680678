import {GET_UNSOLD_PAYOUTS, RESET_UNSOLD_PAYOUTS} from '../actions/unsoldPayouts';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_UNSOLD_PAYOUTS:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return {data: action.payload.data, status: action.payload.status};
		case RESET_UNSOLD_PAYOUTS:
			return action.payload;
		default:
			return state;
	}

}