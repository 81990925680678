import {GET_TRADES, RESET_TRADES} from '../actions/trades';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_TRADES:
			if(action.error) {
				let data = action.payload.response?.data[0]?.message;
				setupRollbar(action);
				return {type: 'error', response: data}
			}
			return {status: 200, data: action.payload.data};
		case RESET_TRADES:
			return action.payload;
		default:
			return state;
	}

}