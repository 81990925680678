import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Loading from 'components/ui_elements/loading'
import NumberFormatter from 'components/ui_elements/number_formatter'
import Kraken from 'images/exchanges/kraken.png'
import TheRock from 'images/exchanges/the-rock.png'
import {getStatusClassname, camelCaseToText, lossStyle} from 'helpers/class_helper'
import {fullTimeUtc} from 'helpers/date_helper'

const TradesTableRows = (props) => {
    let pair = props.currencyPair.split("/");
    let crypto = pair[0];
    let fiat = pair[1];
    let rows = props.rows.map(function(val,i) {
        let stateClass = getStatusClassname(val.status.toUpperCase());
        let exchange = function() {
            switch(val.exchange) {
                case 'Kraken':
                    return <img src={Kraken} alt='Kraken' className='max-width-100' tooltip='Kraken' />
                case 'TheRock':
                    return <img src={TheRock} alt='The Rock' className='max-width-100' tooltip='The Rock' />
                default:
                    return <span>{val.exchange}</span>
            }
        }
        return (
            <tr key={i}>
                <td className='v-align'>
                    <div>
                        <span>{fullTimeUtc(val.created)}</span>
                    </div>
                </td>
                <td className='v-align'>
                    <div>
                        {exchange()}
                    </div>
                </td>
                <td className='v-align'>
                    <div className='list-info text-right'>
                        <h5 className='mrg-top-5 mrg-btm-5'><span className={lossStyle(val.originalAmount)}><NumberFormatter toFormat={val.originalAmount} currCode={crypto} /> </span> <span className='font-size-12'>{crypto}</span></h5>
                    </div>
                </td>
                <td className='v-align'>
                    <div className='text-right'>
                        <span><NumberFormatter toFormat={val.price} currCode={fiat} /> <span className='font-size-12'>{fiat}/{crypto}</span></span>
                    </div>
                </td>
                <td className='v-align'>
                    <div className='text-right'>
                        <span><span className={lossStyle(val.remaining)}><NumberFormatter toFormat={val.remaining} currCode={crypto} /> </span><span className='font-size-12'>{crypto}</span></span>
                    </div>
                </td> 
                <td className='v-align'>
                    <div>
                        <span>{val.orderId}</span>
                    </div>
                </td>
                <td className='v-align'>
                    <div>
                        <span>{val.tradeId}</span>
                    </div>
                </td>
                <td className='v-align'>
                    <div>
                        <div className='relative'>
                            <span className={stateClass}> </span>
                            <span className='pdd-left-20'>{camelCaseToText(val.status)}</span>
                        </div>
                    </div>
                </td> 
            </tr>
        )
    });
    return rows;
}

const TradesBody = (props) => {
    let rows = props.trades.map(function(value, ind) {
        return <TradesTableRows currencyPair={value.currencyPair} rows={value.exchangeEvents} key={ind} />
    });
    return rows;
}

const TradesTable = (props) => {
    return (
        <div>
            <div className='relative scrollable'>
                <table className='table table-lg table-hover table-bordered-top mrg-btm-15'>
                    <thead>
                        <tr>
                            <th>
                                Created
                            </th>
                            <th>
                                Exchange
                            </th>
                            <th className='text-right'>
                                Original amount
                            </th>
                            <th className='text-right'>
                                Exchange rate
                            </th>
                            <th className='text-right'>
                                Remaining
                            </th>
                            <th>
                                Order ID
                            </th>
                            <th>
                                Trade ID
                            </th>
                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>
                    {_.isEmpty(props.trades) ? (
                        <tbody className='collapsible-body'>
                            <tr>
                                <td colSpan='5'>
                                    <Loading />
                                </td>
                            </tr>
                        </tbody>
                    ) : (
                        <tbody className='collapsible-body'>
                            {props.trades.data.trades.length ? (
                                <TradesBody trades={props.trades.data.trades} />
                            ) : (
                                <tr>
                                    <td colSpan='5'>
                                        <h5>No results</h5>
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    )}
                </table>
            </div>
        </div>
    )
}

TradesTableRows.propTypes = {
    rows: PropTypes.array.isRequired
}

TradesTable.propTypes = {
    trades: PropTypes.object.isRequired
}

export default TradesTable;