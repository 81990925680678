import Form from 'react-validation/build/form'
import Button from 'react-validation/build/button'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import Loading from 'components/ui_elements/loading'
import ModalContainer from 'components/ui_elements/modal_container'
import Export from 'components/ui_elements/export'
import NumberFormatter from 'components/ui_elements/number_formatter'
import TradesTable from './trades_table'
import {lossStyle} from 'helpers/class_helper'


const TradesModal = ({isPayout, trades, disabledForm, handleModalHide, paymentId, selectedExport, showOnInit}) => {
    const [currencyPair, setCurrencyPair] = useState({
        crypto: '',
        fiat: ''
    })
    const [totals, setTotals] = useState();
    let isPayoutVal = isPayout ? 'payout' : 'payment';
    useEffect(() => {
        if(!_.isEmpty(trades) && trades.type !== 'error') {
            let totalsVal = trades.data.totals;
            setTotals(totalsVal);
            let pair = totalsVal.currencyPair.split("/");
            setCurrencyPair({
                crypto: pair[0],
                fiat: pair[1]
            })
        }
    }, [trades]);
    return (
    	<ModalContainer classNames='modal-xl' thisId='tradesModal' title={'Trades for ' + isPayoutVal + ' ' + paymentId} onHide={handleModalHide} showInit={showOnInit}>
            {trades.type === 'error' ? (
                <div className='text-danger mrg-btm-20 mrg-top-5'>
                    <b>Error</b> - trades not found
                </div>
            ) : (
                <div className='row'>
                    {_.isEmpty(trades) || !totals ? (
                        <Loading />
                    ) : (
                        <div className='col-md-12'>
                            <p className='mrg-btm-15'>Trades summary</p>
                            <div className='flex-container'>
                                <div className='mrg-right-30'>
                                    <h2 className='no-mrg-btm min-height-34'><span className={lossStyle(totals.originalAmount)}><NumberFormatter toFormat={totals.originalAmount} currCode={currencyPair.crypto} /></span><b className='font-size-12 mrg-left-5'>{currencyPair.crypto}</b></h2>
                                    <span className='inline-block mrg-btm-5 font-size-13 text-semibold'>Original amount</span>
                                </div>
                                <div className='mrg-right-30'>
                                    <h2 className='no-mrg-btm min-height-34'><span className={lossStyle(totals.actualAmount)}><NumberFormatter toFormat={totals.actualAmount} currCode={currencyPair.crypto} /></span><b className='font-size-12 mrg-left-5'>{currencyPair.crypto}</b></h2>
                                    <span className='inline-block mrg-btm-5 font-size-13 text-semibold'>Actual amount</span>
                                </div>
                                <div className='mrg-right-30'>
                                    <h2 className='no-mrg-btm min-height-34'><NumberFormatter toFormat={totals.averagePrice} currCode={currencyPair.fiat} /><b className='font-size-12 mrg-left-5'>{currencyPair.fiat && currencyPair.crypto ? currencyPair.fiat + '/' + currencyPair.crypto : ''}</b></h2>
                                    <span className='inline-block mrg-btm-5 font-size-13 text-semibold'>Exchange rate</span>
                                </div>
                                <div className='mrg-right-30'>
                                    <h2 className='no-mrg-btm min-height-34'><span className={lossStyle(totals.remainingAmount)}><NumberFormatter toFormat={totals.remainingAmount} currCode={currencyPair.crypto} /></span><b className='font-size-12 mrg-left-5'>{currencyPair.crypto}</b></h2>
                                    <span className='inline-block mrg-btm-5 font-size-13 text-semibold'>Remaining amount</span>
                                </div>
                                <div className='mrg-right-30'>
                                    <h2 className='no-mrg-btm min-height-34'><span className={lossStyle(totals.spent)}><NumberFormatter toFormat={totals.spent} currCode={currencyPair.fiat} /></span><b className='font-size-12 mrg-left-5'>{currencyPair.fiat}</b></h2>
                                    <span className='inline-block mrg-btm-5 font-size-13 text-semibold'>Fiat amount</span>
                                </div>

                            </div>
                            <hr />
                            <p className='mrg-btm-15'>Trades details</p>
                            <TradesTable trades={trades} />
                        </div>
                    )}
                </div>
            )}
            {!_.isEmpty(trades) && (
                <div className='row'>
                    <div className={trades.type == 'error' ? 'col-md-12' : 'col-md-6'}>
                        <button type="button" className="btn btn-default btn-full" data-dismiss="modal">Close</button>
                    </div>
                    {trades.type !== 'error' && (
                        <div className='col-md-6'>
                            <Export 
                                classNames='btn-full btn-primary'
                                formats={['CSV','XLS']} 
                                id='dropdownMenuButton' 
                                name='Export table' 
                                /*crypto={currencyPair.crypto}
                                fiat={currencyPair.fiat}*/
                                onSelected={selectedExport} 
                            />
                        </div>
                    )}
                </div>
            )}
        </ModalContainer>
    )
}

TradesModal.propTypes = {
    handleModalHide: PropTypes.func.isRequired,
    isPayout: PropTypes.bool.isRequired,
    paymentId: PropTypes.string.isRequired,
    selectedExport: PropTypes.func.isRequired,
    showOnInit: PropTypes.bool.isRequired,
    trades: PropTypes.object.isRequired
}

export default TradesModal;