import React from 'react'
import PropTypes from 'prop-types'
import NumberFormatter from 'components/ui_elements/number_formatter'
import styled from 'styled-components'
import {lossStyle} from '../../helpers/class_helper'

const SimpleDataBoxContainer = styled.div`
    .btn-sm2 {
        padding: 2px 10px!important;
        height: 28px;
        position: absolute;
        right: 15px;
    }
`

export const AdditionalDataContainer = styled.div`
    display: block!important;
    .btn-sm {
        width: 100%;
        margin-top: 10px;
        padding-top: 2px!important;
        padding-bottom: 2px!important;
    }
`

export const AdditionalData = styled.div`
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    padding: 2px 0;
    label, h5 {
        display: inline-block;
    }
    label {
        margin-right: 5px;
        width: 40%;
    }
    h5 {
        margin-bottom: 0;
    }
    &.last {
        border-bottom: 0;
    }
`

const SimpleDataBox = ({additionalData, additionalDataValues, smallBox, subText, subText2, textCenter, labelText, valueText, valueText2, currency, moreData, iconClassName, icon, showMoreData, chart}) => (
        <div className={smallBox === true ? 'widget card simple-data-box simple-data-small' : 'widget card simple-data-box'}>
            <SimpleDataBoxContainer className={subText2 ? 'simple-box-double' : ''}>
                <div className={textCenter === false ? 'pdd-vertical-20 clearfix simple-data-inner' : 'text-center pdd-vertical-20 clearfix simple-data-inner'}>
                    <div className={subText ? 'text-div' : additionalData ? 'mrg-top-10' : 'text-div mrg-top-10'}>
                        <p className='no-mrg-btm'>{labelText}</p>
                        <div className={subText2 ? ('inline-header clearfix') : ('')}>
                            <h2 className='font-primary no-mrg-top'><div className={lossStyle(valueText, false) + ' inline-block'}><NumberFormatter toFormat={valueText} currCode={currency} /></div><span>{currency}</span></h2>
                            {valueText2 &&
                                <h2 className='font-primary no-mrg-top'><div className={lossStyle(valueText2) + ' inline-block'}>{valueText2}</div><span>{currency}</span></h2>
                            }
                        </div>
                        <div className={subText2 ? ('inline-header clearfix') : ('')}>
                            {subText &&
                                <p>{subText}</p>
                            }
                            {subText2 &&
                                <p>{subText2}</p>
                            }
                        </div>
                    </div>
                    {moreData && <button className='btn btn-default btn-sm2' onClick={showMoreData}>More data</button>}
                    <div className={iconClassName + ' icon-div'} >
                        <i className={icon}></i>
                    </div>
                </div>
            </SimpleDataBoxContainer>
            {additionalData ? (
                <AdditionalDataContainer className='pdd-btm-20 clearfix simple-data-inner'>
                    {additionalDataValues ? (
                            <>
                                {additionalDataValues.map(data => (
                                    <AdditionalData className={data.last ? 'last' : ''}>
                                        <label className='no-mrg-btm'>{data.labelText}</label>
                                        <h5 className='value'>{data.value} {data.currency}</h5>
                                    </AdditionalData>
                                ))}
                            </>
                            
                        ) : (
                        <></>
                    )}
                </AdditionalDataContainer>
            ) : (<></>)}
            {chart ? (
                <>{chart}</>
            ) : (<></>)}
        </div>
)

SimpleDataBox.propTypes = {
    currency: PropTypes.string.isRequired,
    icon: PropTypes.string,
    iconClassName: PropTypes.string,
    labelText: PropTypes.string.isRequired,
    additionalData: PropTypes.bool,
    additionalDataValues: PropTypes.array,
    smallBox: PropTypes.bool,
    valueText: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]).isRequired
}

export default SimpleDataBox;