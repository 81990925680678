import React, {Component} from 'react'
import _ from 'lodash'
import LoadingError from 'components/ui_elements/loading_error'
import LayoutContainer from '../layout/layout_container'
import Breadcrumbs from 'components/ui_elements/breadcrumbs'
import MerchantsTable from 'components/merchants/merchants_table'
import PaginationContainer from 'components/ui_elements/pagination_container'
import NewMerchantModal from 'components/merchants/new_merchant_modal'
import MerchantsSearchFormContainer from 'components/merchants/merchants_search_form_container'
import {connect} from 'react-redux';
import {initAlert} from 'helpers/alert_helper';
import { getMerchantsPaginated, resetMerchantsPaginated } from 'actions/merchantsPaginated'
import { resetPostMerchant } from 'actions/merchant'
import { getMeta } from 'helpers/helpers'; 
import { handleSubmit } from 'helpers/form_helper'
import { calculatePage, calculatePages, handlePageChange, handlePaginationChange } from 'helpers/pagination_helper'
import PropTypes from 'prop-types'

class Merchants extends Component {
    constructor(props) { 
        super(props);
        let limit = 32;
        let offset = 0;
        if(!_.isEmpty(this.props.merchantsPaginated)) {
            let meta = this.props.merchantsPaginated.meta;
            limit = getMeta(meta.limit, limit);
            offset = getMeta(meta.offset, offset, () => {return meta.offset / meta.limit});
        }
        this.state = { 
            allResults: 0,
            initalLoad: false,
            limit: limit,
            merchantId: '',
            offset: offset,
            searchParams: {},
            showModal: false
        }
        this.sendRequest = this.sendRequest.bind(this);
        this.searchParamsUpdate = this.searchParamsUpdate.bind(this);
        this.showModalFunc = this.showModalFunc.bind(this);
        this.modalClosed = this.modalClosed.bind(this);
        this.newMerchantSubmit = this.newMerchantSubmit.bind(this);
    }
    componentDidMount() {
        document.title = "Merchants - Bitcoin Payment Processor";
    }
    componentDidUpdate() {
        let thisState = this.state;
        if(!_.isEmpty(thisState.searchParams) && thisState.initalLoad === false) {
            this.props.getMerchantsPaginated({
                limit: this.state.limit,
                offset: this.state.offset,
                name: this.state.searchParams.name
            });
            this.setState({
                initalLoad: true
            })
        }
        if(!_.isEmpty(this.props.merchantsPaginated) && this.props.merchantsPaginated !== 'error') {
            let newCount = this.props.merchantsPaginated.data.paginationInfo.itemsCount;
            if(newCount !== this.state.allResults) {
                this.setState({
                    allResults: newCount
                })
            }
        }
    }
    showModalFunc() {
        this.setState({
            showModal: true
        });
    }
    modalClosed() {
        this.setState({
            showModal: false
        });
    }
    newMerchantSubmit(data, name) {
        if(data.type === 'error') {
            initAlert({
                type: 'error', 
                title: 'Error', 
                html: data.response, 
                confirmText: 'OK'
            });
        } else {
            initAlert({
                type: 'success', 
                title: 'Success', 
                html: 
                    'You have successfully created new merchant' +
                    '<br />' +
                    '<b>' + name + '</b>', 
                confirmText: 'OK'
            });
            this.props.getMerchantsPaginated({
                limit: this.state.limit,
                offset: 0,
                name: this.state.searchParams.name
            });
            this.setState({
                offset: 0
            });
            this.props.resetPostMerchant();
        }
    }
    handlePaginationChange(limit,offset) {
        handlePaginationChange({
            self: this, 
            limit: limit, 
            offset: offset, 
            funct: this.sendRequest, 
            args: [this.props.merchantsPaginated.meta, limit, offset]
        });
    }
    handlePageChange(offset) {
        handlePageChange({
            self: this, 
            offset: offset, 
            funct: this.sendRequest, 
            args: [this.props.merchantsPaginated.meta, this.state.limit, offset]
        });
    }
    handleSubmit(event, params) {
        handleSubmit({
            event: event, 
            self: this, 
            funct: this.sendRequest, 
            args: [params, this.state.limit, 0], 
            hasPagination: true
        });
    }
    sendRequest(thisState, limit, offset) {
        this.props.resetMerchantsPaginated();
        this.props.getMerchantsPaginated({
            limit: limit,
            offset: offset,
            name: thisState.name
        });
    }
    searchParamsUpdate(params, isFormSent, event) {
        this.setState({
            searchParams: params
        })
        if(isFormSent) {
            this.handleSubmit(event, params);
        }
    }
    render() {
        let thisState = this.state;
        let thisProps = this.props;
        let pages = calculatePages(thisState.allResults, thisState.limit); 
        let page = calculatePage(thisState.offset);

        return ( 
            <LayoutContainer active='merchants'>
                <div>
                    {(this.props.merchantsPaginated === 'error') ? (
                        <LoadingError />
                    ) : (
                        <div>
                            <Breadcrumbs dataPath={{0: ['Home', '/'], 1: ['Merchants', '']}}/>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h2 className='pull-left'>Merchants</h2>
                                    {this.props.isAdmin ? (
                                        <button className='btn btn-primary pull-right btn-medium mrg-btm-12' onClick={this.showModalFunc} >New merchant <i className='fa fa-plus-circle'></i></button>
                                    ) : (<></>)
                                    }
                                </div>
                            </div>
                            <MerchantsSearchFormContainer searchParamsUpdate={this.searchParamsUpdate} />
                            <div className='card no-border-top'>
                                <MerchantsTable isAdmin={this.props.isAdmin} merchantsPaginated={this.props.merchantsPaginated} />
                                {this.state.pages > 0 &&
                                    <div className='mrg-btm-20'>
                                        <PaginationContainer classNames='pdd-left-15' pages={this.state.pages} all={this.state.allResults} initial={this.state.page} perPage={this.state.limit} onChange={this.handlePaginationChange} onPageChange={this.handlePageChange} />
                                    </div>
                                }
                            </div>
                            {this.state.showModal === true &&
                                <NewMerchantModal onModalClosed={this.modalClosed} formSent={this.newMerchantSubmit} />
                            }
                        </div>
                    )}
                </div>  
            </LayoutContainer>
        )
    } 
}

Merchants.propTypes = {
    history: PropTypes.object,
    location:  PropTypes.object
}

function mapStateToProps(state) {
    return {
        isAdmin: state.isAdmin,
        merchantsPaginated: state.merchantsPaginated
    }
}

export default connect(mapStateToProps, {
    getMerchantsPaginated,
    resetMerchantsPaginated,
    resetPostMerchant
})(Merchants);