import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const RESEND_PAYOUT_NOTIFICATION = 'RESEND_PAYOUT_NOTIFICATION'
export const RESET_RESEND_PAYOUT_NOTIFICATION = 'RESET_RESEND_PAYOUT_NOTIFICATION'

export function resendPayoutNotification(payoutId) {
    let config = {
    	headers: getHeader()
  	}
	const dataPayload = axios.post(`${ENV.API_URL}/v3/payouts/${payoutId}/resend-notification`, {}, config)
	return {
		type: RESEND_PAYOUT_NOTIFICATION,
		payload: dataPayload
	}
}

export function resetResendPayoutNotification() {
	const dataPayload = {}
	return {
		type: RESET_RESEND_PAYOUT_NOTIFICATION,
		payload: dataPayload
	}
}