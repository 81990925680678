import { useEffect } from 'react'
import {Link} from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getCurrencies } from 'actions/currencies'
import Loading from 'components/ui_elements/loading'
import TransactionsTableInner from './transactions_table_inner'
import TradesModalContainer from 'components/trades_modal/trades_modal_container'
import ProfitModalContainer from 'components/profit/profit_modal_container'
import ConfirmFiatPayoutModal from 'components/confirm_payout/confirm_fiat_payout_modal'
import Tooltip from 'components/ui_elements/tooltip'
import NumberFormatter from 'components/ui_elements/number_formatter'
import {encodeBasicUrl} from 'helpers/helpers'
import {getStatusClassname, lossStyle} from 'helpers/class_helper'
import {fullTimeUtc} from 'helpers/date_helper'
import {percentMultiplied} from 'helpers/fees_helper'
import variables from 'styles/_variables.scss'
import { ADMIN_PREFIX } from 'App'

const Badge = styled.span`
    .badge {
        width: 110px;
    }
    .badge-primary {
        background-color: ${variables.logoColor};
    }
    .badge-info {
        background-color: ${variables.defaultInfo};
    }
    .badge-secondary {
        background-color: ${variables.defaultSuccess};
    }
    .text {
        line-height: 16px;
    }
    .fa {
        font-size: 14px;
        position: relative;
        padding-right: 5px;
        top: 1px;
    }
`

const TransactionBadge = ({transactionType}) => (
    <Badge>
        <span class={transactionType === "PAYMENT" ? "badge badge-primary" : transactionType === "CONVERSION" ? "badge badge-secondary" : transactionType === "BALANCE_ADJUSTMENT" ? "badge badge-warning" : "badge badge-info"}>
            <i className={transactionType === "PAYMENT" ? 'fa fa-arrow-circle-down' : transactionType === "CONVERSION" ? 'fa fa-refresh' : transactionType === "BALANCE_ADJUSTMENT" ? 'fa fa-pencil' : 'fa fa-arrow-circle-up'}></i>
            <span className='text'>{transactionType === "BALANCE_ADJUSTMENT" ? "ADJUSTMENT" : transactionType}</span>
        </span>
    </Badge>
)

const TransactionsTable = (props) => {
    const currencies = useSelector(state => state.currencies);
    const isAdmin = useSelector(state => state.isAdmin);
    const isUserAdmin = useSelector(state => state.isUserAdmin);
    const dispatch = useDispatch();
    useEffect(() => {
        if(!currencies.length) {
            dispatch(getCurrencies());
        }
    }, []);
    let payHistory = props.transactionsHistory.data;
    let tbody = <tbody></tbody>;
    let tbodyInner = <tr><td></td></tr>;
    if(payHistory === undefined || payHistory.data === undefined) {
        tbody = (
            <tbody>
                <tr height={props.tableHeight}>
                    <td colSpan='9' style={{paddingTop: (props.tableHeight / 2) - 20, textAlign: 'center'}}>
                        <Loading />             
                    </td>
                </tr>
            </tbody>
        )
    } else {
        if(payHistory.data.items.length === 0) {
            tbodyInner = (
                <tr>
                    <td colSpan='11'>
                        <h4 className='text-center mrg-top-15'>No results.</h4>
                    </td>
                </tr>
            )
             tbody = (
                <tbody className='collapsible-body'>
                    {tbodyInner}
                </tbody>
            )
        } else {
            tbodyInner = payHistory.data.items.map(function(val,i) {
                if(val.transactionType === 'PAYOUT' || val.transactionType === 'PAYMENT' || val.transactionType === 'CONVERSION' || val.transactionType === 'BALANCE_ADJUSTMENT') {
                    let stateClass = getStatusClassname(val.status ? val.status : 'DONE');
                    let name = val.merchantId ? encodeBasicUrl(val.merchantId) : '';
                    let details = val.profitDetails;
                    let profitAmount;
                    let profitPercentage;
                    let actualProfitAmount;
                    let actualProfitPercentage;
                    let actualProfit;
                    let profit;
                    if(props.isAdmin && details) {
                        actualProfit = details.actualProfit;
                        profit = details.tradeProfit;
                        if(actualProfit) {
                            actualProfitAmount = actualProfit.profitAmount.toFixed(2);
                            actualProfitPercentage = percentMultiplied(actualProfit.profitPercentage).toFixed(2);
                        }
                        if(profit) {
                            profitAmount = profit.profitAmount.toFixed(2);
                            profitPercentage = percentMultiplied(profit.profitPercentage).toFixed(2);
                        }
                    }

                    
                    return (
                        <>
                            <tr key={i}>
                                <td className='v-align'>
                                    {val.transactionType &&
                                        <h5><TransactionBadge transactionType={val.transactionType} /></h5>
                                    }
                                </td>
                                <td className='v-align'>
                                    <div className='list-info'>
                                        <h5 className='number-display'>{val.transferAmount !== null ? (<><span className={lossStyle(val.transferAmount)}><NumberFormatter toFormat={val.transactionType === 'BALANCE_ADJUSTMENT' ? val.amount : val.transferAmount} currCode={val.transactionType === 'BALANCE_ADJUSTMENT' ? val.currency : val.transferCurrency} /></span> {val.transactionType === 'BALANCE_ADJUSTMENT' ? val.currency : val.transferCurrency}</>) : ('-')}</h5>
                                    </div>
                                </td>
                                <td className='v-align'>
                                    <div className='text-right'>
                                        <span className='number-display'>{val.transactionType === 'BALANCE_ADJUSTMENT' ? (<>-</>) : (<><span className={lossStyle(val.priceAmount)}><NumberFormatter toFormat={val.priceAmount} currCode={val.priceCurrency} /></span> {val.priceCurrency}</>)}</span>
                                    </div>
                                </td>
                                <td className='v-align'>
                                    <div>
                                        <span>{val.transactionType === 'BALANCE_ADJUSTMENT' ? fullTimeUtc(val.asOfDate) : val.createdTime ? fullTimeUtc(val.createdTime) : '-'}</span>
                                    </div>
                                </td>
                                <td className='v-align max-width-200'>
                                    <div className='line-18'>
                                        <span><Link to={isAdmin ? '/' + ADMIN_PREFIX + '/merchants/' + name : isUserAdmin ? '/' + ADMIN_PREFIX + '/context/keys' : '/keys'}>{val.merchantName}</Link></span>
                                    </div>
                                </td>
                                <td className='v-align'>
                                    <div className='relative'>
                                        <span className={'status ' + stateClass}> </span>
                                        <span className='pdd-left-20'>{val.status ? val.status === 'IN_PROGRESS' ? 'IN PROGRESS' : val.status : 'DONE'}</span>
                                    </div>
                                </td>
                                {props.isAdmin && (
                                        <td className='profit-td v-align' key={'actualProfit' + i}>
                                            <div>
                                                {actualProfit ? (
                                                    <div>
                                                        {actualProfitAmount && <span className={actualProfitAmount > 0 ? 'inline-block text-success mrg-right-5' : actualProfitAmount < 0 ? 'inline-block text-danger mrg-right-5' : ''}><NumberFormatter toFormat={actualProfitAmount} currCode='EUR' /></span>}
                                                        <span className={actualProfitPercentage > 0 ? 'inline-block text-success' : actualProfitPercentage < 0 ? 'inline-block text-danger' : ''} >({actualProfitPercentage}%)</span>
                                                    </div>
                                                ) : (
                                                    <span className='profit-td empty'>-</span>
                                                )}
                                            </div>
                                        </td>
                                    )
                                }
                                <td className='v-align text-right'>
                                    <button type="button" className="btn btn-default accordion-toggle no-mrg-btm btn-sm" data-toggle='collapse' data-target={'#collapse-table' + i}>Info<i className='ti-angle-down'></i></button>
                                </td>
                               
                            </tr>
                            {currencies.length ? (
                                <tr className='trHidden' key={i + 'hidden'}>
                                    <td colSpan='9' className='hiddenRow'>
                                        <div className='accordion-body collapse' id={'collapse-table' + i} >
                                            <TransactionsTableInner 
                                                showProfitModal={props.showProfitModal}
                                                showConfirmModal={props.showConfirmModal}
                                                showModal={props.showModal}
                                                isAdmin={props.isAdmin} 
                                                val={val} 
                                                handleResendNotification={props.handleResendNotification} 
                                                transactionsGetHistory={props.transactionsGetHistory}
                                                handleConfirmClick={props.showConfirmModalFunc}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ) : (<></>)}
                        </>
                    )
                }
            })
            tbody = (
                <tbody className='collapsible-body'>
                    {tbodyInner}
                </tbody>
            )
        }
    } 
    return (
        <div className={props.noMargin !== true ? 'overflow-y-auto relative scrollable mrg-top-15' : 'overflow-y-auto relative scrollable '}>
            <table className='table table-lg table-hover table-bordered-top'>
                <thead>
                    <tr>
                        <th>
                            Type
                        </th>
                        <th className='min-width-130'>
                            Transfer amount
                        </th>
                        <th className='min-width-130 text-right'>
                            Price
                        </th>
                        <th className='min-width-165'>
                            Time & date
                        </th>
                        <th>
                            Merchant
                        </th>
                        <th>
                            <span>State <Tooltip id='stateInfoTooltip' placement='top' text='Transaction state' /></span>
                        </th>
                        {props.isAdmin && (
                            <th key='actualProfit'>
                                Profit in EUR
                            </th>
                        )}
                        <th className='text-right pdd-right-20' key='profitAmount'>
                           Additional info
                        </th>
                    </tr>
                </thead>
                {tbody}
            </table>
            {props.showModalStatus === true && props.isAdmin &&
                <TradesModalContainer onModalClosed={props.modalClosed} showOnInit={true} paymentId={props.transactionId} isPayout={props.isPayout} />
            }
            {props.showProfitModalStatus === true && props.isAdmin &&
                <ProfitModalContainer onModalClosed={props.modalProfitClosed} showOnInit={true} paymentId={props.transactionId} profitDetails={props.profitDetails} />
            }
            {props.showConfirmModalStatus === true && props.isAdmin &&
                <ConfirmFiatPayoutModal onModalClosed={props.modalConfirmClosed} showOnInit={true} transactionId={props.transactionId} />
            }
        </div>
    )
}

TransactionsTable.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    limit: PropTypes.number.isRequired,
    handleConfirmClick: PropTypes.func.isRequired,
    modalClosed: PropTypes.func.isRequired,
    modalConfirmClosed: PropTypes.func.isRequired,
    modalProfitClosed: PropTypes.func.isRequired,
    transactionId: PropTypes.string,
    transactionsHistory: PropTypes.object.isRequired,
    transactionsGetHistory: PropTypes.func.isRequired,
    profitDetails: PropTypes.object.isRequired,
    showModal: PropTypes.func.isRequired,
    showProfitModal: PropTypes.func.isRequired,
    showProfitModalStatus: PropTypes.bool.isRequired,
    showConfirmModal: PropTypes.func.isRequired,
    showConfirmModalStatus: PropTypes.bool.isRequired,
    showModalStatus: PropTypes.bool.isRequired,
    tableHeight: PropTypes.number.isRequired
}

export default TransactionsTable;