import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const PUT_AUTO_CONVERT_SETTING = 'PUT_AUTO_CONVERT_SETTING'
export const RESET_AUTO_CONVERT_SETTING = 'RESET_AUTO_CONVERT_SETTING'


export function putAutoConvertSetting({merchantId, autoConvertToEur}) {

	const dataPayload = axios.put(`${ENV.API_URL}/internal/admin/merchants/${merchantId}`, {autoConvertToEur: autoConvertToEur}, {
	    headers: getHeader()
	})
	return {
		type: PUT_AUTO_CONVERT_SETTING,
		payload: dataPayload
	}
}

export function resetAutoConvertSetting(merchantId) {
	const dataPayload = '';
	return {
		type: RESET_AUTO_CONVERT_SETTING,
		payload: dataPayload
	}
}