import {GET_TRANSACTIONS_ALL_HISTORY, RESET_TRANSACTIONS_ALL_HISTORY} from 'actions/transactionsHistoryAll';
import {setupRollbar} from 'helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_TRANSACTIONS_ALL_HISTORY:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return {data: action.payload.data, meta: action.meta}
		case RESET_TRANSACTIONS_ALL_HISTORY:
			return action.payload
		default:
			return state;
	}

}