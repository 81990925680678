import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

const MerchantTable = (props) => {
    let merchant = props.merchant;
    return (
        <div>
            {_.isEmpty(merchant.apiKeys) ? (
                <p className='mrg-top-20 mrg-btm-5 lead'>There are no API keys for this merchant</p>
            ) : (
                <div className='overflow-y-auto relative scrollable mrg-top-15'>
                    <table className='table table-lg table-hover table-bordered-top'>
                        <thead>
                            <tr>
                                <th className='min-width-120'>
                                    Key name
                                </th>
                                <th>
                                    Key ID
                                </th>
                                <th>
                                    Status
                                </th>
                                {(props.isAdmin || props.isMerchantAdmin) &&
                                    <th className='min-width-160' key='enable'>
                                        Disable / enable
                                    </th>
                                }
                                {(props.isAdmin || props.isMerchantAdmin || props.isMerchantUser) &&
                                <th className='width-num-240' key='edit'>
                                    Delete key
                                </th>}
                            </tr>
                        </thead>
                        <tbody className='collapsible-body'>
                            {merchant.apiKeys.slice(0).reverse().map(function(val,i) {
                                let isEnabled = val.enabled === true ? 'enabled' : 'disabled';
                                return (
                                    <tr key={i}>
                                        <td className='v-align'>
                                            <div>
                                                <h5>{val.name}</h5>
                                            </div>
                                        </td>
                                        <td className='v-align'>
                                            <div>
                                                <span>{val.id}</span>
                                            </div>
                                        </td>
                                        <td className='min-width-130 v-align'>
                                            <div className='relative'>
                                                <span className={"status " + (val.enabled ? 'online' : '')}> </span>
                                                <span className='pdd-left-20'>{isEnabled}</span>
                                            </div>
                                        </td>
                                        {(props.isAdmin || props.isMerchantAdmin) &&
                                            <td key={i + '1'}>
                                                <div className='toggle-checkbox toggle-success toggle-sm checkbox-inline mrg-top-12'>
                                                    <input type='checkbox' name={'toggle' + i} id={'toggle' + i} checked={val.enabled} onChange={(event) => props.handleApiSwith(event, val.id, val.name)} />
                                                    <label htmlFor={'toggle' + i}></label>
                                                </div>
                                            </td>
                                        }
                                        {(props.isAdmin || props.isMerchantAdmin || props.isMerchantUser) &&
                                        <td key={i + '2'} className='v-align'>
                                            <div className='mrg-left-5'>
                                                <a href='' className='delete' onClick={(event) => {
                                                    props.handleDeleteClick(event, val.id)
                                                }}><i className='ei ei-garbage-alt'></i></a>
                                            </div>
                                        </td>
                                        }
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
              )}
        </div>
    )
}

MerchantTable.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    isMerchantAdmin: PropTypes.bool.isRequired,
    isMerchantUser: PropTypes.bool.isRequired,
    handleApiSwith: PropTypes.func.isRequired,
    handleDeleteClick: PropTypes.func.isRequired,
    merchant: PropTypes.object.isRequired,
    merchantId: PropTypes.string.isRequired
}

export default MerchantTable;
