import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types'
import { TabsContext } from 'contexts/tabs.context'
import NotFound from 'components/pages/notfound'
import _ from 'lodash'
import LayoutContainer from 'components/layout/layout_container'
import ApiKeyTab from './tabs/api_keys/api_keys_tab'
import NotifyTab from './tabs/notifications/notify_tab'
import SettingsTab from './tabs/settings/settings_tab'
import AccountFeesTab from './tabs/account_fees/account_fees_tab'
import ContactTab from './tabs/contact/contact_tab'
import NavItemTab from 'components/ui_elements/nav_item_tab'
import Loading from 'components/ui_elements/loading'
import LoadingError from 'components/ui_elements/loading_error'
import Breadcrumbs from 'components/ui_elements/breadcrumbs'

const MerchantLayout = (props) => {
    const { active, setActive } = useContext(TabsContext);

    let merchant;
    if(props.notFound === true) {
        return <NotFound />
    }
    if(props.isAdmin === true) {
        merchant = props.merchant;
        if(_.isEmpty(merchant) ) {
            return (
                <LayoutContainer active='merchants' history={props.history} >
                    <Loading />
                </LayoutContainer>
            )
        }
    } else {
        merchant = props.merchant;
        if(_.isEmpty(merchant)) {
            return (
                <LayoutContainer active='merchants'>
                    <Loading />
                </LayoutContainer>
            )
        }
    }
    if(merchant === 'error') {
        return (
            <LayoutContainer active='merchants'>
                <LoadingError />
            </LayoutContainer>
        )
    }
  	return (
        <LayoutContainer active={props.view}>
            {(props.isAdmin || props.isMerchant) && props.view === 'merchants' ? (
				<Breadcrumbs dataPath={{0: ['Home', '/'], 1: ['Merchants', '/merchants'], 2: [merchant.name, '']}}/>
            ) : (
                <Breadcrumbs dataPath={{0: ['Home', '/'], 1: [merchant.name, '']}}/>
            )}
            <div className='row'>
                <div className='col-md-12'>
                    {props.view === 'merchants' ? (
                        <h2 className='pull-left mrg-btm-10'>Merchant {merchant.name}</h2>
                    ) : (
                        <h2 className='pull-left mrg-btm-10'>{merchant.name}</h2>
                    )
                    }
                </div>
            </div>
    		<div className='card'>
                <div className='tab-info'>
                    {((props.view !== 'merchants' || props.isAdmin)) && (
                        <ul className='nav nav-tabs' id='myTab' role='tablist'>
                            <NavItemTab key='home' name='Merchant details' hrefText='home' id='home' idText='home-tab' handleClick={() => setActive('home')} active={active} />
                            <NavItemTab key='notify' name='Notification Key' hrefText='notify' id='notify' idText='notify-tab' handleClick={() => setActive('notify')} active={active} />
                            {props.isAdmin &&
                                <>
                                    <NavItemTab key='account-fees' name='Account fees' hrefText='account-fees' id='account-fees' idText='account-fees-tab' handleClick={() => setActive('account-fees')} active={active} />
                                    <NavItemTab key='settings' name='Settings' hrefText='settings' id='settings' idText='settings-tab' handleClick={() => setActive('settings')} active={active} />
                                </>
                            }
                            <NavItemTab key='contact' name='Contact & Billing' hrefText='contact' idText='contact-tab' id='contact' handleClick={() => setActive('contact')} active={active} />
                        </ul>
                    )}
                    <div className='tab-content' id='myTabContent'>
                        <ApiKeyTab />
                        {((props.view !== 'merchants' || props.isAdmin)) && (
                            <NotifyTab />
                        )}
                        {props.isAdmin &&
                            <>
                                <AccountFeesTab key='account-fees' />
                                <SettingsTab key='settings' />
                            </>
                        }
                        <ContactTab />
                    </div>
                </div>
            </div>
	  	</LayoutContainer>
	)
}
MerchantLayout.propTypes = {
    isAdmin: PropTypes.bool.isRequired,
    isMerchant: PropTypes.bool.isRequired,
    merchant: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ]).isRequired,
    merchantId: PropTypes.string.isRequired,
    notFound: PropTypes.bool.isRequired,
    onSuccess: PropTypes.func.isRequired,
    selfMerchant: PropTypes.object.isRequired,
    view: PropTypes.string.isRequired
}

export default MerchantLayout;