import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_CURRENCIES = 'GET_CURRENCIES'
export const PUT_CURRENCY = 'PUT_CURRENCY'
export const RESET_CURRENCY = 'RESET_CURRENCY'

export function getCurrencies() {
	let data = {
	  headers: getHeader()
	}
	let dataPayload = axios.get(`${ENV.API_URL}/currencies`, data)
	
	return {
		type: GET_CURRENCIES,
		payload: dataPayload
	}
}
export function putCurrency({code, currencyType, blockchain, paymentPriceEnabled, payoutPriceEnabled, payoutTransferEnabled, paymentTransferEnabled, precision}) {
	let data = {
  		'code': code,
  		'currencyType': currencyType,
  		'paymentPriceEnabled': paymentPriceEnabled,
  		'payoutPriceEnabled': payoutPriceEnabled,
  		'payoutTransferEnabled': payoutTransferEnabled,
  		'paymentTransferEnabled': paymentTransferEnabled, 
  		'precision': precision
	}
	if(blockchain && blockchain !== '') {
		data.blockchain = blockchain;
	}
	const dataPayload = axios.put(`${ENV.API_URL}/currencies`, data, {
	    headers: getHeader()
	})
	return {
		type: PUT_CURRENCY,
		payload: dataPayload
	}
}
export function resetCurrency() {
	const dataPayload = ''
	return {
		type: RESET_CURRENCY,
		payload: dataPayload
	}
}