import {POST_USER, RESET_USER} from '../actions/user';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case POST_USER:
			if(action.error) {
				setupRollbar(action);
				return {type: 'error', response: action.payload.response?.data?.message}
			}
			return action.payload;
		case RESET_USER:
			return action.payload;
		default:
			return state;
	}

}