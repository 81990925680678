import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'
 
export const GET_PAYOUT_PRICE = 'GET_PAYOUT_PRICE'
export const GET_PAYOUT_PRICE_FIAT = 'GET_PAYOUT_PRICE_FIAT'
export const RESET_PAYOUT_PRICE = 'RESET_PAYOUT_PRICE'
export const RESET_PAYOUT_PRICE_FIAT = 'RESET_PAYOUT_PRICE_FIAT'

export function getPayoutPrice(amount, currency, transferCurrency) {
    let data = { 
        headers: getHeader(),
        params: {
            'priceCurrency': transferCurrency,
            'transferCurrency': currency.value ? currency.value : currency,
            'priceAmount': amount
        } 
    } 
    const dataPayload = axios.get(`${ENV.API_URL}/internal/estimate-payout`, data)
    return { 
        type: GET_PAYOUT_PRICE, 
        payload: dataPayload,
    } 
}

export function resetPayoutPrice(type) {
    let dataType
    if(type === 'fiat') {
        dataType = RESET_PAYOUT_PRICE_FIAT
    } else {
        dataType = RESET_PAYOUT_PRICE
    }
    const dataPayload = ''
    return { 
        type: dataType, 
        payload: dataPayload
    } 
}




