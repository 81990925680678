import {DELETE_PERMISSION, PUT_PERMISSION, RESET_PERMISSION} from '../actions/permissions';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case DELETE_PERMISSION:
			let responseDelete = 'Some error occured while disabling this permission, please try again';
			if(action.error) {
				setupRollbar(action);
				return {type: 'error', response: responseDelete}
			}
			return {status: action.payload.status};
		case PUT_PERMISSION:
			let responsePut = 'Some error occured while enabling this permission, please try again';
			if(action.error) {
				setupRollbar(action);
				return {type: 'error', response: responsePut}
			}
			return {status: action.payload.status};
		case RESET_PERMISSION:
			return action.payload;
		default:
			return state;
	}

}