import {GET_CONVERSION_ESTIMATION, RESET_CONVERSION_ESTIMATION} from '../actions/conversionEstimate';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = '', action) {
	switch(action.type) {
		case GET_CONVERSION_ESTIMATION:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return action.payload.data
		case RESET_CONVERSION_ESTIMATION:
			return action.payload
		default:
			return state;
	}

}