import {GET_CURRENCIES} from '../actions/currencies';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = [], action) {
	switch(action.type) {
		case GET_CURRENCIES:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			let currencies = [];
			action.payload.data.map(function(val,i) {
				if (val.payoutPriceEnabled) {
					currencies.push(val.code);
					return val.code
				} 
				return
			});
			return currencies;
		default:
			return state;
	}

}