import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import {putUser, resetUser} from 'actions/user'
import {getUsers, resetUsers} from 'actions/users'
import {putResetAllTerms, resetAllTerms} from 'actions/termsAndConditions'
import {getAccount} from 'actions/account'
import LoadingError from 'components/ui_elements/loading_error'
import Breadcrumbs from 'components/ui_elements/breadcrumbs'
import LayoutContainer from 'components/layout/layout_container'
import UsersTable from 'components/users/users_table'
import NewUserModal from 'components/users/new_user_modal'
import UsersSearchFormContainer from 'components/users/users_search_form_container'
import {initAlert} from 'helpers/alert_helper'
import {handleSubmitHook} from 'helpers/form_helper'

const Users = () => {
    const dispatch = useDispatch();
    const account = useSelector(state => state.account);
    const allTermsReset = useSelector(state => state.allTermsReset);
    const isAdmin = useSelector(state => state.isAdmin);
    const isMerchant = useSelector(state => state.isMerchant);
    const isMerchantAdmin = useSelector(state => state.isMerchantAdmin);
    const users = useSelector(state => state.users);
    const updatedUser = useSelector(state => state.updatedUser);
    const [updatedUserState, setUpdatedUserState] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showModalMerchantAccess, setShowModalMerchantAccess] = useState(false);
    const [initalLoad, setInitalLoad] = useState(false);
    const [refreshUsers, setRefreshUsers] = useState(false);
    const [resettingAllTerms, setResettingAllTerms] = useState(false);
    const [resetUserForm, setResetUserForm] = useState(false);
    const [searchParams, setSearchParams] = useState({});
    const [userId, setUserId] = useState('');
    const [userName, setUserName] = useState('');
    const [userMerchants, setUserMerchants] = useState([]);
    const [formDisabled, setFormDisabled] = useState(false);

    useEffect(() => {
        document.title = "Users - Bitcoin Payment Processor";
        dispatch(getAccount());
    }, []);

    useEffect(() => {
        if(!_.isEmpty(searchParams) && initalLoad === false) {
            dispatch(getUsers(searchParams.email, searchParams.merchantId));
            setInitalLoad(true);
        }
    }, [initalLoad]);

    useEffect(() => {
        if(updatedUser === 200 && updatedUserState === false) {
            dispatch(getAccount());
            dispatch(getUsers(searchParams.email, searchParams.merchantId));
            setUpdatedUserState(true);
        } 
    }, [updatedUser]);

    useEffect(() => {
        if(refreshUsers === true) {
            setRefreshUsers(false);
        }
    }, [refreshUsers]);

    useEffect(() => {
        if(resetUserForm === true) {
            setResetUserForm(false);
        }
    }, [resetUserForm]);

     useEffect(() => {
        if(resettingAllTerms === true && !_.isEmpty(allTermsReset)) {
            let terms = allTermsReset;
            if(terms === 'error') {
                initAlert({
                    type: 'error', 
                    title: 'Error', 
                    html: terms.response, 
                    confirmText: 'OK'
                });
            } else {
                initAlert({
                    type: 'success', 
                    title: 'Success', 
                    html: 
                        'All Terms & Conditions consents were resetted'
                });
            }
            dispatch(resetAllTerms());
            dispatch(getUsers());
            setResettingAllTerms(false);
        }
    }, [allTermsReset]);
    
    const handleSubmit = (event, params) => {
        handleSubmitHook({
            event: event, 
            funct: formSent, 
            args: [params.email, params.merchantId], 
            setFormDisabled: setFormDisabled,
            hasPagination: false
        });
        
    }
    const formSent = (email, merchant) => {
        dispatch(resetUsers());
        dispatch(getAccount());
        dispatch(getUsers(email, merchant));
    }
    const showModalFunc = () => {
        setShowModal(true);
    }
    const showModalMerchantAccessFunc = (id,email, userMerchantsProp) => {
        setUserMerchants(userMerchantsProp);
        setUserName(email);
        setUserId(id);
        setShowModalMerchantAccess(true);
    }
    const modalClosed = () => {
        setShowModal(false);
    }
    const modalClosedMerchantAccess = () => {
        setUserMerchants([]);
        setUserName('');
        setUserId('');
        setShowModalMerchantAccess(false);
    }
    const newUserSubmit = (data, name) => {
        if(data.type === 'error') {
            initAlert({
                type: 'error', 
                title: 'Error', 
                html: data.response, 
                confirmText: 'OK'
            });
        } else {
            initAlert({
                type: 'success', 
                title: 'Success', 
                html: 
                    'New user: ' +
                    '<br />' +
                    '<b>' +  name + '</b>' +
                    '<br />' +
                    'was created!'
            });
            setResetUserForm(true);
            dispatch(getAccount());
            dispatch(getUsers());
            setRefreshUsers(true);
        }
    }
    const onSwitchedUserKey = (event,value) => {
        dispatch(resetUser());
        dispatch(putUser(value, event));
        setUpdatedUserState(false);
    }
    const onCanceled = () => {
        return
    }
    const merchantAccessFormSent = (data,user) => {
        if(data.type === 'error') {
            initAlert({
                type: 'error', 
                title: 'Error', 
                html: data.response, 
                confirmText: 'OK'
            });
        } else {
            initAlert({
                type: 'success', 
                title: 'Success', 
                html: 
                    'You assigned new merchant' +
                    '<br />to user <b>' + user + '</b>'
            });
            dispatch(getAccount());
            dispatch(getUsers());
            setUserMerchants([]);
            setUserName('');
            setUserId('');
            setRefreshUsers(true);
            setShowModalMerchantAccess(false);
        }
    }
    const merchantAccessFormDelete = (data,user) => {
        if(data.type === 'error') {
            initAlert({
                type: 'error', 
                title: 'Error', 
                html: data.response, 
                confirmText: 'OK'
            });
        } else {
            initAlert({
                type: 'success', 
                title: 'Success', 
                html: 
                    'You deleted merchant' +
                    '<br />from user <b>' + user + '</b>'
            });
            dispatch(getAccount());
            dispatch(getUsers());
            setUserMerchants([]);
            setUserName('');
            setUserId('');
            setRefreshUsers(true);
            setShowModalMerchantAccess(false);
        }
    }
    const merchantAccessFormUpdate = (data, user, merchant, role) => {
        if(data.type === 'error') {
            initAlert({
                type: 'error', 
                title: 'Error', 
                html: data.response, 
                confirmText: 'OK'
            });
        } else {
            initAlert({
                type: 'success', 
                title: 'Success', 
                html: 
                    'You updated <b>' + user + '</b> role' +
                    '<br />for merchant <b>' + merchant + '</b> to <b>MERCHANT ' + role + '</b>' 
            });
            dispatch(getAccount());
            dispatch(getUsers());
            setUserMerchants([]);
            setUserName('');
            setUserId('');
            setRefreshUsers(true);
            setShowModalMerchantAccess(false);
        }
    }
    const handleUserSwitch = (event, value, name) => {
        let isEnabled = event.target.checked === true ? 'enable' : 'disable';
        let etar = event.target.checked;
        initAlert({
            type: 'warning', 
            title: 'User ' + (event.target.checked === true ? 'enabling' : 'disabling'), 
            html: 
                'You are about to ' + isEnabled + ' user ' +
                '<br />' +
                '<b>' + name + '</b>' +
                '<br />' + 
                'Are you sure you want to do this?', 
            confirmText: 'Yes, ' + isEnabled, 
            onSuccess: () => onSwitchedUserKey(etar, value) ,
            onDismiss: onCanceled
        });
    }
    const searchParamsUpdate = (params, isFormSent, event) => {
        setSearchParams(params);
        if(isFormSent) {
            handleSubmit(event, params);
        }
    }
    const resetTerms = () => {
        initAlert({
            type: 'warning', 
            title: 'Reset Terms & Conditions', 
            html: 
                'You are about to reset <b>Terms and Conditions</b>' +
                '<br />' +
                'for all user' +
                '<br />' + 
                'Are you sure you want to do this?', 
            confirmText: 'Yes, reset' ,
            onSuccess: onResetTerms,
            onDismiss: onCanceled
        });
    }
    const onResetTerms = () => {
        dispatch(putResetAllTerms());
        setResettingAllTerms(true);
    }
    let usersList = [];
    if(users !== 'error' && !_.isEmpty(users)) {
        usersList = users.data;
    }
    return (
        <>
            {users === 'error' ? (
                <LayoutContainer active='users' >
                    <LoadingError />
                </LayoutContainer>
            ) : (
                <LayoutContainer active='users'>
                    <div>
                        <Breadcrumbs dataPath={{0: ['Home', '/'], 1: ['Users', '']}}/>
                        <div className='row'>
                            <div className='col-md-12'>
                                <h2 className='pull-left mrg-btm-20'>Users</h2> 
                                {isAdmin &&
                                    [<button className='btn btn-default pull-right btn-medium mrg-btm-12' onClick={resetTerms} >Reset Terms<i className='fa fa-times'></i></button>,
                                     <button className='btn btn-primary pull-right btn-medium mrg-btm-12' onClick={showModalFunc} >New user<i className='fa fa-plus-circle'></i></button>]
                                }
                                {isMerchantAdmin &&
                                    <button className='btn btn-primary pull-right btn-medium mrg-btm-12' onClick={showModalFunc} >New user <i className='fa fa-plus-circle'></i></button>
                                }
                            </div>
                        </div>
                        <UsersSearchFormContainer resetUserForm={resetUserForm} searchParamsUpdate={searchParamsUpdate} />
                        <div className='card no-border-top'> 
                            {refreshUsers !== true &&
                                <UsersTable 
                                    formSent={merchantAccessFormSent}
                                    formSentDeleted={merchantAccessFormDelete}
                                    formSentUpdated={merchantAccessFormUpdate}
                                    isAdmin={isAdmin}
                                    isMerchant={isMerchant}
                                    isMerchantAdmin={isMerchantAdmin}
                                    modalClosedMerchantAccess={modalClosedMerchantAccess}
                                    onUserSwitch={handleUserSwitch}
                                    showModalMerchantAccess={showModalMerchantAccessFunc}
                                    showModalMerchantAccessState={showModalMerchantAccess}
                                    userEmail={account.user ? account.user.email : ''}
                                    userId={userId}
                                    userMerchants={userMerchants}
                                    userName={userName}
                                    users={users} 
                                    usersList={usersList}
                                />
                            }
                        </div>
                    </div>
                    {showModal === true &&
                        <NewUserModal onModalClosed={modalClosed} formSent={newUserSubmit} showOnInit={true}/>
                    }
                </LayoutContainer>
            )}
        </>
    )
}

export default Users;
