import React, {Component} from 'react';
import $ from 'jquery'; 
import _ from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'; 
import {postNotificationKey} from 'actions/notificationKey';
import NewNotifyKeyModal from './new_notify_key_modal';
import {handleModalFormSubmit} from 'helpers/form_helper';

class NewNotifyKeyModalContainer extends Component {
    state = {
        disabledForm: false
    }
    componentDidUpdate(prevProps) {
        let newNotificationKey = this.props.newNotificationKey;
        if(!_.isEmpty(newNotificationKey)) {
            this.props.formSent(newNotificationKey);
            $('#notifyKeyModal').modal('hide');
        }
    }
    handleModalHide() {
        this.props.onModalClosed();
    }
    handleSubmit(event) {
        handleModalFormSubmit({
            self: this, 
            event: event, 
            states: {
                disabledForm: true
            }, 
            sendFunction: this.props.postNotificationKey, 
            sendProps: {
                merchantId: this.props.merchant.id
            },
            resetFunction: this.props.resetFunction
        });
    }
    render() {
        let thisProps = this.props;
        let thisState = this.state;
        let showOnInit = thisProps.showOnInit || false;
        return ( 
            <NewNotifyKeyModal 
                disabledForm={thisState.disabledForm} 
                handleModalHide={this.handleModalHide.bind(this)} 
                handleSubmit={this.handleSubmit.bind(this)} 
                merchant={thisProps.merchant} 
                showOnInit={showOnInit} 
            />
        )
    }
}

NewNotifyKeyModalContainer.propTypes = {
    formSent: PropTypes.func.isRequired,
    onModalClosed: PropTypes.func.isRequired,
    showOnInit: PropTypes.bool.isRequired
}

function mapStateToProps(state) { 
    return { 
        merchant: state.merchant,
        newNotificationKey: state.newNotificationKey
    } 
} 

export default connect(mapStateToProps, {postNotificationKey})(NewNotifyKeyModalContainer);