import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import Nav from 'react-bootstrap/Nav'
import variables from 'styles/_variables.scss'
import { useDispatch, useSelector } from 'react-redux'
import { TabsContext } from 'contexts/tabs.context'
import { getMerchantFees, resetGetMerchantFees, resetPutMerchantFees } from 'actions/merchantFees'
import { getCurrencies } from 'actions/currencies'
import { initAlert } from 'helpers/alert_helper'
import Tab from 'components/ui_elements/tab'
import Loading from 'components/ui_elements/loading';

import DataPresentationRow from 'components/ui_elements/data_presentation_row'
import EditFeeModal from './edit_fee_modal'
import {feeMultiplied} from 'helpers/fees_helper'

const NavLink = styled(Nav.Link)`
    padding: 8px 30px;
    color: ${variables.defaultDark};
    border-bottom: 2px solid transparent;
    &.activeTab {
        border-bottom: 2px solid ${variables.defaultInfo}!important;
    }
`

const FeeDisplay = styled.div`
    margin-right: 15px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    div {
        color: ${variables.defaultDark};
        display: inline-block;
    }
    label {
        font-size: 12px;
        margin-bottom: 0;
        position: relative;
        display: inline-block;
    }
`

const FeeTier = ({tier, feesLength, ind}) => (
    <FeeDisplay>
        <label>Tier {ind + 1} ({ind === -1 || (tier.to === undefined && tier.from === undefined) ? '> 0 EUR' : ind === 0 ? `< ${tier.to} EUR` : (ind === feesLength -1) ? `≥ ${tier.from} EUR` : `${tier.from} - ${tier.to} EUR`}):</label>
        <div>{feeMultiplied(tier.fee)}%</div>
    </FeeDisplay>
)

const AccountFeesTabForm = ({type}) => {
    const dispatch = useDispatch();
    const isAdmin = useSelector(state => state.isAdmin);
    const merchant = useSelector(state => state.merchant);
    const merchantFees = useSelector(state => state.merchantFees);
    const [showFeeModal, setShowFeeModal] = useState(false);
    const [reloadCurrency, setReloadCurrency] = useState(true);

    const merchantId = merchant.id;
    const [feesValues, setFeesValues] = useState({});
    const feeTiersDefault = []
    let fxFeeTiersMap = feesValues.fxFeeTiers ? feesValues.fxFeeTiers : feeTiersDefault;
    let payoutFxFeeTiersMap = feesValues.payoutFxFeeTiers ? feesValues.payoutFxFeeTiers : feeTiersDefault;
    let payoutNetworkFeeTiersMap = feesValues.payoutNetworkFeeTiers ? feesValues.payoutNetworkFeeTiers : feeTiersDefault;
    let conversionFeeTiersMap = feesValues.conversionFeeTiers ? feesValues.conversionFeeTiers : feeTiersDefault;

    useEffect(() => {
        if(reloadCurrency === true && merchantFees.fees && merchantFees.fees.length && merchantFees !== 'error') {
            let currencyFeeValues = merchantFees.fees.find((currencyFee) => currencyFee.currencyCode === type);
            if(currencyFeeValues) {
                setFeesValues(currencyFeeValues)
            } else {
                setFeesValues({
                    currencyCode: type
                })
            }
            setReloadCurrency(false);
        }
    }, [merchantFees]);

    const feeModalClosed = () => {
        setShowFeeModal(false);
    }
    const showFeeModalFunc = () => {
        setShowFeeModal(true);
    }
    const editFeeSubmit = (data) => {
        if(data.type === 'error' || data === 'error') {
            initAlert({
                type: 'error', 
                title: 'Error', 
                html: data.response ? data.response : 'Some error occured, please try again', 
                confirmText: 'OK'
            });
            dispatch(resetPutMerchantFees());
        } else {
            initAlert({
                type: 'success', 
                title: 'Success', 
                html: 'You successfully updated fees.'
            });
            dispatch(resetPutMerchantFees());
            setReloadCurrency(true);
            dispatch(getMerchantFees(merchantId));
        }
    }
    return (
        <>
            <div className='row'>
                <div className='col-md-12 mrg-btm-10'>
                    <h2 className='pull-left mrg-btm-20'>{type} Account fees</h2>
                     {isAdmin &&
                        <button className='btn btn-default pull-right btn-long absolute top-0 right-15' onClick={showFeeModalFunc}>Edit {type} fees</button>
                    }
                </div>
            </div>
            <div className='row'>
                <div className='col-md-6'>
                    <h4 className='mrg-btm-20'>Payment fees</h4>
                    <DataPresentationRow name='FX fee tiers' large={true}>
                        <div>{fxFeeTiersMap.map((tier,i) => (
                            <FeeTier tier={tier} feesLength={fxFeeTiersMap.length} ind={i} />
                        ))}</div>
                    </DataPresentationRow>
                </div>
            </div>
            {fxFeeTiersMap.length ? <hr /> : <></>}
            <div className='row'>
                <div className='col-md-6'>
                    <DataPresentationRow name='TX fee' large={true}>
                        {feesValues.txFee !== undefined ? <p>{feeMultiplied(feesValues.txFee)}%</p> : <p></p>}
                    </DataPresentationRow>
                    
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-md-6'>
                    <h4 className='mrg-btm-20'>Conversion fees</h4>
                    <DataPresentationRow name='Conversion fee tiers' large={true}>
                        <div>{conversionFeeTiersMap.map((tier,i) => (
                            <FeeTier tier={tier} feesLength={conversionFeeTiersMap.length} ind={i} />
                        ))}</div>
                    </DataPresentationRow>
                </div>
            </div>
            <hr />
            <div className='row'>
                <div className='col-md-6'>
                    <h4 className='mrg-btm-20'>Payout fees</h4>
                    <DataPresentationRow name='Payout FX fee tiers' large={true}>
                        <div>{payoutFxFeeTiersMap.map((tier,i) => (
                            <FeeTier tier={tier} feesLength={payoutFxFeeTiersMap.length} ind={i} />
                        ))}</div>
                    </DataPresentationRow>
                </div>
            </div>
            {payoutFxFeeTiersMap.length ? <hr /> : <></>}
            <div className='row'>
                <div className='col-md-6'>
                    <DataPresentationRow name='Payout network fee tiers' large={true}>
                        <div>{payoutNetworkFeeTiersMap.map((tier,i) => (
                            <FeeTier tier={tier} feesLength={payoutNetworkFeeTiersMap.length} ind={i} />
                        ))}</div>
                    </DataPresentationRow>
                </div>
            </div>
            {payoutNetworkFeeTiersMap.length ? <hr /> : <></>}
            <div className='row'>
                <div className='col-md-6'>
                    <DataPresentationRow name='Payout TX fee' large={true}>
                        {feesValues.payoutTxFee !== undefined ? <p>{feeMultiplied(feesValues.payoutTxFee)}%</p> : <p></p>}
                    </DataPresentationRow>
                </div>
            </div>
            {showFeeModal === true &&
                <EditFeeModal 
                    currencyCode={type}
                    feesValues={feesValues}
                    formSent={editFeeSubmit} 
                    merchant={merchant} 
                    onModalClosed={feeModalClosed} 
                    showOnInit={true}
                />
            }
        </>
    )
}

const AccountFeesTab = () => {
    const dispatch = useDispatch();
    const { active } = useContext(TabsContext);
    const isAdmin = useSelector(state => state.isAdmin);
    const merchant = useSelector(state => state.merchant);
    const merchantFees = useSelector(state => state.merchantFees);
    const currencies = useSelector(state => state.currencies);
    const [transferCurrencies, setTransferCurrencies] = useState([]);
    const [activeTab, setActiveTab] = useState('');

    useEffect(() => {
        dispatch(getMerchantFees(merchant.id));
        dispatch(getCurrencies());
        return () => {
            dispatch(resetGetMerchantFees());
        }
    }, []);
    
    useEffect(() => {
        if(currencies.length) {
            let transfer = currencies.filter(curr => curr.paymentTransferEnabled || curr.payoutTransferEnabled);
            setTransferCurrencies(transfer);
            setActiveTab(transfer[0] ? transfer[0].code : '');
        }
    }, [currencies]);

    return (
        <Tab name='account-fees' labelledby='fees-tab' active={active}>
            {transferCurrencies.length ?  (
                <>
                    <Nav variant="tabs" defaultActiveKey="/home">
                        {transferCurrencies.map(curr => (
                            <Nav.Item>
                                <NavLink onClick={() => setActiveTab(curr.code)} className={activeTab === curr.code ? 'activeTab' : ''}>{curr.code}</NavLink>
                            </Nav.Item>
                        ))}
                    </Nav>
                    <div className='pdd-horizon-30 pdd-vertical-30'>
                        {transferCurrencies.map(curr => (
                            <>{activeTab === curr.code && <AccountFeesTabForm type={curr.code} />}</>
                        ))}
                    </div>
                </>
            ) : (
                <Loading />
            )}
            
        </Tab>
    )
}

export default AccountFeesTab;
