import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import React from 'react';
import PropTypes from 'prop-types';
import {secureStorage} from '../../helpers/secure_storage_helper';

const MerchantNav = (props) => {
    let availableMerchants = props.merchantsIds;
    return (
        <div className='side-panel'>
            <div className='side-panel-wrapper bg-white'>
                <div id='profile'>
                    <div className='profile scrollable'>
                        <div className='pdd-horizon-20 pdd-top-20'>
                            <div className='border bottom'>
                                <div className='text-center mrg-vertical-20'>
                                    <h4 className='mrg-top-20 relative'>
                                        {props.merchantName && (
                                            <span>
                                                <span>{props.merchantName}</span>
                                                {!props.isAdmin &&
                                                    <span className={props.enabled ? 'status online' : 'status'}><span></span></span>
                                                }
                                            </span>
                                        )}
                                    </h4>
                                    <span>{props.account.user ? props.account.user.email : ''}</span>
                                </div>
                            </div>
                        </div>
                        <div className='pdd-horizon-20 pdd-top-20'>
                            <div className='border bottom'>
                                <h5 className='text-dark mrg-btm-15'>Available accounts</h5>
                                <Form onSubmit={props.handleSubmit} id='merchantSearchForm' className='basic-form'>
                                    <div className='row'>
                                        <div className='form-group col-md-12'>
                                            <input placeholder='Search merchant...' type='text' className='form-control' id='merchantName' name='merchantName' value={props.searchMerchantName} onChange={props.searchMerchant} />
                                        </div>
                                    </div>
                                </Form>
                                {availableMerchants && availableMerchants.length  ? (
                                    <ul className='list-unstyled list-link font-size-16 pdd-btm-20'>
                                        {availableMerchants.map(function(val,i){
                                            if(secureStorage.getItem('merchantId') !== val.id) {
                                                return (
                                                    <li className='overflow-hidden' key={i}>
                                                        <a href='#' onClick={() => props.switchMerchant(val.id)}>
                                                            <i className='ti-user pdd-right-10'></i>
                                                            <span>{val.name}</span>
                                                        </a>
                                                    </li>
                                                )
                                            }
                                        })}
                                    </ul>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </div>
                        <div className='pdd-horizon-20 pdd-top-20'>
                            <ul className='list-unstyled list-link font-size-16 pdd-btm-20'>
                                <li>
                                    <a href='#' onClick={props.logout}>
                                        <i className='ei ei-power-button-alt pdd-right-10'></i>
                                        <span>Logout</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

MerchantNav.propTypes = {
    account: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    logout: PropTypes.func.isRequired,
    merchantName: PropTypes.string.isRequired,
    searchMerchantName: PropTypes.string.isRequired,
    searchMerchant: PropTypes.func.isRequired,
    switchMerchant: PropTypes.func.isRequired
}

export default MerchantNav;