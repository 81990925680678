import generateZeroWithDecimals from 'helpers/generate_zero_with_decimals'

export let totalComma = function(x, isCoin, isAbs) {
	let el = x;
	if(typeof x === 'string') {
		el = parseFloat(x);
	}
	let fixDigits = isCoin ? 8 : 2;
	let value = el.toFixed(fixDigits);
	if(el > 0 && value == 0) {
        if (fixDigits == 8) {
            return 0.00000001
        }
        else {
            return 0.01;
        }
		
	}
	if(isAbs) {
		value = Math.abs(value);
	}
	let numb = value.toString().split('.');
	return numb[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'") + '.' + numb[1];
}

export let totalCommaPrecision = ({toFormat, currCode, currencies, isAbs}) => {
	let currencyDetails = currencies.find((curr) => curr.code === currCode);

    if(!currencyDetails || !currencyDetails.precision) {
    	return 0
    }
	let el = toFormat;
	if(typeof toFormat === 'array' || typeof toFormat === 'object') {
		return 0
	}
	if(typeof toFormat === 'string') {
		el = parseFloat(toFormat);
	}
	if(el === undefined) {
		return 0
	}
	let value = el.toFixed(currencyDetails.precision);

	if(el > 0 && value == 0) {
		return generateZeroWithDecimals(currencyDetails.precision)
		
	}
	if(isAbs) {
		value = Math.abs(value);
	}
	let numb = value.toString().split('.');
	return numb[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'") + '.' + numb[1];
	
}

export let totalNum = function(x, isCoin, isAbs) {
	let el = x;
	if(typeof x === 'string') {
		el = parseFloat(x);
	}
	let fixDigits = isCoin ? 8 : 2;
	let value = el.toFixed(fixDigits);
	if(el > 0 && value == 0) {
        if (fixDigits == 8) {
            return 0.00000001
        }
        else {
            return 0.01;
        }
		
	}
	if(isAbs) {
		value = Math.abs(value);
	}
	let numb = value.toString();
	return numb;
}

	