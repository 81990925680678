import React from 'react';
import PropTypes from 'prop-types';
import {secureStorage} from '../../helpers/secure_storage_helper';
import { useSelector } from 'react-redux';

const Navbar = ({onToggle, merchantRole, showRole}) => {
    const account = useSelector(state => state.account);
    const selfMerchant = useSelector(state => state.selfMerchant)
    return (
        <div className='header navbar'>
            <div className='header-container'>
                <ul className='nav-left'>
                    <li>
                        <a className='side-nav-toggle' href='#' onClick={onToggle}>
                            <i className='ti-menu'></i>
                        </a>
                    </li>
                </ul>
                <ul className='nav-right'>
                    <li className='user-profile'>
                        <a href='#'>
                            <div className='user-info'>
                                <span className='name pdd-right-20'>{account.user?.email} 
                                        <span>
                                            {selfMerchant.name ? (
                                                <span> ({selfMerchant.name})</span> 
                                                ) : (
                                                    <></>
                                                )}
                                        </span>
                                </span>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a className='side-panel-toggle pdd-horizon-30' href='#' onClick={e => e.preventDefault()}>
                            <i className='ti-close ti-align-right'></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Navbar