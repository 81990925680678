import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const GET_MERCHANTS_PAGINATED = 'GET_MERCHANTS_PAGINATED'
export const RESET_MERCHANTS_PAGINATED = 'RESET_MERCHANTS_PAGINATED'
export const GET_MERCHANTS_NAMES_PAGINATED = 'GET_MERCHANTS_NAMES_PAGINATED'


export function getMerchantsPaginated({limit, offset, select, name}) {
	let offsetCalc = 0
    if(offset !== 0 || offset !== undefined) {
        offsetCalc = parseInt(offset,10) * limit
    }
	let data = {
	  	headers: getHeader(),
	  	params: {
	  		limit: limit,
	  		offset: offsetCalc
	  	}
	}
	if(name) {
		data.params.name = name
	}
    let dataPayload = axios.get(`${ENV.API_URL}/internal/admin/merchants/list`, data)
    let type = GET_MERCHANTS_PAGINATED
    if(select === true) {
    	type = GET_MERCHANTS_NAMES_PAGINATED
    }
	return {
		type: type,
		payload: dataPayload,
		meta: data.params, 
	}
}

export function resetMerchantsPaginated() {
	const dataPayload = {}
	return {
		type: RESET_MERCHANTS_PAGINATED,
		payload: dataPayload
	}
}