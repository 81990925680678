import { POST_MERCHANT, RESET_POST_MERCHANT } from '../actions/merchant'
import  {setupRollbar } from '../helpers/rollbar_helper'

export default function(state = {}, action) {
	switch(action.type) {
		case POST_MERCHANT:
			let response = 'Some error occured while creating merchant, please try again';
			if(action.payload.response) {
				if(action.payload.response.data?.message) {
					response = action.payload.response.data.message;
				}
			}
			if(action.error) {
				setupRollbar(action);
				return {type: 'error', response: response}
			}
			return action.payload.data;
		case RESET_POST_MERCHANT:
			return action.payload
		default:
			return state;
	}

}