import React, { useState, useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { DashboardTabsProvider } from 'contexts/dashboard.tabs.context'
import {switchMerchant} from 'actions/switchMerchant'
import LayoutContainer from 'components/layout/layout_container'
import DashboardSummary from 'components/dashboard/dashboard_summary'
import {removeStorage} from 'helpers/helpers'
import {secureStorage} from 'helpers/secure_storage_helper'

const Dashboard = () => {
    const account = useSelector(state => state.account);
    const isMerchant = useSelector(state => state.isMerchant);
    const isAdmin = useSelector(state => state.isAdmin)
    const state = useSelector(state => state);
    const isUnassignedMerchant = useSelector(state => state.isUnassignedMerchant);

    const [loadingMerchants, setLoadingMerchants] = useState(false);

    const dispatch = useDispatch();
    const history = useNavigate();

    useEffect(() => {
        document.title = "Bitcoin Payment Processor";
    }, []);

    const switchMerchantFunc = (el) => {
        dispatch(switchMerchant(el));
        removeStorage(el, account);
        history('/');
        window.location.reload();
    }
    const setLoadingMerchantsFunc = (val) => {
        setLoadingMerchants(val)
    }
    return (
        <LayoutContainer active='dashboard'>
            {!isAdmin && (isUnassignedMerchant || secureStorage.getItem('merchantId') === null) ? (
                <div>
                    <h2>Available accounts</h2>
                    <p>Please choose which account You want to use:</p>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='widget card'>
                                <div className='card-block'>
                                    <div className='row'>
                                        {account.context?.availableMerchants &&
                                            <ul className="list-unstyled list-link list-switch font-size-16 no-pdd-btm pdd-left-15"> 
                                                {account.context.availableMerchants.map(function(val,i){
                                                    return (
                                                        <li key={i}>
                                                            <a href='javascript:void(0);' onClick={() => switchMerchantFunc(val.id)}>
                                                                <i className='ti-user pdd-right-10'></i>
                                                                <span>{val.name}</span>
                                                            </a>
                                                        </li>
                                                    )
                                                })}
                                             </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div key="payments-in-time">
                    <DashboardTabsProvider>
                        <DashboardSummary />
                    </DashboardTabsProvider>
                </div>
                
            )}
        </LayoutContainer>
    )
}

export default Dashboard;