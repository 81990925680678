import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import PropTypes from 'prop-types'
import moment from 'moment'
import {TotalLineParent} from 'components/ui_elements/total_line'
import Loading from 'components/ui_elements/loading'
import LoadingError from 'components/ui_elements/loading_error'
import NumberFormatter from 'components/ui_elements/number_formatter'
import Tooltip from 'components/ui_elements/tooltip'
import {fullTimeUtc,dateToIsoFull, getTimeDifference} from "helpers/date_helper"
import {lossStyle} from 'helpers/class_helper'
import { CRYPTO_NAMES } from 'helpers/crypto_names'

const BalancesTable = (props) => {
    const currencies = useSelector(state => state.currencies);
    let balances = props.balances;
    let summary = balances.data.exchangeBalanceSummary;
    let exchangesCount = summary.exchangeBalances.length === 1;
    let timeDifference = 60000; // 1 min;
    const [exchangeNames, setExchangeNames] = useState([]);
    useEffect(() => {
        if(summary.exchangeBalances && summary.exchangeBalances.length) { 
            getExchangesNames(summary.exchangeBalances);
        }
    }, [summary]);
    const getExchangesNames = (exchangeBalances) => {
        let exchangeNamesVal = exchangeBalances.map((exchange) => exchange.exchange);
        setExchangeNames(exchangeNamesVal);
    }
    const calculateTotal = (walletBalanceValue, exchangesValues) => {
        let total = 0;
        if(walletBalanceValue) {
            total += walletBalanceValue.amount;
        }
        if(exchangesValues) {
            exchangesValues.map((exValue,i) => {
                total += exValue.total
            });
        }
        return total
    }
    return (
        <div className="card">
            <div className='row'>
                <div className='col-md-12'>
                    <div className='overflow-y-auto relative scrollable'>
                        <table className='table table-lg table-hover table-bordered-top'>
                            <thead>
                                <tr>
                                    <th width={140}>
                                        Currency
                                    </th>
                                    <th width={150}>
                                        Wallet Balance
                                    </th>
                                    {exchangeNames.map((name) => <th width={410}>{name}</th>)}
                                    <th style={{textAlign: 'right'}}>
                                        Total Balance
                                    </th>
                                </tr>
                            </thead>
                            {_.isEmpty(summary.currenciesTotal) ? (
                                <tbody>
                                    <tr>
                                        <td colSpan='6'>
                                            <Loading />
                                        </td>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    {summary.currenciesTotal.sort((a,b) => a.currency < b.currency ? -1 : 1).map(function(currency,i) {
                                        let exchangesValues = summary.exchangeBalances.map(exchange => exchange.balances.find(balance => balance?.currency === currency?.currency) ? exchange.balances.find(balance => balance?.currency === currency?.currency) : {available: 0, currency : currency?.currency, depositing: 0, frozen: 0,total: 0, withdrawing: 0});
                                        let walletBalanceValue = balances.data.walletBalances.find(el => el.currency === currency.currency);
                                        let allZeroValues = () => {
                                            let walletIsZero = false;
                                            let exchanesTotalsZero = false;
                                            let exchangesValuesZero = false;
                                            if((!walletBalanceValue || (walletBalanceValue && walletBalanceValue.amount === 0)) && props.showZero !== true) {
                                                walletIsZero = true;
                                            }
                                            
                                            if(!currency || (currency && currency.amount === 0)) {
                                                exchanesTotalsZero = true;
                                            }
                                            if(!exchangesValues) {
                                                exchangesValuesZero = true;
                                            } else {
                                                exchangesValues.map((eValue,i) => {
                                                    if(eValue.total === 0 && eValue.withdrawing === 0 && eValue.depositing === 0 && eValue.frozen === 0 && eValue.available === 0) {
                                                        exchangesValuesZero = true;
                                                    }
                                                })
                                            }
                                            if(walletIsZero && exchanesTotalsZero && exchangesValuesZero && props.showZero === false) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }
                                        let isAllZero = allZeroValues();
                                        if(isAllZero) return<></>;
                                        let currencyDetails = currencies.find(el => el.code === currency.currency);
                                        return (
                                        [
                                            <tr key={i}>
                                                <td className='v-align'>
                                                    <div>
                                                        <h5 style={{fontWeight: 'bold'}}>{CRYPTO_NAMES[currency.currency] ? CRYPTO_NAMES[currency.currency] : currency.currency}</h5>
                                                    </div>
                                                </td>
                                                <td className='v-align'>
                                                    <div>
                                                        <span style={{fontWeight: 'bold', textTransform: 'capitalize'}}>{currencyDetails && currencyDetails.currencyType === 'fiat' ? '-' : <NumberFormatter toFormat={walletBalanceValue ? walletBalanceValue.amount : 0} currCode={currency.currency} />}</span>
                                                    </div>
                                                </td>
                                                {exchangesValues.map((exValue,i) => {
                                                    let d = new Date();
                                                    let now = dateToIsoFull(d);
                                                    let result = getTimeDifference(now, summary.exchangeBalances[i].time);
                                                    let live = false;
                                                    if(result < timeDifference) {
                                                        live = true;
                                                    }
                                                    return (
                                                        <td className='v-align' style={{position: 'relative'}}>
                                                            <div className='flex-container' style={{'justify-content': 'space-between'}}>
                                                                <span>{<NumberFormatter toFormat={exValue ? exValue.available : 0} currCode={currency.currency} />}</span>
                                                                <div>
                                                                    {exValue.depositing ? (
                                                                        <Tooltip id={currency.currency + summary.exchangeBalances[i].exchange + 'depositing'} placement='top' text={`Depositing: ${exValue.depositing} ${currency.currency}`} custom={true}>
                                                                            <span style={{marginRight: 5}} className={'badge badge-secondary'}>Depositing</span>
                                                                        </Tooltip>
                                                                    ) : (<></>)}
                                                                    {exValue.frozen ? (
                                                                        <Tooltip id={currency.currency + summary.exchangeBalances[i].exchange + 'frozen'} placement='top' text={`Frozen amount: ${exValue.frozen} ${currency.currency}`} custom={true}>
                                                                            <span style={{marginRight: 5}} className={'badge badge-info'}>Frozen</span>
                                                                        </Tooltip>
                                                                    ) : (<></>)}
                                                                    {exValue.withdrawing ? (
                                                                        <Tooltip id={currency.currency + summary.exchangeBalances[i].exchange + 'withdrawing'} placement='top' text={`Withdrawing: ${exValue.withdrawing} ${currency.currency}`} custom={true}>
                                                                            <span style={{marginRight: 5}} className={'badge badge-warning'}>Withdrawing</span>
                                                                        </Tooltip>
                                                                    ) : (<></>)}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    )
                                                })}
                                                <td style={{textAlign: 'right'}}><b>{<NumberFormatter toFormat={calculateTotal(walletBalanceValue, exchangesValues)} currCode={currency.currency} />}</b></td>
                                            </tr>
                                        ]
                                    )
                                    })}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

BalancesTable.propTypes = {
    balances: PropTypes.object.isRequired
}

export default BalancesTable;