import {GET_ACCOUNT} from '../actions/account';

export default function(state = false, action) {
	switch(action.type) {
		case GET_ACCOUNT:
			if(action.payload.data) {
				if(action.payload.data.context?.viewType === 'ADMIN') {
					return true
				} else {
					return false
				}
			} return false
		default:
			return state;
	}

}