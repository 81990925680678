import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import {getBalances, resetBalances} from 'actions/balances'
import PropTypes from 'prop-types'
import Loading from 'components/ui_elements/loading'
import LoadingError from 'components/ui_elements/loading_error'
import NumberFormatter from 'components/ui_elements/number_formatter'
import ExchangesSummary from './exchanges_summary'
import BalancesTable from './balances_table'
import TotalLine from 'components/ui_elements/total_line'
import { lossStyle } from 'helpers/class_helper'
import { CRYPTO_NAMES } from 'helpers/crypto_names'

const BalancesSummary = ({showZero}) => {
    const dispatch = useDispatch();
    const balances = useSelector(state => state.balances);

    useEffect(()  => {
        dispatch(getBalances());
        return () => {dispatch(resetBalances())}
    }, []);

    let isEmpty = _.isEmpty(balances);
    let isError = balances === 'error';

    
    return (
        <div>
            {isEmpty|| isError ? (
                <div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-block">
                                    <div className="flex-container flex-full">
                                        {isEmpty &&
                                            <Loading />
                                        }
                                        {isError &&
                                            <LoadingError />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {balances.data.exchangeBalanceSummary &&
                        <BalancesTable balances={balances} showZero={showZero} />
                    }
                </div>
            )}
        </div>
    )
}

BalancesSummary.propTypes = {
    balances: PropTypes.object.isRequired,
    history: PropTypes.object
}


export default BalancesSummary;