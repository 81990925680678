import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '../ui_elements/tooltip'; 

function TotalLineLabel(props) {
    if(props.label.length > 0) {
        return (
            <span className="inline-block mrg-btm-5 font-size-13 text-semibold">{props.label} {props.tooltip && <Tooltip id={props.tooltipID} placement='top' text={props.tooltipText} />}</span>
        );
    }
    return '';
}

const TotalLine = (props) => {
    return (
        <div className={props.subtotal ? "mrg-right-30 min-width-210" : "mrg-right-30"}>
            <h3 className="no-mrg-btm min-height-34"><span className={props.currency && props.totalValue < 0 ? 'text-danger' : ''}>{props.totalValue}</span>
                {props.currency &&
                    <b className="font-size-12 mrg-left-5">{props.currency}</b>
                }
            </h3>
            <TotalLineLabel 
                label={props.label} 
                tooltip={props.tooltip}
                tooltipID={props.tooltipID}
                tooltipText={props.tooltipText}
            />
        </div>
    )
}

export const TotalLineParent = (props) => {
    return (
        <div className={props.subtotal ? "mrg-right-30 min-width-210" : "mrg-right-30"}>
            <h3 className="no-mrg-btm min-height-34"><span>{props.children}</span>
                {props.currency &&
                    <b className="font-size-12 mrg-left-5">{props.currency}</b>
                }
            </h3>
            <TotalLineLabel 
                label={props.label} 
                tooltip={props.tooltip}
                tooltipID={props.tooltipID}
                tooltipText={props.tooltipText}
            />
        </div>
    )
}

TotalLineLabel.propTypes = {
    label: PropTypes.string.isRequired,
    tooltip:PropTypes.bool,
    tooltipID: PropTypes.string,
    tooltipText: PropTypes.string,
}

TotalLine.propTypes = {
    currency: PropTypes.string,
    label: PropTypes.string.isRequired,
    subtotal: PropTypes.bool,
    tooltip:PropTypes.bool,
    tooltipID: PropTypes.string,
    tooltipText: PropTypes.string,
    totalValue: PropTypes.number.isRequired
}

export default TotalLine;