import { axios } from 'axiosInstance'
import { ENV } from "index"
import {getHeader} from '../helpers/headers_helper'

export const PUT_RETRY_CONVERSION = 'PUT_RETRY_CONVERSION'
export const RESET_RETRY_CONVERSION = 'RESET_RETRY_CONVERSION'

export function putRetryConversion(id) {
	const dataPayload = axios.put(`${ENV.API_URL}/v3/conversions/${id}/retry-trade`, {},  {
	    headers: getHeader()
	})
	return {
		type: PUT_RETRY_CONVERSION,
		payload: dataPayload
	}
}

export function resetRetryConversion() {
	const dataPayload = {}
	return {
		type: RESET_RETRY_CONVERSION,
		payload: dataPayload
	}
}