import React, {Component} from 'react';
import _ from 'lodash';
import $ from 'jquery';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'; 
import {putMerchantAccess, deleteMerchantAccess, updateMerchantAccess, resetMerchantAccess, resetDeletedMerchantAccess, resetUpdateMerchantAccess} from '../../actions/merchantAccess';
import {getMerchantsPaginated, resetMerchantsPaginated} from '../../actions/merchantsPaginated';
import {initAlert} from '../../helpers/alert_helper';
import MerchantAccessModal from './merchant_access_modal';
import Loading from '../ui_elements/loading';
import {updateInputState, handleModalFormSubmit} from '../../helpers/form_helper';

class MerchantAccessModalContainer extends Component {
    state = {
        assignForm: false,
        assignValues: {},
        disabledForm: false,
        editAssignForm: false,
        limit: 128,
        offset: 0,
        merchantId: '',
        merchantName: '',
        merchantsNamesLoaded: false,
        searchedMerchant: ''
    }
    componentDidUpdate(prevProps) {
        let newMerchantAccess = this.props.newMerchantAccess;
        let deletedMerchantAccess = this.props.deletedMerchantAccess;
        let updatedMerchantAccess = this.props.updatedMerchantAccess;
        if(this.state.disabledForm === true) {
            if(!_.isEmpty(newMerchantAccess)) {
                this.props.formSent(newMerchantAccess, this.props.userName);
                $('#merchantAccessModal').modal('hide');
                this.props.resetMerchantAccess();
            }
            if(!_.isEmpty(deletedMerchantAccess)) {
                this.props.formSentDeleted(deletedMerchantAccess, this.props.userName);
                $('#merchantAccessModal').modal('hide');
                this.props.resetDeletedMerchantAccess();
            }
            if(!_.isEmpty(updatedMerchantAccess)) { 
                this.props.formSentUpdated(updatedMerchantAccess, this.props.userName, this.state.assignValues.name, this.state.userRole);
                $('#merchantAccessModal').modal('hide');
                this.props.resetUpdateMerchantAccess();
            }
        }
        if(_.isEmpty(this.props.merchantsIdsPaginated) && this.props.isAdmin && this.state.merchantsNamesLoaded === false) {
            let params = {
                limit: this.state.limit,
                offset: this.state.offset,
                select: true
            };
            if(this.state.searchedMerchant !== '') {
                params.name = this.state.searchedMerchant;
            }
            this.props.getMerchantsPaginated(params);
            this.setState({
                merchantsNamesLoaded: true
            })
        }
        
    }
    deleteAccessMerchant(id,name) {
        let self = this;
        initAlert({
            type: 'warning', 
            title: 'Warning', 
            html: 
                'You are about to delete access to merchant ' +
                '<br />' +
                '<h4 class="mrg-btm-10 mrg-top-5"><b>' + name + '</b></h4>' +
                'from user ' +
                '<br />' +
                '<h4 class="mrg-btm-10 mrg-top-5"><b>' + this.props.userName + '</b></h4>' +
                '<br /' +
                'Do you want to continue?',
            confirmText: 'Yes, delete access', 
            onSuccess: function(){ self.handleDelete(id)}, 
            onDismiss: this.onCanceled
        });
    }
    onCanceled() {
        return
    }
    handleDelete(id) {
        this.props.deleteMerchantAccess({
            userId: this.props.userId,
            merchantId: id
        });
        this.setState({
            disabledForm: true
        })
    }
    editAccessMerchant(val) {
        this.setState({
            editAssignForm: true,
            assignValues: val
        })
    }
    selectMounted(el, id) {
        this.setState({
            [id]: el.value
        })
    }
    showAssignForm() {
        this.setState({
            assignForm: true
        })
    }
    hideAssignForm() {
        this.setState({
            assignForm: false,
            assignValues: {},
            editAssignForm: false,
            merchantId: '',
            merchantName: ''
        })
    }
    handleSelectInputChange(el) {
        if(this.state.searchedMerchant !== el) {
            this.setState({
                searchedMerchant: el
            })
            let params = {
                limit: this.state.limit,
                offset: this.state.offset,
                select: true
            };
            if(el !== '') {
                params.name = el;
            }
            this.props.getMerchantsPaginated(params);
        }
        
    }
    handleModalHide() {
        this.props.onModalClosed();
    }
    handleChange(event) {
        updateInputState(this, event);
    }
    handleSelectChange(val, e) {
        this.setState({ [e]: val});
    }
    handleSubmit(event) {
        let thisState = this.state;
        handleModalFormSubmit({
            self: this, 
            event: event,  
            states: {
                disabledForm: true
            }, 
            sendFunction: this.props.putMerchantAccess, 
            sendProps: {
               userId: this.props.userId, 
               merchantId: thisState.merchantId
            }
        });
    }
    handleUpdateSubmit(event) {
        let thisState = this.state;
        handleModalFormSubmit({
            self: this, 
            event: event,  
            states: {
                disabledForm: true
            }, 
            sendFunction: this.props.updateMerchantAccess, 
            sendProps: {
               userId: this.props.userId, 
               merchantId: thisState.assignValues.id,
               role: thisState.userRole
            }
        });
    }
    render() {
        let thisProps = this.props;
        let thisState = this.state;
        let showOnInit = thisProps.showOnInit || false;
        if(_.isEmpty(thisProps.merchantsIdsPaginated) && thisProps.isAdmin) {
            return <Loading />
        }
        let merchants = [];
        if(thisProps.isAdmin) {
            this.props.merchantsIdsPaginated.map(function(el, ind) {
                let result = _.find(thisProps.userMerchants, function(o) { return o.id === el.value; });
                if(!result) {
                    merchants.push(el);
                }
                return el;
            });
            if(merchants.length === 0) {
                //merchants.push({label:'', value:''});
            }
        }
        let formBlocked = false;
        if(_.isEmpty(thisState.assignValues) && merchants.length === 0) {
            formBlocked = true;
        }
        return ( 
            <MerchantAccessModal 
                assignForm={thisState.assignForm}
                assignValues={thisState.assignValues}
                deleteAccessMerchant={this.deleteAccessMerchant.bind(this)}
                disabledForm={thisState.disabledForm} 
                editAccessMerchant={this.editAccessMerchant.bind(this)}
                editAssignForm={thisState.editAssignForm}
                formBlocked={formBlocked}
                handleChange={this.handleChange.bind(this)} 
                handleModalHide={this.handleModalHide.bind(this)} 
                handleSelectChange={this.handleSelectChange.bind(this)} 
                handleSelectInputChange={this.handleSelectInputChange.bind(this)}
                handleSubmit={this.handleSubmit.bind(this)}
                handleUpdateSubmit={this.handleUpdateSubmit.bind(this)}
                hideAssignForm={this.hideAssignForm.bind(this)}
                isAdmin={thisProps.isAdmin}
                isMerchantAdmin={thisProps.isMerchantAdmin}
                merchantsIds={merchants} 
                selectMounted={this.selectMounted.bind(this)} 
                showAssignForm={this.showAssignForm.bind(this)}
                showOnInit={showOnInit} 
                userMerchants={thisProps.userMerchants}
                userName={thisProps.userName} 
                users={thisProps.users} 
            />
        )
    }
}

MerchantAccessModalContainer.propTypes = {
    formSent: PropTypes.func.isRequired,
    formSentDeleted: PropTypes.func.isRequired,
    formSentUpdated: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
    onModalClosed: PropTypes.func.isRequired,
    showOnInit: PropTypes.bool.isRequired,
    userMerchants: PropTypes.array.isRequired,
    userName: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired
}

function mapStateToProps(state) { 
    return { 
        deletedMerchantAccess: state.deletedMerchantAccess,
        merchantsIdsPaginated: state.merchantsIdsPaginated,
        merchantsPaginated: state.merchantsPaginated,
        newMerchantAccess: state.newMerchantAccess,
        updatedMerchantAccess: state.updatedMerchantAccess,
    } 
} 

export default connect(mapStateToProps, {
    getMerchantsPaginated,
    resetMerchantsPaginated,
    putMerchantAccess,
    updateMerchantAccess,
    deleteMerchantAccess,
    resetMerchantAccess,
    resetDeletedMerchantAccess,
    resetUpdateMerchantAccess
})(MerchantAccessModalContainer);