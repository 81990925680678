import {GET_TRANSACTIONS_HISTORY, RESET_TRANSACTIONS_HISTORY} from 'actions/transactionsHistory';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_TRANSACTIONS_HISTORY:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}

			if(action.meta.before == null || action.meta.before == undefined) {
				return {data: action.payload, meta: action.meta}
			} else {
				return {data: {...action.payload, data: {items: [...state.data.data.items, ...action.payload.data.items], paginationInfo: {...action.payload.data.paginationInfo, itemsCount: state.data.data.paginationInfo.itemsCount}}}, meta: action.meta}
			}
			
		case RESET_TRANSACTIONS_HISTORY:
			return action.payload
		default:
			return state;
	}

}