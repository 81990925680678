import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from 'components/ui_elements/tooltip'


const DataPresentationRow = (props) => {
    return (
        <div className='row text-data-presentation'>
            <div className={props.large ? 'col-md-6' : 'col-md-4'}>
                <label>{props.name}{props.emptyLabel !== true ? ':' : ''}{props.tooltip &&<Tooltip id='dataPresentationTooltip' placement='top' text={props.tooltipText} customClass={props.tooltipClass} />}</label>
            </div>
            <div className={props.large ? 'col-md-6 no-pdd-left' : 'col-md-8 no-pdd-left'}>
                {props.children}
            </div>
        </div>
    )
}


DataPresentationRow.propTypes = {
    children: PropTypes.node.isRequired,
    emptyLabel: PropTypes.bool,
    tooltip: PropTypes.bool,
    tooltipText: PropTypes.string,
    tooltipClass: PropTypes.string,
    name: PropTypes.string.isRequired
}

export default DataPresentationRow;