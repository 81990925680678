import {GET_LIVE_BALANCE_MERCHANT, RESET_LIVE_BALANCE_MERCHANT} from 'actions/liveBalanceMerchant';
import {setupRollbar} from 'helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_LIVE_BALANCE_MERCHANT:
			if(action.error) {
				if(action.payload.response?.status === 404 || action.payload.response?.status === 403) {
					return {data: {}, status: 200};
				}
				setupRollbar(action);
				return 'error'
			}
			return {data: action.payload.data, status: action.payload.status};
		case RESET_LIVE_BALANCE_MERCHANT: 
			return action.payload;
		default:
			return state;
	}

}