import { axios } from 'axiosInstance'
import {dateNextDay} from 'helpers/date_helper'
import {getHeader} from 'helpers/headers_helper'
import { ENV } from "index"

export const GET_TRANSACTIONS_ALL_HISTORY = 'GET_TRANSACTIONS_ALL_HISTORY'
export const RESET_TRANSACTIONS_ALL_HISTORY = 'RESET_TRANSACTIONS_ALL_HISTORY'

export function getTransactionsAllHistory({coinAmountFrom, coinAmountTo, fiatAmountFrom, fiatAmountTo, paymentId, txid, from, to, merchantId, address, limit, offset, shownFilters, allResults, status}) {
    let type = GET_TRANSACTIONS_ALL_HISTORY
    let paramsData = new URLSearchParams()
    if(from !== undefined) {
        paramsData.append("paymentTimeStart", from + 'T00:00:00')
    }
    if(to !== undefined) {
        let nextDay = dateNextDay(to);
        paramsData.append("paymentTimeEnd", nextDay + 'T00:00:00')
    }
    if(Array.isArray(status)) {
        status.map(function(val,i) {
            paramsData.append("status", val.value)
            return val;
        })
    } else {
        paramsData.append("status", status)
    }
    let params = {
        'from': from,
        'to': to,
        'shownFilters': shownFilters
    }
    let otherParams = [{'coinAmountFrom': coinAmountFrom}, {'coinAmountTo': coinAmountTo}, {'fiatAmountFrom': fiatAmountFrom}, {'fiatAmountTo': fiatAmountTo}, {'paymentId': paymentId}, {'txid': txid}, {'merchantId': merchantId}, {'address': address}, {'limit': limit}, {'offset': offset}]
    otherParams.map(function(val,i){
        let key = Object.keys(val)[0]
        if(key === 'offset') {
            return val
        } else {
            if(key === 'limit') {
                paramsData.append("limit", allResults);
                params.limit = allResults
            } else {
                let value;
                if(key === 'merchantId') {
                    if(val[key] !== undefined) {
                        if (val[key].value === '') {
                            return val
                        } else {
                            value = val[key]
                        }
                    }
                } else {
                    value = val[key]
                }
                if(value !== '' && value !== undefined) {
                    paramsData.append(key, value)
                    params[key] = value
                }
            }
        }
        return val
    })
    let data = {
        headers: getHeader(),
        params: paramsData
    }
    const dataPayload = axios.get(`${ENV.API_URL}/ui/transactions/list`, data)

    return {
        type: type,
        payload: dataPayload,
        meta: params
    }
}

export function resetTransactionsAllHistory() {
    let type = RESET_TRANSACTIONS_ALL_HISTORY
	const dataPayload = {data: {status: 200}}
	return {
		type: type,
		payload: dataPayload
	}
}