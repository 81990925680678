import React from 'react';
import PropTypes from 'prop-types';

const NavItemTab = (props) => {
    return (
        <li className='nav-item' onClick={props.disabled ? (() => false) : (() => props.handleClick(props.name))}>
            <a 
            	className={props.active === props.id ? 'nav-link active' : 'nav-link'} 
            	id={props.idText} 
            	role='tab' 
                style={{
                    cursor: props.disabled ? 'default' : 'pointer'
                }}
            >
                {props.name}
                <br />
                <span>{props.subText}</span>
            </a>
        </li>
    )
}


NavItemTab.propTypes = {
    id: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    active: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    hrefText: PropTypes.string.isRequired,
    idText: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    subText: PropTypes.string,
    style: PropTypes.object
}

export default NavItemTab;