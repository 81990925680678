import {GET_UNSOLD, RESET_UNSOLD} from '../actions/unsoldTransactions';
import {setupRollbar} from '../helpers/rollbar_helper';

export default function(state = {}, action) {
	switch(action.type) {
		case GET_UNSOLD:
			if(action.error) {
				setupRollbar(action);
				return 'error'
			}
			return {data: action.payload.data, status: action.payload.status};
		case RESET_UNSOLD:
			return action.payload;
		default:
			return state;
	}

}